import { combineReducers } from 'redux';

import { createReducer, fetchingReducer } from '../../utils';
import * as Types from './types';

const dataRoomLinkReducer = () =>
  createReducer({})({
    [Types['SET_LINK']]: (state, { link }) => link,
  });

const roomFeatures = createReducer({})({
  [Types['SET_FEATURES_FOR_ROOM']]: (state, { payload, key }) => ({
    ...state,
    [key]: [...payload],
  }),
});

const roomReducer = createReducer({})({
  [Types['SET_ROOM']]: (state, { payload, accessCode }) => ({
    ...state,
    [accessCode]: {
      ...payload,
    },
  }),
  [Types['SET_ROOM_TOKEN']]: (state, { payload, accessCode }) => ({
    ...state,
    [accessCode]: {
      ...state[accessCode],
      ...payload,
    },
  }),
});

export default combineReducers({
  dataRoomLink: dataRoomLinkReducer(),
  room: roomReducer,
  roomFeatures,
  isFetching: fetchingReducer('data'),
});
