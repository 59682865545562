import { RSAA } from 'redux-api-middleware';

import { get, isEqual as _isEqual, lowerCase } from 'lodash-es';

// import NormalizationService from './utils';
import { requestHeaders } from '../../utils/api';
import { getTransactionQuestionnaire } from '../Transaction/operations';
import { setNotice } from '../UI/actions';
import {
  setFeature,
  setFeatures,
  patchFeature as patchFeatureAction,
  setAccountInfo,
  setResourcesByTypeId,
  setResourceById,
  removeIndividual,
  updateModelByKey,
} from './actions';
import * as Types from './types';

export const getFlatFeatures = (body, slug) => {
  const REQUEST = Types[`GET_FEATURES_REQUEST`];
  const SUCCESS = Types[`GET_FEATURES_SUCCESS`];
  const FAILED = Types[`GET_FEATURES_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/user/flat_feature/query',
        method: 'POST',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        body: () => JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      dispatch(setFeatures(actionResponse.payload, slug));
    }
  };
};

export const createFeature = (body, individualName, featureName, isCompany) => {
  return async (dispatch, getState) => {
    const REQUEST = Types[`CREATE_FEATURE_REQUEST`];
    const SUCCESS = Types[`CREATE_FEATURE_SUCCESS`];
    const FAILED = Types[`CREATE_FEATURE_FAILED`];
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/flat_feature`,
        method: 'POST',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        body: () => JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      if (!isCompany) {
        dispatch(setFeature(actionResponse.payload, individualName, featureName));
      }
      dispatch(setNotice(`${individualName}'s ${lowerCase(featureName)} created.`));
    } else {
      dispatch(setNotice({ type: 'error', message: actionResponse.payload.message }));
    }
  };
};

export const patchFeature = (body, individualName, featureName, isCompany) => {
  return async (dispatch, getState) => {
    const REQUEST = Types[`PATCH_FEATURE_REQUEST`];
    const SUCCESS = Types[`PATCH_FEATURE_SUCCESS`];
    const FAILED = Types[`PATCH_FEATURE_FAILED`];
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/account/flat_feature',
        method: 'POST',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        body: () => JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      if (!isCompany) {
        dispatch(patchFeatureAction(actionResponse.payload, individualName, featureName));
      }
      dispatch(setNotice(`${individualName}'s ${lowerCase(featureName)} updated.`));
      return await actionResponse.payload;
    } else {
      dispatch(setNotice({ type: 'error', message: actionResponse.payload.message }));
      return await actionResponse.payload;
    }
  };
};

export const updateResourceFeatures = (
  values,
  resourceFeatures,
  featureTypes,
  resource_id,
  individualName,
  isCompany = true,
) => {
  return async dispatch => {
    return Object.entries(values)
      .reduce(async (previousPromise, [key, value]) => {
        return await previousPromise.then(
          e => {
            let isEqual = true;
            let updatedVal = value;
            const originalVal = get(resourceFeatures, `[${key}].value`, '');
            const featureTypeIndex = featureTypes.findIndex(
              e => e.feature_type.name === key,
            );
            const feature_type_id = featureTypes[featureTypeIndex].feature_type_id;
            const feature_type = featureTypes[featureTypeIndex].feature_type.feature_type;
            if (feature_type === 'document') {
              return Promise.resolve(100);
            } else if (key === 'company_signatory') {
              if (
                value.constructor === Object &&
                !_isEqual(value.label, resourceFeatures[key].value)
              ) {
                dispatch(updateCompanySignatory(value.resource_id));
                return dispatch(
                  createFeature(
                    {
                      value: value.label,
                      feature_type_id,
                      resource_id,
                    },
                    individualName,
                    key,
                    isCompany,
                  ),
                );
              }
            } else if (feature_type === 'boolean') {
              updatedVal = value === '0' || value === 'false' || !value ? '0' : '1';
            } else if (Array.isArray(value)) {
              updatedVal = value.map(val => val.value).join('|');
            } else if (value.constructor === Object && value.value) {
              updatedVal = value.value;
            }

            if (!_isEqual(originalVal, updatedVal)) {
              isEqual = false;
            }

            if (updatedVal.constructor === Object) {
              const {
                line_one = '',
                line_two = '',
                city = '',
                state = '',
                zip = '',
                country = '',
              } = value;
              const origAddress =
                originalVal.match(
                  /(.*)\n(.*)\n(.*), ([a-z\s]*) ([0-9]*)( \n([a-z\s]*))?/i,
                ) || [];
              const origLineOne = get(origAddress, '[1]', '');
              const origLineTwo = get(origAddress, '[2]', '');
              const origCity = get(origAddress, '[3]', '');
              const origState = get(origAddress, '[4]', '');
              const origZip = get(origAddress, '[5]', '');
              const origCountry = get(origAddress, '[7]', '');

              if (
                origLineOne === line_one &&
                origLineTwo === line_two &&
                origCity === city &&
                origState === state &&
                origZip === zip &&
                (origCountry === country || (!line_one && !line_two && !city && !state))
              ) {
                isEqual = true;
              } else {
                isEqual = false;
                updatedVal = `${line_one} \n${line_two} \n${city}, ${
                  state.constructor === Object ? state.value : state
                } ${zip}`;
                if (country) {
                  updatedVal += ` \n${
                    country.constructor === Object ? country.value : country
                  }`;
                }
              }
            }

            if (!isEqual) {
              return dispatch(
                patchFeature(
                  {
                    value: updatedVal.trim(),
                    feature_type_id,
                    resource_id,
                  },
                  individualName,
                  key,
                  isCompany,
                ),
              );
            }
            return Promise.resolve(100);
          },
          error => {
            console.warn('error', error);
            return Promise.resolve();
          },
        );
      }, Promise.resolve(100))
      .then(e => e);
  };
};

export const getAccountInfo = companyId => {
  const REQUEST = Types[`GET_ACCOUNT_INFO_REQUEST`];
  const SUCCESS = Types[`GET_ACCOUNT_INFO_SUCCESS`];
  const FAILED = Types[`GET_ACCOUNT_INFO_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/account/me',
        method: 'get',
        headers: requestHeaders(true, companyId),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      return await dispatch(setAccountInfo(actionResponse.payload));
    }
    throw await get(actionResponse, 'payload.message', 'An error has occurred.');
  };
};

export const patchAccountInfo = (body, moduleId) => {
  const REQUEST = Types[`PATCH_ACCOUNT_INFO_REQUEST`];
  const SUCCESS = Types[`PATCH_ACCOUNT_INFO_SUCCESS`];
  const FAILED = Types[`PATCH_ACCOUNT_INFO_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/account/me',
        method: 'PATCH',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        body: () => JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      dispatch(setAccountInfo(actionResponse.payload));
    }
  };
};

export const updateCompanySignatory = signatoryId => {
  const REQUEST = Types[`UPDATE_COMPANY_SIGNATORY_REQUEST`];
  const SUCCESS = Types[`UPDATE_COMPANY_SIGNATORY_SUCCESS`];
  const FAILED = Types[`UPDATE_COMPANY_SIGNATORY_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/signatory/${signatoryId}`,
        method: 'PATCH',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    return await actionResponse.payload;
  };
};

export const fetchResourcesByTypeId = resourceTypeId => {
  const REQUEST = Types[`GET_RESOURCES_BY_TYPE_ID_REQUEST`];
  const SUCCESS = Types[`GET_RESOURCES_BY_TYPE_ID_SUCCESS`];
  const FAILED = Types[`GET_RESOURCES_BY_TYPE_ID_FAILED`];

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/resource/?resource_type_id=${resourceTypeId}`,
        method: 'GET',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      dispatch(setResourcesByTypeId(actionResponse.payload.results, resourceTypeId));
      return await actionResponse.payload;
    } else {
      dispatch(
        setNotice({
          type: 'error',
          message: get(actionResponse, 'payload.message', 'An error has occurred.'),
        }),
      );
    }
  };
};

export const fetchResourceId = (resourceId, resourceTypeId) => {
  const REQUEST = Types[`GET_RESOURCE_BY_ID_REQUEST`];
  const SUCCESS = Types[`GET_RESOURCE_BY_ID_SUCCESS`];
  const FAILED = Types[`GET_RESOURCE_BY_ID_FAILED`];

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/resource/${resourceId}`,
        method: 'GET',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      dispatch(setResourceById(actionResponse.payload, resourceTypeId));
      return await actionResponse.payload;
    } else {
      dispatch(
        setNotice({
          type: 'error',
          message: get(actionResponse, 'payload.message', 'An error has occurred.'),
        }),
      );
    }
  };
};

export const deleteIndividual = (resourceId, resourceName, transactionId) => {
  const REQUEST = Types[`DELETE_INDIVIDUAL_REQUEST`];
  const SUCCESS = Types[`DELETE_INDIVIDUAL_SUCCESS`];
  const FAILED = Types[`DELETE_INDIVIDUAL_FAILED`];

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/resource/${resourceId}`,
        method: 'DELETE',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      if (!transactionId) {
        dispatch(removeIndividual(resourceId));
        dispatch(setNotice(`${resourceName} removed from our system.`));
      } else {
        dispatch(setNotice(`${resourceName} removed from our system.`));
        dispatch(getTransactionQuestionnaire(transactionId));
      }
    } else {
      dispatch(
        setNotice({
          type: 'error',
          message: `error removing ${resourceName}`,
        }),
      );
    }
  };
};

export const setLabel = (key, value) => {
  const REQUEST = Types[`SET_LABEL_REQUEST`];
  const SUCCESS = Types[`SET_LABEL_SUCCESS`];
  const FAILED = Types[`SET_LABEL_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/tasks/${key}/label`,
        method: 'post',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        body: () => JSON.stringify({ label: value }),
      },
    });
    if (!actionResponse.error) {
      // const normalizedData = normalizer.camelize(actionResponse.payload);
      // dispatch(updateModelByKey(normalizedData, 'task'));
    } else {
    }
  };
};

export const finalizeTask = (key, myModule, questionnaireId, push) => {
  const REQUEST = Types[`FINALIZE_TASK_REQUEST`];
  const SUCCESS = Types[`FINALIZE_TASK_SUCCESS`];
  const FAILED = Types[`FINALIZE_TASK_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/tasks/${key}/finalize`,
        method: 'post',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        // body: () => JSON.stringify(values),
      },
    });
    if (!actionResponse.error) {
      localStorage.removeItem(`task-${key}-currentSubtask`);
      localStorage.removeItem(`task-${key}-questionnaire-${questionnaireId}`);

      push(`/workbench/${myModule}/${key}/success`);
      dispatch(updateModelByKey(actionResponse.payload, 'task'));
    } else {
      dispatch(setNotice({ type: 'error', message: actionResponse.payload.message }));
    }
  };
};
