import { combineReducers } from 'redux';

import { apiErrorsReducer, createReducer, fetchingReducer } from '../../utils';
import {
  ADD_ACH_SUCCESS,
  ADD_METHOD_SUCCESS,
  GET_METHODS_SUCCESS,
  MERGE_SUBSCRIPTION,
  REMOVE_METHOD,
  SET_DEFAULT_SUCCESS,
  SET_INVOICE,
  SET_SUBSCRIPTION_PREVIEW,
  VERIFY_ACH_SUCCESS,
} from './types';

const isFetching = fetchingReducer('billing');

const methodsReducer = createReducer([])({
  [GET_METHODS_SUCCESS]: (state, { payload }) => {
    return payload.methods;
  },
  [ADD_METHOD_SUCCESS]: (state, { payload }) => payload.methods,
  [SET_DEFAULT_SUCCESS]: (state, { payload }) => payload.methods,
  [REMOVE_METHOD]: (state, { payload }) =>
    state.filter(method => method.id !== payload.methodId),
});

const defaultMethodReducer = createReducer('')({
  [GET_METHODS_SUCCESS]: (state, { payload }) => payload.default,
  [ADD_METHOD_SUCCESS]: (state, { payload }) => payload.default,
  [SET_DEFAULT_SUCCESS]: (state, { payload }) => payload.default,
});

const invoiceReducer = createReducer([])({
  [SET_INVOICE]: (state, { payload }) => payload,
});

const subscriptionReducer = createReducer({})({
  [MERGE_SUBSCRIPTION]: (state, { payload }) => ({
    ...state,
    ...payload,
  }),
});

const subscriptionPreviewReducer = createReducer({})({
  [SET_SUBSCRIPTION_PREVIEW]: (state, { payload }) => payload,
});

const achReducer = createReducer({})({
  [ADD_ACH_SUCCESS]: (state, { payload }) => ({ ...state, payload }),
  [VERIFY_ACH_SUCCESS]: (state, { payload }) => ({ ...state, payload }),
});

const isSynced = createReducer(false)({});

export default combineReducers({
  isFetching,
  methods: methodsReducer,
  invoices: invoiceReducer,
  subscription: subscriptionReducer,
  subscriptionPreview: subscriptionPreviewReducer,
  ach: achReducer,
  apiErrors: apiErrorsReducer('billing'),
  defaultMethod: defaultMethodReducer,
  isSynced,
});
