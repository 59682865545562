import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { updateRoom } from '../../../redux/modules/Data/operations';

import { Button } from '../../../components/common';
import { getRoomWithUpdatedFeatures } from '../../../redux/modules/Data/selectors';
import { getAuthUser } from '../../../redux/modules/User/selectors';
import ProjectHeader from '../../ProjectHeader';
import handlePath from '../../../utils/handlePath';
import { SidebarNavLinkCompany } from '..';

const RoomNav = ({ isAuthorized, isEditing }) => {
  const dispatch = useDispatch();
  const {
    url,
    params: { room_access_code },
  } = useRouteMatch();

  const dataRoom = useSelector(state =>
    getRoomWithUpdatedFeatures(state, room_access_code),
  );
  const currentUser = useSelector(getAuthUser);
  const { userId } = currentUser;
  const {
    outside_forms = [],
    room: { account_id, account_project_id, id, label, users = [] } = {},
  } = dataRoom;
  const isSettingsShowing =
    isAuthorized || users.findIndex(e => e.id === userId && e.invite_rights) !== -1;

  const handleUpdateProjectName = label =>
    dispatch(updateRoom({ label }, id, room_access_code, account_id));

  return (
    <div className="sidebar__nav sidebar__nav--roomnav">
      {isAuthorized && <SidebarNavLinkCompany isWorkbench />}
      {!isAuthorized && <div className="sidebar__nav-goto-transaction" />}
      <ProjectHeader
        handleUpdateProjectName={handleUpdateProjectName}
        isEditingAllowed={isAuthorized && isEditing}
        icon={['fal', 'home']}
        projectLabel={label}
        roomId={id}
      />
      <NavLink
        activeClassName="workbench-nav__link--selected"
        className="workbench-nav__link"
        to={url}
        exact
      >
        <div className="workbench-nav__link-padding" />
        <div className="workbench-nav__link-items">
          <h4>Data Room</h4>
        </div>
      </NavLink>
      {outside_forms.map(form => (
        <NavLink
          key={`outside-form-${form.questionnaire.access_code}`}
          activeClassName="workbench-nav__link--selected"
          className="workbench-nav__link"
          to={`${url}/outside-form/${form.questionnaire.access_code}`}
        >
          <div className="workbench-nav__link-padding" />
          <div className="workbench-nav__link-items">
            <h4>{form.questionnaire.name}</h4>
          </div>
        </NavLink>
      ))}
      {isSettingsShowing && (
        <NavLink
          activeClassName="workbench-nav__link--selected"
          className="workbench-nav__link"
          to={`${url}/settings`}
        >
          <div className="workbench-nav__link-padding" />
          <div className="workbench-nav__link-items">
            <h4>Sharing</h4>
          </div>
        </NavLink>
      )}
      {/* <NavLink
          activeClassName="workbench-nav__link--selected"
          className="workbench-nav__link"
          to={`${url}/share-documents`}
        >
          <div className="workbench-nav__link-padding" />
          <div className="workbench-nav__link-items">
            <h4>Share Documents</h4>
          </div>
        </NavLink> */}
      {isAuthorized && (
        <Button
          buttonType="link"
          className="sidebar__exit"
          to={handlePath(`/workbench/0/${account_project_id}/-1/overview`, account_id)}
        >
          Go to connected Project
          <FontAwesomeIcon icon={['fal', 'chevron-right']} />
        </Button>
      )}
    </div>
  );
};

export default RoomNav;
