import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import './Dropdown.scss';

export default ({ children, className = '', onClose, isDefaultPrevented }) => {
  useEffect(() => {
    window.addEventListener('click', onClose);
    return () => window.removeEventListener('click', onClose);
  }, [onClose]);
  return (
    <div
      className={`dropdown ${className}`}
      onClick={e => {
        if (isDefaultPrevented) {
          onClose(e);
        }
      }}
    >
      {children}
    </div>
  );
};

export const DropdownAction = ({ children, className, left, upward, Toggle }) => {
  const [isShowing, setIsShowing] = useState(false);

  useEffect(() => {
    const handleClose = () => {
      setIsShowing(false);
    };
    if (isShowing) {
      window.addEventListener('click', handleClose);
    }
    return () => window.removeEventListener('click', handleClose);
  }, [isShowing, setIsShowing]);

  return (
    <div className={`dropdown__wrapper ${className}`}>
      <Toggle
        onClick={e => {
          e.preventDefault();
          setIsShowing(!isShowing);
        }}
      />
      {isShowing && (
        <div
          className={classNames('dropdown', {
            'dropdown--left': left,
            'dropdown--upward': upward,
          })}
          onClick={e => {
            e.preventDefault();
            setIsShowing(false);
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
};
