import '../components/FileUploader/FileUploader';

import React from 'react';

import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';

import {
  AddressGroup,
  CheckboxGroup,
  Comment,
  FormCustomDoc,
  FormFileUploader,
  Password,
  PhoneNumber,
  Select,
  ShareLinks,
  StyledCheckbox,
  TemplateLink,
  TextField,
  Textarea,
  ResourceList,
} from '../components/formik';
import { options } from '../data/StateData';
import { parseShareLink } from '../components/formik/ShareLinks/ShareLinks';

export const validateAddress = (val, isUniversal) => {
  let error;
  if (
    isEmpty(val) ||
    (isEmpty(val.line_one) && isEmpty(val.city) && isEmpty(val.state) && isEmpty(val.zip))
  ) {
    error = {
      ...error,
      address: 'Full Address required',
    };
  }
  if (isEmpty(val.line_one)) {
    error = {
      ...error,
      line_one: 'Line One required',
    };
  }
  if (isEmpty(val.city)) {
    error = {
      ...error,
      city: 'City required',
    };
  }
  if (isEmpty(val.state)) {
    error = {
      ...error,
      state: 'State required',
    };
  }
  if (isEmpty(val.zip)) {
    error = {
      ...error,
      zip: 'Zip required',
    };
  }
  if (isEmpty(val.country) && isUniversal) {
    error = {
      ...error,
      country: 'Country required',
    };
  }
  return error;
};

export const validateBoolean = value => {
  let error;
  if (!value) {
    error = 'Selection required';
  }
  return error;
};

export const validateCheckboxGroup = value => {
  let error;
  const trimmedVal = value ? value.trim() : '';
  if (isEmpty(trimmedVal)) {
    error = 'Selection required';
  }
  return error;
};

export const validateCurrency = val => {
  let error;
  const trimmedVal = val.trim();
  if (isEmpty(trimmedVal)) {
    error = 'Currency required';
    return error;
  }
  if (trimmedVal.length < 2) {
    error = 'Currency required';
  }
  return error;
};

export const validateDate = (val, isOptional, isTouched) => {
  let error;
  const trimmedVal = val.trim();
  let valArr = [];
  if (val.indexOf('-') !== -1) {
    valArr = val.split('-');
  } else if (val.indexOf('/') !== -1) {
    valArr = val.split('/');
  }

  if (isEmpty(trimmedVal)) {
    if (!isOptional) {
      error = 'Date required';
    }
    return error;
  }
  // if (!isTouched) {
  //   return error;
  // }
  if (valArr[0] && valArr[0].length < 2) {
    error = 'Full date required';
  }
  if (!valArr[1] || (valArr[1] && valArr[1].length < 2)) {
    error = 'Month and year required';
  } else if (!valArr[2] || (valArr[2] && valArr[2].length < 4)) {
    error = 'Full 4 digit year required.';
  }
  if (trimmedVal === 'Invalid date') {
    error = 'Valid date required';
  }
  return error;
};

export const validateEmail = value => {
  let error;
  if (isEmpty(value)) {
    error = 'Email required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
    error = 'Invalid email address';
  }
  return error;
};

export const validateMonth = val => {
  let error;
  const trimmedVal = val.trim();
  const valArr = val.split('-');
  if (isEmpty(trimmedVal)) {
    error = 'Month and Year required';
    return error;
  }
  if (valArr[0].length !== 2) {
    error = 'Full date required';
  } else if (!valArr[1] || (valArr[1] && valArr[1].length < 4)) {
    error = 'Full 4 digit year required';
  }
  return error;
};

export const validatePassword = value => {
  let error;
  const trimmedVal = value.trim();
  if (isEmpty(trimmedVal)) {
    error = 'Field required';
  } else if (trimmedVal.length < 6) {
    error = 'Password must be longer than 6 characters.';
  }
  return error;
};

export const validatePhone = value => {
  let error;
  const trimmedVal = value.trim();
  if (isEmpty(trimmedVal)) {
    error = 'Field required';
  } else if (trimmedVal.includes('+1') && trimmedVal.length < 17) {
    error = 'Full phone number required.';
  }
  return error;
};

export const validateSalary = val => {
  let error;
  const trimmedVal = val.trim();
  // eslint-disable-next-line
  let parsedVal = trimmedVal.replace(/[^0-9|\.]/g, '');
  if (isEmpty(parsedVal)) {
    error = 'Salary required';
    return error;
  }
  if (parsedVal.length < 2) {
    error = 'Salary required';
  }
  return error;
};

export const validateSelect = value => {
  let error;
  if (Array.isArray(value)) {
    const trimmedArr = value.map(e => get(e, 'label', e).trim());
    if (isEmpty(trimmedArr)) {
      error = 'Field required';
    }
  } else {
    const trimmedVal = value ? get(value, 'label', value + '').trim() : '';
    if (isEmpty(trimmedVal)) {
      error = 'Field required';
    }
  }
  return error;
};

export const validateShareLinks = value => {
  let error;
  let updatedValue = value;
  if (!Array.isArray(value)) {
    updatedValue = parseShareLink(value);
  }
  if (Array.isArray(updatedValue)) {
    updatedValue.forEach(e => {
      if (!e.label || !e.url) {
        error = 'Incomplete Link Input';
      }
    });
  }
  if (isEmpty(updatedValue)) {
    error = 'Link required';
  }
  return error;
};

export const validateSocialSecurity = value => {
  let error;
  const trimmedVal = value.trim();
  if (isEmpty(trimmedVal)) {
    error = 'Field required';
  } else if (trimmedVal.length < 9) {
    error = 'Full social security required.';
  }
  return error;
};

export const validateString = value => {
  let error;
  const trimmedVal = value ? value.trim() : '';
  if (isEmpty(trimmedVal)) {
    error = 'Field required';
  }
  return error;
};

export const validateZip = value => {
  let error;
  const trimmedVal = value ? value.trim() : '';
  if (isEmpty(trimmedVal)) {
    error = 'Field required';
  }
  return error;
};

export default {
  attorney: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => <Select {...props} />,
    validate: validateSelect,
  },
  attorney_lane: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => <Select {...props} />,
    validate: validateSelect,
  },
  address: {
    value: {
      line_one: '',
      line_two: '',
      city: '',
      state: '',
      zip: '',
    },
    gridColWidth: 'minMax(140px, 2fr)',
    Formik: ({ ...props }) => <AddressGroup options={options} {...props} />,
    validate: validateAddress,
  },
  address_universal: {
    value: {
      line_one: '',
      line_two: '',
      city: '',
      state: '',
      zip: '',
      country: '',
    },
    gridColWidth: 'minMax(140px, 2fr)',
    Formik: ({ ...props }) => <AddressGroup isUniversal options={options} {...props} />,
    validate: val => validateAddress(val, true),
  },
  boolean: {
    value: false,
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => <StyledCheckbox {...props} />,
    validate: validateBoolean,
  },
  checkbox_group: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => <CheckboxGroup type="checkbox" {...props} />,
    validate: validateCheckboxGroup,
  },
  comment: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: props => <Comment {...props} />,
  },
  currency: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => (
      <TextField myType="currency" defaultPlaceholder="E.g., $15.99" {...props} />
    ),
    validate: validateCurrency,
  },
  detailed_options: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => <Select {...props} multiEntry={true} />,
    validate: validateSelect,
  },
  document: {
    value: '',
    gridColWidth: 'minMax(140px, 1.33fr)',
    Formik: ({ ...props }) => <FormFileUploader {...props} />,
    validate: validateString,
  },
  date: {
    value: '',
    gridColWidth: 'minMax(112px, 1fr)',
    Formik: ({ ...props }) => (
      <TextField myType="date" defaultPlaceholder="E.g., 03-21-1991" {...props} />
    ),
    validate: validateDate,
  },
  email: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => <TextField type="email" {...props} />,
    validate: validateEmail,
  },
  template_document: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => <TemplateLink {...props} />,
  },
  first_name: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => <TextField {...props} />,
    validate: validateString,
  },
  float: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => <TextField {...props} myType="float" />,
    validate: validateString,
  },
  float_currency: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => (
      <TextField myType="float_currency" defaultPlaceholder="E.g., $0.00001" {...props} />
    ),
    validate: validateCurrency,
  },
  Individual: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => <TextField {...props} />,
    validate: validateSelect,
  },
  integer: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => <TextField myType="integer" {...props} />,
    validate: validateString,
  },
  last_name: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => <TextField {...props} />,
    validate: validateString,
  },
  month: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => (
      <TextField myType="month" defaultPlaceholder="E.g., 07-2019" {...props} />
    ),
    validate: validateMonth,
  },
  name: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => (
      <TextField defaultPlaceholder="E.g., Henry Weinhurst" {...props} />
    ),
    validate: validateSelect,
  },
  number: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => <TextField {...props} myType="float" />,
    validate: validateString,
  },
  options: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => (
      <Select defaultPlaceholder="Type to search or select option..." {...props} />
    ),
    validate: validateSelect,
  },
  person_name: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => (
      <TextField defaultPlaceholder="E.g., Henry Weinhurst" {...props} />
    ),
    validate: validateSelect,
  },
  percent: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => (
      <TextField myType="float" defaultPlaceholder="E.g., 75%" {...props} />
    ),
    validate: validateString,
  },
  phone: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => (
      <PhoneNumber defaultPlaceholder="E.g., +1 (801) 792-2031" {...props} />
    ),
    validate: validatePhone,
  },
  related_resource: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => (
      <Select defaultPlaceholder="Type to search or select option..." {...props} />
    ),
    validate: validateSelect,
  },
  radio: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => <CheckboxGroup type="radio" {...props} />,
    validate: validateCheckboxGroup,
  },
  resource_list: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => <ResourceList {...props} />,
    validate: validateString,
  },
  salary: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => (
      <TextField myType="salary" defaultPlaceholder="E.g., $65,000" {...props} />
    ),
    validate: validateSalary,
  },
  share_links: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => <ShareLinks {...props} />,
    validate: validateShareLinks,
  },
  social_security: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => (
      <Password autoComplete="nope" type="text" myType="social_security" {...props} />
    ),
    validate: validateSocialSecurity,
  },
  state: {
    value: '',
    Formik: ({ ...props }) => (
      <Select
        {...props}
        defaultPlaceholder="Type to search or select option..."
        options={options}
      />
    ),
    gridColWidth: 'minMax(120px, 1fr)',
    validate: validateSelect,
  },
  string: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => <TextField {...props} />,
    validate: validateString,
  },
  custom_document: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => <FormCustomDoc {...props} />,
    validate: validateString,
  },
  text: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => <Textarea {...props} />,
    validate: validateString,
  },
  zip: {
    value: '',
    gridColWidth: 'minMax(120px, 1fr)',
    Formik: ({ ...props }) => (
      <TextField myType="zip" type="tel" defaultPlaceholder="E.g., 84057" {...props} />
    ),
    validate: validateZip,
  },
};
