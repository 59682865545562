import { isObject } from 'lodash-es';

const handlePathWithUrl = (path, url) =>
  isObject(path)
    ? {
        ...path,
        pathname: `${url}` + path.pathname,
      }
    : `${url}` + path;

const handlePathWithCompanyId = (path, companyId) =>
  isObject(path)
    ? {
        ...path,
        pathname: `/c/${companyId || '0'}` + path.pathname,
      }
    : `/c/${companyId || '0'}` + path;

export default (path, companyId, url) =>
  url ? handlePathWithUrl(path, url) : handlePathWithCompanyId(path, companyId);

export const handleExternalUrlWithCompanyId = (path, companyId) => {
  let updatedPath = path;
  if (updatedPath.includes('/c/0')) {
    updatedPath = updatedPath.replace('/c/0', `/c/${companyId}`);
  }
  return updatedPath;
};

export const handleRoute = (path, routePath) => routePath + path;
