export const OPEN_TERMS_MODAL = 'savvi/UI/OPEN_TERMS_MODAL';
export const CLOSE_TERMS_MODAL = 'savvi/UI/CLOSE_TERMS_MODAL';

export const OPEN_USER_DROPDOWN = 'savvi/UI/OPEN_USER_DROPDOWN';
export const CLOSE_USER_DROPDOWN = 'savvi/UI/CLOSE_USER_DROPDOWN';

export const SET_NOTICE = 'savvi/UI/SET_NOTICE';
export const CLEAR_NOTICE = 'savvi/UI/CLEAR_NOTICE';

export const UPDATE_TASK_REQUEST = 'savvi/formation/UPDATE_TASK_REQUEST';
