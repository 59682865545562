import decode from 'jwt-decode';
import { isEmpty } from 'lodash-es';

// const checkToken = decodedToken =>
//   !!decodedToken && !!decodedToken.user && !!decodedToken.user.isUnconfirmed;

// export const decodeSavedToken = token => decodeToken(getToken(token));

export const removeEmailVerification = templateKey => {
  let emailVerifications = {
    ...JSON.parse(localStorage.getItem('emailVerifications') || '{}'),
  };

  if (!isEmpty(emailVerifications[templateKey])) {
    delete emailVerifications[templateKey];
  }

  localStorage.setItem('emailVerifications', JSON.stringify(emailVerifications));
  return emailVerifications;
};

export const getEmailVerification = templateKey => {
  const emailVerifications = JSON.parse(
    localStorage.getItem('emailVerifications') || '{}',
  );
  return emailVerifications[templateKey] || {};
};

export const setEmailVerification = (templateKey, meta) => {
  let updatedEmailVerifications = {
    ...JSON.parse(localStorage.getItem('emailVerifications') || '{}'),
    [templateKey]: { ...meta, sent_at: new Date() },
  };
  localStorage.setItem('emailVerifications', JSON.stringify(updatedEmailVerifications));
  return updatedEmailVerifications;
};

export const InitUser = {
  impersonator_id: null,
  isAuthenticated: false,
  email: null,
  email_verified: false,
  name: null,
  first_name: null,
  last_name: null,
  company: null,
  partners: [],
  accountId: '0',
  userId: null,
};

export const isCompanyTokenStored = companyId => {
  const tokens = JSON.parse(localStorage.id_tokens || '{}');
  return !!tokens[companyId];
};

export const getStoredCompanyId = () => {
  const tokens = JSON.parse(localStorage.id_tokens || '{}');
  const url = window.location.href;
  let urlCompanyId;
  if (url.includes('/c/')) {
    urlCompanyId = url.split('/')[4];
  }

  if (!tokens[urlCompanyId]) {
    if (isEmpty(tokens)) {
      return '0';
    }
    return Object.entries(tokens)[0][0];
  }
  return urlCompanyId || '0';
};

export const getStoredToken = accountId => {
  const tokens = JSON.parse(localStorage.id_tokens || '{}');
  const url = window.location.href;
  let urlCompanyId;
  if (url.includes('/c/')) {
    urlCompanyId = url.split('/')[4];
  }
  let tokenId = accountId || urlCompanyId;
  // let tokenId = (accountId + '' === '0' && urlCompanyId) || accountId || urlCompanyId;

  if (!tokens[tokenId]) {
    if (isEmpty(tokens)) {
      return '';
    }
    return Object.entries(tokens)[0][1];
  }
  return tokens[tokenId] || '';
};

export const getDecodedToken = id_token => {
  if (!id_token || id_token === 'undefined' || id_token === 'false') {
    return {
      ...InitUser,
      error: 'token invalid',
    };
  }
  const {
    account_id: accountId,
    company,
    email,
    email_verified,
    exp,
    family_name,
    first_name,
    given_name,
    impersonator_id,
    last_name,
    name,
    partners,
    sub: userId,
  } = decode(id_token);

  return {
    ...InitUser,
    accountId,
    company,
    email,
    email_verified,
    exp,
    first_name: first_name || given_name,
    id_token,
    impersonator_id,
    isAuthenticated: true,
    last_name: last_name || family_name,
    name,
    partners,
    userId,
  };
};

export const decodeToken = idToken => {
  let id_token = idToken;
  if (id_token === 'stored') {
    id_token = getStoredToken();
  }

  const error = validateToken(id_token);
  if (error) {
    return {
      ...InitUser,
      error,
    };
  }
  const {
    account_id: accountId,
    company,
    email,
    email_verified,
    exp,
    family_name,
    first_name,
    given_name,
    impersonator_id,
    last_name,
    name,
    partners,
    sub: userId,
  } = decode(id_token);

  const url = window.location.href;
  let urlCompanyId = url.includes('/c/') && url.split('/')[4];

  return {
    ...InitUser,
    accountId,
    company,
    email,
    email_verified,
    exp,
    first_name: first_name || given_name,
    id_token,
    impersonator_id,
    isAuthenticated: true,
    last_name: last_name || family_name,
    name,
    partners,
    urlCompanyId,
    userId,
  };
};

export const validateToken = id_token => {
  let message = '';
  if (id_token === 'undefined' || id_token === 'false' || !id_token) {
    return 'Undefined Token';
  }
  const decodedToken = getDecodedToken(id_token);
  const { userId, email, error, exp } = decodedToken;
  if (!userId || error) {
    message = error || 'No User Id in Token';
  } else if (userId && email && !!exp) {
    const [now, expires] = [new Date(), new Date(exp * 1000)];
    message = now < expires ? '' : 'Token has expired.';
  } else {
    message = '';
  }
  return message;
};
