import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'moment';

import { setCompany } from '../../redux/modules/Company/operations';
import { getAccountId } from '../../redux/modules/Formation/selectors';
import { Button } from '../common';
import AssignTodo from '../AssignTodo';

import './TasksSection.scss';
import { performTaskAction } from '../../redux/modules/Project/operations';
import handlePath from '../../utils/handlePath';

const sortStrings = (a, b, dir) => {
  const aLower = a + ''.toLowerCase();
  const bLower = b + ''.toLowerCase();

  let isAGreater = aLower > bLower;
  let isBGreater = aLower < bLower;

  if (isAGreater) {
    return dir === 'asc' ? 1 : -1;
  }
  if (isBGreater) {
    return dir === 'asc' ? -1 : 1;
  }
  return 0;
};

const TasksSection = ({
  handleDeleteProject,
  isHeightUnset,
  isSectionOpenInit,
  isAllTasks,
  isMyDashboard,
  isWarning,
  moduleId,
  sectionLabel,
  transactions,
}) => {
  const dispatch = useDispatch();
  const currentAccountId = useSelector(getAccountId);
  const [dir, setDir] = useState('asc');
  const [filter, setFilterSlug] = useState('date');
  const [isSectionOpen, setIsOpen] = useState(isSectionOpenInit);
  const [myTransactions, setTransactions] = useState(transactions);
  const [selectedTodo, setSelectedTodo] = useState({});
  const [isEditTodoOpen, setIsEditTodoOpen] = useState(false);

  useEffect(() => {
    setTransactions(
      transactions.sort((a, b) => Moment(b.created_at).diff(Moment(a.created_at))),
    );
  }, [moduleId, transactions]);

  const setFilter = useCallback(
    clickedFilter => {
      let updatedDir = dir;
      let updatedFilter = filter;
      if (clickedFilter === filter) {
        updatedDir = dir === 'desc' ? 'asc' : 'desc';
      }
      if (clickedFilter && clickedFilter !== filter) {
        updatedDir = 'desc';
      }
      updatedFilter = clickedFilter || filter;

      const updatedTasks = myTransactions.sort((a, b) => {
        if (updatedFilter === 'name') {
          return sortStrings(a.label, b.label, updatedDir);
        }
        if (updatedFilter === 'project_name') {
          return sortStrings(a.project_name, b.project_name, updatedDir);
        }
        if (updatedFilter === 'status') {
          return sortStrings(a.taskStatus, b.taskStatus, updatedDir);
        }
        if (updatedFilter === 'type') {
          return sortStrings(a.taskType, b.taskType, updatedDir);
        }
        if (updatedFilter === 'company_name') {
          return sortStrings(a.account_name, b.account_name, updatedDir);
        }
        // Default to date;
        return updatedDir === 'desc'
          ? Moment(a.created_at).diff(Moment(b.created_at))
          : Moment(b.created_at).diff(Moment(a.created_at));
      });
      setDir(updatedDir);
      setFilterSlug(updatedFilter);
      setTransactions(updatedTasks);
    },
    [dir, filter, myTransactions, setDir, setFilterSlug, setTransactions],
  );

  return (
    <div className="tasksSection">
      <h2 className="tasksSection__header">
        <FontAwesomeIcon icon={['fal', 'ballot-check']} />
        {sectionLabel}
        <span>{` (${myTransactions.length > 0 ? myTransactions.length : 'None'})`}</span>
      </h2>
      <div
        className={classnames('tasksSection__list', {
          unsetHeight: isHeightUnset,
          isMyDashboard,
        })}
      >
        <h4
          className="tasksSection__listHeaderLabel first"
          onClick={() => setFilter('name')}
        >
          Name
          {filter === 'name' && (
            <FontAwesomeIcon icon={dir === 'desc' ? 'caret-down' : 'caret-up'} />
          )}
        </h4>
        <h4
          className="tasksSection__listHeaderLabel"
          onClick={() => setFilter('project_name')}
        >
          Workflow Name
          {filter === 'project_name' && (
            <FontAwesomeIcon icon={dir === 'desc' ? 'caret-down' : 'caret-up'} />
          )}
        </h4>
        {isMyDashboard && (
          <h4
            className="tasksSection__listHeaderLabel"
            onClick={() => setFilter('company_name')}
          >
            Company Name
            {filter === 'company_name' && (
              <FontAwesomeIcon icon={dir === 'desc' ? 'caret-down' : 'caret-up'} />
            )}
          </h4>
        )}
        <h4 className="tasksSection__listHeaderLabel" onClick={() => setFilter('date')}>
          Date Created
          {filter === 'date' && (
            <FontAwesomeIcon icon={dir === 'desc' ? 'caret-down' : 'caret-up'} />
          )}
        </h4>
        {myTransactions.map(
          (props, index) =>
            (isSectionOpen || index < 4) && (
              <TaskRow
                key={`actionItems-${props.id}-${index}`}
                currentAccountId={currentAccountId}
                dispatch={dispatch}
                handleDeleteProject={handleDeleteProject}
                isAllTasks={isAllTasks}
                isMyDashboard={isMyDashboard}
                moduleId={moduleId}
                setSelectedTodo={setSelectedTodo}
                setIsEditTodoOpen={setIsEditTodoOpen}
                {...props}
              />
            ),
        )}
        {myTransactions.length > 4 && (
          <Button
            buttonType="link"
            className="tasksSection__listToggle"
            onClick={() => setIsOpen(!isSectionOpen)}
            size="sm"
          >
            {!isSectionOpen
              ? `Show ${myTransactions.length - 4} more tasks`
              : 'Show less tasks'}
            <FontAwesomeIcon icon={isSectionOpen ? 'caret-up' : 'caret-down'} />
          </Button>
        )}
      </div>
      <AssignTodo
        isModalOpen={isEditTodoOpen}
        isMyDashboard={isMyDashboard}
        isNew
        todoMeta={selectedTodo}
        toggleModal={() => {
          setIsEditTodoOpen(false);
          setSelectedTodo({});
        }}
      />
    </div>
  );
};

const TaskRow = props => {
  const {
    account,
    account_id,
    created_at,
    dispatch,
    currentAccountId,
    filename,
    id,
    isAllTasks,
    isMyDashboard,
    // handleDeleteProject,
    label,
    moduleId,
    module_id,
    project_id,
    // project_is_deletable,
    project_name,
    project_template_id,
    setSelectedTodo,
    setIsEditTodoOpen,
    state,
    // updatedActionLabel,
    taskStatus,
    template = {},
  } = props;
  const { push } = useHistory();
  const { search } = useLocation();
  const { companyId } = useParams();

  let path = {
    pathname: `/workbench/${module_id || moduleId}/${project_id}/${id}/initialize`,
    search,
    state: { isClicked: true },
  };
  const stateText = taskStatus || state || 'Needs Review';

  const handleDeleteTask = e => {
    e.stopPropagation();
    const message = `Are you sure you want to delete ${label || 'this task'}?`;
    if (window.confirm(message)) {
      dispatch(performTaskAction('delete', id));
    }
  };

  return (
    <div
      className={`tasksSection__listRow ${isAllTasks && 'isAllTasks'}`}
      onClick={() => {
        if (isMyDashboard) {
          dispatch(setCompany(account_id, path, account_id === currentAccountId));
        } else {
          push(handlePath(path, companyId));
        }
      }}
    >
      <h4 className="tasksSection__listItem first">
        {label || 'Unknown name'}
        {isAllTasks && (
          <span
            className={taskStatus === 'Open' || taskStatus === 'Pending' ? 'isMint' : ''}
          >
            {stateText}
          </span>
        )}
      </h4>
      <h4 className="tasksSection__listItem">{project_name}</h4>
      {isMyDashboard && <h4 className="tasksSection__listItem">{account}</h4>}
      <div className="tasksSection__listItem last">
        <h4>{Moment(created_at).format('L')}</h4>
        <div className="tasksSection__listActions">
          {taskStatus !== 'Completed' && (
            <Button
              buttonType="icon"
              onClick={e => {
                e.stopPropagation();
                setSelectedTodo({
                  account_id,
                  id,
                  initTodoVals: {
                    label: `Complete "${filename || label}" Task`,
                  },
                  project_template_id,
                });
                setIsEditTodoOpen(true);
              }}
              size="sm"
              tooltip="Assign"
            >
              <FontAwesomeIcon icon={['fal', 'user-plus']} />
            </Button>
          )}
          {!!template.is_deletable && (
            <Button
              buttonType="icon"
              isWarning
              size="sm"
              onClick={handleDeleteTask}
              tooltip="Delete Task"
            >
              <FontAwesomeIcon icon={['fal', 'trash-alt']} />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

TasksSection.propTypes = {
  handleDeleteProject: PropTypes.func,
  isHeightUnset: PropTypes.bool,
  isSectionOpenInit: PropTypes.bool,
  isMyDashboard: PropTypes.bool,
  isWarning: PropTypes.bool,
  moduleId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sectionLabel: PropTypes.string,
  transactions: PropTypes.array,
};

TasksSection.defaultProps = {
  handleDeleteProject: () => {},
  isHeightUnset: true,
  isSectionOpenInit: false,
  isMyDashboard: false,
  isWarning: false,
  moduleId: -1,
  transactions: [],
};

export default TasksSection;
