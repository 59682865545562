import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { pdfjs } from 'react-pdf';

import * as Sentry from '@sentry/browser';

import { Button, NoticeToast } from '../components/common';
import Footer from '../components/Footer';
import Loading from '../components/LoadingComponent';
import { getDocuments } from '../redux/modules/Document/operations';
import {
  getTransaction,
  getTransactionDocuments,
} from '../redux/modules/Transaction/operations';
import { operations as userOps } from '../redux/modules/User';
// import Main from 'containers/Main';
import Basic from '../routes/Basic';
import Dashboard from '../routes/Dashboard';
import { userIsAuthenticated } from '../utils/Auth';
// import SocketContext from '../utils/SocketContext';
import OutsideForm from '../views/OutsideForm/OutsideFormContainer';
import Room from '../views/Room';
import Workbench from '../views/Workbench';
import { getStoredCompanyId } from '../redux/modules/User/utils';
// import { decodeToken, getStoredCompanyId } from '../redux/modules/User/utils';
import {
  getAccessToken,
  getAuthUser,
  IsAccountAuthenticated,
} from '../redux/modules/User/selectors';
import handlePath from '../utils/handlePath';
import VerifyCode from '../views/VerifyCode';
import VerifyByLink from '../views/VerifyByLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setNotice } from '../redux/modules/UI/actions';

const AuthWorkbench = userIsAuthenticated(Workbench);

const unauthedRoutes = [
  'forgot-password',
  'login',
  'register',
  'reset-password',
  'plans',
  'remote-login',
];
const joinedUnauthedRoutes = unauthedRoutes.join('|');

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signoutTime: 1000 * 60 * 180,
      isVerifyHiding: localStorage.getItem('hide_verify'),
    };
    this.ws = null;
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  }
  componentDidMount() {
    const {
      history: { replace },
      location: { pathname, search = '' },
      validateSession,
    } = this.props;
    const { REACT_APP_SENTRY_LABEL: sentryEnvLabel } = process.env;

    // const query = new URLSearchParams(search);
    Sentry.init({
      dsn: 'https://a758a3c1d9f24a828108d5187553a1c1@sentry.io/1801413',
      environment: sentryEnvLabel,
    });
    const storedCompanyId = getStoredCompanyId();

    if (!pathname || pathname === '/' || pathname === '/home') {
      if (storedCompanyId && storedCompanyId !== '0') {
        replace(handlePath('/home', storedCompanyId));
        validateSession();
      } else {
        replace({ pathname: '/login', search });
      }
    } else if (pathname.includes('/c/') && pathname.split('/')[2] === 'undefined') {
      let updatedPathname = pathname.split('/');
      updatedPathname[2] = '0';
      updatedPathname = updatedPathname.join('/');
      replace({
        pathname: '/c/0/company-select',
        search: `?redirect=${updatedPathname}${search}`,
      });
      validateSession();
    } else if (
      !pathname.includes('/c/') &&
      !joinedUnauthedRoutes.includes(pathname.split('/')[1]) &&
      !pathname.includes('/outside-form') &&
      !pathname.includes('/data-room') &&
      !pathname.includes('/verify')
    ) {
      if (!storedCompanyId || storedCompanyId !== '0') {
        replace({
          pathname: '/c/0/company-select',
          search: `?redirect=/c/0${pathname}${search}`,
        });
        validateSession();
      } else {
        replace({
          pathname: '/login',
          search: `?redirect=/c/0${pathname}${search}`,
        });
      }
    } else {
      validateSession();
    }
    Modal.setAppElement('#root');
    /** ** Auth Refresh ** */
    this.events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];
    this.logoutTimeout = null;
    this.warnTimeout = null;
  }

  componentDidUpdate(prevProps) {
    const {
      idToken,
      isAccountAuthenticated,
      location: { pathname },
    } = this.props;
    // Will scroll to the top of the page after every route change.
    if (pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
    if (
      prevProps.isAccountAuthenticated !== isAccountAuthenticated &&
      isAccountAuthenticated
    ) {
      const { REACT_APP_PROXY_URL: proxyUrl } = process.env;
      this.ws = new WebSocket(
        proxyUrl.replace(/^http/, 'ws') + '/api/ws?access_token=' + idToken,
      );
      this.ws.addEventListener('message', ev => {
        const data = JSON.parse(ev.data);
        console.info('Websocket response', data);
        if (data.event === 'console') {
          console.info(data.message);
        }
        if (data.event === 'alert') {
          window.alert(data.message);
        }
        if (data.event === 'toast') {
          setNotice({ type: data.type, message: data.message });
        }
        if (data.event === 'refresh_documents') {
          this.handleRefreshDocument(data.message);
        }
        if (data.event === 'refresh_timeline') {
          this.handleRefreshTimeline(data.message);
        }
      });
      this.ws.addEventListener('error', error => {
        console.info('Websocket error response', error);
      });
      /** ** Auth Refresh ** */
      for (var i in this.events) {
        window.addEventListener(this.events[i], this.resetTimeout);
      }
      this.setTimeout();
    }
  }

  handleRefreshDocument = data => {
    const {
      getDocuments,
      getTransactionDocuments,
      location: { pathname },
    } = this.props;
    if (
      ((pathname.includes('task-view') || pathname.includes('overview')) &&
        pathname.includes(data.transaction_id)) ||
      pathname.includes('home')
    ) {
      setTimeout(() => {
        getTransactionDocuments(data.transaction_id);
        getTransaction(data.transaction_id);
      }, 3500);
    } else if (pathname.includes('home')) {
      setTimeout(() => getDocuments(), 3500);
    }
  };

  handleRefreshTimeline = data => {
    const {
      getTransaction,
      location: { pathname },
    } = this.props;
    if (
      (pathname.includes('task-view') || pathname.includes('overview')) &&
      pathname.includes(data.transaction_id)
    ) {
      getTransaction(data.transaction_id);
    }
  };

  /** ** Auth Refresh ** */
  clearTimeoutFunc = () => {
    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  };

  /** ** Auth Refresh ** */
  setTimeout = () => {
    this.warnTimeout = setTimeout(this.warn, this.state.warningTime);
    this.logoutTimeout = setTimeout(this.logout, this.state.signoutTime);
  };

  /** ** Auth Refresh ** */
  resetTimeout = () => {
    this.clearTimeoutFunc();
    this.setTimeout();
  };

  logout = () => {
    // Send a logout request to the API
    window.alert(
      'You have been logged out due to inactivity. Please login again. Any progress will have been saved and accessed through Task Progress.',
    );
    console.info('Sending a logout request to the API...');
    this.props.logout();
  };

  render() {
    const {
      location: { search, pathname, state },
      user: { isAuthenticated, email_verified },
    } = this.props;
    const { redirectPathname } = state || {};
    const { isVerifyHiding } = this.state;

    const isTeamView =
      ['/company-select', '/teams', '/all-dashboards'].findIndex(e =>
        pathname.includes(e),
      ) !== -1;

    return (
      <div className="app-container">
        {isAuthenticated &&
          !isVerifyHiding &&
          !email_verified &&
          ![
            ...unauthedRoutes,
            'verify-code',
            'verify',
            'data-room',
            'outside-form',
          ].includes(pathname.split('/')[1]) && (
            <div className="app-verify">
              <FontAwesomeIcon className="app-verify__icon" icon="exclamation-circle" />
              <h4>
                For safety and security purposes, please
                <Button
                  buttonType="link"
                  to={{
                    pathname: '/verify-code/verification-email',
                    state: { redirectLocation: pathname },
                  }}
                >
                  verify your account
                </Button>
              </h4>
              <Button
                buttonType="icon"
                size="xs"
                className="app-verify__hide"
                onClick={() => {
                  localStorage.setItem('hide_verify', true);
                  this.setState({ isVerifyHiding: true });
                }}
              >
                <FontAwesomeIcon icon="times" />
              </Button>
            </div>
          )}
        {search.includes('redirect') && (
          <div className={`app-redirect ${isTeamView ? '' : 'login'}`}>
            <FontAwesomeIcon className="app-verify__icon" icon="exclamation-circle" />
            <h4>
              {isTeamView
                ? 'Before continuing, please select a company.'
                : 'Before continuing, please login or register below.'}
            </h4>
          </div>
        )}
        {!!redirectPathname && (
          <div className="app-redirect login">
            <FontAwesomeIcon className="app-verify__icon" icon="exclamation-circle" />
            <h4>
              {redirectPathname.includes('remote-login')
                ? 'To remotely login please first log in as a Savvi Admin user.'
                : 'You will be directed back to the Data Room once you login or register.'}
            </h4>
          </div>
        )}
        <Switch>
          <Route path="/loading" component={Loading} />
          <Route
            path="/c/:companyId/workbench/:moduleId/:projectId/:transactionId/:step"
            component={AuthWorkbench}
          />
          <Route path="/outside-form/:access_code" component={OutsideForm} />
          <Route path="/verify-code/:template_key">
            <VerifyCode
              hideVerify={() => {
                localStorage.setItem('hide_verify', true);
                this.setState({ isVerifyHiding: true });
              }}
            />
          </Route>
          <Route path="/verify/:verification_id/:verification_code">
            <VerifyByLink
              hideVerify={() => {
                localStorage.setItem('hide_verify', true);
                this.setState({ isVerifyHiding: true });
              }}
            />
          </Route>
          <Route path="/data-room/:room_access_code" component={Room} />
          <Route path={`/(${joinedUnauthedRoutes})`} component={Basic} />
          <Route path="/c/:companyId" component={userIsAuthenticated(Dashboard)} />
        </Switch>
        <Route component={Footer} />
        <NoticeToast />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAccountAuthenticated: IsAccountAuthenticated(state),
    idToken: getAccessToken(state),
    user: getAuthUser(state),
  };
};

const mapDispatchToProps = (
  dispatch,
  {
    history: { push },
    match: {
      params: { companyId },
    },
  },
) => ({
  getDocuments: id => dispatch(getDocuments(id)),
  getTransaction: id => dispatch(getTransaction(id)),
  getTransactionDocuments: id => dispatch(getTransactionDocuments(id)),
  validateSession: passedCompanyId =>
    dispatch(userOps.validateSession(passedCompanyId || companyId)),
  logout: () => dispatch(userOps.onLogout(push)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
