import * as Types from './types';

export const setLink = link => ({ type: Types['SET_LINK'], link });

export const setFeaturesForRoom = (payload, key) => ({
  type: Types['SET_FEATURES_FOR_ROOM'],
  payload,
  key,
});

export const setRoom = (payload, accessCode) => ({
  type: Types['SET_ROOM'],
  payload,
  accessCode,
});

export const setRoomToken = (payload, accessCode) => ({
  type: Types['SET_ROOM_TOKEN'],
  payload,
  accessCode,
});
