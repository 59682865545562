import { getStoredToken } from '../modules/User/utils';

export const requestHeaders = (auth = false, passedToken = '') => {
  const token = getStoredToken(passedToken === 'user' ? '0' : passedToken);

  const Authorization = `Bearer ${token}`;

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Request-Headers': '*',
    'Access-Control-Allow-Methods': 'GET',
    'Access-Control-Allow-Origin': process.env.REACT_APP_BASEURL,
  };

  const hasAuth = !!auth && !!token;

  return hasAuth ? { ...headers, Authorization } : headers;
};
