import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEqual, omit } from 'lodash-es';

import logo from '../../assets/images/wordmark-white.svg';
import googleIcon from '../../assets/images/google_icon.svg';
import Button from '../../components/common/Button';
import { InputPassword, InputStyledTextField } from '../../components/inputs';
import SavviLoading from '../../components/SavviLoading';
import TermsOfService from '../../components/TermsOfServiceModal';
import { clearErrors, setUserFromToken } from '../../redux/modules/User/actions';
import { loginUser, verifyEmail } from '../../redux/modules/User/operations';
import { AuthErrors, isFetchingUser } from '../../redux/modules/User/selectors';
import { validateEmail, validatePassword } from '../../utils/FeatureTypes';

import './Login.scss';
import { decodeToken } from '../../redux/modules/User/utils';

const generateOidcUrl = (
  client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID,
  redirect_uri = `${process.env.REACT_APP_BASEURL}/login/`,
  scope = 'email profile',
  login_hint,
) => {
  // a secure-enough random state value
  // (all modern browsers use crypto random Math.random, not that it much matters for a client-side state cache)
  var rnd = Math.random().toString();
  // transform from 0.1234... to hexidecimal
  var state = parseInt(rnd.slice(2).padEnd(16, '0'), 10).toString(16).padStart(14, '0');
  // response_type=id_token requires a nonce (one-time use random value)
  // response_type=token (access token) does not
  var nonceRnd = Math.random().toString();
  var nonce = parseInt(nonceRnd.slice(2).padEnd(16, '0'), 10)
    .toString(16)
    .padStart(14, '0');
  var baseUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
  var options = { state, client_id, redirect_uri, scope, login_hint, nonce };
  // transform from object to 'param1=escaped1&param2=escaped2...'
  var params = Object.keys(options)
    .filter(function (key) {
      return options[key];
    })
    .map(function (key) {
      // the values must be URI-encoded (the %20s and such)
      return key + '=' + encodeURIComponent(options[key]);
    })
    .join('&');
  return baseUrl + '?response_type=id_token&access_type=online&' + params;
};
// var url = generateOidcUrl('XXXX', 'https://EXAMPLE.COM/EXACT_PATH.html', 'email profile', 'JOHN.DOE@EXAMPLE.COM');

const Login = ({ history, location }) => {
  const dispatch = useDispatch();
  const { push } = history;
  const { hash, search, state: { redirectPathname } = {} } = location;

  const authErrors = useSelector(AuthErrors);
  const isFetching = useSelector(isFetchingUser);

  const [email, setEmail] = useState('');
  const [prevAuthErrors, setPrevAuthErrors] = useState(authErrors);
  const [errors, setErrors] = useState(authErrors);
  const [isEmailVerified, setEmailVerified] = useState(false);
  const [isFetchingSavviToken, setIsFetchingSavviToken] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isModalOpen, setModal] = useState(false);
  const [isPasswordPlainText, setPasswordPlainText] = useState(false);
  const [password, setPassword] = useState('');

  const emailRef = useCallback(
    node => {
      if (node !== null && !isEmailVerified) {
        node.focus();
      }
    },
    [isEmailVerified],
  );

  const passwordRef = useCallback(
    node => {
      if (node !== null && isEmailVerified) {
        node.focus();
      }
    },
    [isEmailVerified],
  );
  const hashResults = hash.split('&').reduce(function (res, item) {
    var parts = item.split('=');
    res[parts[0]] = parts[1];
    return res;
  }, {});

  useEffect(() => {
    dispatch(clearErrors());
  }, [dispatch]);

  useEffect(() => {
    if (!isEqual(authErrors, prevAuthErrors)) {
      setErrors({ ...errors, ...authErrors });
      setPrevAuthErrors(authErrors);
    }
  }, [authErrors, errors, prevAuthErrors]);

  const getCreds = () => ({
    email,
    password,
  });

  const hitToken = useCallback(
    async token => {
      var res = await window.fetch('/api/authn/session/oidc/google.com', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      var json = await res.json();
      if ((json.payload || json).id_token) {
        const decodedToken = decodeToken((json.payload || json).id_token);
        dispatch(setUserFromToken(decodedToken));
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (hashResults.id_token && !isFetchingSavviToken) {
      setIsFetchingSavviToken(true);
      hitToken(hashResults.id_token);
    }
  }, [hashResults, hitToken, isFetchingSavviToken, setIsFetchingSavviToken]);

  const handleGoogleAuth = e => {
    localStorage.setItem('storedLocation', JSON.stringify(location));
    const OidcUrl = generateOidcUrl();
    window.location.href = OidcUrl;
  };

  const handleSubmit = e => {
    if (!isEmailVerified && !errors.email) {
      setLoading(true);
      dispatch(verifyEmail(email)).then(
        e => {
          setEmailVerified(true);
          setErrors({});
        },
        error => {
          if (error.code === 'NOT_FOUND') {
            push({ pathname: '/register', search, state: { email, redirectPathname } });
          }
        },
      );
      setTimeout(() => setLoading(false), 500);
    } else if (isEmailVerified && !errors.email && !errors.password) {
      dispatch(clearErrors());
      setErrors({});
      dispatch(loginUser(getCreds()));
    }
  };

  const validateLoginPassword = e => {
    const { name, value } = e.target;
    const password = validatePassword(value);
    setErrors(!password ? omit(errors, name) : { ...errors, password });
  };

  const validateLoginEmail = e => {
    const { name, value } = e.target;
    const email = validateEmail(value);
    setErrors(!email ? omit(errors, name) : { ...errors, email });
  };
  return (
    <div className="login__container">
      {(isLoading || isFetching) && <SavviLoading size="md" />}
      {!isLoading && !isFetching && (
        <>
          <div className="login__logo">
            <img src={logo} alt="savvi-logo" />
          </div>
          <form className="login__form">
            <h2 className="login__heading">
              Welcome
              {isEmailVerified && (
                <>
                  {','}
                  <div
                    className="login__emailLink"
                    onClick={() => setEmailVerified(false)}
                  >
                    <b>{email}</b>
                    <span className="login__link">
                      <FontAwesomeIcon icon={['fal', 'edit']} />
                    </span>
                  </div>
                  <small className="login__sub-heading">
                    {'Please enter your password below.'}
                  </small>
                </>
              )}
              {!isEmailVerified && (
                <>
                  {'!'}
                  <small className="login__sub-heading">
                    Please enter your email below.
                  </small>
                </>
              )}
            </h2>
            <InputStyledTextField
              autoComplete="username"
              error={errors.email}
              inputClass="-js-login__input-email"
              isDataHidden={isEmailVerified}
              label={'Email'}
              name={'email'}
              onBlur={validateLoginEmail}
              onChange={e => setEmail(e.target.value)}
              ref={emailRef}
              type="email"
              value={email}
            />
            <InputPassword
              autoComplete="current-password"
              error={errors.password}
              hasToggle={true}
              inputClass="-js-login__input-password"
              isDataHidden={!isEmailVerified}
              isPlainText={isPasswordPlainText}
              label={'Password'}
              name={'password'}
              onBlur={validateLoginPassword}
              onChange={e => setPassword(e.target.value)}
              onToggle={() => setPasswordPlainText(!isPasswordPlainText)}
              ref={passwordRef}
              value={password}
            />
            {isEmailVerified && (
              <div className="login-actions__secondary">
                <div className="help-block text--right">
                  <Link
                    className="-js-login__btn-forgot-password"
                    to={{ pathname: '/forgot-password', state: { email } }}
                  >
                    Forgot password?
                  </Link>
                </div>
                <div className="help-block text--left">
                  <Link
                    className="-js-login__btn-register"
                    to={{
                      pathname: '/register',
                      search,
                      state: { email, redirectPathname },
                    }}
                  >
                    Register
                  </Link>
                </div>
              </div>
            )}
            {errors.form && <div className="alert form__error">{errors.form}</div>}
            <div className="login__action">
              <Button
                className="-js-login__btn-main"
                isDisabled={isFetching}
                isFetching={isFetching}
                onClick={handleSubmit}
                type="submit"
              >
                {isEmailVerified ? 'Login' : 'Next'}
              </Button>
            </div>
          </form>
          <div className="login__action">
            <Button
              className="-js-login__btn-gauth"
              buttonType="secondary"
              isDisabled={isFetching}
              isFetching={isFetching}
              onClick={handleGoogleAuth}
            >
              <img src={googleIcon} alt="google-logo" />
              Login with Google
            </Button>
          </div>
          <div className="login__text">
            <p>
              By continuing, you're confirming that you've read our
              <a
                href="#terms-of-service"
                onClick={e => {
                  e.preventDefault();
                  setModal(true);
                }}
              >
                {' '}
                Terms &amp; Conditions
              </a>
            </p>
            <p>
              For help with any issues please contact us at{' '}
              <a
                href="mailto:support@savvi.legal"
                target="_blank"
                rel="noopener noreferrer"
              >
                support@savvi.legal
              </a>
            </p>
          </div>
          <TermsOfService
            isOpen={isModalOpen}
            onClose={() => setModal(false)}
            label="Terms of Service"
          />
        </>
      )}
    </div>
  );
};

export default Login;
