import React from 'react';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import { StripeProvider } from 'react-stripe-elements';
import { ConnectedRouter } from 'connected-react-router';

import PropTypes from 'prop-types';

import messages from 'box-ui-elements/i18n/en-US';

import App from './App';

const Root = ({ store, history }) => (
  <Provider store={store}>
    <IntlProvider locale="en-US" messages={messages}>
      <ConnectedRouter history={history}>
        <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
          <Route component={App} />
        </StripeProvider>
      </ConnectedRouter>
    </IntlProvider>
  </Provider>
);

Root.propTypes = {
  store: PropTypes.object.isRequired,
};
export default Root;
