import React from 'react';

import logo from '../../assets/images/wordmark-white.svg';
import OrgButton from '../OrgButton';

import './BasicTopbar.scss';

const BasicTopbar = ({ isDataRoom }) => {
  return (
    <div className="basic-topbar">
      <ul className="basic-topbar__nav">
        {!isDataRoom && (
          <li className="basic-topbar__nav-list-item basic-topbar__logo">
            <img src={logo} alt="" />
          </li>
        )}
        {isDataRoom && (
          <ul className="basic-topbar__secondary-nav">
            <li className="basic-topbar__nav-list-item--secondary user-menu">
              <OrgButton isDataRoom={isDataRoom} darkText />
            </li>
          </ul>
        )}
      </ul>
    </div>
  );
};

BasicTopbar.defaultProps = {
  isAuthenticated: false,
};

export default BasicTopbar;
