import React from 'react';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import Loadable from 'react-loadable';

import ConsistentTopbar from '../../components/ConsistentTopbar';
import ModuleDict from '../../utils/ModuleDict';
import CompanySelect from '../../views/CompanySelect';
import HomeContainer from '../../views/Home';
import Loading from '../../components/LoadingComponent';
import NewCompany from '../../views/NewCompany';
import NewUser from '../../views/NewUser';
import ShareDataRoomModal from '../../views/ShareDataRoomModal';
import Sidebar from '../../views/Sidebar';

import { handleRoute } from '../../utils/handlePath';
import './Dashboard.scss';

const AsyncWorkflowsView = Loadable({
  loader: () => import('../../views/WorkflowsView'),
  loading: Loading,
});
const AsyncTasksView = Loadable({
  loader: () => import('../../views/TasksView'),
  loading: Loading,
});
const AsyncAllDocuments = Loadable({
  loader: () => import('../../views/AllDocuments'),
  loading: Loading,
});
const AsyncCounselView = Loadable({
  loader: () => import('../../views/CounselView'),
  loading: Loading,
});
const AsyncReportsView = Loadable({
  loader: () => import('../../views/ReportsView'),
  loading: Loading,
});
const AsyncTeams = Loadable({
  loader: () => import('../../views/Teams'),
  loading: Loading,
});
const AsyncMyDashboard = Loadable({
  loader: () => import('../../views/MyDashboard'),
  loading: Loading,
});
const AsyncSettings = Loadable({
  loader: () => import('../../views/Settings'),
  loading: Loading,
});
const AsyncCabinet = Loadable({
  loader: () => import('../../views/Cabinet'),
  loading: Loading,
});
const AsyncSubscriptionsModal = Loadable({
  loader: () => import('../../views/SubscriptionsModal/SubscriptionsViewContainer'),
  loading: Loading,
});

const Dashboard = () => {
  let location = useLocation();
  let { path } = useRouteMatch();
  let background = location.state && location.state.background;

  const isTeamView =
    ['/company-select', '/teams', '/all-dashboards'].findIndex(e =>
      location.pathname.includes(e),
    ) !== -1;

  return (
    <div className="dashboard__container">
      <Route>
        <Sidebar isDashboard={!isTeamView} isTeamView={isTeamView} />
      </Route>
      <div
        className={`dashboard__main${
          location.search && location.search.includes('redirect') ? ' warning' : ''
        }`}
      >
        <Route>
          <ConsistentTopbar />
        </Route>
        <Switch location={background || location}>
          <Route path={handleRoute('/company-select', path)}>
            <CompanySelect />
          </Route>
          <Route path={handleRoute('/teams', path)}>
            <AsyncTeams />
          </Route>
          <Route path={handleRoute('/all-dashboards', path)}>
            <AsyncMyDashboard />
          </Route>
          <Route path={handleRoute(ModuleDict['-1'].path, path)}>
            <HomeContainer
              icon={ModuleDict['-1'].icon}
              moduleId={ModuleDict['-1'].id}
              title={ModuleDict['-1'].label}
            />
          </Route>
          <Route path={handleRoute(ModuleDict['10'].path, path)}>
            {routeProps => (
              <AsyncCounselView
                icon={ModuleDict['10'].icon}
                title={ModuleDict['10'].label}
                moduleId={ModuleDict['10'].id}
                {...routeProps}
              />
            )}
          </Route>
          {/* <Suspense fallback={Loading}> */}
          <Route path={handleRoute(ModuleDict['workflows'].path, path)}>
            <AsyncWorkflowsView
              icon={ModuleDict['workflows'].icon}
              title={ModuleDict['workflows'].label}
              moduleId={ModuleDict['workflows'].id}
            />
          </Route>
          {/* </Suspense> */}
          <Route path={handleRoute(ModuleDict['tasks'].path, path)}>
            <AsyncTasksView
              icon={ModuleDict['tasks'].icon}
              title={ModuleDict['tasks'].label}
              moduleId={ModuleDict['tasks'].id}
            />
          </Route>
          <Route path={handleRoute(ModuleDict['documents'].path, path)}>
            <AsyncAllDocuments
              icon={ModuleDict['documents'].icon}
              title={ModuleDict['documents'].label}
              moduleId={ModuleDict['documents'].id}
            />
          </Route>
          <Route path={handleRoute('/cabinet', path)}>
            {routeProps => <AsyncCabinet {...routeProps} />}
          </Route>
          <Route
            path={handleRoute('/:moduleId/share-data-room', path)}
            component={ShareDataRoomModal}
          />
          <Route path={handleRoute('/settings', path)} component={AsyncSettings} />
          <Route path={handleRoute('/reports', path)}>
            <AsyncReportsView />
          </Route>
          <Route
            path={handleRoute('/subscriptions', path)}
            component={AsyncSubscriptionsModal}
          />
          <Redirect from={handleRoute('/', path)} to={handleRoute('/home', path)} />
        </Switch>
        {background && (
          <Route path={handleRoute('/company-select/new', path)} component={NewCompany} />
        )}
        {background && (
          <Route path={handleRoute('/settings/users/new', path)} component={NewUser} />
        )}
      </div>
    </div>
  );
};

export default Dashboard;
