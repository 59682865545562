import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik } from 'formik';

import { Button } from '../../components/common';
import { TextField } from '../../components/formik';
import { getAccountInfo } from '../../redux/modules/Formation/operations';
import { addSavviPartnerGroupMember } from '../../redux/modules/Profile/operations';
import { addNewUser } from '../../redux/modules/User/operations';
import { isFetchingUser } from '../../redux/modules/User/selectors';
import { validateString } from '../../utils/FeatureTypes';
import MyTypes from '../../utils/MyTypes';

import './NewUser.scss';

const customStyles = {
  overlay: {
    zIndex: 9,
    backgroundColor: 'rgba(0,0,0,.66)',
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    overflowY: 'auto',
    paddingTop: 20,
    WebkitOverflowScrolling: 'touch',
  },
  content: {
    position: 'static',
    maxWidth: '450px',
    minHeight: '225px',
    margin: 'auto',
    padding: 0,
    border: 'none',
    boxShadow: '3px 3px 3px 3px #33373859',
    display: 'flex',
    flexDirection: 'column',
  },
};

const NewCompany = ({ Toggle, isPartnerGroupMember, partnerId }) => {
  const dispatch = useDispatch();
  const isFetching = useSelector(isFetchingUser);

  const [isShowing, setIsShowing] = useState(false);

  const header = isPartnerGroupMember
    ? 'Add a Member to your Partner Group'
    : 'Add a User to Your Company Account';

  return (
    <>
      <Toggle onClick={() => setIsShowing(!isShowing)} />
      <Modal
        isOpen={isShowing}
        onRequestClose={e => setIsShowing(false)}
        style={customStyles}
      >
        <div className="new-user">
          <h2>{header}</h2>
          <Formik
            initialValues={{
              email: '',
              first_name: '',
              last_name: '',
            }}
            onSubmit={({ email, first_name, last_name }, actions) => {
              if (isPartnerGroupMember) {
                dispatch(
                  addSavviPartnerGroupMember(partnerId, { email, first_name, last_name }),
                ).then(e => setIsShowing(false));
              } else {
                dispatch(addNewUser({ email, first_name, last_name })).then(e => {
                  dispatch(getAccountInfo());
                  setIsShowing(false);
                });
              }
            }}
          >
            {formikProps => (
              <Form className="new-user__form">
                <Field
                  component={TextField}
                  label="Email:"
                  name="email"
                  validate={validateString}
                />
                <Field
                  component={TextField}
                  label="First Name:"
                  name="first_name"
                  validate={validateString}
                />
                <Field
                  component={TextField}
                  label="Last Name:"
                  name="last_name"
                  validate={validateString}
                />
                <h4>
                  <FontAwesomeIcon icon="exclamation-circle" />
                  An email invite will be sent once the user has been added.
                </h4>
                <Button type="submit" isFetching={isFetching}>
                  Add New {isPartnerGroupMember ? 'Member' : 'User'}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
};

NewCompany.propTypes = {
  fetchError: PropTypes.string,
  isFetching: PropTypes.bool,
  initData: MyTypes.initData,
  myModule: PropTypes.object,
};

export default NewCompany;
