import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import Moment from 'moment';

import { setFeatureChangesDate } from '../../redux/modules/Profile/operations';
import { FeatureChanges, NewFeatureChanges } from '../../redux/modules/Profile/selectors';

import './WhatsNewModal.scss';
import { getAuthUser } from '../../redux/modules/User/selectors';
import Button from '../common/Button';

// import { Link } from 'react-router-dom';

const customStyles = {
  overlay: {
    zIndex: 9,
    backgroundColor: 'rgba(0,0,0,.66)',
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    overflowY: 'auto',
    paddingTop: 42,
    WebkitOverflowScrolling: 'touch',
  },
  content: {
    width: '560px',
    minHeight: 124,
    margin: '0 20px auto auto',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.15)',
    borderRadius: 2,
    padding: 0,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'static',
  },
};

export const WhatsNewModalToggle = () => {
  const newFeatureChanges = useSelector(NewFeatureChanges);
  const user = useSelector(getAuthUser);

  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <button className="whatsNew__toggle" onClick={() => setOpen(true)}>
        <FontAwesomeIcon icon={['fal', 'bell']} />
        {!!user.email_verified && newFeatureChanges.length > 0 && (
          <sup>{newFeatureChanges.length}</sup>
        )}
        {!user.email_verified && (
          <sup>
            <FontAwesomeIcon icon="exclamation-circle" />
          </sup>
        )}
      </button>
      <WhatsNewModal isOpen={isOpen} closeModal={() => setOpen(false)} user={user} />
    </>
  );
};

const WhatsNewModal = ({ isOpen, closeModal, user = {} }) => {
  const dispatch = useDispatch();
  const featureChanges = useSelector(FeatureChanges);
  const newFeatureChanges = useSelector(NewFeatureChanges);

  const [isCollapsed, setCollapsed] = useState(true);

  return (
    <Modal
      isOpen={isOpen}
      closeTimeoutMS={200}
      onRequestClose={e => {
        e.stopPropagation();
        dispatch(setFeatureChangesDate());
        closeModal();
      }}
      style={customStyles}
    >
      <div className="whatsNew">
        <h1>
          Notifications
          <FontAwesomeIcon
            className="whatsNew__exit"
            onClick={e => {
              e.stopPropagation();
              dispatch(setFeatureChangesDate());
              closeModal();
            }}
            icon={['fal', 'times']}
          />
        </h1>
        <div className="whatsNew__body">
          {!user.email_verified && (
            <div className="whatsNew__item verify">
              <FontAwesomeIcon icon="exclamation-circle" />
              <div className="whatsNew__itemBody">
                <h2>
                  You have not yet verified your account, and won't be able to access
                  certain features until you do.
                  <Button to="/verify-code">Verify your account</Button>
                </h2>
              </div>
            </div>
          )}
          {featureChanges.map(
            ({
              change_date,
              details: { description, image, label, link, link_label, type = 'Features' },
              id,
              isOld,
            }) => (
              <div
                className={classnames('whatsNew__item', {
                  hide: isOld && isCollapsed,
                })}
                key={`feature-change-${id}`}
              >
                <div className="whatsNew__itemBody">
                  <h4>
                    <span>{type}</span>
                    <span>{Moment(change_date).fromNow()}</span>
                  </h4>
                  <h2>
                    {label}
                    {!description && !!link && (
                      <>
                        {' ('}
                        <a
                          href={link}
                          onClick={() => dispatch(setFeatureChangesDate())}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {link_label || 'Link'}
                        </a>
                        )
                      </>
                    )}
                  </h2>
                  {(!!description || !!link) && (
                    <p>
                      {description}
                      {!!description && !!link && ' ('}
                      {link && (
                        <a
                          href={link}
                          onClick={() => dispatch(setFeatureChangesDate())}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {link_label || 'Link'}
                        </a>
                      )}
                      {!!description && !!link && ')'}
                    </p>
                  )}
                </div>
                {image && (
                  <div className="whatsNew__itemImage">
                    <img src={image} alt="" />
                  </div>
                )}
              </div>
            ),
          )}
          {newFeatureChanges.length === 0 && !!user.email_verified && (
            <div className="whatsNew__item">
              <div className="whatsNew__itemBody">
                <h2 className="whatsNew__emptyLabel">No new product updates.</h2>
              </div>
            </div>
          )}
          {featureChanges.length > 0 && featureChanges.length > newFeatureChanges.length && (
            <button className="whatsNew__link" onClick={() => setCollapsed(!isCollapsed)}>
              {isCollapsed ? 'Show Older Updates' : 'Hide Older Updates'}
              <FontAwesomeIcon icon={['fal', isCollapsed ? 'caret-down' : 'caret-up']} />
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

WhatsNewModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
};

WhatsNewModal.defaultProps = {
  isOpen: false,
  closeModal: () => {},
};

export default WhatsNewModal;
