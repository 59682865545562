import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  getUser,
  IsAccountSettingsShowing,
  isFetchingProfile,
} from '../../redux/modules/Profile/selectors';
import { getAuthUser, IsAuthenticated } from '../../redux/modules/User/selectors';
import { onLogout } from '../../redux/modules/User/operations';
import UseWindowSize from '../../utils/UseWindowSize';
import SavviLoading from '../SavviLoading';

import './OrgButton.scss';
import { Button } from '../common';
import { IsCompaniesEmpty } from '../../redux/modules/Company/selectors';
import handlePath from '../../utils/handlePath';
import { useLocation, useParams } from 'react-router';
import { DropdownAction } from '../common/Dropdown/Dropdown';

const OrgButton = ({ accountInfo, darkText, isDataRoom }) => {
  const { pathname } = useLocation();
  const { subscriptions = [] } = accountInfo || {};
  const { companyId } = useParams();
  const dispatch = useDispatch();
  const { width } = UseWindowSize();
  const isAuthenticated = useSelector(IsAuthenticated);
  const user = useSelector(getUser);
  const AuthUser = useSelector(getAuthUser);
  const isCompaniesEmpty = useSelector(IsCompaniesEmpty);
  const isFetching = useSelector(isFetchingProfile);
  const isAccountSettingsShowing = useSelector(IsAccountSettingsShowing);

  const [isInitFetchingTimeout, setIsInitFetchingTimeout] = useState(false);

  useEffect(() => {
    const initLoadingTimeout = setTimeout(() => setIsInitFetchingTimeout(true), 1000);
    return () => {
      clearTimeout(initLoadingTimeout);
    };
  }, []);

  const isNameShowing = !isFetching || isInitFetchingTimeout;

  return (
    <DropdownAction
      className="orgButton"
      Toggle={toggleProps => (
        <Button
          buttonType="link"
          size="sm"
          className="orgButton__toggle"
          {...toggleProps}
        >
          {!isNameShowing && <SavviLoading size="sm" />}
          {isNameShowing && (
            <>
              {(width > 678 || isDataRoom) && (
                <h4>{user.email || AuthUser.email || 'Menu'}</h4>
              )}
              {isAuthenticated && (
                <FontAwesomeIcon icon={width <= 678 ? 'ellipsis-v' : 'chevron-down'} />
              )}
            </>
          )}
        </Button>
      )}
    >
      {!isAuthenticated && isDataRoom && (
        <>
          <div className="orgButton__section">
            <h4>
              Sign in to Savvi so you can more easily access & interact with your Data
              Rooms.
            </h4>
          </div>
          <div className="orgButton__separator" />
          <Button
            buttonType="link"
            to={{ pathname: '/login', state: { redirectPathname: pathname } }}
            size="sm"
          >
            Sign In
          </Button>
        </>
      )}
      <div className="orgButton__section">
        {user.first_name && user.last_name && (
          <h2>{`${user.first_name || ''} ${user.last_name || ''}`}</h2>
        )}
        {(user.email || AuthUser.email) && <h4>{user.email || AuthUser.email || ''}</h4>}
      </div>
      {!isCompaniesEmpty && !isDataRoom && (
        <div className="orgButton__section">
          <h2>Subscriptions:</h2>
          <h4>
            {subscriptions.join()}
            {subscriptions.length === 0 && 'Free Basic'}
          </h4>
        </div>
      )}
      {isAuthenticated && <div className="orgButton__separator" />}
      {!isCompaniesEmpty && !isDataRoom && (
        <Button
          className="dd-item-ignore"
          buttonType="link"
          size="sm"
          to={handlePath('/settings/subscriptions', companyId)}
        >
          View your subscriptions
        </Button>
      )}
      {!isCompaniesEmpty && (
        <>
          <Button
            buttonType="link"
            size="sm"
            to={handlePath(
              { pathname: '/company-select', state: { isToggled: true } },
              companyId,
            )}
          >
            View Your Companies
          </Button>
          {isAccountSettingsShowing && !isDataRoom && (
            <Button
              buttonType="link"
              size="sm"
              to={handlePath('/settings/profile', companyId)}
            >
              Account Settings
            </Button>
          )}
        </>
      )}
      {isAuthenticated && (
        <Button
          buttonType="link"
          size="sm"
          onClick={e => {
            e.preventDefault();
            dispatch(onLogout());
          }}
        >
          Logout
        </Button>
      )}
    </DropdownAction>
  );
};

OrgButton.defaultProps = {
  darkText: true,
};

export default OrgButton;
