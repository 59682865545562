import { get, isEmpty, reduce } from 'lodash-es';
import { createSelector, defaultMemoize } from 'reselect';

import { SolutionsFeaturesDict } from '../../../utils/SubscriptionsDict';
import WorkflowLearnMoreLinks from '../../../utils/WorkflowLearnMoreLinks';
import { DocumentsByProject } from '../Document/selectors';

export const isFetchingWithHooks = defaultMemoize(state =>
  get(state, 'Project.isFetching', false),
);

export const isFetching = defaultMemoize(state => get(state, 'isFetching', false));

export const ProjectCounsel = defaultMemoize((state, id) =>
  get(state, `Project.accountProjects.byIds.${id}.counsel`, []).sort((a, b) =>
    a.counsel_type !== 'Other' ? -1 : 1,
  ),
);

export const FirstAttachedPartner = defaultMemoize((state, id) =>
  get(state, `Project.accountProjects.byIds.${id}.attached_partners[0]`, {}),
);

export const getAccountProjectsForHomeView = defaultMemoize(state =>
  get(state, 'accountProjects.byOrder', []).reduce((dict, e) => {
    const submittedTransactions = e.transactions.filter(transaction => !transaction.open);
    const submittedText = `${submittedTransactions.length}/${e.transactions.length} submitted`;
    dict.push({
      ...e,
      submittedText,
    });
    return dict;
  }, []),
);

export const getAccountProjectsForModuleView = defaultMemoize((state, moduleId) =>
  get(state, 'accountProjects.byOrder', []).reduce((dict, e) => {
    if (!moduleId || moduleId === '-1') {
      const submittedTransactions = e.transactions.filter(
        transaction => !transaction.open,
      );
      const submittedText = `${submittedTransactions.length}/${e.transactions.length} submitted`;
      dict.push({
        ...e,
        submittedText,
      });
      return dict;
    }
    if (e.template.module_id + '' === moduleId) {
      const submittedTransactions = e.transactions.filter(
        transaction => !transaction.open,
      );
      const submittedText = `${submittedTransactions.length}/${e.transactions.length} submitted`;
      dict.push({
        ...e,
        submittedText,
      });
    }
    return dict;
  }, []),
);

export const getAccountProject = defaultMemoize((state, id) =>
  get(state, `accountProjects.byIds[${id}]`, {}),
);

export const AccountProject = defaultMemoize((state, id) =>
  get(state, `Project.accountProjects.byIds[${id}]`, {}),
);

export const AccountProjectWithDocuments = createSelector(
  AccountProject,
  DocumentsByProject,
  (project, documents) => {
    const updatedTransactions = project.transactions.reduce(
      (transactions, transaction) => {
        const updatedTasks = transaction.tasks.reduce((dict, e) => {
          const docIndex = documents.findIndex(doc => doc.account_task_id === e.id);
          if (docIndex !== -1) {
            e.document = documents[docIndex];
          }
          dict.push(e);
          return dict;
        }, []);
        transactions.push({
          ...transaction,
          tasks: updatedTasks,
        });
        return transactions;
      },
      [],
    );
    return {
      ...project,
      transactions: updatedTransactions,
    };
  },
);

export const WorkflowsArray = defaultMemoize((state, moduleId) =>
  get(state, 'Project.projects.byOrder', []).reduce((dict, e) => {
    const findSolutionsIndex = feature => feature.id === e.id;
    let isSolutionsHelpShowing = false;
    let solutionsSlug = '';
    let solutionsFeaturesIndex;
    for (const values of Object.entries(SolutionsFeaturesDict)) {
      const innerIndex = values[1].features.findIndex(findSolutionsIndex);
      if (innerIndex !== -1) {
        isSolutionsHelpShowing = true;
        solutionsSlug = values[0];
        solutionsFeaturesIndex = innerIndex;
      }
    }
    if (isSolutionsHelpShowing) {
      e = {
        ...e,
        isSolutionsHelpShowing,
        solutionsSlug,
        solutionsFeaturesIndex,
      };
    }
    dict.push(e);
    return dict;
  }, []),
);

export const GetWorkflows = defaultMemoize((state, moduleId) => {
  let workflows = get(state, 'projects.byOrder', []).reduce((dict, e) => {
    if (!moduleId || e.module_id + '' === moduleId) {
      let isLearnMoreShowing = false;
      let learnMoreUrl = '';
      for (const values of Object.entries(WorkflowLearnMoreLinks)) {
        if (values[0] === e.id + '') {
          isLearnMoreShowing = true;
          learnMoreUrl = values[1];
        }
      }
      e = {
        ...e,
        isLearnMoreShowing,
        learnMoreUrl,
      };
      dict.push(e);
    }
    return dict;
  }, []);
  if (workflows.length > 0) {
    workflows.push({
      description: 'Share Data Room link with investors',
      id: 'share_data_room',
      isDataRoom: true,
      label: 'Share Data Room',
      module_id: 6,
      status: 'Available',
    });
  }
  return workflows;
});

export const getProjects = defaultMemoize((state, moduleId) =>
  get(state, 'projects.byOrder', []).reduce((dict, e) => {
    if (!moduleId || e.module_id + '' === moduleId) {
      let isLearnMoreShowing = false;
      let learnMoreUrl = '';
      for (const values of Object.entries(WorkflowLearnMoreLinks)) {
        if (values[0] === e.id + '') {
          isLearnMoreShowing = true;
          learnMoreUrl = values[1];
        }
      }
      e = {
        ...e,
        isLearnMoreShowing,
        learnMoreUrl,
      };
      dict.push(e);
    }
    return dict;
  }, []),
);

export const getProjectsHomeModule = defaultMemoize((state, moduleId, projects = []) =>
  projects
    .reduce((dict, e) => {
      let isLearnMoreShowing = false;
      let learnMoreUrl = '';
      for (const values of Object.entries(WorkflowLearnMoreLinks)) {
        if (values[0] === e.id + '') {
          isLearnMoreShowing = true;
          learnMoreUrl = values[1];
        }
      }
      e = {
        ...e,
        isLearnMoreShowing,
        learnMoreUrl,
      };
      dict.push(e);
      return dict;
    }, [])
    .sort((a, b) => a.order - b.order),
);

export const getLearnMoreMetaForProjectView = createSelector(
  getAccountProject,
  project => {
    let isLearnMoreShowing = false;
    let learnMoreUrl = '';
    for (const values of Object.entries(WorkflowLearnMoreLinks)) {
      if (values[0] === project.project_template_id + '') {
        isLearnMoreShowing = true;
        learnMoreUrl = values[1];
      }
    }
    return {
      isLearnMoreShowing,
      learnMoreUrl,
    };
  },
);

export const getGroupedTransactionsForProjectView = createSelector(
  getAccountProject,
  project => {
    let initArr = [];
    if (isEmpty(project)) {
      return {};
    }
    if (project.transactions && project.transactions.findIndex(e => !e.group) !== -1) {
      initArr.push({ groupLabel: null, transactions: [] });
    }
    const groupedTransactions = reduce(
      project.transactions,
      (dict, e, index) => {
        const groupIndex = dict.findIndex(
          ({ groupLabel } = {}) => groupLabel === e.group,
        );
        if (groupIndex === -1) {
          dict.push({
            groupLabel: e.group,
            transactions: [e],
          });
        } else {
          dict[groupIndex] = {
            ...dict[groupIndex],
            transactions: [...dict[groupIndex].transactions, e],
          };
        }
        return dict;
      },
      initArr,
    );
    return {
      ...project,
      groupedTransactions,
    };
  },
);
export const getGroupedTransactionsWithDocumentsForProjectView = createSelector(
  AccountProjectWithDocuments,
  project => {
    let initArr = [];
    if (isEmpty(project)) {
      return {};
    }
    if (project.transactions && project.transactions.findIndex(e => !e.group) !== -1) {
      initArr.push({ groupLabel: null, transactions: [] });
    }
    const groupedTransactions = reduce(
      project.transactions,
      (dict, e, index) => {
        const groupIndex = dict.findIndex(
          ({ groupLabel } = {}) => groupLabel === e.group,
        );
        if (groupIndex === -1) {
          dict.push({
            groupLabel: e.group,
            transactions: [e],
          });
        } else {
          dict[groupIndex] = {
            ...dict[groupIndex],
            transactions: [...dict[groupIndex].transactions, e],
          };
        }
        return dict;
      },
      initArr,
    );
    const rooms = get(project, 'rooms', []).reduce((dict, e) => {
      if (e.init_room) {
        dict.push(e);
      }
      return dict;
    }, []);
    return {
      ...project,
      rooms,
      groupedTransactions,
    };
  },
);

/**
 * @return {array} get a module for ModuleView.
 */
export const getProjectsForModuleView = createSelector(
  getAccountProjectsForModuleView,
  getProjects,
  (accountProjects, projects) => {
    let updatedProjects = reduce(
      projects,
      (dict, e) => {
        if (!e.is_repeatable) {
          const projIndex = accountProjects.findIndex(
            val => val.project_template_id === e.id,
          );
          if (projIndex !== -1) {
            let is_started = false;
            let open = false;
            accountProjects[projIndex].transactions.forEach(transaction => {
              if (transaction.is_started) {
                is_started = true;
              }
              if (transaction.open) {
                open = true;
              }
            });
            dict.push({
              ...accountProjects[projIndex],
              ...e,
              is_started,
              open,
            });
          } else {
            dict.push(e);
          }
        } else {
          dict.push(e);
        }
        return dict;
      },
      [],
    );
    updatedProjects.sort((a, b) => a.order - b.order);
    return updatedProjects;
  },
);

export const getFaqsForModuleView = createSelector(getProjects, projects =>
  projects.reduce((dict, e, ind) => {
    const faqIndex = dict.findIndex(faq => faq.id === e.id);
    if (!isEmpty(e.faqs) && faqIndex === -1) {
      e.faqs.forEach(faq => dict.push(faq));
    }
    return dict;
  }, []),
);

export const getFaqsForProjectView = createSelector(
  getAccountProject,
  project =>
    !isEmpty(project) &&
    project.transactions.reduce((dict, e, ind) => {
      const faqs = get(e, 'template.faqs', []);
      if (!isEmpty(faqs)) {
        faqs.forEach(faq => {
          if (dict.findIndex(val => val.id === faq.id) === -1) {
            dict.push(faq);
          }
        });
      }
      return dict;
    }, []),
);

/**
 * @return {array} get a module for ModuleView.
 */
export const getProjectsForHomeView = createSelector(
  getAccountProjectsForModuleView,
  getProjectsHomeModule,
  (accountProjects, projects) => {
    const updatedProjects = reduce(
      projects,
      (dict, e) => {
        if (accountProjects.findIndex(val => val.project_template_id === e.id) === -1) {
          dict.push(e);
        }
        return dict;
      },
      [],
    ).sort((a, b) => a.order - b.order);
    return updatedProjects;
  },
);
