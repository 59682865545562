import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import startCase from 'lodash-es/startCase';

import Topbar from '../../components/ConsistentTopbar';
import SavviLoading from '../../components/SavviLoading/SavviLoading';
import Sidebar from '../Sidebar';
// import SubscriptionsView from '../SubscriptionsModal/SubscriptionsViewContainer';
// import WorkbenchOverview from '../WorkbenchOverview';
// import WorkbenchTaskOverview from '../WorkbenchTaskOverview';
import handlePath from '../../utils/handlePath';
import Loading from '../../components/LoadingComponent';
import UseWindowSize from '../../utils/UseWindowSize';

import './Workbench.scss';

const AsyncWorkbenchOverview = Loadable({
  loader: () => import('../WorkbenchOverview'),
  loading: Loading,
});
const AsyncWorkbenchTaskOverview = Loadable({
  loader: () => import('../WorkbenchTaskOverview'),
  loading: Loading,
});
const AsyncSubscriptionsView = Loadable({
  loader: () => import('../SubscriptionsModal/SubscriptionsViewContainer'),
  loading: Loading,
});

const Workbench = ({
  accountInfo: { credits_info, subscriptions = [] },
  handleExitTransaction,
  isFetchingInit,
  formikValues,
  params,
  transaction,
}) => {
  const { companyId, moduleId, projectId, transactionId } = params;

  const { width } = UseWindowSize();

  const availableCredits = credits_info && credits_info.limit - credits_info.used;

  let isSubscription = false;
  let mySubscriptions = [];
  let isSubscribed = false;
  let documentCredits = 0;

  !isEmpty(transaction) &&
    transaction.tasks.forEach((e, index) => {
      if (!e.bypassed) {
        documentCredits += e.template.document_counter || 0;
      }
      const innerSubs = get(e, 'template.prerequisites.subscriptions', []);
      if (innerSubs.length > 0) {
        isSubscription = true;
        mySubscriptions = innerSubs;
        isSubscribed = mySubscriptions.find(
          sub => subscriptions.findIndex(accountSub => accountSub === sub) !== -1,
        );
      }
    });

  const isDocumentCreditsNeeded =
    availableCredits < 1 &&
    documentCredits > 0 &&
    !!transaction.open &&
    params.step !== 'overview' &&
    !get(transaction, 'template.is_assessment', false);

  const [isInfoSidebarCollapsed, setIsInfoSidebarCollapsed] = useState(width < 768);

  useEffect(() => {
    if (width > 876 && isInfoSidebarCollapsed) {
      setIsInfoSidebarCollapsed(false);
    }
  }, [width, isInfoSidebarCollapsed]);

  if (isFetchingInit) {
    return (
      <div className="component__loader">
        <SavviLoading />
      </div>
    );
  }
  return (
    <div
      className={`workbench__container${
        isInfoSidebarCollapsed ? ' info-sidebar-collapsed' : ''
      }`}
    >
      <Sidebar isWorkbench />
      <Topbar isWorkbench />
      <div className="workbench__main">
        {isDocumentCreditsNeeded && (
          <div className="workbench__banner">
            <h4>
              <FontAwesomeIcon icon="exclamation-circle" />
              You currently do not have enough credits to submit this Task. Increase your
              credit limit by{' '}
              <Link
                to={handlePath(
                  `/workbench/${moduleId}/${projectId}/${transactionId}/subscriptions`,
                  companyId,
                )}
              >
                Upgrading your plan
              </Link>
            </h4>
          </div>
        )}
        {isSubscription &&
          !isSubscribed &&
          !!transaction.open &&
          !transaction.is_assessment && (
            <div
              className={`workbench__banner${isDocumentCreditsNeeded ? ' second' : ''}`}
            >
              <h4>
                <FontAwesomeIcon icon="exclamation-circle" />
                {startCase(mySubscriptions.join(', '))} Subscription(s) required to
                complete this task.{' '}
                <Link
                  to={handlePath(
                    `/workbench/${moduleId}/${projectId}/${transactionId}/subscriptions`,
                    companyId,
                  )}
                >
                  Sign up here
                </Link>
              </h4>
            </div>
          )}
        <Switch>
          <Route
            path={handlePath(
              `/workbench/${moduleId}/${projectId}/${transactionId}/overview`,
              companyId,
            )}
          >
            <AsyncWorkbenchOverview
              handleExitTransaction={handleExitTransaction}
              params={params}
            />
          </Route>
          <Route
            exact
            path={handlePath(
              `/workbench/${moduleId}/${projectId}/${transactionId}/task-view`,
              companyId,
            )}
          >
            <AsyncWorkbenchTaskOverview
              documentCredits={documentCredits}
              formikValues={formikValues}
              isDocumentCreditsNeeded={isDocumentCreditsNeeded}
              isSubscriptionNeeded={isSubscription && !isSubscribed}
              params={params}
            />
          </Route>
        </Switch>
        <Route
          path={handlePath(
            `/workbench/${moduleId}/${projectId}/${transactionId}/subscriptions`,
            companyId,
          )}
          render={routeProps => (
            <AsyncSubscriptionsView params={params} {...routeProps} />
          )}
        />
      </div>
      {width < 876 && (
        <div
          className="workbench__toggle-info-sidebar"
          onClick={e => setIsInfoSidebarCollapsed(!isInfoSidebarCollapsed)}
        >
          <FontAwesomeIcon
            icon={['fal', isInfoSidebarCollapsed ? 'arrow-to-left' : 'arrow-from-left']}
          />
        </div>
      )}
    </div>
  );
};

Workbench.propTypes = {
  isFetching: PropTypes.bool,
  formTitle: PropTypes.string,
  status: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  values: PropTypes.object,
};

Workbench.defaultProps = {
  status: {},
};

export default Workbench;
