import React, { Component, Fragment, createRef } from 'react';
import DropdownMenu from 'react-dd-menu';
import { components } from 'react-select';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import toLower from 'lodash-es/toLower';
import trim from 'lodash-es/trim';

import { InputSelect, InputStyledTextField } from '../../inputs';
import { Button } from '../../common';

import './ResourceSelect.scss';

class ResourceSelect extends Component {
  constructor(props) {
    super(props);
    this.textInput = createRef();
    this.state = {
      initVal: this.props.field.value,
      changeOnBlur: false,
      isOpen: false,
      isEditable: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      field: { name },
      form: { setFieldValue },
      isLabel,
      isNewResource,
      handleResourceName,
      options,
      setLabel,
    } = this.props;
    const { changeOnBlur, inputValue } = this.state;
    if (isNewResource && changeOnBlur && !isEmpty(inputValue)) {
      const optionIndex = options.findIndex(
        option => toLower(option.label) === toLower(inputValue),
      );
      let value = this.createOption(inputValue);
      if (optionIndex !== -1) {
        value = options[optionIndex];
      }
      handleResourceName(value);
      setFieldValue(name, value);
      if (isLabel) {
        setLabel(inputValue);
      }
      this.setState({ changeOnBlur: false });
    } else if (changeOnBlur) {
      this.setState({ changeOnBlur: false });
    }
  }

  onToggleClick = e => {
    e.preventDefault();
    this.setState({ isOpen: !this.state.isOpen });
  };

  toggleActions = () => (
    <div
      className={classnames('select-actions', { selected: this.state.isOpen })}
      onClick={this.onToggleClick}
    >
      <FontAwesomeIcon icon="ellipsis-v" />
    </div>
  );

  createOption = (label, __isNew__) => ({
    label,
    value: label.toLowerCase(),
    __isNew__,
  });

  Option = props => {
    return (
      <div
        className={`select-resource__option${
          props.innerProps.isFocused ? ' select-resource__option--focus' : ''
        }`}
      >
        <components.Option {...props} />
        {!props.data.finalized && !props.data.__isNew__ && !props.data.is_state && (
          <Button
            onClick={() =>
              this.props.handleDelete(props.data.resourceId, props.data.label)
            }
            buttonType="icon"
            isWarning
            size="sm"
            tooltip="Remove from List"
            tooltipAlign="right"
            type="button"
          >
            <FontAwesomeIcon icon={['fal', 'trash-alt']} />
          </Button>
        )}
      </div>
    );
  };

  render() {
    const {
      field: { name, value: fieldValue, onChange, onBlur },
      form: { errors, setFieldTouched, setFieldValue, status, touched },
      handleFormBlur,
      handleRemoveResource,
      handleResourceName,
      isCompany,
      isDisabled,
      isLabel,
      isNewResource,
      isOutsideForm,
      multi,
      multiEntry,
      options,
      resourceTypeName,
      resourceTypeCustomLabel,
      setLabel,
      isFixed,
      ...props
    } = this.props; // everything that we should pass goes through here classnames etc
    const isMulti = !!multiEntry || multi;

    const apiError = get(status, name, false);
    const isTouched = get(touched, name, false);
    const error = apiError || (isTouched && get(errors, name, null));
    let updatedOptions = options;
    if (!Array.isArray(options) && !options[0].value) {
      updatedOptions = options.split(',').map(resource => ({
        value: resource,
        label: resource,
      }));
    }

    let updatedVal = fieldValue;
    if (updatedVal && typeof updatedVal === 'string') {
      if (fieldValue.indexOf('|')) {
        updatedVal = updatedVal.split('|').map(e => this.createOption(e));
      } else {
        updatedVal = this.createOption(fieldValue);
      }
    } else if (Array.isArray(updatedVal) && typeof updatedVal[0] === 'string') {
      updatedVal = updatedVal.map(e => this.createOption(e));
      setFieldValue(name, updatedVal);
    }

    if (!isNewResource) {
      const menuOptions = {
        toggle: this.toggleActions(),
        isOpen: this.state.isOpen,
        close: this.onToggleClick,
        closeOnInsideClick: true,
        animate: true,
        align: 'right',
        className: 'select-actions-popup',
      };
      return (
        <div className="select-resource">
          <InputStyledTextField
            error={error}
            ref={this.textInput}
            isDisabled={isDisabled}
            isFixed={isFixed || !this.state.isEditable}
            name={name}
            onBlur={(e = {}) => {
              const { target: { value } = {} } = e;
              if (isEmpty(trim(value))) {
                setFieldValue(name, this.state.initVal);
                this.setState({ isEditable: false });
                return;
              }
              if (isLabel) {
                setLabel(value);
              }
              onBlur(e);
              handleResourceName(value);
              this.setState({ isEditable: false, initVal: value });
            }}
            onChange={onChange}
            value={get(fieldValue, 'value', fieldValue)}
            {...props}
          />
          {!isFixed && !isOutsideForm && !isDisabled && (
            <DropdownMenu {...menuOptions}>
              <li
                onClick={() =>
                  this.setState({ isEditable: true }, () =>
                    this.textInput.current.focus(),
                  )
                }
              >
                Edit Saved Name
              </li>
              {/* <li onClick={handleChangeResource}>
                Change {resourceTypeCustomLabel}
              </li> */}
              <li onClick={handleRemoveResource}>Remove {resourceTypeCustomLabel}</li>
            </DropdownMenu>
          )}
        </div>
      );
    }

    return (
      <Fragment>
        <InputSelect
          components={{ Option: this.Option }}
          error={error}
          isDisabled={isDisabled}
          isFixed={isFixed}
          isMulti={isMulti}
          name={name}
          onBlur={(e, action) => {
            setFieldTouched(name, true);
          }}
          onChange={(value, { action }) => {
            if (action === 'clear' && isEmpty(value) && isEmpty(updatedVal)) {
              return;
            }
            setFieldValue(name, value);
            return handleResourceName(value);
          }}
          onInputChange={(inputValue, { action }) => {
            // if (action !== 'menu-close') {
            //   this.setState({ inputValue });
            // }
            if (action === 'input-blur') {
              this.setState({ changeOnBlur: true });
              // if (inputValue && inputValue.label) {
              //   handleResourceName(inputValue);
              // }
            } else if (action !== 'menu-close') {
              this.setState({ inputValue });
            }
          }}
          value={updatedVal}
          options={updatedOptions}
          {...props}
        />
      </Fragment>
    );
  }
}

ResourceSelect.defaultProps = {
  handleChangeResource: () => {},
  handleFormBlur: () => {},
  handleResourceName: () => {},
  options: [],
};

export default ResourceSelect;
