import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'moment';
import get from 'lodash-es/get';
import classnames from 'classnames';

import { getCompanies, setCompany } from '../../redux/modules/Company/operations';
import { IsMultiCompany, MyCompanies } from '../../redux/modules/Company/selectors';
import { getAccountId } from '../../redux/modules/Formation/selectors';
import { IsPartner } from '../../redux/modules/User/selectors';

import './CompanySelect.scss';
import PageLoader from '../../components/PageLoader';
// import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { useHistory, useLocation, useParams } from 'react-router';
import handlePath from '../../utils/handlePath';
import { Button } from '../../components/common';

const sortNumbers = (a, b, dir) => {
  let isAGreater = a > b;
  let isBGreater = a < b;

  if (isAGreater) {
    return dir === 'asc' ? 1 : -1;
  }
  if (isBGreater) {
    return dir === 'asc' ? -1 : 1;
  }
  return 0;
};

const sortStrings = (a, b, dir) => {
  const aLower = a.toLowerCase();
  const bLower = b.toLowerCase();

  let isAGreater = aLower > bLower;
  let isBGreater = aLower < bLower;

  if (isAGreater) {
    return dir === 'asc' ? 1 : -1;
  }
  if (isBGreater) {
    return dir === 'asc' ? -1 : 1;
  }
  return 0;
};

const CompanySelect = () => {
  const history = useHistory();
  const location = useLocation();
  const { companyId } = useParams();
  const dispatch = useDispatch();
  const { search } = location;
  const companies = useSelector(MyCompanies);
  const isMultiCompany = useSelector(IsMultiCompany);
  const isPartner = useSelector(IsPartner);
  const currentAccountId = useSelector(getAccountId);

  const [dir, setDir] = useState('desc');
  const [isFetchingInit, setFetchingInit] = useState(true);
  const [filter, setFilterSlug] = useState('date');
  const [myCompanies, setCompanies] = useState(companies);

  useEffect(() => {
    const url = window.location.href;
    if (companies.length === 0 && isFetchingInit && !url.includes('/new')) {
      setFetchingInit(false);
      dispatch(getCompanies());
    }
  }, [dispatch, companies.length, isFetchingInit]);

  useEffect(() => {
    setCompanies(companies);
  }, [companies]);

  const setFilter = useCallback(
    clickedFilter => {
      let updatedDir = dir;
      let updatedFilter = filter;
      if (clickedFilter === filter) {
        updatedDir = dir === 'desc' ? 'asc' : 'desc';
      }
      if (clickedFilter && clickedFilter !== filter) {
        updatedDir = 'desc';
      }
      updatedFilter = clickedFilter || filter;

      const updatedTasks = myCompanies.sort((a, b) => {
        if (updatedFilter === 'name') {
          return sortStrings(a.name, b.name, updatedDir);
        }
        if (updatedFilter === 'role') {
          return sortStrings(a.role, b.role, updatedDir);
        }
        if (updatedFilter === 'date') {
          return updatedDir === 'desc'
            ? Moment(a.created_at).diff(Moment(b.created_at))
            : Moment(b.created_at).diff(Moment(a.created_at));
        }
        if (updatedFilter === 'partnerGroup') {
          return sortStrings(a.partner, b.partner, updatedDir);
        }
        if (updatedFilter === 'missingSignatures') {
          return sortNumbers(a.documents.length, b.documents.length, updatedDir);
        }
        return updatedDir === 'desc'
          ? Moment(a.created_at).diff(Moment(b.created_at))
          : Moment(b.created_at).diff(Moment(a.created_at));
      });
      setDir(updatedDir);
      setFilterSlug(updatedFilter);
      setCompanies(updatedTasks);
    },
    [dir, filter, myCompanies, setDir, setFilterSlug, setCompanies],
  );
  return (
    <div className="dashboard__content">
      <section className="company-select__header">
        <h1>
          Pick your Company Workspace
          <span>
            <Button
              buttonType="link"
              size="lg"
              onClick={() =>
                history.push(
                  handlePath(
                    {
                      pathname: '/company-select/new',
                      search,
                      state: { background: location },
                    },
                    companyId,
                  ),
                )
              }
            >
              <FontAwesomeIcon icon={['fal', 'plus']} /> Add Company
            </Button>
          </span>
        </h1>
      </section>
      {isFetchingInit && companies.length === 0 && <PageLoader />}
      {!isFetchingInit && companies.length === 0 && (
        <div className="company-select__list-none">
          <h2>Currently no Company Connected.</h2>
        </div>
      )}
      {companies.length > 0 && (
        <section
          className={classnames('company-select__list', {
            fourCols: !isPartner && isMultiCompany,
            fiveCols: isPartner,
          })}
        >
          <div className="company-select__list-headers">
            <h4 className="company-select__list-header" onClick={() => setFilter('name')}>
              Name
              {filter === 'name' && (
                <FontAwesomeIcon
                  className="transaction-progress__sorting-icon"
                  icon={dir === 'desc' ? 'caret-down' : 'caret-up'}
                />
              )}
              {filter !== 'name' && <div className="transaction-progress__placeholder" />}
            </h4>
            <h4 className="company-select__list-header" onClick={() => setFilter('date')}>
              Date Created
              {filter === 'date' && (
                <FontAwesomeIcon
                  className="transaction-progress__sorting-icon"
                  icon={dir === 'desc' ? 'caret-down' : 'caret-up'}
                />
              )}
            </h4>
            <h4 className="company-select__list-header" onClick={() => setFilter('role')}>
              Role
              {filter === 'role' && (
                <FontAwesomeIcon
                  className="transaction-progress__sorting-icon"
                  icon={dir === 'desc' ? 'caret-down' : 'caret-up'}
                />
              )}
            </h4>
            {isPartner && (
              <h4
                className="company-select__list-header"
                onClick={() => setFilter('partnerGroup')}
              >
                Partner Group
                {filter === 'partnerGroup' && (
                  <FontAwesomeIcon
                    className="transaction-progress__sorting-icon"
                    icon={dir === 'desc' ? 'caret-down' : 'caret-up'}
                  />
                )}
              </h4>
            )}
            {(isPartner || isMultiCompany) && (
              <h4
                className="company-select__list-header"
                onClick={() => setFilter('missingSignatures')}
              >
                Missing Signatures
                {filter === 'missingSignatures' && (
                  <FontAwesomeIcon
                    className="transaction-progress__sorting-icon"
                    icon={dir === 'desc' ? 'caret-down' : 'caret-up'}
                  />
                )}
              </h4>
            )}
          </div>
          {companies.map(company => {
            const included_states = [
              'document.sent',
              'document.viewed',
              'document.waiting_approval',
              'document.waiting_pay',
            ];
            const filteredDocuments = company.documents.filter(e =>
              included_states.includes(get(e, 'status', '')),
            );
            return (
              <div
                className="company-select__list-row"
                key={`company-option-${company.id}`}
              >
                <div
                  className="company-select__list-item"
                  onClick={() =>
                    dispatch(
                      setCompany(
                        company.id,
                        { pathname: '/home', search },
                        company.id === currentAccountId,
                      ),
                    )
                  }
                >
                  <h4
                    className="company-select__list-item-company-name"
                    style={{ fontSize: 16 }}
                  >
                    {company.name}
                    {company.id === currentAccountId && ' (current)'}
                  </h4>
                </div>
                <div className="company-select__list-item">
                  <h4 className="company-select__list-item-label">
                    {Moment(company.created_at).format('L')}
                  </h4>
                </div>
                <div className="company-select__list-item">
                  <h4 className="company-select__list-item-label">
                    {company.role || '--'}
                  </h4>
                </div>
                {isPartner && (
                  <div className="company-select__list-item">
                    <h4 className="company-select__list-item-label">
                      {company.partner || '--'}
                    </h4>
                  </div>
                )}
                {(isPartner || isMultiCompany) && (
                  <div className="company-select__list-item">
                    <h4 className="company-select__list-item-label">
                      {filteredDocuments.length || '--'}
                    </h4>
                  </div>
                )}
              </div>
            );
          })}
          {/* <div
          className="company-select__add-entity"
          onClick={() =>
            history.push(
              handlePath(
                {
                  pathname: '/company-select/new',
                  search,
                  state: { background: location },
                },
                companyId,
              ),
            )
          }
        >
          <FontAwesomeIcon icon={['fal', 'plus']} /> Add Company
        </div> */}
        </section>
      )}
    </div>
  );
};

export default CompanySelect;
