import { get, isPlainObject } from 'lodash-es';
import { RSAA } from 'redux-api-middleware';

import { requestHeaders } from '../../utils/api';
import { setTransactionQuestionnaire } from '../Transaction/actions';
// import { setTransactionQuestionnaire } from '../Transaction/actions';
import { setNotice } from '../UI/actions';
import { setFeaturesForRoom, setLink, setRoom, setRoomToken } from './actions';
import * as Types from './types';

// import { addProductToCart, setCart, emptyCart } from './actions';

export const fetchRoom = (access_code, account_id) => {
  const REQUEST = Types[`FETCH_ROOM_REQUEST`];
  const SUCCESS = Types[`FETCH_ROOM_SUCCESS`];
  const FAILED = Types[`FETCH_ROOM_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/unlisted/room/${access_code}`,
        method: 'GET',
        headers: requestHeaders(true, account_id),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      await dispatch(setRoom(actionResponse.payload, access_code));
      if (actionResponse.payload.outside_forms.length > 0) {
        actionResponse.payload.outside_forms.forEach(form => {
          dispatch(setTransactionQuestionnaire(form, form.questionnaire.access_code));
        });
      }
      return await actionResponse.payload;
    }
    // await get(
    //   actionResponse,
    //   'payload.message',
    //   'This form has already been completed.  Please contact technical support if you believe this is in error.',
    // );
    await dispatch(setRoom(actionResponse.payload, access_code));
    throw await actionResponse.payload;
  };
};

export const updateRoom = (body, roomId, access_code, account_id) => {
  /**
   * body:
  {
    "open": true,
    "require_auth":false,
    "strict":false,
    "label":"We're The Best Data Room Eva!!!"
  }
   */
  const REQUEST = Types[`PUT_ROOM_REQUEST`];
  const SUCCESS = Types[`PUT_ROOM_SUCCESS`];
  const FAILED = Types[`PUT_ROOM_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/room/${roomId}`,
        method: 'PUT',
        headers: requestHeaders(true, account_id),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      dispatch(setNotice('Room Updated.'));
      if (access_code) {
        dispatch(fetchRoom(access_code, account_id));
      }
      return await actionResponse.payload;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(actionResponse, 'payload.message', 'Failed to update room.'),
      }),
    );
    throw await get(actionResponse, 'payload.message', 'Failed to update room.');
  };
};

export const fetchFeaturesForRoom = (key, query, account_id) => {
  const REQUEST = Types[`FETCH_FEATURES_FOR_ROOM_REQUEST`];
  const SUCCESS = Types[`FETCH_FEATURES_FOR_ROOM_SUCCESS`];
  const FAILED = Types[`FETCH_FEATURES_FOR_ROOM_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/feature${query || ''}`,
        method: 'GET',
        headers: requestHeaders(true, account_id),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      await dispatch(setFeaturesForRoom(actionResponse.payload, key));
      return await actionResponse.payload;
    }
    throw await actionResponse.payload;
    // throw await get(
    //   actionResponse,
    //   'payload.message',
    //   'This form has already been completed.  Please contact technical support if you believe this is in error.',
    // );
  };
};

export const createRoomFeatures = (features, baseBody, account_id) => {
  return async dispatch => {
    return features.reduce(async (previousPromise, feature) => {
      return await previousPromise.then(
        e =>
          dispatch(
            createRoomFeature(
              { ...baseBody, feature_type_id: feature.feature_type_id },
              null,
              account_id,
            ),
          ),
        error => {
          console.warn('error', error);
          return Promise.resolve();
        },
      );
    }, Promise.resolve(100));
  };
};

export const createRoomFeature = (body, access_code, account_id) => {
  /**
   * body:
    {
      "room_id":1,
      "feature_type_id":406,
      "resource_id":730,
      "resource_variable_name": "Bob",
      "resource_custom_label": "Blob"
    }
   */
  const REQUEST = Types[`POST_ROOM_FEATURE_REQUEST`];
  const SUCCESS = Types[`POST_ROOM_FEATURE_SUCCESS`];
  const FAILED = Types[`POST_ROOM_FEATURE_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/account/room/feature',
        method: 'POST',
        headers: requestHeaders(true, account_id),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      if (access_code) {
        await dispatch(fetchRoom(access_code, account_id));
      }
      return await actionResponse.payload;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(actionResponse, 'payload.message', 'Failed to create room feature.'),
      }),
    );
    throw await get(actionResponse, 'payload.message', 'Failed to create room feature');
  };
};

export const updateRoomFeatures = (resources, body, account_id) => {
  return async dispatch => {
    const features = Object.entries(resources).reduce((dict, [resourceKey, resource]) => {
      dict = [...dict, ...resource.features];
      return dict;
    }, []);
    return features.reduce(async (previousPromise, feature) => {
      return await previousPromise.then(
        e => dispatch(updateRoomFeature(feature.room_feature_id, body, null, account_id)),
        error => {
          console.warn('error', error);
          return Promise.resolve();
        },
      );
    }, Promise.resolve(100));
  };
};

export const updateRoomFeature = (roomFeatureId, body, access_code, account_id) => {
  /**
   * body:
  {
    "resource_variable_name": "bobby",
    "resource_custom_label":"Blobby"
  }
   */
  const REQUEST = Types[`PUT_ROOM_FEATURE_REQUEST`];
  const SUCCESS = Types[`PUT_ROOM_FEATURE_SUCCESS`];
  const FAILED = Types[`PUT_ROOM_FEATURE_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/room/feature/${roomFeatureId}`,
        method: 'PUT',
        headers: requestHeaders(true, account_id),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      if (access_code) {
        await dispatch(fetchRoom(access_code, account_id));
      }
      return await actionResponse.payload;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(actionResponse, 'payload.message', 'Failed to update room feature.'),
      }),
    );
    throw await get(actionResponse, 'payload.message', 'Failed to update room feature');
  };
};

export const deleteRoomFeatures = (collection, account_id) => {
  return async dispatch => {
    let features = collection;
    if (isPlainObject(collection)) {
      features = Object.entries(collection).reduce((dict, [resourceKey, resource]) => {
        dict = [...dict, ...resource.features];
        return dict;
      }, []);
    }
    return features.reduce(async (previousPromise, feature) => {
      return await previousPromise.then(
        e => dispatch(deleteRoomFeature(feature.room_feature_id, null, account_id)),
        error => {
          console.warn('error', error);
          return Promise.resolve();
        },
      );
    }, Promise.resolve(100));
  };
};

export const deleteRoomFeature = (roomFeatureId, access_code, account_id) => {
  /**
   * body:
  {
    "room_id": 1,
  }
   */
  const REQUEST = Types[`DELETE_ROOM_FEATURE_REQUEST`];
  const SUCCESS = Types[`DELETE_ROOM_FEATURE_SUCCESS`];
  const FAILED = Types[`DELETE_ROOM_FEATURE_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/room/feature/${roomFeatureId}`,
        method: 'DELETE',
        headers: requestHeaders(true, account_id),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      if (access_code) {
        dispatch(setNotice('Room field removed.'));
        await dispatch(fetchRoom(access_code, account_id));
      }
      return await actionResponse.payload;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(actionResponse, 'payload.message', 'Failed to delete room feature.'),
      }),
    );
    throw await get(actionResponse, 'payload.message', 'Failed to delete room feature');
  };
};

export const createRoomLink = (body, access_code, account_id) => {
  /**
   * body:
  {
    "room_id":1,
    "label": "Let there be light",
    "link": "https://bulbs.org"
  }
   */
  const REQUEST = Types[`POST_ROOM_LINK_REQUEST`];
  const SUCCESS = Types[`POST_ROOM_LINK_SUCCESS`];
  const FAILED = Types[`POST_ROOM_LINK_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/account/room/link',
        method: 'POST',
        headers: requestHeaders(true, account_id),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      await dispatch(fetchRoom(access_code, account_id));
      dispatch(setNotice('Room field added.'));
      return await actionResponse.payload;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(actionResponse, 'payload.message', 'Failed to create room link.'),
      }),
    );
    throw await get(actionResponse, 'payload.message', 'Failed to create room link');
  };
};

export const updateRoomLink = (body, roomLinkId, access_code, account_id) => {
  /**
   * body:
  {
    "room_id":1
  }
   */
  const REQUEST = Types[`PUT_ROOM_LINK_REQUEST`];
  const SUCCESS = Types[`PUT_ROOM_LINK_SUCCESS`];
  const FAILED = Types[`PUT_ROOM_LINK_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/room/link/${roomLinkId}`,
        method: 'PUT',
        headers: requestHeaders(true, account_id),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      dispatch(setNotice('Room Link Updated.'));
      await dispatch(fetchRoom(access_code));
      return await actionResponse.payload;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(actionResponse, 'payload.message', 'Failed to update room link.'),
      }),
    );
    throw await get(actionResponse, 'payload.message', 'Failed to update room link');
  };
};

export const deleteRoomLink = (roomLinkId, access_code, account_id) => {
  const REQUEST = Types[`DELETE_ROOM_LINK_REQUEST`];
  const SUCCESS = Types[`DELETE_ROOM_LINK_SUCCESS`];
  const FAILED = Types[`DELETE_ROOM_LINK_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/room/link/${roomLinkId}`,
        method: 'DELETE',
        headers: requestHeaders(true, account_id),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      await dispatch(fetchRoom(access_code, account_id));
      dispatch(setNotice('Room Link Deleted.'));
      return await actionResponse.payload;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(actionResponse, 'payload.message', 'Failed to delete room link.'),
      }),
    );
    throw await get(actionResponse, 'payload.message', 'Failed to delete room link');
  };
};

export const createRoomDocument = (body, access_code, account_id) => {
  /**
   * body:
  {
    "room_id":1,
    "label": "Let there be light",
    "link": "https://bulbs.org"
  }
   */
  const REQUEST = Types[`POST_ROOM_DOCUMENT_REQUEST`];
  const SUCCESS = Types[`POST_ROOM_DOCUMENT_SUCCESS`];
  const FAILED = Types[`POST_ROOM_DOCUMENT_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/account/room/document',
        method: 'POST',
        headers: requestHeaders(true, account_id),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      await dispatch(fetchRoom(access_code, account_id));
      dispatch(setNotice('Room Document Added.'));
      return await actionResponse.payload;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(actionResponse, 'payload.message', 'Failed to add room document.'),
      }),
    );
    throw await get(actionResponse, 'payload.message', 'Failed to add room document');
  };
};

export const deleteRoomDocument = (roomDocumentId, access_code, account_id) => {
  const REQUEST = Types[`DELETE_ROOM_DOCUMENT_REQUEST`];
  const SUCCESS = Types[`DELETE_ROOM_DOCUMENT_SUCCESS`];
  const FAILED = Types[`DELETE_ROOM_DOCUMENT_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/room/document/${roomDocumentId}`,
        method: 'DELETE',
        headers: requestHeaders(true, account_id),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      await dispatch(fetchRoom(access_code, account_id));
      dispatch(setNotice('Room Document Removed.'));
      return await actionResponse.payload;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(
          actionResponse,
          'payload.message',
          'Failed to remove room document.',
        ),
      }),
    );
    throw await get(actionResponse, 'payload.message', 'Failed to remove room document');
  };
};

export const addRoomUser = (body, access_code, account_id) => {
  /**
   * body:
  {
    "room_id":1,
    "email":"brack@savvi.legal"
  }
   */
  const REQUEST = Types[`POST_ROOM_USER_REQUEST`];
  const SUCCESS = Types[`POST_ROOM_USER_SUCCESS`];
  const FAILED = Types[`POST_ROOM_USER_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/unlisted/room/user',
        method: 'POST',
        headers: requestHeaders(true, account_id),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      dispatch(setNotice('User added.'));
      await dispatch(fetchRoom(access_code, account_id));
      return await actionResponse.payload;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(actionResponse, 'payload.message', 'Failed to add user to room.'),
      }),
    );
    throw await get(actionResponse, 'payload.message', 'Failed to add user to room');
  };
};

export const updateRoomUser = (body, roomUserId, access_code, account_id) => {
  /**
   * body:
  {
    "invite_rights":0 // They can add people, allows them to hit post invite to invite user by email
  }
   */
  const REQUEST = Types[`PUT_ROOM_USER_REQUEST`];
  const SUCCESS = Types[`PUT_ROOM_USER_SUCCESS`];
  const FAILED = Types[`PUT_ROOM_USER_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/room/user/${roomUserId}`,
        method: 'PUT',
        headers: requestHeaders(true, account_id),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      await dispatch(fetchRoom(access_code, account_id));
      return await actionResponse.payload;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(actionResponse, 'payload.message', 'Failed to update room user.'),
      }),
    );
    throw await get(actionResponse, 'payload.message', 'Failed to update room user');
  };
};

export const deleteRoomUser = (roomUserId, access_code, account_id) => {
  const REQUEST = Types[`DELETE_ROOM_USER_REQUEST`];
  const SUCCESS = Types[`DELETE_ROOM_USER_SUCCESS`];
  const FAILED = Types[`DELETE_ROOM_USER_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/room/user/${roomUserId}`,
        method: 'DELETE',
        headers: requestHeaders(true, account_id),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      await dispatch(fetchRoom(access_code, account_id));
      return await actionResponse.payload;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(
          actionResponse,
          'payload.message',
          'Failed to remove user from room.',
        ),
      }),
    );
    throw await get(actionResponse, 'payload.message', 'Failed to remove user from room');
  };
};

export const swapRoomLinks = (id1, id2, access_code, account_id) => {
  const REQUEST = Types[`SWAP_ROOM_LINKS_REQUEST`];
  const SUCCESS = Types[`SWAP_ROOM_LINKS_SUCCESS`];
  const FAILED = Types[`SWAP_ROOM_LINKS_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/room/link/swap/${id1}/${id2}`,
        method: 'PATCH',
        headers: requestHeaders(true, account_id),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      await dispatch(setNotice('Room links swapped.'));
      await dispatch(fetchRoom(access_code, account_id));
      return await actionResponse.payload;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(actionResponse, 'payload.message', 'Failed to swap room links.'),
      }),
    );
    throw await get(actionResponse, 'payload.message', 'Failed to swap room links.');
  };
};

export const swapRoomDocuments = (id1, id2, access_code, account_id) => {
  const REQUEST = Types[`SWAP_ROOM_DOCUMENTS_REQUEST`];
  const SUCCESS = Types[`SWAP_ROOM_DOCUMENTS_SUCCESS`];
  const FAILED = Types[`SWAP_ROOM_DOCUMENTS_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/room/document/swap/${id1}/${id2}`,
        method: 'PATCH',
        headers: requestHeaders(true, account_id),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      await dispatch(setNotice('Room documents swapped.'));
      await dispatch(fetchRoom(access_code, account_id));
      return await actionResponse.payload;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(actionResponse, 'payload.message', 'Failed to swap room documents.'),
      }),
    );
    throw await get(actionResponse, 'payload.message', 'Failed to swap room documents.');
  };
};

/** *** End of DataRoom Editing */

export const fetchRoomToken = access_code => {
  const REQUEST = Types[`FETCH_ROOM_TOKEN_REQUEST`];
  const SUCCESS = Types[`FETCH_ROOM_TOKEN_SUCCESS`];
  const FAILED = Types[`FETCH_ROOM_TOKEN_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/unlisted/room/${access_code}/token`,
        method: 'GET',
        headers: requestHeaders(),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      await dispatch(setRoomToken(actionResponse.payload, access_code));
      return await actionResponse.payload;
    }
    throw await get(
      actionResponse,
      'payload.message',
      'This form has already been completed.  Please contact technical support if you believe this is in error.',
    );
  };
};

export const fetchBoxLinkById = (folderId, accountId) => {
  const REQUEST = Types['FETCH_LINK_BY_ID_REQUEST'];
  const SUCCESS = Types['FETCH_LINK_BY_ID_SUCCESS'];
  const FAILED = Types['FETCH_LINK_BY_ID_FAILED'];

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/shared_link/${folderId}`,
        method: 'GET',
        headers: requestHeaders(true, accountId),
        types: [REQUEST, SUCCESS, FAILED],
        bailout: state => state.Data.isFetching,
      },
    });
    if (!actionResponse.error) {
      if (!actionResponse.payload.url) {
        dispatch(
          setNotice({
            type: 'error',
            message:
              'Unable to fetch folder link. If problem persists, please message our support team.',
          }),
        );
      }
      return await actionResponse.payload;
    } else {
      dispatch(
        setNotice({
          type: 'error',
          message: actionResponse.message || 'failed to fetch Box link',
        }),
      );
      throw actionResponse.payload;
    }
  };
};

const getDataRoomLink = () => {
  const REQUEST = Types['GET_LINK_REQUEST'];
  const SUCCESS = Types['GET_LINK_SUCCESS'];
  const FAILED = Types['GET_LINK_FAILED'];

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/shared_link',
        method: 'GET',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        bailout: state => state.Data.isFetching,
      },
    });
    if (!actionResponse) {
      return;
    }
    if (!actionResponse.error) {
      dispatch(setLink(actionResponse.payload));
    } else {
      dispatch(
        setNotice({
          type: 'error',
          message: actionResponse.message || 'failed to get Data Room link',
        }),
      );
    }
  };
};

const createDataRoomLink = () => {
  const REQUEST = Types['POST_LINK_REQUEST'];
  const SUCCESS = Types['POST_LINK_SUCCESS'];
  const FAILED = Types['POST_LINK_FAILED'];

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/shared_link',
        method: 'POST',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        bailout: state => state.Data.isFetching,
      },
    });
    if (!actionResponse.error) {
      dispatch(setLink(actionResponse.payload));
      dispatch(setNotice('Data Room link created/extended'));
    } else {
      dispatch(
        setNotice({
          type: 'error',
          message: actionResponse.message || 'failed to create Data Room link',
        }),
      );
    }
  };
};

const updateDataRoomLink = () => {
  const REQUEST = Types['UPDATE_LINK_REQUEST'];
  const SUCCESS = Types['UPDATE_LINK_SUCCESS'];
  const FAILED = Types['UPDATE_LINK_FAILED'];

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/shared_link',
        method: 'PUT',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        bailout: state => state.Data.isFetching,
      },
    });
    if (!actionResponse.error) {
      dispatch(setLink(actionResponse.payload));
      dispatch(setNotice('New Data Room link created'));
    } else {
      dispatch(
        setNotice({
          type: 'error',
          message: actionResponse.message || 'failed to update Data Room link',
        }),
      );
    }
  };
};

export { createDataRoomLink, getDataRoomLink, updateDataRoomLink };
