import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import get from 'lodash-es/get';
import classnames from 'classnames';

import SolutionsModal from '../../components/SolutionsModal';

import './SubscriptionsModal.scss';

const SubscriptionFeaturesSection = ({
  featuresDict: { columns = [], features = [] } = {},
  isSectionCollapsedInit,
  isSingleColumn,
  sectionDescription,
  sectionTitle,
  selectedSubscriptionIndex,
}) => {
  const [modalState, setModalState] = useState({});
  const [isSectionCollapsed, setSectionCollapsed] = useState(false);
  const [isModalOpen, setModal] = useState(false);

  useEffect(() => {
    if (isSectionCollapsedInit) {
      setSectionCollapsed(true);
    }
  }, [isSectionCollapsedInit]);

  return (
    <>
      {sectionTitle && (
        <div
          className="features-section__header"
          onClick={() => setSectionCollapsed(!isSectionCollapsed)}
        >
          <div className="features-section__header-collapsible">
            <FontAwesomeIcon icon={['fal', isSectionCollapsed ? 'plus' : 'minus']} />
          </div>
          <div className="features-section__header-title">
            <h3>{sectionTitle}</h3>
          </div>
          <div className="features-section__subheader">{sectionDescription}</div>
        </div>
      )}
      {!isSectionCollapsed && (
        <div
          style={{
            gridTemplateRows: `repeat(${features.length}, auto)`,
            msGridRows: `(auto)[${features.length}]`,
          }}
          className="features-section__table"
        >
          {features.map(
            ({ rowComments = [], rowFeature = {}, rowSubfeatures = [] }, index) => (
              <div
                key={`row-feature-${index}`}
                style={{
                  cursor:
                    rowSubfeatures.length > 0 || rowFeature.description
                      ? 'pointer'
                      : 'inherit',
                  gridColumn: 1,
                  gridRow: index + 1,
                }}
                onClick={() => {
                  if (rowSubfeatures.length > 0 || rowFeature.description) {
                    setModalState({
                      description: rowFeature.description,
                      featureHover: rowFeature.hover,
                      header: rowFeature.label,
                      rowComments: rowComments,
                      rowSubfeatures: rowSubfeatures,
                    });
                    setModal(true);
                  }
                }}
                className={`features-section__row-feature${
                  rowSubfeatures.length > 0 || rowFeature.description
                    ? ' features-section__row-feature--link'
                    : ''
                }`}
              >
                {rowSubfeatures.length === 0 && !rowFeature.description && (
                  <div className="row-feature-icon--spacer" />
                )}
                {(rowSubfeatures.length > 0 || rowFeature.description) && (
                  <FontAwesomeIcon className="row-feature-icon" icon="chevron-down" />
                )}
                <h4>{rowFeature.label}</h4>
              </div>
            ),
          )}
          {columns.map((column, index) =>
            column.map((item, ind) => (
              <div
                key={`feature-items-${ind}-${ind}`}
                style={{
                  gridColumn: index + 2,
                  gridRow: ind + 1,
                }}
                className={classnames('features-section__item', {
                  'features-section__item--dark': index % 2 == 0, // eslint-disable-line eqeqeq
                  selected: selectedSubscriptionIndex === index,
                  highlighted:
                    item !== 'X' &&
                    item !== '-' &&
                    item !== 'Add-on' &&
                    item !== 'Full Price',
                })}
              >
                {item === 'check' && <FontAwesomeIcon icon="check" />}
                {item === 'X' && ''}
                {item !== 'check' && item !== 'X' && item}
              </div>
            )),
          )}
        </div>
      )}
      <SolutionsModal
        isOpen={isModalOpen}
        isSingleColumn={isSingleColumn}
        onClose={() => {
          setModalState({});
          setModal(false);
        }}
        {...modalState}
      />
    </>
  );
};

SubscriptionFeaturesSection.propTypes = {
  formData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string, //TODO: clean up Review PropTypes
            value: PropTypes.string,
          }),
        ),
      ]),
    }),
  ),
  title: PropTypes.string,
};

export default SubscriptionFeaturesSection;
