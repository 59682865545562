import React from 'react';
import { useSelector } from 'react-redux';

import { IsFileCabinetShowing } from '../../../redux/modules/Profile/selectors';
import ModuleDict from '../../../utils/ModuleDict';
import { SidebarNavLink, SidebarNavLinkCompany } from '../../Sidebar';

const DashboardNav = ({ account_id, isCollapsed }) => {
  const isCabinetShowing = useSelector(IsFileCabinetShowing);

  return (
    <div className="sidebar__nav">
      <SidebarNavLinkCompany isCollapsed={isCollapsed} link={ModuleDict['-1'].path} />
      <SidebarNavLink
        account_id={account_id}
        icon={ModuleDict['-1'].icon}
        link={ModuleDict['-1'].path}
        text={ModuleDict['-1'].label}
      />
      <SidebarNavLink
        account_id={account_id}
        icon={['fal', 'file-signature']}
        link={ModuleDict['workflows'].path}
        text={ModuleDict['workflows'].label}
      />
      <SidebarNavLink
        account_id={account_id}
        icon={ModuleDict['tasks'].icon}
        id={ModuleDict['tasks'].id}
        link={ModuleDict['tasks'].path}
        text={ModuleDict['tasks'].label}
      />
      <SidebarNavLink
        account_id={account_id}
        icon={ModuleDict['documents'].icon}
        id={ModuleDict['documents'].id}
        link={ModuleDict['documents'].path}
        text={ModuleDict['documents'].label}
      />
      <SidebarNavLink
        account_id={account_id}
        icon={['fal', 'info-circle']}
        link={'/reports'}
        text="Reports"
      />
      <div className="sidebar__divider" />
      <SidebarNavLink
        account_id={account_id}
        icon={ModuleDict['10'].icon}
        link={ModuleDict['10'].path}
        text={ModuleDict['10'].label}
      />
      {isCabinetShowing && (
        <SidebarNavLink
          account_id={account_id}
          icon={['fal', 'file-alt']}
          link={'/cabinet'}
          text="File Cabinet"
        />
      )}
      <SidebarNavLink
        account_id={account_id}
        icon={ModuleDict['account'].icon}
        link={ModuleDict['account'].path}
        text={ModuleDict['account'].label}
      />
    </div>
  );
};

export default DashboardNav;
