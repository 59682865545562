import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '../common';
import DropdownMenu from 'react-dd-menu';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AccountInfo } from '../../redux/modules/Formation/selectors';
import OrgButton from '../OrgButton';
import { WhatsNewModalToggle } from '../WhatsNewModal';

import './ConsistentTopbar.scss';
import handlePath from '../../utils/handlePath';
import { useHistory, useLocation, useParams } from 'react-router';
import { getAuthUser } from '../../redux/modules/User/selectors';

const Topbar = ({ isWorkbench }) => {
  const { action, goBack, replace } = useHistory();
  const location = useLocation();
  const { companyId, moduleId, projectId, transactionId } = useParams();
  const accountInfo = useSelector(AccountInfo);
  const user = useSelector(getAuthUser);
  const [isDocumentCountModalOpen, setDocumentCountModal] = useState(false);

  const onClose = () => {
    if (action !== 'POP') {
      return goBack();
    }
    if (isWorkbench) {
      return replace(
        handlePath(
          `/workbench/${moduleId}/${projectId}/${transactionId}/task-view`,
          companyId,
        ),
      );
    }
    return replace(handlePath('/home', companyId));
  };

  const availableCredits =
    accountInfo.credits_info &&
    accountInfo.credits_info.limit - accountInfo.credits_info.used;

  const isVerifyHiding = localStorage.getItem('hide_verify');

  return (
    <div className="ConsistentTopbar">
      <ul className="ConsistentTopbar__nav">
        {location.pathname.includes('/subscriptions') &&
          !location.pathname.includes('/settings/subscriptions') && (
            <h3 className="ConsistentTopbar__back" onClick={onClose}>
              <FontAwesomeIcon onClick={onClose} icon={['fal', 'chevron-left']} />
              Back
            </h3>
          )}
        <ul className="ConsistentTopbar__secondary-nav">
          {(user.email_verified || isVerifyHiding) &&
            !isNaN(availableCredits) &&
            availableCredits < 6 && (
              <li className="ConsistentTopbar__navListItem right">
                <DropdownMenu
                  align="left"
                  className="ConsistentTopbar__credits-popup"
                  close={() => setDocumentCountModal(false)}
                  closeOnInsideClick={true}
                  isOpen={isDocumentCountModalOpen}
                  toggle={
                    <div
                      className="ConsistentTopbar__credits"
                      onClick={() => setDocumentCountModal(!isDocumentCountModalOpen)}
                    >
                      <div className="ConsistentTopbar__credits-icon">
                        <h4>{availableCredits}</h4>
                        <FontAwesomeIcon icon={['fal', 'file']} />
                      </div>
                      <sup className="ConsistentTopbar__credits-help">
                        <FontAwesomeIcon icon="exclamation-circle" />
                      </sup>
                    </div>
                  }
                >
                  <li>
                    You only have
                    <span className="ConsistentTopbar__credits-number">
                      {availableCredits}
                    </span>
                    Document Credit(s) remaining on this plan.
                    <br />
                    <br />
                    Credits are used when tasks are submitted that generate or send legal
                    documents or filings.{' '}
                    <Button
                      className="ConsistentTopbar__credits-upgrade"
                      buttonType="link"
                      to={handlePath('/subscriptions', companyId)}
                      size="sm"
                    >
                      Upgrade your Plan
                    </Button>{' '}
                    to get more credits.
                  </li>
                </DropdownMenu>
                <Button
                  className="ConsistentTopbar__credits-upgrade"
                  buttonType="link"
                  to={handlePath('/subscriptions', companyId)}
                  size="sm"
                >
                  Upgrade Plan
                </Button>
              </li>
            )}
          <li className="ConsistentTopbar__navListItem--secondary">
            <WhatsNewModalToggle />
          </li>
          <li className="ConsistentTopbar__navListItem--secondary">
            <OrgButton accountInfo={accountInfo} darkText />
          </li>
        </ul>
      </ul>
    </div>
  );
};

// Topbar.defaultProps = {
//   isAuthenticated: false,
// };

export default Topbar;
