import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isEmpty } from 'lodash-es';

import logo from '../../assets/images/wordmark-white.svg';
import {
  finalizeEmailVerification,
  requestVerificationEmail,
} from '../../redux/modules/User/operations';
import { getAuthUser } from '../../redux/modules/User/selectors';

import './VerifyByLink.scss';
import { useHistory, useLocation, useParams } from 'react-router';
import { Button } from '../../components/common';
import { clearErrors } from '../../redux/modules/User/actions';
import handlePath from '../../utils/handlePath';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import PageLoader from '../../components/PageLoader';
import {
  getEmailVerification,
  removeEmailVerification,
} from '../../redux/modules/User/utils';

const templateDescriptions = {
  'verification-email': 'verification',
  'email-change-code': 'email replacement',
  'reset-password': 'password reset',
  'magic-link': 'login',
  'new-user-to-account': 'new user invite',
};

const VerifyByLink = ({ hideVerify }) => {
  const dispatch = useDispatch();
  const { push, replace } = useHistory();
  const {
    template_key = 'verification-email',
    verification_id,
    verification_code,
  } = useParams();
  const { search } = useLocation();

  const user = useSelector(getAuthUser);

  const [initialized, setInitialized] = useState(false);
  const [initializing, setInitializing] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const query = new URLSearchParams(search);
  let email = query.get('email');

  useEffect(() => {
    const storedVerification = getEmailVerification(template_key);
    const isExpired = dayjs().diff(storedVerification.sent_at, 'h') >= 24;

    if (isSuccess || initializing || initialized) {
      return;
    }

    if (!isExpired && (verification_id || !isEmpty(storedVerification))) {
      setInitializing(true);
      dispatch(
        finalizeEmailVerification(
          {
            id: verification_id || storedVerification.id,
            token: verification_code,
          },
          template_key,
          true,
        ),
      ).then(
        e => {
          setInitialized(true);
          hideVerify();
          setIsSuccess(true);
          setInitializing(false);
        },
        error => {
          if (error.code === 'E_CODE_REDEEMED') {
            setIsSuccess(true);
          }
          setInitialized(true);
          setInitializing(false);
        },
      );
    } else {
      setInitialized(true);
      setIsSuccess(false);
    }
  }, [
    dispatch,
    hideVerify,
    initialized,
    initializing,
    isSuccess,
    setIsSuccess,
    template_key,
    verification_code,
    verification_id,
  ]);

  return (
    <div className="verify-link__container">
      <div
        className="verify-link__logo"
        onClick={() => push(handlePath('/company-select', 0))}
      >
        <img src={logo} alt="savvi-logo" />
      </div>
      <div className="verify-link__heading">
        <h3>
          {initialized && !isSuccess && <FontAwesomeIcon icon="exclamation-circle" />}
          {`Your ${templateDescriptions[template_key]} link from`}
        </h3>
        <h2>
          <b>{user.email || email || 'your email'}</b>
        </h2>
        {!initialized && <PageLoader />}
        {initialized && isSuccess && (
          <h3>
            {'has succeeded. You can close this tab or '}
            <Button
              buttonType="link"
              to={handlePath('/company-select', 0)}
              onClick={() => removeEmailVerification(template_key)}
            >
              continue from here
            </Button>
          </h3>
        )}
        {initialized && !isSuccess && (
          <h3>
            has failed. Your verification link may have expired or you may have already
            verified.
            <Button
              buttonType="link"
              to={handlePath('/company-select', 0)}
              onClick={() => removeEmailVerification(template_key)}
            >
              continue from here
            </Button>
            {' or '}
            <Button
              buttonType="link"
              onClick={() => {
                dispatch(clearErrors());
                dispatch(
                  requestVerificationEmail(
                    {
                      type: 'verification_code',
                      value: `${verification_id}:${verification_code}`,
                    },
                    template_key,
                  ),
                ).then(e => replace('/verify-code/verification-email'));
              }}
            >
              resend verification code
            </Button>
          </h3>
        )}
      </div>
      <div className="verify-link__text">
        <p>
          For help with any issues please contact us at{' '}
          <a href="mailto:support@savvi.legal">support@savvi.legal</a>
        </p>
      </div>
    </div>
  );
};

export default VerifyByLink;
