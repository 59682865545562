import React from 'react';
import PropTypes from 'prop-types';

import './Tooltip.scss';

const Tooltip = ({ text, align = 'left', position = 'top' }) => {
  return (
    <span
      className={`savvi-tooltip savvi-tooltip--align-${align} savvi-tooltip--position-${position}`}
    >
      {text}
    </span>
  );
};

Tooltip.propTypes = {
  align: PropTypes.oneOf(['left', 'right']),
  position: PropTypes.oneOf(['bottom', 'left', 'right', 'top']),
};
Tooltip.defaultProps = {
  align: 'left',
  position: 'top',
};

export default Tooltip;
