import React from 'react';

import logo from '../../assets/images/wordmark-white.svg';
import { Button } from '../common';

import './LandingTopbar.scss';

const LandingTopbar = () => (
  <header className="landing-topbar">
    <a href="https://savvi.legal">
      <img src={logo} className="landing-topbar__wordmark" alt="logo" />
    </a>
    <a
      href="https://app.savvi.legal/plans"
      className="landing-topbar-menu__link"
      // activeClassName="landing-topbar-menu__link--active"
    >
      Pricing
    </a>
    <div className="landing-topbar__actions">
      <Button
        // className="landing-topbar__button landing-topbar__button--secondary"
        href="https://app.savvi.legal/login"
        size="sm"
        buttonType="secondary"
      >
        Sign In
      </Button>
      <Button
        // className="landing-topbar__button landing-topbar__button--primary"
        buttonType="primaryLink"
        href="https://app.savvi.legal/register"
        size="sm"
      >
        Get Started
      </Button>
    </div>
  </header>
);

export default LandingTopbar;
