import { get, isEmpty } from 'lodash-es';
import { createSelector, defaultMemoize } from 'reselect';

import { MyTransactions } from '../Transaction/selectors';

export const isCompanyFetching = defaultMemoize(state =>
  get(state, 'Company.isFetching', false),
);

export const IsMultiCompany = defaultMemoize(
  state => Object.keys(get(state, 'Company.companies.byIds', {})).length > 1,
);

export const MyCompanies = defaultMemoize(state =>
  Object.values(get(state, 'Company.companies.byIds', {})),
);

export const IsCompaniesEmpty = defaultMemoize(state =>
  isEmpty(get(state, 'Company.companies.byIds', {})),
);

export const getCompanyDataTables = defaultMemoize(state =>
  Object.values(get(state, 'Company.companyDataTables', {})),
);

const getCurrentCompanyID = defaultMemoize(state =>
  get(state, 'Auth.account.accountId', ''),
);

export const CurrentCompany = createSelector(
  MyCompanies,
  getCurrentCompanyID,
  (companies, currentCompanyID) =>
    get(
      companies,
      `[${companies.findIndex(e => e.account_id === currentCompanyID)}]`,
      {},
    ),
);

export const CompaniesActionableDocuments = createSelector(MyCompanies, companies =>
  companies.reduce((dict, e) => {
    if (e.documents.length > 0) {
      e.documents.forEach(doc => {
        let included_states = [
          'document.sent',
          'document.viewed',
          'document.waiting_approval',
          'document.waiting_pay',
        ];

        if (included_states.includes(get(doc, 'status', '')) && doc.account_task_id) {
          dict.push({ account: e, ...doc });
        }
      });
    }
    return dict;
  }, []),
);

export const CompaniesDocuments = createSelector(
  MyCompanies,
  MyTransactions,
  (companies, transactions) => {
    let filteredDocs = [];
    companies.forEach(e => {
      if (e.documents.length > 0) {
        e.documents.forEach(doc => {
          let skip_states = [
            'document.sent',
            'document.viewed',
            'document.waiting_approval',
            'document.waiting_pay',
          ];

          if (skip_states.includes(get(doc, 'status', ''))) {
            filteredDocs = [...filteredDocs, { companyMeta: e, ...doc }];
          }
        });
      }
    });
    const documents = filteredDocs.reduce(
      (dict, e) => {
        let document = { ...e };
        let isRedlining = false;
        if (!e.account_task_id) {
          return dict;
        }
        transactions.some(transaction => {
          let taskIndex = transaction.tasks.findIndex(
            task => task.id + '' === e.savvi_task_id,
          );
          if (taskIndex !== -1) {
            isRedlining = e.revision_count > 0;
            document = {
              ...document,
              ...transaction,
              taskIndex,
            };
            return true;
          }
          return false;
        });
        if (isRedlining) {
          dict.editableDocs.push(document);
        } else {
          dict.missingSignatureDocs.push(document);
        }
        return dict;
      },
      { editableDocs: [], missingSignatureDocs: [] },
    );
    return documents;
  },
);
