import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './InputHelp.scss';
import { DropdownAction } from '../../common/Dropdown/Dropdown';

const InputHelpButton = ({ text }) => {
  return (
    <DropdownAction
      className="input-help"
      Toggle={toggleProps => (
        <button type="button" className="input-help__icon" {...toggleProps}>
          <FontAwesomeIcon icon={['fal', 'question-circle']} className="text-muted" />
        </button>
      )}
      upward
    >
      <p className="input-help-item">{text}</p>
    </DropdownAction>
  );
};

InputHelpButton.defaultProps = {
  align: 'left',
  upwards: true,
};

export default InputHelpButton;
