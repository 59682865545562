import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select, { createFilter } from 'react-select';
import Creatable from 'react-select/creatable';

import { InputFeedback, InputHelp, InputSublabel } from '../';
import { isEmpty } from 'lodash-es';
import classnames from 'classnames';
import './InputSelect.scss';

const bem = elementName => `input-select${elementName ? '__' + elementName : ''}`;

const InputSelect = props => {
  const {
    className,
    defaultValue,
    defaultPlaceholder,
    error,
    isClearable,
    isCreatable,
    isDisabled,
    isFixed,
    isMulti,
    isSearchable,
    isRequired,
    label,
    menuPlacement,
    name,
    onBlur,
    onChange,
    onFocus,
    options,
    placeholder,
    questionHelp,
    selectStyles,
    sublabel,
    value,
    ignoreCase,
    ignoreAccents,
    trim,
    matchFrom,
    ...restOfProps
  } = props;

  const [isActive, setIsActive] = useState(!!value);
  useEffect(() => {
    if (!isActive && !!value) {
      setIsActive(true);
    }
  }, [isActive, value]);

  const filterOptions = createFilter({
    ignoreCase,
    ignoreAccents,
    trim,
    matchFrom,
  });
  const selectClass = classnames('', className);
  const isGrouped = options[0] && !isEmpty(options[0].options);
  let selectContainer = { maxWidth: 600 };
  if (!!error) {
    selectContainer.borderColor = '#e04f4f';
  }

  let customStyles = {
    menu: provided => ({ ...provided, zIndex: 10 }),
    container: provided => ({ ...provided, ...selectContainer }),
    control: provided => ({
      ...provided,
      minHeight: 52,
      borderRadius: 10,
      paddingTop: isDisabled || isFixed ? 8 : 0,
      ':hover': {
        borderColor: isDisabled || isFixed ? 'inherit' : '#0da1ff',
        cursor: isDisabled || isFixed ? 'default' : 'pointer',
      },
      backgroundColor: isDisabled || isFixed ? '#eee' : 'white',
    }),
    multiValue: provided => ({ ...provided, marginTop: isDisabled || isFixed ? 8 : 2 }),
    placeholder: provided => ({ ...provided, color: '#AFBDC5', marginLeft: 0 }),
    singleValue: provided => ({
      ...provided,
      color: '#2d363c',
      fontSize: 16,
      marginLeft: 0,
    }),
    valueContainer: provided => ({
      ...provided,
      marginTop: 0,
      marginLeft: 8,
      ':hover': {
        cursor:
          ((isDisabled || isFixed) && 'default') ||
          (!isCreatable && !isSearchable && 'pointer') ||
          'text',
      },
    }),
    ...selectStyles,
  };

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };
  const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  };

  const formatGroupLabel = data => {
    return (
      <div style={groupStyles}>
        <span>Saved {data.label}(s)</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
      </div>
    );
  };

  return (
    <div className={bem('container')}>
      <div
        className={classnames(bem('wrapper'), {
          [bem('wrapper--error')]: !!error,
          [bem('wrapper--disabled')]: isFixed || isDisabled,
        })}
      >
        {label && (
          <label
            className={classnames(bem('label'), { [bem('label--active')]: isActive })}
            htmlFor={name}
          >
            {label}
            {!!isRequired && <span className="workbench-form__required">*</span>}
            {questionHelp && <InputHelp text={questionHelp} />}
          </label>
        )}
        {!!isCreatable && (
          <Creatable
            blurInputOnSelect={isMulti ? false : true}
            className={selectClass}
            closeMenuOnSelect={isMulti ? false : true}
            defaultValue={defaultValue}
            formatGroupLabel={e => isGrouped && formatGroupLabel(e)}
            isClearable
            isDisabled={isFixed || isDisabled}
            isMulti={isMulti}
            isSearchable={isSearchable}
            id={props.id || name}
            menuPlacement="auto"
            menuShouldScrollIntoView
            name={name}
            noOptionsMessage={() => 'No Options. Start typing to create new option.'}
            openMenuOnFocus
            options={options}
            onBlur={e => {
              if (!e.target.value) {
                setIsActive(false);
              }
              onBlur(e);
            }}
            onChange={onChange}
            onFocus={e => {
              setIsActive(true);
              onFocus(e);
              document
                .querySelector(`[for="${name}"]`)
                .classList.add(bem('label--active'));
            }}
            placeholder={placeholder || defaultPlaceholder}
            value={value}
            styles={customStyles}
            {...restOfProps}
          />
        )}
        {!isCreatable && (
          <Select
            blurInputOnSelect={isMulti ? false : true}
            className={selectClass}
            closeMenuOnSelect={isMulti ? false : true}
            filterOption={filterOptions}
            formatGroupLabel={e => isGrouped && formatGroupLabel(e)}
            isClearable={isClearable}
            isDisabled={isFixed || isDisabled}
            isMulti={isMulti}
            isSearchable={isSearchable}
            id={props.id || name}
            menuPlacement="auto"
            menuShouldScrollIntoView
            name={name}
            options={options}
            onBlur={e => {
              if (!e.target.value) {
                setIsActive(false);
              }
              onBlur(e);
            }}
            onChange={onChange}
            onFocus={e => {
              setIsActive(true);
              onFocus(e);
              document
                .querySelector(`[for="${name}"]`)
                .classList.add(bem('label--active'));
            }}
            openMenuOnFocus
            placeholder={placeholder || defaultPlaceholder}
            styles={customStyles}
            value={value}
            {...restOfProps}
          />
        )}
      </div>
      <InputSublabel>{sublabel}</InputSublabel>
      <InputFeedback error={error} />
    </div>
  );
};

InputSelect.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  defaultValue: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  defaultPlaceholder: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isClearable: PropTypes.bool,
  isCreatable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isFixed: PropTypes.bool,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  menuPlacement: PropTypes.oneOf(['auto', 'center', 'right', 'left']),
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  questionHelp: PropTypes.string,
  sublabel: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
  // filter options
  ignoreCase: PropTypes.bool,
  ignoreAccents: PropTypes.bool,
  trim: PropTypes.bool,
  matchFrom: PropTypes.string,
};

InputSelect.defaultProps = {
  className: false,
  ignoreCase: true,
  ignoreAccents: true,
  isClearable: true,
  isSearchable: true,
  matchFrom: 'any',
  menuPlacement: 'auto',
  onBlur: () => {},
  onFocus: () => {},
  sublabel: null,
  trim: true,
};

export default InputSelect;
