import React from 'react';

import logo from '../../assets/images/wordmark-white.svg';

import './Footer.scss';

const Footer = ({ hideBack, hideLogo, hideSettings }) => {
  return (
    <div className="footer__main">
      <ul className="footer__list">
        <li className="footer__list-item">
          <img className="footer__logo" src={logo} alt="" />
        </li>
        <li className="footer__list-item">
          <a
            href="http://docs.wixstatic.com/ugd/342422_fa8385a417404f138a687a08f8c5bc15.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="footer__link"
          >
            Terms of Use
          </a>
        </li>
        <li className="footer__list-item">
          <a
            href="http://docs.wixstatic.com/ugd/342422_2fcd1c73409a443d988a73a2fb263716.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="footer__link"
          >
            Privacy Policy
          </a>
        </li>
        <li className="footer__list-item">
          <a
            href="mailto:support@savvi.legal"
            target="_blank"
            rel="noopener noreferrer"
            className="footer__link"
          >
            Contact Support
          </a>
        </li>
      </ul>
    </div>
  );
};

Footer.defaultProps = {
  isAuthenticated: false,
};

export default Footer;
