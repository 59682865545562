import React, { useState } from 'react';
import { DropdownMenu } from 'react-dd-menu';
import Modal from 'react-modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './SolutionsModal.scss';
import { Button } from '../common';

const customStyles = {
  overlay: {
    zIndex: 10,
    backgroundColor: 'rgba(0,0,0,.66)',
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    overflowY: 'auto',
    paddingTop: 20,
    WebkitOverflowScrolling: 'touch',
  },
  content: {
    position: 'static',
    margin: 'auto auto 0',
    minHeight: 200,
    bottom: 0,
    padding: 0,
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};

const SolutionsModal = props => {
  const [isSubfeatureOpen, setSubfeatureOpen] = useState(-1);
  const [isHoverOpen, setHoverOpen] = useState(false);

  if (props.isSingleColumn) {
    return <SingleFeaturesModal {...props} />;
  }
  const {
    description = '',
    featureHover = '',
    header = '',
    isOpen = false,
    onClose = () => {},
    rowComments = [],
    rowSubfeatures = [],
  } = props;

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles}>
      <div className="solutions-modal__header">
        <h2>{header}</h2>
        {featureHover && (
          <DropdownMenu
            align="left"
            className="solutions-modal__header-help"
            close={() => setHoverOpen(false)}
            closeOnInsideClick={true}
            isOpen={isHoverOpen}
            toggle={
              <FontAwesomeIcon
                onClick={() => setHoverOpen(!isHoverOpen)}
                icon={['fal', 'info-circle']}
              />
            }
          >
            <li>{featureHover}</li>
          </DropdownMenu>
        )}
      </div>
      <FontAwesomeIcon
        className="solutions-modal__exit"
        onClick={onClose}
        icon={['fal', 'times']}
      />
      <div className="solutions-modal__body">
        {description && (
          <div className="solutions-modal__description">
            <h3>{description}</h3>
          </div>
        )}
        {rowSubfeatures.map((subfeatures, index) => (
          <div key={`row-subfeatures-${index}`} className="solutions-modal__list">
            {index === 0 && (
              <h3 className="solutions-modal__list-header">What you'll receive</h3>
            )}
            {index === 1 && (
              <h3 className="solutions-modal__list-header">Requirements</h3>
            )}
            <ul
              className={`solutions-modal__ul${
                index === 1 ? ' solutions-modal__dashed' : ''
              }`}
              key={`paired-subfeatures-${index}`}
            >
              {subfeatures.map((subfeature = {}, ind) => (
                <li
                  className={`${index === 1 ? 'solutions-modal__requirements' : ''}`}
                  key={`row-subfeature-${ind}`}
                >
                  <h4>
                    {index === 1 && '- '}
                    {subfeature.label}
                    {subfeature.hover && (
                      <span>
                        <DropdownMenu
                          align="left"
                          className="features-section__row-feature-help"
                          close={() => setSubfeatureOpen(-1)}
                          closeOnInsideClick={true}
                          isOpen={isSubfeatureOpen === ind}
                          toggle={
                            <sup>
                              <FontAwesomeIcon
                                onClick={() => setSubfeatureOpen(ind)}
                                icon={['fal', 'info-circle']}
                              />
                            </sup>
                          }
                        >
                          <li>{subfeature.hover}</li>
                        </DropdownMenu>
                      </span>
                    )}
                  </h4>
                </li>
              ))}
              {rowComments.map((rowComment, ind) => (
                <div key={`row-comment-${ind}`} className="features-section__row-comment">
                  {rowComment}
                </div>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </Modal>
  );
};

const SingleFeaturesModal = props => {
  const [isSubfeatureOpen, setSubfeatureOpen] = useState(-1);
  const [isHoverOpen, setHoverOpen] = useState(false);

  const {
    description = '',
    isOpen = false,
    featureHover = '',
    onClose = () => {},
    header = '',
    rowComments = [],
    rowSubfeatures = [],
  } = props;

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles}>
      <div className="solutions-modal__header">
        <h2>{header}</h2>
        {featureHover && (
          <DropdownMenu
            align="left"
            className="solutions-modal__header-help"
            close={() => setHoverOpen(false)}
            closeOnInsideClick={true}
            isOpen={isHoverOpen}
            toggle={
              <FontAwesomeIcon
                onClick={() => setHoverOpen(!isHoverOpen)}
                icon={['fal', 'info-circle']}
              />
            }
          >
            <li>{featureHover}</li>
          </DropdownMenu>
        )}
      </div>
      <FontAwesomeIcon
        className="solutions-modal__exit"
        onClick={onClose}
        icon={['fal', 'times']}
      />
      <div className="solutions-modal__body solutions-modal__single-col">
        <div className="solutions-modal__description">
          <h3>{description}</h3>
        </div>
        <ul className="solutions-modal__ul">
          {rowSubfeatures.map((subfeature = {}, ind) => (
            <li key={`row-subfeature-${ind}`}>
              <h4>
                {subfeature.label}
                {subfeature.linkUrl && (
                  <>
                    (
                    <Button buttonType="link" href={subfeature.linkUrl}>
                      {subfeature.linkLabel || subfeature.linkUrl}
                    </Button>
                    )
                  </>
                )}
                {subfeature.hover && (
                  <span>
                    <DropdownMenu
                      align="left"
                      className="solutions-modal__help"
                      close={() => setSubfeatureOpen(-1)}
                      closeOnInsideClick={true}
                      isOpen={isSubfeatureOpen === ind}
                      toggle={
                        <sup>
                          <FontAwesomeIcon
                            onClick={() => setSubfeatureOpen(ind)}
                            icon={['fal', 'info-circle']}
                          />
                        </sup>
                      }
                    >
                      <li>{subfeature.hover}</li>
                    </DropdownMenu>
                  </span>
                )}
              </h4>
            </li>
          ))}
          {rowComments.map((rowComment, ind) => (
            <div key={`row-comment-${ind}`} className="features-section__row-comment">
              {rowComment}
            </div>
          ))}
        </ul>
      </div>
    </Modal>
  );
};

export default SolutionsModal;
