import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { getStoredToken } from '../../../redux/modules/User/utils';
import handlePath from '../../../utils/handlePath';

import { SidebarNavLink, SidebarNavLinkCompany } from '../../Sidebar';

const TeamsNav = ({ isCollapsed, isPartner }) => {
  const { goBack, push } = useHistory();
  const { search, state: { isToggled } = {} } = useLocation();
  const { companyId } = useParams();

  return (
    <>
      <div className="sidebar__nav">
        <SidebarNavLinkCompany isCollapsed={isCollapsed} isTeam isToggled={isToggled} />
        <SidebarNavLink
          icon={['fal', 'house-user']}
          isReplace
          link={{ pathname: '/company-select', search, state: { isToggled } }}
          text={'Company Select'}
        />
        {isPartner && (
          <SidebarNavLink
            icon={['fal', 'users-class']}
            isReplace
            link={{ pathname: '/teams', search, state: { isToggled } }}
            text={'Teams'}
          />
        )}
        <SidebarNavLink
          icon={['fal', 'tasks']}
          isReplace
          link={{ pathname: '/all-dashboards', search, state: { isToggled } }}
          text={'My Dashboard'}
        />
      </div>
      {companyId !== '0' && (
        <div
          className="workbench-nav__exit"
          onClick={() => {
            let updatedCompanyId = companyId;
            if (!companyId || companyId === '-1') {
              updatedCompanyId = getStoredToken().accountId;
            }
            if (search) {
              push(handlePath({ pathname: '/home', search }, updatedCompanyId));
            } else if (isToggled) {
              goBack();
            } else {
              push(handlePath('/home', updatedCompanyId));
            }
          }}
        >
          {isToggled && <FontAwesomeIcon icon={['fal', 'chevron-left']} />}
          {isToggled ? 'Back' : 'To Dashboard'}
          {!isToggled && <FontAwesomeIcon icon={['fal', 'chevron-right']} />}
        </div>
      )}
    </>
  );
};

export default TeamsNav;
