import { combineReducers } from 'redux';

import { apiErrorsReducer, createReducer, fetchingReducer } from '../../utils';
import * as Types from './types';

const companyReducer = createReducer({})({
  [Types['SET_COMPANIES']]: (state, { payload }) => ({
    ...state,
    byIds: {
      ...state.byIds,
      ...payload.reduce((dict, e, ind) => {
        dict[e.id] = { ...e };
        return dict;
      }, {}),
    },
  }),
});

const companyDataTablesReducer = createReducer([])({
  [Types['SET_COMPANY_DATA_TABLES']]: (state, { payload }) => [...payload],
});

const isFetching = fetchingReducer('company');

export default combineReducers({
  isFetching,
  apiErrors: apiErrorsReducer('company'),
  companies: companyReducer,
  companyDataTables: companyDataTablesReducer,
});
