import { RSAA } from 'redux-api-middleware';
import { push } from 'connected-react-router';

import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import isObject from 'lodash-es/isObject';

import { requestHeaders } from '../../utils/api';
import { getAccountInfo } from '../Formation/operations';
import { getFeatureChanges, getSettings } from '../Profile/operations';
import { setNotice } from '../UI/actions';
import {
  clearErrors,
  logoutUser,
  setInitLoading,
  setPartnerByCode,
  setUserFromToken,
} from './actions';
import * as types from './types';
import {
  decodeToken,
  getStoredToken,
  removeEmailVerification,
  setEmailVerification,
} from './utils';
import { getCompanies, refreshCompanyToken } from '../Company/operations';
// import { getCompanies, refreshCompanyToken } from '../Company/operations';

const loadLiveMessenger = (email, name, first_name, last_name) => {
  const { REACT_APP_HS_CHAT_CODE } = process.env;
  var _hsq = (window._hsq = window._hsq || []);
  _hsq.push([
    'identify',
    {
      displayName:
        name || (!!first_name && !!last_name && first_name + ' ' + last_name) || email,
      email,
      firstName: first_name || 'Not Given',
      lastName: last_name || 'Not Given',
    },
  ]);
  var body = document.querySelector('body');
  var script = document.createElement('script');

  script.setAttribute('type', 'text/javascript');
  script.setAttribute('id', 'hs-script-loader');
  script.setAttribute('async', true);
  script.setAttribute('defer', true);
  script.setAttribute('src', `//js-na1.hs-scripts.com/${REACT_APP_HS_CHAT_CODE}.js`);
  body.appendChild(script);
};

const fetchInitialUserData = async (dispatch, tokenData) => {
  const { REACT_APP_LOG_ENV } = process.env;
  const { email, userId, name, first_name, last_name, company } = tokenData;
  if (!userId) return;

  if (!email.includes('savvi.legal') && REACT_APP_LOG_ENV === 'app') {
    window.FS.identify(userId, { email, name, company });
  }
  if (email && (REACT_APP_LOG_ENV === 'production' || REACT_APP_LOG_ENV === 'demo')) {
    loadLiveMessenger(email, name, first_name, last_name);
  }
  return await Promise.all([dispatch(getSettings()), dispatch(getFeatureChanges())]);
};

export const onLogout = path => {
  const {
    LOGOUT_REQUEST: REQUEST,
    LOGOUT_SUCCESS: SUCCESS,
    LOGOUT_FAILED: FAILED,
  } = types;

  return async (dispatch, getState) => {
    await dispatch({
      [RSAA]: {
        endpoint: `/api/authn/session`,
        method: 'DELETE',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    dispatch(logoutUser());
    dispatch(push(path || '/login'));
    window.location.reload();
  };
};

export const verifyEmail = email => {
  const {
    VALIDATE_EMAIL_REQUEST: REQUEST,
    VALIDATE_EMAIL_SUCCESS: SUCCESS,
    VALIDATE_EMAIL_FAILED: FAILED,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/public/auth/email/${email}`,
        method: 'GET',
        headers: requestHeaders(),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      dispatch(clearErrors());
      return actionResponse.payload;
    } else {
      throw await actionResponse.payload;
    }
  };
};

const loginUser = (creds, isCreating) => {
  const { LOGIN_REQUEST: REQUEST, LOGIN_SUCCESS: SUCCESS, LOGIN_FAILED: FAILED } = types;

  return async (dispatch, getState) => {
    try {
      await dispatch(setInitLoading(true));
      const actionResponse = await dispatch({
        [RSAA]: {
          endpoint: '/api/authn/session',
          method: 'POST',
          headers: requestHeaders(),
          types: [REQUEST, SUCCESS, FAILED],
          body: JSON.stringify(creds),
        },
      });
      if (!actionResponse.error) {
        const decodedToken = decodeToken(actionResponse.payload.id_token);
        dispatch(setUserFromToken(decodedToken));
        if (!isCreating) {
          await fetchInitialUserData(dispatch, decodedToken);
          dispatch(setInitLoading(false));
        }
        return actionResponse.payload.id_token;
      } else {
        throw actionResponse.payload;
      }
    } catch (error) {
      dispatch(setInitLoading(false));
    }
  };
};

const validateSession = companyId => {
  let body = {};
  if (companyId && companyId + '' !== '0') {
    body.account_id = companyId;
  }
  const url = window.location.href;
  const { TOKEN_REQUEST: REQUEST, TOKEN_SUCCESS: SUCCESS, TOKEN_FAILED: FAILED } = types;
  return async (dispatch, getState) => {
    try {
      const localStorageJwt = getStoredToken(0);
      const actionResponse = await dispatch({
        [RSAA]: {
          endpoint: '/api/authn/refresh',
          method: 'POST',
          headers: requestHeaders(),
          types: [REQUEST, SUCCESS, FAILED],
          body: JSON.stringify(body),
          bailout: !localStorageJwt,
        },
      });
      if (!actionResponse) {
        // Bad login ju-ju. Logout and retry
        // localStorage.removeItem('id_tokens');
        // localStorage.removeItem('storedLocation');
        return await dispatch(setInitLoading(false));
      } else if (!actionResponse.error) {
        // Token containing User Data
        const decodedIdToken = decodeToken(actionResponse.payload.id_token);
        const { email, name, first_name, last_name, userId, urlCompanyId } =
          decodedIdToken;
        dispatch(setUserFromToken(decodedIdToken));

        // Token containing Company Data.
        let decodedAccessToken = {};
        if (companyId || Number(urlCompanyId)) {
          let accessToken = await dispatch(
            refreshCompanyToken(companyId || urlCompanyId),
          ).then(access_token => access_token);
          decodedAccessToken = decodeToken(accessToken);
        }
        const { company } = decodedAccessToken;

        await fetchInitialUserData(dispatch, {
          email,
          userId,
          name,
          first_name,
          last_name,
          company,
        });
        dispatch(getCompanies());
        dispatch(setInitLoading(false));
      } else {
        throw actionResponse.payload;
      }
    } catch (error) {
      const excludedRoutes = [
        'forgot-password',
        'login',
        'register',
        'reset-password',
        'plans',
        'remote-login',
        'data-room',
        'outside-form',
      ];
      if (!excludedRoutes.includes(url.split('/')[3])) {
        dispatch(
          setNotice({
            type: 'error',
            message: get(error, 'message', 'An error has occurred.'),
          }),
        );
      }
      const logoutCodes = [
        'JSON_WEB_TOKEN_EROR',
        'E_INVALID_SESSION',
        'INVALID_JWT',
        'TOKEN_EXPIRED_ERROR',
      ];
      if (error.logout) {
        dispatch(onLogout());
      } else if (logoutCodes.includes(error.code)) {
        localStorage.removeItem('id_tokens');
        localStorage.removeItem('id_token');
        localStorage.removeItem('storedLocation');
        localStorage.removeItem('hide_verify');
      }
      dispatch(setInitLoading(false));
      return error;
    }
  };
};

export const createUser = (userBody, companyBody) => {
  // use same as login
  // pass in create=true;
  const {
    CREATE_USER_REQUEST: REQUEST,
    CREATE_USER_FAILED: FAILED,
    CREATE_USER_SUCCESS: SUCCESS,
  } = types;

  return async (dispatch, getState) => {
    await dispatch(setInitLoading(true));
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/authn/session',
        method: 'POST',
        headers: requestHeaders(),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify(userBody),
      },
    });
    if (!actionResponse.error) {
      dispatch(
        setNotice({
          message: 'Account Created! Please wait as we set everything up',
        }),
      );
      const decodedToken = decodeToken(actionResponse.payload.id_token);
      dispatch(setUserFromToken(decodedToken));
      dispatch(
        requestVerificationEmail(
          {
            type: 'email',
            value: userBody.email,
          },
          'verification-email',
        ),
      );
      return await dispatch(createCompany(decodedToken, companyBody));
    }

    if (isObject(actionResponse.payload)) {
      dispatch(setNotice({ type: 'error', message: actionResponse.payload.message }));
    } else {
      dispatch(setNotice({ type: 'error', message: actionResponse.payload }));
    }
    throw actionResponse.payload;
  };
};

const createCompany = (decodedToken, body) => {
  const {
    CREATE_COMPANY_REQUEST: REQUEST,
    CREATE_COMPANY_FAILED: FAILED,
    CREATE_COMPANY_SUCCESS: SUCCESS,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/user/accounts',
        method: 'POST',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        body: () => JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      await fetchInitialUserData(dispatch, decodedToken);
      setTimeout(() => {
        dispatch(setInitLoading(true));
        window.location.reload();
      }, 1000);
      return await actionResponse.payload;
    }

    if (isObject(actionResponse.payload)) {
      dispatch(setNotice({ type: 'error', message: actionResponse.payload.message }));
    } else {
      dispatch(setNotice({ type: 'error', message: actionResponse.payload }));
    }
    throw actionResponse.payload;
  };
};

const confirmUserEmail = (code, push) => {
  const {
    EMAIL_CONFIRM_REQUEST: REQUEST,
    EMAIL_CONFIRM_FAILED: FAILED,
    EMAIL_CONFIRM_SUCCESS: SUCCESS,
  } = types;
  const endpoint = `/api/user/confirm/${code}`;

  return async dispatch => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint,
        method: 'GET',
        headers: requestHeaders('onboard'),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });

    if (!actionResponse.error) {
      dispatch(
        setUserFromToken(
          decodeToken((actionResponse.payload || actionResponse).id_token),
        ),
      );
      push('/onboarding/basic-info');
    }
  };
};

const resendEmailConfirmation = () => {
  const {
    RESEND_EMAIL_CONFIRM_REQUEST: REQUEST,
    RESEND_EMAIL_CONFIRM_FAILED: FAILED,
    RESEND_EMAIL_CONFIRM_SUCCESS: SUCCESS,
  } = types;
  const endpoint = '/api/user/confirm/code/resend';

  return async dispatch => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint,
        method: 'POST',
        headers: requestHeaders('onboard'),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });

    if (!actionResponse.error) {
      dispatch(setNotice('New confirmation code sent!'));
    }
  };
};

const sendPasswordReset = email => {
  return async (dispatch, getState) => {
    await Promise.resolve(
      dispatch({
        [RSAA]: {
          endpoint: '/api/public/user/password/forgot',
          method: 'PATCH',
          headers: requestHeaders(),
          types: [
            types.SEND_PW_RESET_REQUEST,
            {
              type: types.SEND_PW_RESET_SUCCESS,
              payload: () =>
                dispatch(
                  setNotice(
                    "If there's a Savvi account linked to this email address, you will receive reset instructions in your inbox.",
                  ),
                ),
            },
            types.SEND_PW_RESET_FAILED,
          ],
          body: JSON.stringify({ email }),
        },
      }),
    );

    const { Auth } = getState();
    if (!Auth.isFetching) {
      if (isEmpty(Auth.errors)) {
      }
    }
  };
};

const resetPassword = ({ code, password }, push) => {
  return async (dispatch, getState) => {
    await Promise.resolve(
      dispatch({
        [RSAA]: {
          endpoint: '/api/public/user/password/reset/',
          method: 'POST',
          headers: requestHeaders(),
          types: [
            types.RESET_PASSWORD_REQUEST,
            {
              type: types.RESET_PASSWORD_SUCCESS,
              payload: () =>
                dispatch(
                  setNotice(
                    'Your password has been reset, you may now login, using your new password.',
                  ),
                ),
            },
            types.RESET_PASSWORD_FAILED,
          ],
          body: JSON.stringify({ code, password }),
        },
      }),
    );

    const { Auth } = getState();
    if (!Auth.isFetching) {
      if (isEmpty(Auth.errors)) {
        push('/login');
      }
    }
  };
};

const setHubspot = body => {
  const {
    SET_HUBSPOT_REQUEST: REQUEST,
    SET_HUBSPOT_FAILED: FAILED,
    SET_HUBSPOT_SUCCESS: SUCCESS,
  } = types;

  return async (dispatch, getState) => {
    await dispatch({
      [RSAA]: {
        endpoint: '/api/hubspot',
        method: 'POST',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify(body),
      },
    });
  };
};

const clearNotification = (socket, id) => {
  socket.emit('read', [id * 1]);
  // socket.emit('get_all');
};

const addNewUser = body => {
  const {
    CREATE_NEW_USER_REQUEST: REQUEST,
    CREATE_NEW_USER_FAILED: FAILED,
    CREATE_NEW_USER_SUCCESS: SUCCESS,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/account/add_user',
        method: 'POST',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      dispatch(
        setNotice(
          `${body.first_name} ${body.last_name} added as admin. Email invite sent.`,
        ),
      );
      dispatch(getAccountInfo());
      return await actionResponse.payload;
    } else {
      dispatch(
        setNotice({
          type: 'error',
          message: get(actionResponse, 'payload.message', 'An error as occurred.'),
        }),
      );
    }
  };
};

const changeOwner = (newOwnerId, fullName) => {
  const {
    CHANGE_OWNER_REQUEST: REQUEST,
    CHANGE_OWNER_FAILED: FAILED,
    CHANGE_OWNER_SUCCESS: SUCCESS,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/change_owner/${newOwnerId}`,
        method: 'PATCH',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      dispatch(setNotice(`${fullName} Set as new Owner.`));
      dispatch(getAccountInfo());
      dispatch(getSettings());
    } else {
      dispatch(
        setNotice({
          type: 'error',
          message: get(actionResponse, 'payload.message', 'An error as occurred.'),
        }),
      );
    }
  };
};

const deleteUser = (userId, fullName) => {
  const {
    DELETE_USER_REQUEST: REQUEST,
    DELETE_USER_FAILED: FAILED,
    DELETE_USER_SUCCESS: SUCCESS,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/remove_user/${userId}`,
        method: 'DELETE',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      dispatch(setNotice(`${fullName} has been removed.`));
      dispatch(getAccountInfo());
    } else {
      dispatch(
        setNotice({
          type: 'error',
          message: get(actionResponse, 'payload.message', 'An error as occurred.'),
        }),
      );
    }
  };
};

const getPartnerByCode = code => {
  const {
    GET_PARTNER_BY_CODE_REQUEST: REQUEST,
    GET_PARTNER_BY_CODE_FAILED: FAILED,
    GET_PARTNER_BY_CODE_SUCCESS: SUCCESS,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/public/partner/code/${code}`,
        method: 'get',
        headers: requestHeaders(true, 'user'),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      await dispatch(setPartnerByCode(actionResponse.payload, code));
      return await actionResponse.payload;
    } else {
      console.error('get Partner by code error', actionResponse);
      await dispatch(
        setNotice({
          type: 'error',
          message: actionResponse.payload.message || 'An error has occurred',
        }),
      );
      throw await actionResponse.payload;
    }
  };
};

const connectPartner = code => {
  const {
    CONNECT_PARTNER_REQUEST: REQUEST,
    CONNECT_PARTNER_FAILED: FAILED,
    CONNECT_PARTNER_SUCCESS: SUCCESS,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/partner/${code}`,
        method: 'POST',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      await dispatch(setNotice('Partner Connected.'));
      return await actionResponse.payload;
    } else {
      await dispatch(
        setNotice({
          type: 'error',
          message: actionResponse.payload.message || 'An error has occurred',
        }),
      );
      throw await actionResponse;
    }
  };
};

export const removePartner = id => {
  const {
    REMOVE_PARTNER_REQUEST: REQUEST,
    REMOVE_PARTNER_FAILED: FAILED,
    REMOVE_PARTNER_SUCCESS: SUCCESS,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/partner/${id}`,
        method: 'DELETE',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      await dispatch(
        setNotice({ type: 'warning', message: actionResponse.payload.message }),
      );
      return await actionResponse.payload;
    } else {
      await dispatch(
        setNotice({
          type: 'error',
          message: actionResponse.payload.message || 'An error has occurred',
        }),
      );
      throw await actionResponse;
    }
  };
};

export const requestVerificationEmail = (body, template) => {
  const {
    REQUEST_VERIFICATION_EMAIL_REQUEST: REQUEST,
    REQUEST_VERIFICATION_EMAIL_FAILED: FAILED,
    REQUEST_VERIFICATION_EMAIL_SUCCESS: SUCCESS,
  } = types;

  return async (dispatch, getState) => {
    /**
     * body: {
     *  type,
     *  value,
     *  template: oneOf: ['email-change-code', 'reset-password', 'verification-email']
     *  _developmentSendChallengeSecret: true,
     *  _skipEmail: true
     * }
     */
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/authn/challenge/order',
        method: 'POST',
        headers: requestHeaders(),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify({ ...body, template }),
      },
    });
    /**
     body: {
      "success": true,
      "id": "TJNOdm",
      "receipt": "7oHMZbcOwvCy7rmXslOKnB",
      "_expiry": "24h"
    }
     */
    if (!actionResponse.error) {
      return await setEmailVerification(template, actionResponse.payload)[template];
    } else {
      await dispatch(
        setNotice({
          type: 'error',
          message: actionResponse.payload.message || 'An error has occurred',
        }),
      );
      throw await actionResponse;
    }
  };
};

export const checkVerificationStatus = (id, receipt, templateKey) => {
  const {
    CHECK_VERIFICATION_STATUS_REQUEST: REQUEST,
    CHECK_VERIFICATION_STATUS_FAILED: FAILED,
    CHECK_VERIFICATION_STATUS_SUCCESS: SUCCESS,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/authn/challenge?id=${id}&receipt=${receipt}`,
        method: 'get',
        headers: requestHeaders(),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    /**
     * status pending response
     * {
          "success": true,
          "id": "2NmDib",
          "status": "pending",
          "ordered_at": "2021-08-10T20:01:21.498Z",
          "ordered_by": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36",
          "receipt": "zuQWS0PjUrmzajdvCrJn_R"
      }
     */
    if (!actionResponse.error) {
      // await dispatch(setNotice('Email Sent.'));
      return await actionResponse.payload;
    } else {
      removeEmailVerification(templateKey);
      // await dispatch(
      //   setNotice({
      //     type: 'error',
      //     message: actionResponse.payload.message || 'An error has occurred',
      //   }),
      // );
      throw await actionResponse.payload.message;
    }
  };
};

export const checkSecretStatus = (id, secret) => {
  const {
    CHECK_SECRET_STATUS_REQUEST: REQUEST,
    CHECK_SECRET_STATUS_FAILED: FAILED,
    CHECK_SECRET_STATUS_SUCCESS: SUCCESS,
  } = types;
  /**
    ?id=abc123
    &receipt=yyyyyyyy
    &token=AB34-EF78
   */

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/authn/challenge?id=${id}&token=${secret}`,
        method: 'get',
        headers: requestHeaders(),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    /**
     {
        "success": true,
        "status": "pending",
        "ordered_at": "2021-06-20T13:30:59Z",
        "ordered_by": "Chrome/x.y.z Windows 10",
        "verified_at": "",
        "verified_by": ""
      }
     */
    if (!actionResponse.error) {
      return await actionResponse.payload;
    } else {
      await dispatch(
        setNotice({
          type: 'error',
          message: actionResponse.payload.message || 'An error has occurred',
        }),
      );
      throw await actionResponse.payload;
    }
  };
};

export const finalizeEmailVerification = (body, templateKey, isLink) => {
  const {
    FINALIZE_EMAIL_VERIFICATION_REQUEST: REQUEST,
    FINALIZE_EMAIL_VERIFICATION_FAILED: FAILED,
    FINALIZE_EMAIL_VERIFICATION_SUCCESS: SUCCESS,
  } = types;
  /**
   {
    "id": "abc123",
    "token": "AB34-EF78"
    }
   */

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/authn/challenge/finalize`,
        method: 'post',
        headers: requestHeaders(),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify(body),
      },
    });
    /**
     {
        "id_token": "xxxx.yyyy.zzzz",
        "access_token": "xxx2.yyy2.zzz2"
      }
     */
    if (!actionResponse.error) {
      await dispatch(setNotice('Verification Finalized.'));
      const decodedToken = decodeToken(actionResponse.payload.id_token);
      dispatch(setUserFromToken(decodedToken));
      if (!isLink) {
        removeEmailVerification(templateKey);
      }
      return await decodedToken;
    } else {
      if (actionResponse.payload.code !== 'E_CODE_RETRY' && !isLink) {
        removeEmailVerification(templateKey);
      }
      if (actionResponse.payload.code === 'E_CODE_REDEEMED') {
        dispatch(
          setNotice(
            actionResponse.payload.message || 'This code has already been redeemed.',
          ),
        );
      } else {
        dispatch(
          setNotice({
            type: 'error',
            message: actionResponse.payload.message || 'An error has occurred',
          }),
        );
      }
      throw await actionResponse.payload;
    }
  };
};

export const exchangeChallenge = (body, templateKey) => {
  const {
    EXCHANGE_CHALLENGE_REQUEST: REQUEST,
    EXCHANGE_CHALLENGE_FAILED: FAILED,
    EXCHANGE_CHALLENGE_SUCCESS: SUCCESS,
  } = types;
  /**
   {
    "id": "abc123",
    "receipt": "yyyyyyyy"
    }
   */

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/authn/challenge/exchange`,
        method: 'post',
        headers: requestHeaders(),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify(body),
      },
    });
    /**
     {
        "success": true,
        "status": "valid",
        "id_token": "xxxx.yyyy.zzzz"
        "access_token": "xxx2.yyy2.zzz2"
      }
     */
    if (!actionResponse.error) {
      await dispatch(setNotice('Verification Finalized.'));
      const decodedToken = decodeToken(actionResponse.payload.id_token);
      dispatch(setUserFromToken(decodedToken));
      removeEmailVerification(templateKey);
      // await fetchInitialUserData(dispatch, decodedToken);
      return await decodedToken;
    } else {
      // removeEmailVerification(templateKey);
      await dispatch(
        setNotice({
          type: 'error',
          message: actionResponse.payload.message || 'An error has occurred',
        }),
      );
      throw await actionResponse.payload;
    }
  };
};

export {
  clearNotification,
  confirmUserEmail,
  connectPartner,
  getPartnerByCode,
  loginUser,
  resendEmailConfirmation,
  resetPassword,
  setHubspot,
  sendPasswordReset,
  validateSession,
  addNewUser,
  changeOwner,
  deleteUser,
};
