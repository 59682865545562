import React from 'react';

import ReactMarkdown from 'react-markdown';
import './Comment.scss';

const Textarea = ({ label, markdown }) => (
  <div className="comment__container">
    {label && <label className="comment__label">{label}</label>}
    <ReactMarkdown>{markdown}</ReactMarkdown>
  </div>
);

export default Textarea;
