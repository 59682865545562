import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Formik } from 'formik';
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';

import { getOutsideBoxToken } from '../../redux/modules/Cabinet/operations';
import {
  createFeature,
  createResourceName,
  finalizeOutsideForm,
  getOutsideForm,
  updateResourceName,
} from '../../redux/modules/Transaction/operations';
import {
  getFaqsFromTransaction,
  getFormikValues,
  getOrderedQuestions,
  getQuestionnaireByTransactionId,
  getQuestionnaireSnapshotByTransactionId,
  getReviewValues,
} from '../../redux/modules/Transaction/selectors';
import { setNotice } from '../../redux/modules/UI/actions';
import InfoSidebar from '../InfoSidebar';
import OutsideForm from './OutsideForm';
import BasicTopbar from '../../components/BasicTopbar';
import SavviLoading from '../../components/SavviLoading';

class OutsideFormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      isFinalized: false,
      isReviewModalOpen: false,
      isFetchingInit: true,
      isSubmitWaiting: false,
    };
  }

  componentDidMount() {
    // let isChrome = !!window.chrome;
    // if (window.screen.width <= 768 && !isChrome) {
    //   this.setState({ isSafariMobile: true });
    // }
    const { getOutsideBoxToken, getOutsideForm, reviewInfo, room_access_code, match } =
      this.props;
    const { params: { access_code } = {} } = match || {};
    if (isEmpty(reviewInfo) && !room_access_code) {
      getOutsideForm(access_code).then(
        e => {
          this.setState({ isFetchingInit: false });
          getOutsideBoxToken();
        },
        error => this.setState({ isFetchingInit: false, error }),
      );
    } else {
      getOutsideBoxToken(access_code).then(
        e => this.setState({ isFetchingInit: false }),
        error => this.setState({ isFetchingInit: false, error }),
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.questions, this.props.questions)) {
      const { match, questions } = this.props;
      const { params: { access_code } = {} } = match || {};
      questions.forEach(question =>
        question.resourceArr.forEach(resource => {
          resource.innerQuestions.forEach(innerQ => {
            if (innerQ.question_default && !innerQ.value) {
              this.props.createFeature({
                access_code,
                value: innerQ.question_default,
                feature_type_id: innerQ.feature_type.id,
                resource_id: resource.resource_id,
                resourceTypeId: resource.resource_type_id,
              });
            }
          });
        }),
      );
    }
    if (this.state.isSubmitWaiting && !this.props.isFetching) {
      this.setState({ isSubmitWaiting: false }, () => this.handleSubmit());
    }
  }

  handleDelete = (resourceId, resourceName) => {
    const message = `Are you sure you want to remove ${resourceName} from our system?`;
    if (window.confirm(message)) {
      this.props.deleteIndividual(resourceId, resourceName);
    }
  };

  handleFinalize = () => {
    const { finalizeOutsideForm, reviewInfo } = this.props;
    finalizeOutsideForm(reviewInfo).then(
      e => this.setState({ isFinalized: true, isReviewModalOpen: false }),
      error => this.setState({ isReviewModalOpen: false }),
    );
  };

  handleFormBlur = (value, feature_type_id, feature_id, resource_id, resourceTypeId) => {
    const { createFeature } = this.props;
    const {
      match: {
        params: { access_code },
      },
    } = this.props;

    createFeature({
      access_code,
      value,
      feature_id,
      feature_type_id,
      resource_id,
      resourceTypeId,
    });
  };

  handleSubmit = (a, b) => {
    const { isReviewModalOpen } = this.state;

    if (this.props.isFetching) {
      return this.setState({ isSubmitWaiting: true });
    }

    this.setState({ isReviewModalOpen: !isReviewModalOpen });
  };

  // handleSetResourceIds = (resourceTypeName, resourceIds) => {
  //   return this.props.setResourceIds({ [resourceTypeName]: resourceIds });
  // };

  handleResourceName = (body, resourceId) => {
    const { createResourceName, params, updateResourceName } = this.props;
    body.transactionId = params.transactionId;
    return resourceId ? updateResourceName(body, resourceId) : createResourceName(body);
  };

  render() {
    const {
      faqs,
      formikValues,
      history,
      isFetching,
      match,
      questions,
      outsideFormSnapshot,
      reviewInfo,
      questionnaireMeta,
    } = this.props;
    const {
      error,
      isFinalized,
      isFetchingInit,
      isReviewModalOpen,
      // isSafariMobile,
    } = this.state;
    const { params = {} } = match || {};

    return (
      <div className="outside-form__container">
        <BasicTopbar />
        <div
          className={`outside-form__main${
            faqs.length === 0 && isEmpty(outsideFormSnapshot) ? ' no-sidebar' : ''
          }`}
        >
          {isFetchingInit && (
            <div className="workbench__loader">
              <span className="workbench__loading">Loading...</span>
              <SavviLoading />
            </div>
          )}
          {!isFetchingInit && (
            <Formik
              // enableReinitialize
              initialValues={formikValues}
              onSubmit={this.handleSubmit}
            >
              {formikProps => {
                return (
                  <OutsideForm
                    {...formikProps}
                    error={error}
                    handleDelete={this.handleDelete}
                    handleFinalize={this.handleFinalize}
                    handleFormBlur={this.handleFormBlur}
                    handleResourceName={this.handleResourceName}
                    history={history}
                    isFetching={isFetching}
                    isFetchingInit={isFetchingInit}
                    isFinalized={isFinalized}
                    isReviewModalOpen={isReviewModalOpen}
                    // isSafariMobile={isSafariMobile}
                    params={params}
                    questionnaireMeta={questionnaireMeta}
                    questions={questions}
                    reviewInfo={reviewInfo}
                    toggleReviewModal={() =>
                      this.setState({ isReviewModalOpen: !isReviewModalOpen })
                    }
                  />
                );
              }}
            </Formik>
          )}
          {<InfoSidebar faqs={faqs} isOutsideForm reviewInfo={outsideFormSnapshot} />}
        </div>
      </div>
    );
  }
}

OutsideFormContainer.propTypes = {
  formValues: PropTypes.object.isRequired,
  formikValues: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  match: PropTypes.object,
};
OutsideFormContainer.defaultProps = {
  formValues: {},
  formikValues: {},
  isFetching: false,
  getFormInfo: () => {},
};

const mapStateToProps = ({ Transaction }, ownProps) => {
  const { isFetching } = Transaction;
  const { params: { access_code } = {} } = ownProps.match || {};
  return {
    isFetching,
    formikValues: getFormikValues(Transaction, access_code),
    outsideFormSnapshot: getQuestionnaireSnapshotByTransactionId(
      Transaction,
      access_code,
    ),
    questions: getOrderedQuestions(Transaction, access_code),
    reviewInfo: getReviewValues(Transaction, access_code),
    questionnaireMeta: getQuestionnaireByTransactionId(Transaction, access_code),
    faqs: getFaqsFromTransaction(Transaction, access_code),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { params: { access_code } = {} } = ownProps.match || {};

  return {
    createFeature: meta => dispatch(createFeature(meta, true)),
    createResourceName: body => dispatch(createResourceName(body)),
    finalizeOutsideForm: snapshot => dispatch(finalizeOutsideForm(access_code, snapshot)),
    getOutsideBoxToken: accessCode => dispatch(getOutsideBoxToken(accessCode)),
    getOutsideForm: () => dispatch(getOutsideForm(access_code)),
    setNotice: obj => dispatch(setNotice(obj)),
    updateResourceName: (body, resourceId) =>
      dispatch(updateResourceName(body, resourceId)),
  };
};

const enhance = connect(mapStateToProps, mapDispatchToProps);

export default enhance(OutsideFormContainer);
