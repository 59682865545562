import { SET_BOX_TOKEN, SET_TEMP_TOKEN_FETCH_INITIALIZED } from './types';

export const setBoxToken = token => {
  return {
    type: SET_BOX_TOKEN,
    payload: { token },
  };
};

export const setTempTokenFetchInitialized = () => {
  return {
    type: SET_TEMP_TOKEN_FETCH_INITIALIZED,
    isInitialized: true,
  };
};
