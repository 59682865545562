import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik } from 'formik';

import { Button } from '../../components/common';
import { Select, TextField } from '../../components/formik';
import { createNewCompany } from '../../redux/modules/Company/operations';
import { isCompanyFetching } from '../../redux/modules/Company/selectors';
import { validateSelect, validateString } from '../../utils/FeatureTypes';
import MyTypes from '../../utils/MyTypes';

import './NewCompany.scss';

const customStyles = {
  overlay: {
    zIndex: 9,
    backgroundColor: 'rgba(0,0,0,.66)',
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    overflowY: 'auto',
    paddingTop: 20,
    WebkitOverflowScrolling: 'touch',
  },
  content: {
    position: 'static',
    maxWidth: 450,
    height: 400,
    margin: 'auto',
    padding: 0,
    border: 'none',
    boxShadow: '3px 3px 3px 3px #33373859',
    display: 'flex',
    flexDirection: 'column',
  },
};

export const businessTypeOptions = [
  { label: 'For-profit Company', value: 'For-profit Company' },
  { label: 'Investment Vehicle', value: 'Investment Vehicle' },
  { label: 'Legal/Accounting Provider', value: 'Legal/Accounting Provider' },
  { label: 'Other', value: 'Other' },
];

const NewCompany = ({ history: { goBack }, location: { search } }) => {
  const dispatch = useDispatch();
  const isFetching = useSelector(isCompanyFetching);
  const [isSubmitted, setSubmitted] = useState(false);
  return (
    <Modal
      isOpen={true}
      onRequestClose={e => {
        e.stopPropagation();
        goBack();
      }}
      style={customStyles}
    >
      <div className="new-company">
        <h2>
          <FontAwesomeIcon icon="exclamation-circle" />
          Add a company to your User account
        </h2>
        <Formik
          initialValues={{
            company_name: '',
            business_type: '',
          }}
          onSubmit={({ company_name, business_type }, actions) => {
            setSubmitted(true);
            const body = {
              company_name,
              features: [{ feature_type_id: 881, value: business_type.value }],
            };
            dispatch(createNewCompany(body, search)).then(
              e => {},
              error => setSubmitted(false),
            );
          }}
        >
          {formikProps => (
            <Form>
              <Field
                component={TextField}
                label="Company Name:"
                name="company_name"
                validate={validateString}
              />
              <Field
                component={Select}
                isValidatingOnBlur={false}
                label="What is the purpose of this business entity?"
                name="business_type"
                options={businessTypeOptions}
                validate={validateSelect}
              />
              <div className="new-company__action">
                <Button
                  type="submit"
                  isDisabled={isFetching || isSubmitted}
                  isFetching={isFetching || isSubmitted}
                >
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {/* </div> */}
    </Modal>
  );
};

NewCompany.propTypes = {
  fetchError: PropTypes.string,
  isFetching: PropTypes.bool,
  initData: MyTypes.initData,
  myModule: PropTypes.object,
};

export default NewCompany;
