export default {
  '-1': {
    icon: ['fal', 'home'],
    id: '-1',
    path: '/home',
    label: 'Dashboard',
  },
  0: {
    icon: ['fal', 'home'],
    id: '0',
    path: '/home',
    label: 'Dashboard',
  },
  2: {
    icon: ['fal', 'building'],
    id: '2',
    isModule: true,
    path: {
      pathname: '/workflows',
      hash: '#entity',
    },
    label: 'Entity',
  },
  3: {
    icon: ['fal', 'lightbulb'],
    id: '3',
    isModule: true,
    path: {
      pathname: '/workflows',
      hash: '#intellectual-property',
    },
    label: 'Intellectual Property',
  },
  4: {
    icon: ['fal', 'users'],
    id: '4',
    isModule: true,
    path: {
      pathname: '/workflows',
      hash: '#team',
    },
    label: 'Team',
  },
  5: {
    icon: ['fal', 'signature'],
    id: '5',
    isModule: true,
    path: {
      pathname: '/workflows',
      hash: '#customers-partners',
    },
    label: 'Customers | Partners',
  },
  6: {
    icon: ['fal', 'dollar-sign'],
    id: '6',
    isModule: true,
    path: {
      pathname: '/workflows',
      hash: '#financing',
    },
    label: 'Financing',
  },
  7: {
    icon: ['fal', 'user-tie'],
    id: '7',
    isModule: true,
    path: {
      pathname: '/workflows',
      hash: '#counsel',
    },
    label: 'Counsel',
  },
  8: {
    icon: ['fal', 'file-user'],
    id: '8',
    isModule: true,
    path: {
      pathname: '/workflows',
      hash: '#contract-requests',
    },
    label: 'Contract Requests',
  },
  9: {
    icon: ['fal', 'chart-pie'],
    id: '9',
    isModule: true,
    path: {
      pathname: '/workflows',
      hash: '#equity',
    },
    label: 'Equity',
  },
  10: {
    icon: ['fal', 'users'],
    id: '10',
    path: '/savvi-partners',
    label: 'Savvi Partners',
  },
  tasks: {
    icon: ['fal', 'ballot-check'],
    id: 'tasks',
    path: '/tasks',
    label: 'Tasks',
  },
  submitted_tasks: {
    icon: ['fal', 'history'],
    id: 'submitted_tasks',
    path: '/submitted-tasks',
    label: 'Submitted Tasks',
  },
  documents: {
    icon: ['fal', 'copy'],
    id: 'documents',
    path: '/documents',
    label: 'Documents',
  },
  workflows: {
    icon: ['fal', 'file-signature'],
    id: 'workflows',
    path: '/workflows',
    label: 'Workflows',
  },
  account: {
    icon: ['fal', 'cog'],
    id: 'account',
    path: '/settings',
    label: 'Account',
  },
};
