import React from 'react';

import SavviLoading from '../SavviLoading';

const PageLoader = ({ children }) => {
  return (
    <div className="dashboard-page__loading--large">
      {/* <FontAwesomeIcon icon="spinner" spin /> */}
      <SavviLoading />
    </div>
  );
};

export default PageLoader;
