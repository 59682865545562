import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContentPreview from 'box-ui-elements/es/elements/content-preview';
import classNames from 'classnames';

import { Button } from '../common';
import './DataTable.scss';
import { get, isEmpty } from 'lodash-es';
import { getBoxFileInfo } from '../../redux/modules/Cabinet/operations';
import AddResourceModal from '../AddResourceModal/AddResourceModal';
import { BoxToken } from '../../redux/modules/Cabinet/selectors';
import { CurrentCompany } from '../../redux/modules/Company/selectors';
import { someValuesEveryString } from '../../utils/FilterTools';
import FeatureTypes from '../../utils/FeatureTypes';
import { useParams } from 'react-router';
import handlePath from '../../utils/handlePath';

export default ({
  filterInput,
  isDataRoom,
  json: {
    allow_new,
    feature_types = [],
    links = [],
    resource_label,
    resource_type,
    resource_type_id,
  } = {},
  question_label,
  resourceListData = {},
}) => {
  const { REACT_APP_BASEURL } = process.env;
  const dispatch = useDispatch();
  const { companyId } = useParams();

  const boxToken = useSelector(BoxToken);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [selectedResource, setSelectedResource] = useState({});

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedResource({});
  };

  const handleDocumentLink = docId => {
    dispatch(getBoxFileInfo(docId)).then(payload => {
      const win = window.open(
        `${REACT_APP_BASEURL}/c/${companyId}/cabinet/${get(
          payload,
          'parent.id',
          -1,
        )}/${docId}`,
        '_blank',
      );
      win.focus();
    });
  };

  const filteredFeatureTypes = feature_types.filter(e => !!e.table_view);

  const resourceList = Object.entries(resourceListData)
    .filter(table => someValuesEveryString(table, [filterInput]))
    .reduce((dict, e, index) => {
      const rowItems = e[1].reduce(
        (features, feature) => ({
          ...features,
          [feature.feature]: feature,
        }),
        {},
      );
      dict.push(rowItems);
      return dict;
    }, []);

  return (
    <div className="dataTable__wrapper">
      <h2 className="dataTable__title">{question_label}</h2>
      <div
        className="dataTable"
        style={{
          // gridTemplateColumns: `repeat(${filteredFeatureTypes.length}, auto)`,
          gridTemplateColumns: filteredFeatureTypes.reduce(
            (str, e) =>
              `${str} ${get(
                FeatureTypes,
                `[${e.feature_type.feature_type}].gridColWidth`,
                'minMax(120px, 1fr)',
              )}`,
            '',
          ),
        }}
      >
        {resourceList.length === 0 && (
          <h2 className="dataTable__empty">{`No ${resource_label} Data Found`}</h2>
        )}
        {resourceList.length > 0 &&
          filteredFeatureTypes.map(
            (jsonFeatureType, index) =>
              !!jsonFeatureType.table_view && (
                <h4
                  className={`dataTable__header ${index === 0 ? 'first' : ''}`}
                  key={`resource-list-header-${jsonFeatureType.feature_name}`}
                >
                  {jsonFeatureType.feature_name}
                </h4>
              ),
          )}
        {resourceList.map((row, rowIndex) => (
          <DataRow
            boxToken={boxToken}
            companyId={companyId}
            featureTypes={filteredFeatureTypes}
            handleDocumentLink={handleDocumentLink}
            isDataRoom={isDataRoom}
            key={`resource-row-${rowIndex}`}
            row={row}
            rowIndex={rowIndex}
            setSelectedResource={setSelectedResource}
            setIsModalOpen={setIsModalOpen}
          />
        ))}
        {allow_new && !isDataRoom && (
          <Button
            buttonType="link"
            className="dataTable__add"
            onClick={() => {
              setSelectedResource({});
              setIsNew(true);
              setIsModalOpen(true);
            }}
          >
            <FontAwesomeIcon icon={['fal', 'user-plus']} />
            {`Add Existing ${resource_label}`}
          </Button>
        )}
      </div>
      {!isDataRoom &&
        links.map(
          (link, ind) =>
            link.table_view && (
              <h4 className="dataTable__subquestion" key={`resource-link-${ind}`}>
                <FontAwesomeIcon className="dataTable__info" icon="exclamation-circle" />
                {link.label}
                <Button buttonType="link" href={handlePath(link.link_url, companyId)}>
                  {link.link_text}
                </Button>
              </h4>
            ),
        )}
      <AddResourceModal
        feature_types={feature_types}
        handleClose={handleCloseModal}
        isLocked={isDataRoom}
        isOpen={isModalOpen}
        isNew={isNew}
        setIsNew={setIsNew}
        links={links}
        resource={selectedResource}
        resource_label={resource_label}
        resource_type={resource_type}
        resource_type_id={resource_type_id}
        setSelectedResource={setSelectedResource}
      />
    </div>
  );
};

const DataRow = ({
  boxToken,
  companyId,
  featureTypes,
  handleDocumentLink,
  isDataRoom,
  row,
  rowIndex,
  setSelectedResource,
  setIsModalOpen,
}) => {
  const [isPreviewShowing, setIsPreviewShowing] = useState(false);

  return featureTypes.map((jsonFeatureType, index) => {
    const item = get(row, `${jsonFeatureType.feature_type.name}`, {});
    // check if related_resource, if so use value in resourceListData.
    let label = item.value || '--';
    if (label.indexOf('|') !== -1) {
      label = label.split('|').join(', ');
    }
    if (!item.value && !isEmpty(jsonFeatureType.action)) {
      label = jsonFeatureType.action.label;
    }
    return (
      !!jsonFeatureType.table_view && (
        <div
          key={`resource-list-item-${rowIndex}-${index}-${item.feature}`}
          className={classNames('dataTable__item', {
            first: index === 0,
            // 'dataTable__item--locked': index === 0 && isDataRoom,
          })}
          style={{ maxWidth: featureTypes.length < 5 ? 'none' : 400 }}
          onClick={() => {
            if (index === 0 && !isDataRoom) {
              setSelectedResource(row);
              setIsModalOpen(true);
            }
          }}
        >
          {index === 0 && (
            <FontAwesomeIcon icon={isDataRoom ? 'file-search' : ['fal', 'edit']} />
          )}
          {item.feature_type !== 'document' && label}
          {item.feature_type === 'document' && (
            <Button
              buttonType="link"
              size="sm"
              onClick={() => setIsPreviewShowing(!isPreviewShowing)}
            >
              {isPreviewShowing ? 'Hide Document' : 'Show Document'}
            </Button>
          )}
          {isPreviewShowing && item.feature_type === 'document' && item.value && (
            <>
              <div className="dataTable__preview">
                <ContentPreview fileId={item.value} token={boxToken} />
              </div>
              {isDataRoom && (
                <Button
                  buttonType="link"
                  size="sm"
                  onClick={() => handleDocumentLink(item.value)}
                >
                  View in File Cabinet
                </Button>
              )}
            </>
          )}
          {!item.value && !isEmpty(jsonFeatureType.action) && !isDataRoom && (
            <>
              {label}
              <Button
                buttonType="link"
                href={handlePath(jsonFeatureType.action.link_url, companyId)}
                size="sm"
              >
                {jsonFeatureType.action.link_text}
              </Button>
            </>
          )}
        </div>
      )
    );
  });
};

export const CompanyTable = ({
  json: {
    feature_types = [],
    links = [],
    resource_label,
    resource_type,
    resource_type_id,
  } = {},
  question_label,
  resourceListData,
}) => {
  const { REACT_APP_BASEURL } = process.env;
  const dispatch = useDispatch();
  const { companyId } = useParams();

  const boxToken = useSelector(BoxToken);
  const currentCompany = useSelector(CurrentCompany);

  const [isPreviewShowing, setIsPreviewShowing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDocumentLink = docId => {
    dispatch(getBoxFileInfo(docId)).then(payload => {
      const win = window.open(
        `${REACT_APP_BASEURL}/c/${companyId}/cabinet/${get(
          payload,
          'parent.id',
          -1,
        )}/${docId}`,
        '_blank',
      );
      win.focus();
    });
  };

  const filteredFeatureTypes = feature_types.filter(e => !!e.table_view);

  const resourceList = Object.entries(resourceListData).reduce((dict, e, index) => {
    const rowItems = e[1].reduce(
      (features, feature) => ({
        ...features,
        [feature.feature]: feature,
      }),
      {},
    );
    if (
      get(e[1], '[0].resource', '') === currentCompany.name ||
      get(e[1], '[0].resource', '') === currentCompany.account_name
    ) {
      dict.push(rowItems);
    }
    return dict;
  }, []);

  return (
    <div className="dataTable__wrapper">
      <h2 className="dataTable__title isCompany">
        {question_label}
        <Button
          buttonType="icon"
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          <FontAwesomeIcon icon={['fal', 'edit']} />
        </Button>
      </h2>
      <div
        className="dataTable isCompany"
        style={{
          gridTemplateColumns: `repeat(5, auto)`,
        }}
      >
        {resourceList.length === 0 && (
          <h2 className="dataTable__empty">{`No ${resource_label} Data Found`}</h2>
        )}
        {resourceList.map((row, rowIndex) =>
          filteredFeatureTypes.map((jsonFeatureType, index) => {
            const item = get(row, `${jsonFeatureType.feature_type.name}`, {});
            let label = item.value || '--';
            if (label.indexOf('|') !== -1) {
              label = label.split('|').join(', ');
            }
            if (!item.value && !isEmpty(jsonFeatureType.action)) {
              label = jsonFeatureType.action.label;
            }

            if (!jsonFeatureType.table_view) {
              return null;
            }
            return (
              !jsonFeatureType.edit_only && (
                <div
                  key={`resource-list-item-${rowIndex}-${index}-${item.feature}`}
                  className="dataTable__item is-company"
                >
                  <h4
                    className="dataTable__header"
                    key={`resource-list-header-${jsonFeatureType.feature_name}`}
                  >
                    {jsonFeatureType.feature_name}:
                  </h4>
                  {item.feature_type === 'document' && (
                    <Button
                      buttonType="link"
                      size="sm"
                      onClick={() => setIsPreviewShowing(!isPreviewShowing)}
                    >
                      {isPreviewShowing ? 'Hide Document' : 'Show Document'}
                    </Button>
                  )}
                  {isPreviewShowing && item.feature_type === 'document' && item.value && (
                    <>
                      <div className="dataTable__preview">
                        <ContentPreview fileId={item.value} token={boxToken} />
                      </div>
                      <Button
                        buttonType="link"
                        size="sm"
                        onClick={() => handleDocumentLink(item.value)}
                      >
                        View in File Cabinet
                      </Button>
                    </>
                  )}
                  {item.feature_type !== 'document' && label}
                  {!item.value && !isEmpty(jsonFeatureType.action) && (
                    <>
                      {label}
                      <Button
                        buttonType="link"
                        href={handlePath(jsonFeatureType.action.link_text, companyId)}
                        size="sm"
                      >
                        {jsonFeatureType.action.link_text}
                      </Button>
                    </>
                  )}
                </div>
              )
            );
          }),
        )}
      </div>
      {links.map(
        (link, ind) =>
          link.table_view && (
            <h4 className="dataTable__subquestion" key={`resource-link-${ind}`}>
              <FontAwesomeIcon className="dataTable__info" icon="exclamation-circle" />
              {link.label}
              <Button buttonType="link" href={handlePath(link.link_url, companyId)}>
                {link.link_text}
              </Button>
            </h4>
          ),
      )}
      <AddResourceModal
        feature_types={feature_types}
        handleClose={handleCloseModal}
        isCompany
        isOpen={isModalOpen}
        links={links}
        resource={resourceList[0]}
        resource_label={resource_label}
        resource_type={resource_type}
        resource_type_id={resource_type_id}
        setIsNew={() => {}}
        setSelectedResource={() => {}}
      />
    </div>
  );
};
