// Submit Form Actions
export const GET_COMPANIES_REQUEST = 'savvi/company/GET_COMPANIES_REQUEST';
export const GET_COMPANIES_SUCCESS = 'savvi/company/GET_COMPANIES_SUCCESS';
export const GET_COMPANIES_FAILED = 'savvi/company/GET_COMPANIES_FAILED';

export const CREATE_COMPANY_REQUEST = 'savvi/company/CREATE_COMPANY_REQUEST';
export const CREATE_COMPANY_SUCCESS = 'savvi/company/CREATE_COMPANY_SUCCESS';
export const CREATE_COMPANY_FAILED = 'savvi/company/CREATE_COMPANY_FAILED';

export const REFRESH_COMPANY_TOKEN_REQUEST =
  'savvi/company/REFRESH_COMPANY_TOKEN_REQUEST';
export const REFRESH_COMPANY_TOKEN_SUCCESS =
  'savvi/company/REFRESH_COMPANY_TOKEN_SUCCESS';
export const REFRESH_COMPANY_TOKEN_FAILED = 'savvi/company/REFRESH_COMPANY_TOKEN_FAILED';

export const SET_COMPANY_TOKEN_REQUEST = 'savvi/company/SET_COMPANY_TOKEN_REQUEST';
export const SET_COMPANY_TOKEN_SUCCESS = 'savvi/company/SET_COMPANY_TOKEN_SUCCESS';
export const SET_COMPANY_TOKEN_FAILED = 'savvi/company/SET_COMPANY_TOKEN_FAILED';

export const GET_COMPANY_DATA_TABLES_REQUEST =
  'savvi/company/GET_COMPANY_DATA_TABLES_REQUEST';
export const GET_COMPANY_DATA_TABLES_SUCCESS =
  'savvi/company/GET_COMPANY_DATA_TABLES_SUCCESS';
export const GET_COMPANY_DATA_TABLES_FAILED =
  'savvi/company/GET_COMPANY_DATA_TABLES_FAILED';

export const SET_COMPANIES = 'savvi/company/SET_COMPANIES';
export const SET_COMPANY_DATA_TABLES = 'savvi/company/SET_COMPANY_DATA_TABLES';
export const SET_COMPANY = 'savvi/company/SET_COMPANY';
