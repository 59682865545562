import React, { Fragment, useState } from 'react';
import { DropdownMenu } from 'react-dd-menu';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IsDocumentTransaction } from '../../redux/modules/Transaction/selectors';
import Button from '../common/Button';
import InputStyledCheckbox from '../common/InputStyledCheckbox';

import './FinalizeReviewModal.scss';
import { get, isArray, isObject, startCase } from 'lodash-es';
import { IsAccountAdmin } from '../../redux/modules/Profile/selectors';

const ReviewInfo = reviewObj =>
  Object.entries(reviewObj).map(([resourceKey, resourceArr], index) => (
    <div className="finalizeReviewModal__header" key={`review-${resourceKey}-${index}`}>
      {resourceArr.map((resource, resourceInd) => (
        <Fragment key={`resource-array-${resourceInd}`}>
          {resource.resource && (
            <h4>
              <em>{`${resource.resource} (${resource['Custom Label']})`}</em>
            </h4>
          )}
          {resource.resource && (
            <div className="finalizeReviewModal__row">
              {Object.entries(resource).map(([key, value], ind) => {
                if (
                  key !== 'resource' &&
                  key !== 'Company Name' &&
                  key !== 'Name' &&
                  key !== 'Custom Label'
                ) {
                  let updatedVal = value;

                  if (isObject(value)) {
                    if (value.isDocument) {
                      return (
                        <Fragment key={`review-info-${key}-${ind}`}>
                          <h4>
                            {key}: <p>{value.value}</p>
                          </h4>
                        </Fragment>
                      );
                    }
                    if (value.isLink) {
                      return (
                        <Fragment key={`review-info-${key}-${ind}`}>
                          <h4>
                            {key}: <a href={value.path}>{value.label}</a>
                          </h4>
                        </Fragment>
                      );
                    }
                    if (isArray(value)) {
                      return (
                        <Fragment key={`review-info-link-${key}}-${ind}`}>
                          <h4>{key}:</h4>
                          <div className="finalizeReviewModal__reviewRow">
                            {value.map((e, linkIndex) => (
                              <Fragment key={`review-info-link-${e.label}}-${ind}`}>
                                <p>
                                  Link #{linkIndex + 1}:
                                  <a
                                    href={e.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {e.label}
                                  </a>
                                </p>
                              </Fragment>
                            ))}
                          </div>
                        </Fragment>
                      );
                    }
                    if (!Object.values(value).join('')) {
                      updatedVal = 'None Given';
                    } else {
                      return (
                        <Fragment key={`review-info-${key}-${ind}`}>
                          <h4>{key}:</h4>
                          <p>{value.line_one}</p>
                          {value.line_two.length > 0 && <p>{value.line_two}</p>}
                          <p>{`${startCase(value.city)}, ${value.state} ${value.zip}`}</p>
                        </Fragment>
                      );
                    }
                  } else if (value.indexOf('|') !== -1) {
                    updatedVal = updatedVal.split('|').join(', ');
                  }
                  return (
                    <h4 key={`review-info-${key}-${ind}`}>
                      {key}: <p>{updatedVal || 'Not Given'}</p>
                    </h4>
                  );
                }
                return null;
              })}
            </div>
          )}
        </Fragment>
      ))}
    </div>
  ));

const FinalizeReviewModal = ({
  addons,
  amountTotal,
  documentCredits,
  fees,
  feesTotal,
  isAddonsHidden,
  isAssessment,
  isOpen,
  isOutsideForm,
  isTotalShowing,
  onClose,
  onFinalizeClick,
  params,
  reviewInfo,
  room_use,
}) => {
  const customStyles = {
    overlay: {
      zIndex: 9,
      backgroundColor: 'rgba(0,0,0,.66)',
      position: 'fixed',
      height: '100vh',
      width: '100vw',
      display: 'flex',
      overflowY: 'auto',
      paddingTop: 20,
      WebkitOverflowScrolling: 'touch',
    },
    content: {
      position: 'static',
      maxWidth: '450px',
      minHeight: '250px',
      margin: 'auto',
      padding: 0,
      border: 'none',
      boxShadow: '3px 3px 3px 3px #33373859',
      display: 'flex',
      flexDirection: 'column',
    },
  };
  const { transactionId } = params;
  const { REACT_APP_LOG_ENV } = process.env;
  const isDevEnv = REACT_APP_LOG_ENV !== 'production';

  const isDocumentTransaction = useSelector(state =>
    IsDocumentTransaction(state, transactionId),
  );
  const isAdmin = useSelector(IsAccountAdmin);

  const [isAutoSend, setIsAutoSend] = useState(true);
  const [isDevPandadoc, setDevPandadoc] = useState(false);
  const [isPricingOpen, setPricingOpen] = useState(false);

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles}>
      <div className="finalizeReviewModal">
        <FontAwesomeIcon
          className="finalizeReviewModal__exit"
          onClick={onClose}
          icon={['fal', 'times']}
        />
        <h2>Important</h2>
        <p>
          <FontAwesomeIcon icon="exclamation-circle" />
          {isAssessment
            ? 'Please make sure this information is correct before continuing. This data is used to generate specific Tasks for your legal needs.'
            : "Please make sure this information is correct before finalizing. This data is used in important legal documents and will be stored on this entity's account."}
        </p>
        <div className="finalizeReviewModal__info">
          <ReviewInfo {...reviewInfo} />
        </div>
        {isAdmin && !!room_use && (
          <p className="finalizeReviewModal__room">
            <FontAwesomeIcon icon="exclamation-circle" />
            Finalizing will generate an open Data Room.
          </p>
        )}
        {isDocumentTransaction && isDevEnv && (
          <div className="finalizeReviewModal__redlining">
            <InputStyledCheckbox
              checked={isDevPandadoc}
              label="Send Pandadoc Documents on finalize(dev-only feature)"
              name="send-panda-doc"
              onChange={() => setDevPandadoc(!isDevPandadoc)}
              value={isDevPandadoc}
            />
          </div>
        )}
        {isDocumentTransaction && (
          <div className="finalizeReviewModal__redlining">
            <h4>How do you want your document links to be sent?</h4>
            <InputStyledCheckbox
              checked={isAutoSend}
              inputHelpAlign="center"
              label="Automatically"
              name="enable-document-editing"
              onChange={() => setIsAutoSend(true)}
              // questionHelp="Document Editing allows allocated reviewers as well as all signers to edit/adjust documents before sending the selected edited document version out for signature."
              type="radio"
              value={isAutoSend}
            />
            <InputStyledCheckbox
              checked={!isAutoSend}
              inputHelpAlign="center"
              label="Manually"
              name="disable-document-editing"
              onChange={() => setIsAutoSend(false)}
              type="radio"
              value={!isAutoSend}
            />
          </div>
        )}
        {isTotalShowing && (
          <div className="finalizeReviewModal__info visible">
            <h4>
              <FontAwesomeIcon icon="exclamation-circle" />
              Your Account will be billed{' '}
              <span>
                ${isAddonsHidden ? feesTotal : amountTotal}
                <DropdownMenu
                  align="center"
                  upwards
                  className="finalizeReviewModal__help"
                  close={() => setPricingOpen(false)}
                  closeOnInsideClick={false}
                  isOpen={isPricingOpen} // eslint-disable-line eqeqeq
                  toggle={
                    <sup>
                      <FontAwesomeIcon
                        onClick={() => setPricingOpen(!isPricingOpen)}
                        icon={['fal', 'info-circle']}
                      />
                    </sup>
                  }
                >
                  {fees.length > 0 && (
                    <>
                      <h4>Additional Fees:</h4>
                      {fees.map((fee, feeInd) => (
                        <li key={`product-fee-${feeInd}`}>
                          {`- ${fee.label} - ${
                            fee.is_annual ? fee.amount : '$' + fee.amount
                          }`}
                          {fee.comment && (
                            <ul>
                              <li>{fee.comment}</li>
                            </ul>
                          )}
                        </li>
                      ))}
                    </>
                  )}
                  {addons.length > 0 && !isAddonsHidden && (
                    <>
                      <h4>Add-ons:</h4>
                      <ul>
                        {addons.map((addon, addonInd) => (
                          <li key={`product-addon-${addonInd}`}>
                            {`- ${addon.label} - ${'$' + addon.amount}`}/
                            {addon.period === 'year' ? 'Yr' : 'Mo'}
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                  {(fees.length > 0 || addons.length > 0) &&
                    !get(fees, '[0].is_annual', false) && (
                      <h4>Total - ${isAddonsHidden ? feesTotal : amountTotal}</h4>
                    )}
                </DropdownMenu>
              </span>
              And <span>{documentCredits || 'zero'}</span> document credit(s) when you
              finalize.
            </h4>
          </div>
        )}
        <div className="finalizeReviewModal__buttons">
          <Button buttonType="secondary" onClick={() => onClose()}>
            Back
          </Button>
          <Button
            onClick={() =>
              isDevEnv
                ? onFinalizeClick(isAutoSend, isDevEnv, isDevPandadoc)
                : onFinalizeClick(isAutoSend)
            }
          >
            {(isAssessment && 'Generate Tasks') ||
              (isOutsideForm && 'Submit') ||
              'Finalize'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default FinalizeReviewModal;
