// Box Token Actions
export const SET_ACCOUNT_INFO = 'savvi/formation/SET_ACCOUNT_INFO';

export const GET_FEATURES_REQUEST = 'savvi/formation/GET_FEATURES_REQUEST';
export const GET_ACCOUNT_INFO_REQUEST = 'savvi/formation/GET_ACCOUNT_INFO_REQUEST';
export const PATCH_ACCOUNT_INFO_REQUEST = 'savvi/formation/PATCH_ACCOUNT_INFO_REQUEST';

export const GET_FEATURES_FAILED = 'savvi/formation/GET_FEATURES_FAILED';
export const GET_ACCOUNT_INFO_FAILED = 'savvi/formation/GET_ACCOUNT_INFO_FAILED';
export const PATCH_ACCOUNT_INFO_FAILED = 'savvi/formation/PATCH_ACCOUNT_INFO_FAILED';

export const GET_FEATURES_SUCCESS = 'savvi/formation/GET_FEATURES_SUCCESS';
export const GET_ACCOUNT_INFO_SUCCESS = 'savvi/formation/GET_ACCOUNT_INFO_SUCCESS';
export const PATCH_ACCOUNT_INFO_SUCCESS = 'savvi/formation/PATCH_ACCOUNT_INFO_SUCCESS';

export const SET_LABEL = 'savvi/formation/SET_LABEL';
export const SET_LABEL_REQUEST = 'savvi/formation/SET_LABEL_REQUEST';
export const SET_LABEL_FAILED = 'savvi/formation/SET_LABEL_FAILED';
export const SET_LABEL_SUCCESS = 'savvi/formation/SET_LABEL_SUCCESS';

export const FINALIZE_TASK_REQUEST = 'savvi/formation/FINALIZE_TASK_REQUEST';
export const FINALIZE_TASK_FAILED = 'savvi/formation/FINALIZE_TASK_FAILED';
export const FINALIZE_TASK_SUCCESS = 'savvi/formation/FINALIZE_TASK_SUCCESS';

export const PATCH_FEATURE_REQUEST = 'savvi/formation/PATCH_FEATURE_REQUEST';
export const PATCH_FEATURE_FAILED = 'savvi/formation/PATCH_FEATURE_FAILED';
export const PATCH_FEATURE_SUCCESS = 'savvi/formation/PATCH_FEATURE_SUCCESS';

export const CREATE_FEATURE_REQUEST = 'savvi/formation/CREATE_FEATURE_REQUEST';
export const CREATE_FEATURE_FAILED = 'savvi/formation/CREATE_FEATURE_FAILED';
export const CREATE_FEATURE_SUCCESS = 'savvi/formation/CREATE_FEATURE_SUCCESS';

export const SET_FEATURES = 'savvi/formation/SET_FEATURES';
export const SET_FEATURE = 'savvi/formation/SET_FEATURE';
export const PATCH_FEATURE = 'savvi/formation/PATCH_FEATURE';

export const CLEAR_ERRORS = 'savvi/formation/CLEAR_ERRORS';

export const UPDATE_COMPANY_SIGNATORY_REQUEST =
  'savvi/formation/UPDATE_COMPANY_SIGNATORY_REQUEST';
export const UPDATE_COMPANY_SIGNATORY_FAILED =
  'savvi/formation/UPDATE_COMPANY_SIGNATORY_FAILED';
export const UPDATE_COMPANY_SIGNATORY_SUCCESS =
  'savvi/formation/UPDATE_COMPANY_SIGNATORY_SUCCESS';

export const GET_RESOURCES_BY_TYPE_ID_REQUEST =
  'savvi/formation/GET_RESOURCES_BY_TYPE_ID_REQUEST';
export const GET_RESOURCES_BY_TYPE_ID_FAILED =
  'savvi/formation/GET_RESOURCES_BY_TYPE_ID_FAILED';
export const GET_RESOURCES_BY_TYPE_ID_SUCCESS =
  'savvi/formation/GET_RESOURCES_BY_TYPE_ID_SUCCESS';
export const SET_RESOURCES_BY_TYPE_ID = 'savvi/formation/SET_RESOURCES_BY_TYPE_ID';

export const GET_RESOURCE_BY_ID_REQUEST = 'savvi/formation/GET_RESOURCE_BY_ID_REQUEST';
export const GET_RESOURCE_BY_ID_FAILED = 'savvi/formation/GET_RESOURCE_BY_ID_FAILED';
export const GET_RESOURCE_BY_ID_SUCCESS = 'savvi/formation/GET_RESOURCE_BY_ID_SUCCESS';
export const SET_RESOURCE_BY_ID = 'savvi/formation/SET_RESOURCE_BY_ID';

export const DELETE_INDIVIDUAL_REQUEST = 'savvi/formation/DELETE_INDIVIDUAL_REQUEST';
export const DELETE_INDIVIDUAL_FAILED = 'savvi/formation/DELETE_INDIVIDUAL_FAILED';
export const DELETE_INDIVIDUAL_SUCCESS = 'savvi/formation/DELETE_INDIVIDUAL_SUCCESS';
export const REMOVE_INDIVIDUAL = 'savvi/formation/REMOVE_INDIVIDUAL';
