import { combineReducers } from 'redux';

import { createReducer, fetchingReducer, getValidationErrors } from '../../utils';
import * as Types from './types';

let profile = {
  firstName: '',
  lastName: '',
  phone: '',
};

const isFetching = fetchingReducer('profile');
const isDirty = createReducer(false)({
  [Types['UPDATE']]: (state, { payload }) => true,
  [Types['GET_SUCCESS']]: () => false,
  [Types['SAVE_SUCCESS']]: () => false,
});

const profileReducer = createReducer(profile)({
  [Types['UPDATE']]: (state, { payload }) => ({ ...state, ...payload }),
  [Types['GET_SUCCESS']]: (state, { payload }) => ({ ...state, ...payload }),
  [Types['SAVE_SUCCESS']]: (state, { payload }) => ({ ...state, ...payload }),
});

const counselsReducer = createReducer({})({
  [Types['SET_COUNSELS']]: (state, { payload }) => payload,
});

const teamsReducer = createReducer([])({
  [Types['SET_TEAMS']]: (state, { payload }) => payload,
});

const savviPartnersReducer = createReducer({ myPartners: [], partnerGroups: [] })({
  [Types['SET_SAVVI_PARTNERS']]: (state, { payload }) => ({
    ...state,
    myPartners: [...payload.my_partners],
  }),
  [Types['SET_SAVVI_PARTNER_GROUP']]: (state, { payload }) => {
    let newPartnerGroups = state.partnerGroups.slice();
    let groupIndex = newPartnerGroups.findIndex(e => e.id === payload.id);
    if (groupIndex === -1) {
      newPartnerGroups.push(payload);
    } else {
      newPartnerGroups[groupIndex] = {
        ...newPartnerGroups[groupIndex],
        ...payload,
      };
    }
    return {
      ...state,
      partnerGroups: [...newPartnerGroups],
    };
  },
  [Types['ADD_SAVVI_PARTNER_GROUP_MEMBER']]: (state, { payload, groupId }) => {
    let newPartnerGroups = state.partnerGroups.slice();
    let groupInd = newPartnerGroups.findIndex(e => e.id === groupId);
    let memberInd = newPartnerGroups[groupInd].members.findIndex(
      e => e.id === payload.id,
    );
    newPartnerGroups[groupInd].members.splice(memberInd, 0, payload);

    return {
      ...state,
      partnerGroups: [...newPartnerGroups],
    };
  },
  [Types['UPDATE_SAVVI_PARTNER_GROUP_MEMBER']]: (state, { payload, groupId }) => {
    let newPartnerGroups = state.partnerGroups.map((item, index) => {
      if (item.id !== groupId) {
        return item;
      }
      return {
        ...item,
        members: [
          ...item.members.map((member, ind) => {
            if (payload.id !== member.id) {
              return member;
            }
            return {
              ...member,
              ...payload,
            };
          }),
        ],
      };
    });

    return {
      ...state,
      partnerGroups: [...newPartnerGroups],
    };
  },
  [Types['REMOVE_SAVVI_PARTNER_GROUP_MEMBER']]: (state, { groupId, memberId }) => {
    let newPartnerGroups = state.partnerGroups.slice();
    let groupInd = newPartnerGroups.findIndex(e => e.id === groupId);
    let memberInd = newPartnerGroups[groupInd].members.findIndex(e => e.id === memberId);
    newPartnerGroups[groupInd].members.splice(memberInd, 1);

    return {
      ...state,
      partnerGroups: [...newPartnerGroups],
    };
  },
});

const emailsReducer = createReducer({})({
  [Types['SET_EMAILS']]: (state, { payload }) => payload,
});

const featureChangesReducer = createReducer([])({
  [Types['SET_FEATURE_CHANGES']]: (state, { payload }) => payload,
});

const errorsReducer = createReducer({})({
  [Types['PASSWORD_REQUEST']]: () => ({}),
  [Types['GET_FAILED']]: (state, { payload }) => {
    const { message } = payload;
    if (message) {
      return { form: message };
    } else {
      return { form: 'There was an error while retrieving your profile.' };
    }
  },
  [Types['SAVE_FAILED']]: (state, { payload }) => {
    const { message, errors } = payload;
    if (errors) {
      return getValidationErrors(errors);
    } else if (message) {
      return { form: message };
    } else {
      return { form: 'There was an error while saving your settings.' };
    }
  },
  [Types['PASSWORD_FAILED']]: (state, { payload }) => {
    const { code, message, errors } = payload;
    if (code === 'INVALID_CREDENTIALS') {
      return { form: 'Your current password is incorrect, please try again.' };
    } else if (errors) {
      return getValidationErrors(errors);
    } else if (message) {
      return { form: message };
    } else {
      return { form: 'There was an error while saving your settings.' };
    }
  },
});

export default combineReducers({
  counsels: counselsReducer,
  isFetching,
  isDirty,
  savviPartners: savviPartnersReducer,
  teams: teamsReducer,
  user: profileReducer,
  emails: emailsReducer,
  featureChanges: featureChangesReducer,
  errors: errorsReducer,
});
