import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as SavviLoadingSVG } from '../../assets/images/savvi_loading.svg';

import './SavviLoading.scss';

const SavviLoading = ({ customClass, color, size }) => (
  <SavviLoadingSVG
    className={`savviLoading__svg ${customClass} savviLoading--${color} savviLoading--${size}`}
  />
);

SavviLoading.propTypes = {
  customClass: PropTypes.string,
  color: PropTypes.oneOf(['black', 'cerulean', 'mint', 'powderblue', 'tiber', 'white']),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

SavviLoading.defaultProps = {
  color: 'black',
  size: 'lg',
};

export default SavviLoading;
