import get from 'lodash-es/get';

export default status => get(statusDict, `${status}`, 'N/A');

export const statusDict = {
  'document.upload': 'Uploaded',
  'document.draft': 'Under Savvi Review',
  'document.sent': 'Sent',
  'document.viewed': 'Viewed',
  'document.waiting_approval': 'Waiting Approval',
  'document.rejected': 'Rejected',
  'document.approved': 'Approved',
  'document.waiting_pay': 'Waiting Payment',
  'document.paid': 'Paid',
  'document.completed': 'Completed',
  'document.voided': 'Voided',
  'document.declined': 'Declined',
  'document.deleted': 'Deleted',
};
