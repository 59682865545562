import { RSAA } from 'redux-api-middleware';

import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';

import { requestHeaders } from '../../utils/api';
import { setNotice } from '../UI/actions';
import * as actions from './actions';
import * as Types from './types';

export const getTodos = isTeam => {
  const REQUEST = Types[`GET_${isTeam ? 'ACCOUNT' : 'USER'}_TODOS_REQUEST`];
  const SUCCESS = Types[`GET_${isTeam ? 'ACCOUNT' : 'USER'}_TODOS_SUCCESS`];
  const FAILED = Types[`GET_${isTeam ? 'ACCOUNT' : 'USER'}_TODOS_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/${isTeam ? 'user' : 'account'}/recommendation`,
        method: 'GET',
        headers: requestHeaders(true, isTeam ? 'user' : ''),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      return await dispatch(
        actions[isTeam ? 'setTeamTodos' : 'setAccountTodos'](actionResponse.payload),
      );
      // await dispatch(actions.setQuestionnaire(actionResponse.payload));
    }
  };
};

export const updateTodo = (todoId, body, isTeam, accountId, isFetchingTodosAfter) => {
  const REQUEST = Types[`UPDATE_${isTeam ? 'USER' : 'ACCOUNT'}_TODO_REQUEST`];
  const SUCCESS = Types[`UPDATE_${isTeam ? 'USER' : 'ACCOUNT'}_TODO_SUCCESS`];
  const FAILED = Types[`UPDATE_${isTeam ? 'USER' : 'ACCOUNT'}_TODO_FAILED`];
  const url = `/api/${
    isTeam ? `user/dashboard/_account/${accountId}` : 'account'
  }/recommendation/${todoId}`;
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: url,
        method: 'PATCH',
        headers: requestHeaders(true, isTeam ? 'user' : ''),
        types: [REQUEST, SUCCESS, FAILED],
        body: () => JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      await dispatch(setNotice('Assignee Updated.'));
      if (isFetchingTodosAfter) {
        return await dispatch(getTodos(isTeam));
      }
      // return await dispatch(actions.setProjects(actionResponse.payload));
    }
    await dispatch(
      setNotice({
        type: 'error',
        message: get(actionResponse, 'payload.message', 'An error has occurred.'),
      }),
    );
    throw await get(actionResponse, 'payload.message', 'An error has occurred.');
  };
};

export const assignTaskTodo = (accountTransactionId, body, isTeam, accountId) => {
  const REQUEST = Types[`ASSIGN_${isTeam ? 'USER' : 'ACCOUNT'}_TASK_TODO_REQUEST`];
  const SUCCESS = Types[`ASSIGN_${isTeam ? 'USER' : 'ACCOUNT'}_TASK_TODO_SUCCESS`];
  const FAILED = Types[`ASSIGN_${isTeam ? 'USER' : 'ACCOUNT'}_TASK_TODO_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/${
          isTeam ? `user/dashboard/_account/${accountId}` : 'account'
        }/transaction/${accountTransactionId}/assign`,
        method: 'POST',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        body: () => JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      await dispatch(setNotice('Task To-do assigned!'));
      return await dispatch(getTodos(isTeam));
    }
    await dispatch(
      setNotice({
        type: 'warning',
        message: get(actionResponse, 'payload.message', 'An error has occurred.'),
      }),
    );
    throw await get(actionResponse, 'payload.message', 'An error has occurred.');
  };
};

export const deleteTodo = (id, isTeam, accountId, isFetchingTodosAfter) => {
  const REQUEST = Types[`DELETE_${isTeam ? 'USER' : 'ACCOUNT'}_TODO_REQUEST`];
  const SUCCESS = Types[`DELETE_${isTeam ? 'USER' : 'ACCOUNT'}_TODO_SUCCESS`];
  const FAILED = Types[`DELETE_${isTeam ? 'USER' : 'ACCOUNT'}_TODO_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/${
          isTeam ? `user/dashboard/_account/${accountId}` : 'account'
        }/recommendation/${id}`,
        method: 'DELETE',
        headers: requestHeaders(true, isTeam ? 'user' : ''),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      await dispatch(setNotice('To-do deleted'));
      if (isFetchingTodosAfter) {
        return await dispatch(getTodos(isTeam));
      }
    }
    throw await get(actionResponse, 'payload.message', 'An error has occurred.');
  };
};

export const getAssigneeOptions = (accountId, projectTemplateId, isTeam) => {
  const REQUEST = Types[`GET_${isTeam ? 'USER' : 'ACCOUNT'}_ASSIGNEE_OPTIONS_REQUEST`];
  const SUCCESS = Types[`GET_${isTeam ? 'USER' : 'ACCOUNT'}_ASSIGNEE_OPTIONS_SUCCESS`];
  const FAILED = Types[`GET_${isTeam ? 'USER' : 'ACCOUNT'}_ASSIGNEE_OPTIONS_FAILED`];
  let endpoint = `/api/${
    isTeam ? `user/dashboard/_account/${accountId}` : 'account'
  }/recommendation/project_template/${projectTemplateId}`;
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint,
        method: 'GET',
        headers: requestHeaders(true, isTeam ? 'user' : ''),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error && !isEmpty(actionResponse.payload)) {
      await dispatch(
        actions.setAssigneeOptions(actionResponse.payload, accountId, projectTemplateId),
      );
      return await actionResponse.payload;
    }
    throw await get(actionResponse, 'payload.message', 'An error has occurred.');
  };
};
