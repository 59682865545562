import React, { Component, Fragment, createRef } from 'react';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContentPicker from 'box-ui-elements/es/elements/content-picker';
import ContentPreview from 'box-ui-elements/es/elements/content-preview';
import ContentUploader from 'box-ui-elements/es/elements/content-uploader';
import classnames from 'classnames';
import get from 'lodash-es/get';

// import { deleteDocumentUpload } from '../../redux/modules/Transaction/operations';
import savviLogo from '../../assets/images/savvi_logo.svg';
import {
  getBoxFileInfo,
  getBoxTokenFolderId,
  uploadBoxFile,
} from '../../redux/modules/Cabinet/operations';
import {
  BoxToken,
  IsTempBoxTokenFetchInitialized,
} from '../../redux/modules/Cabinet/selectors';
import { CurrentCompany } from '../../redux/modules/Company/selectors';

import './FileUploader.scss';

class FileUploader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      folder: null,
      isDeleteOpen: false,
      isInitialFetching: true,
      error: 'Unable to load, please refresh and try again.',
      isPickerOpen: false,
    };
    this.uploaderRef = createRef();
    this.innerUploaderRef = createRef();
  }

  componentDidMount() {
    const { fileId, getBoxTokenFolderId, path } = this.props;

    if (path && !fileId) {
      getBoxTokenFolderId(path)
        .then(payload => {
          this.setState({
            isInitialFetching: false,
            folder: payload.upload_folder_id,
          });
        })
        .catch(error => this.setState({ error }));
    } else {
      this.setState({ isInitialFetching: false });
    }
  }

  render() {
    const {
      access_code,
      account_transaction_id,
      boxToken,
      currentCompany,
      feature_type_id,
      filename,
      fileId,
      handleGotoCabinet,
      isDisabled,
      multiEntry,
      onUpload,
      push,
      resource_id,
      resource_name,
      tags,
      uploadBoxFile,
    } = this.props;
    const { error, folder, isInitialFetching, isPickerOpen } = this.state;

    const boxRootFolderId = get(currentCompany, 'account.box_root_folder_id', '0');

    if (!isInitialFetching && !boxToken && !folder) {
      return <div className="file-uploader-error">{error}</div>;
    }
    return (
      <>
        {isInitialFetching ? (
          <div className="loading">
            <FontAwesomeIcon icon="spinner" spin />
          </div>
        ) : (
          <>
            {!!fileId && !isPickerOpen && (
              <div
                className={classnames('file-uploader-content-preview', {
                  'file-uploader-content-preview--multi': multiEntry,
                })}
              >
                {!!boxToken && !multiEntry && (
                  <ContentPreview fileId={fileId} token={boxToken} hasHeader />
                )}
                {!!boxToken && multiEntry && <p>File(s) uploaded</p>}
                {!access_code && !isDisabled && (
                  <div className="file-uploader__actions">
                    <button
                      className="file-uploader__remove-upload"
                      onClick={e => this.setState({ isPickerOpen: true })}
                      type="button"
                    >
                      <FontAwesomeIcon icon={['fal', 'file-alt']} />
                      Select different file
                    </button>
                    <button
                      className="file-uploader__primary-action"
                      onClick={e => handleGotoCabinet(fileId, push)}
                      type="button"
                    >
                      View in File Cabinet
                    </button>
                  </div>
                )}
              </div>
            )}
            {!fileId && !isPickerOpen && (
              <div
                className={classnames('file-uploader__content-uploader', {
                  disabled: isDisabled,
                  'file-uploader__content-uploader--hidden-footer': !multiEntry,
                })}
              >
                {!!boxToken && (
                  <>
                    <ContentUploader
                      fileLimit={!multiEntry ? 1 : 100}
                      onBeforeUpload={e => {
                        if (!multiEntry) {
                          setTimeout(() =>
                            this.uploaderRef.current.lastElementChild.lastElementChild.lastElementChild.click(),
                          );
                        }
                      }}
                      token={boxToken}
                      measureRef={this.uploaderRef}
                      rootFolderId={folder || boxRootFolderId}
                      onUpload={e =>
                        uploadBoxFile(e.id, {
                          tags: tags || [],
                          feature_type_id,
                          filename,
                          resource_id,
                          resource_name,
                          account_transaction_id,
                        }).then(payload => {
                          onUpload(e.id);
                        })
                      }
                    />
                    {!access_code && !isDisabled && (
                      <button
                        onClick={() => this.setState({ isPickerOpen: true })}
                        type="button"
                        className="file-uploader__primary-action align-right"
                      >
                        Choose previously uploaded file
                      </button>
                    )}
                  </>
                )}
              </div>
            )}
            {isPickerOpen && boxToken && (
              <div
                className={classnames('file-uploader__content-uploader', {
                  disabled: isDisabled,
                  'file-uploader__content-uploader--hidden-footer': !multiEntry,
                })}
              >
                <ContentPicker
                  autoFocus
                  canCreateNewFolder={false}
                  canSetShareAccess={false}
                  canUpload
                  contentUploaderProps={{
                    fileLimit: !multiEntry ? 1 : 100,
                    onBeforeUpload: e => {
                      if (!multiEntry) {
                        setTimeout(() =>
                          this.innerUploaderRef.current.lastElementChild.lastElementChild.lastElementChild.click(),
                        );
                      }
                    },
                    measureRef: this.innerUploaderRef,
                    onUpload: e => {
                      uploadBoxFile(e.id, {
                        tags: tags || [],
                        feature_type_id,
                        filename,
                        resource_id,
                        resource_name,
                        account_transaction_id,
                      }).then(payload => {
                        this.setState({ isPickerOpen: false });
                        onUpload(e.id);
                      });
                    },
                  }}
                  isSmall
                  logoUrl={savviLogo}
                  maxSelectable={filename ? 1 : 100}
                  rootFolderId={boxRootFolderId}
                  token={boxToken}
                  measureRef={this.uploaderRef}
                  onCancel={e => this.setState({ isPickerOpen: false })}
                  onChoose={e => {
                    this.setState({ isPickerOpen: false });
                    onUpload(e[0].id);
                  }}
                />
                <button
                  onClick={() => this.setState({ isPickerOpen: false })}
                  type="button"
                  className="file-uploader__primary-action"
                >
                  {fileId ? 'Cancel' : 'Back to upload file'}
                </button>
              </div>
            )}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (State, ownProps) => ({
  isTempBoxTokenFetchInitialized: IsTempBoxTokenFetchInitialized(State),
  boxToken: BoxToken(State),
  currentCompany: CurrentCompany(State),
});

const mapDispatchToProps = (dispatch, { access_code, companyId, isOutsideForm }) => ({
  handleGotoCabinet: (fileId, push) => dispatch(getBoxFileInfo(fileId, push, companyId)),
  getBoxTokenFolderId: path =>
    dispatch(
      getBoxTokenFolderId(path, isOutsideForm, isOutsideForm ? false : access_code),
    ),
  uploadBoxFile: (fileId, body) => dispatch(uploadBoxFile(fileId, body, isOutsideForm)),
});

const enhance = connect(mapStateToProps, mapDispatchToProps);
export default enhance(FileUploader);
