import { RSAA } from 'redux-api-middleware';
import { push, replace } from 'connected-react-router';

import { requestHeaders } from '../../utils/api';
import { setNotice } from '../UI/actions';
import { setInitLoading, setAccountFromToken } from '../User/actions';
import * as actions from './actions';
import * as Types from './types';
import handlePath from '../../../utils/handlePath';
import {
  decodeToken,
  getDecodedToken,
  getStoredCompanyId,
  getStoredToken,
} from '../User/utils';
import { get, isObjectLike, isString } from 'lodash-es';
import { getAccountInfo } from '../Formation/operations';

export const getCompanies = (isLogin, push, search) => {
  const REQUEST = Types[`GET_COMPANIES_REQUEST`];
  const SUCCESS = Types[`GET_COMPANIES_SUCCESS`];
  const FAILED = Types[`GET_COMPANIES_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/user/accounts`,
        method: 'GET',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      // if (isLogin && actionResponse.payload.length > 0) {
      // if (isLogin) {
      //   await dispatch(actions.setCompaniesById(actionResponse.payload));
      //   return await push({ pathname: '/company-select', search });
      // }
      await dispatch(actions.setCompaniesById(actionResponse.payload));
      return await actionResponse;
    } else {
      throw actionResponse.payload.message;
    }
  };
};

export const refreshCompanyToken = (account_id, user_id) => {
  const REQUEST = Types[`REFRESH_COMPANY_TOKEN_REQUEST`];
  const SUCCESS = Types[`REFRESH_COMPANY_TOKEN_SUCCESS`];
  const FAILED = Types[`REFRESH_COMPANY_TOKEN_FAILED`];
  return async (dispatch, getState) => {
    const { impersonator_id, userId } = getDecodedToken(getStoredToken(account_id));
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/authn/exchange`,
        method: 'POST',
        headers: requestHeaders(true, 'user'),
        body: JSON.stringify(
          { account_id, user_id: user_id || (!!impersonator_id && userId) || null },
          null,
          2,
        ),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      let decodedToken = decodeToken(actionResponse.payload.access_token);
      dispatch(getAccountInfo(account_id));
      // let decodedToken = decodeToken((actionResponse.payload || actionResponse).id_token);
      dispatch(setAccountFromToken(decodedToken));
      return await actionResponse.payload.access_token;
    }
    throw actionResponse.payload;
  };
};

export const setCompany = (
  accountId,
  path = '/home',
  isCurrent,
  gotoType = 'push',
  isPathLocked,
) => {
  const REQUEST = Types[`SET_COMPANY_TOKEN_REQUEST`];
  const SUCCESS = Types[`SET_COMPANY_TOKEN_SUCCESS`];
  const FAILED = Types[`SET_COMPANY_TOKEN_FAILED`];
  const history = { push, replace };

  let updatedPath = isPathLocked ? path : handlePath(path || '/home', accountId);
  let storedCompanyId = getStoredCompanyId();
  if (!isPathLocked && storedCompanyId !== accountId && isString(updatedPath.search)) {
    if (updatedPath.search.includes(`%2Fc%2F${storedCompanyId}`)) {
      updatedPath.search = updatedPath.search.replace(
        `%2Fc%2F${storedCompanyId}`,
        `%2Fc%2F${accountId}`,
      );
    } else if (updatedPath.search.includes(`/c/${storedCompanyId}`)) {
      updatedPath.search = updatedPath.search.replace(
        `/c/${storedCompanyId}`,
        `/c/${accountId}`,
      );
    } else if (updatedPath.search.includes(`2Fc%2F0`)) {
      updatedPath.search = updatedPath.search.replace(`2Fc%2F0`, `2Fc%2F${accountId}`);
    } else if (updatedPath.search.includes(`/c/0`)) {
      updatedPath.search = updatedPath.search.replace(`/c/0`, `/c/${accountId}`);
    }
  } else if (!isPathLocked && isString(updatedPath.search)) {
    if (updatedPath.search.includes('%2Fc%2F0')) {
      updatedPath.search = updatedPath.search.replace('%2Fc%2F0', `%2Fc%2F${accountId}`);
    } else if (updatedPath.search.includes('/c/0')) {
      updatedPath.search = updatedPath.search.replace('/c/0', `/c/${accountId}`);
    }
  }
  return async (dispatch, getState) => {
    if (isCurrent) {
      return await dispatch(history[gotoType](updatedPath));
    }
    await dispatch(setInitLoading(true));
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/authn/exchange`,
        method: 'POST',
        headers: requestHeaders(true, 'user'),
        body: JSON.stringify({ account_id: accountId }, null, 2),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      let decodedToken = decodeToken(actionResponse.payload.access_token);
      // let decodedToken = decodeToken((actionResponse.payload || actionResponse).id_token);
      dispatch(getAccountInfo());
      dispatch(setAccountFromToken(decodedToken));
      await dispatch(history[gotoType](updatedPath));
      await dispatch(window.location.reload());
    } else {
      dispatch(
        setNotice({
          type: 'error',
          message: get(
            actionResponse,
            'payload.message',
            'An error has occurred. if error persists, please contact support',
          ),
        }),
      );
      throw actionResponse.payload.message;
    }
  };
};

export const createNewCompany = (body, search) => {
  const REQUEST = Types[`CREATE_COMPANY_REQUEST`];
  const SUCCESS = Types[`CREATE_COMPANY_SUCCESS`];
  const FAILED = Types[`CREATE_COMPANY_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/user/accounts`,
        method: 'POST',
        headers: requestHeaders(true, 'user'),
        types: [REQUEST, SUCCESS, FAILED],
        body: () => JSON.stringify(body),
      },
    });
    if (actionResponse.error) {
      if (isObjectLike(actionResponse.payload)) {
        dispatch(setNotice({ type: 'error', message: actionResponse.payload.message }));
      } else {
        dispatch(setNotice({ type: 'error', message: actionResponse.payload }));
      }
      throw actionResponse.payload;
    }

    if (!actionResponse.error) {
      // Hubspot integration
      await dispatch(
        setNotice({
          message: 'Company Added! Please wait a few seconds as we set everything up.',
        }),
      );
      return await setTimeout(async () => {
        const { account_id } = actionResponse.payload;
        // await dispatch(setAccountFromToken(decodedToken));
        let updatedPath = {
          pathname: `/c/${account_id}/home`,
          search,
        };
        if (isString(updatedPath.search)) {
          if (updatedPath.search.includes('%2Fc%2F0')) {
            updatedPath.search = updatedPath.search.replace(
              '%2Fc%2F0',
              `%2Fc%2F${account_id}`,
            );
          } else if (updatedPath.search.includes('/c/0')) {
            updatedPath.search = updatedPath.search.replace('/c/0', `/c/${account_id}`);
          }
        }
        // await dispatch(replace(updatedPath));
        dispatch(setCompany(actionResponse.payload.account_id, updatedPath));
        // await window.location.reload();
      }, 2000);
      // return await { email, name, userId };
    }
  };
};

export const fetchCompanyDataTables = () => {
  const REQUEST = Types[`GET_COMPANY_DATA_TABLES_REQUEST`];
  const SUCCESS = Types[`GET_COMPANY_DATA_TABLES_SUCCESS`];
  const FAILED = Types[`GET_COMPANY_DATA_TABLES_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/company_info/all`,
        method: 'GET',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      // return await dispatch(actions.setCompaniesById(actionResponse.payload));
      return await dispatch(actions.setCompanyDataTables(actionResponse.payload));
    } else {
      throw actionResponse.payload.message;
    }
  };
};
