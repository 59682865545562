import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useHistory, useParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-modal';

import { setCompany } from '../../../redux/modules/Company/operations';
import { MyCompanies } from '../../../redux/modules/Company/selectors';
import {
  CompanyNameWithHooks,
  getAccountId,
} from '../../../redux/modules/Formation/selectors';

import './SidebarNavLink.scss';
import handlePath from '../../../utils/handlePath';
import classNames from 'classnames';
import { Tooltip } from '../../common';

const SideBarNavLink = ({ account_id, isActive, icon, isReplace, text, link }) => {
  const { companyId } = useParams();
  return (
    <li className="sidebarNavLink__wrapper">
      <NavLink
        activeClassName="sidebarNavLink--selected"
        className="sidebarNavLink"
        isActive={(match, location) => {
          if (isActive) {
            return isActive(match, location);
          }
          return (
            location.pathname ===
            handlePath(link.pathname || link, account_id || companyId)
          );
        }}
        replace={isReplace}
        to={handlePath(link, account_id || companyId)}
      >
        <FontAwesomeIcon icon={icon} className="sidebarNavLink__icon" fixedWidth />
        <h4 className="sidebarNavLink__text">{text}</h4>
      </NavLink>
    </li>
  );
};

SideBarNavLink.defaultProps = {
  icon: 'user',
  text: 'link',
};

export const SidebarNavLinkCompany = ({ isCollapsed, isTeam, isWorkbench }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { companyId = '0' } = useParams();

  const companies = useSelector(MyCompanies);
  const companyName = useSelector(CompanyNameWithHooks);
  const currentAccountId = useSelector(getAccountId);
  const isTooltipShowing = isWorkbench
    ? companyName.length > 24
    : companyName.length > 18;

  const [isOpen, setOpen] = useState(false);

  return (
    <div className="sidebarNavLink__wrapper isCompany">
      <div
        className={classNames('sidebarNavLink__companyWrapper', {
          workbench: isWorkbench,
        })}
        onClick={() => companyName && (isTeam ? history.goBack() : setOpen(!isOpen))}
      >
        {companyName && (
          <>
            <div className="sidebarNavLink__companyIcon">{companyName[0]}</div>
            <h4 className="sidebarNavLink__companyText">{companyName}</h4>
            {!isWorkbench && (
              <FontAwesomeIcon icon={isTeam ? 'chevron-right' : 'chevron-down'} />
            )}
          </>
        )}
        {isTooltipShowing && (
          <Tooltip text={companyName} position="bottom" align="center" />
        )}
      </div>
      {!isTeam && (
        <Modal
          className={`sidebarNavLink__companyMenu ${isCollapsed && 'collapsed'}`}
          isOpen={isOpen}
          onRequestClose={() => setOpen(false)}
          overlayClassName="sidebarNavLink__companyMenuOverlay"
        >
          <div className="sidebarNavLink__companyMenuArrow" />
          <Link
            className="sidebarNavLink__companyMenuItem first"
            to={handlePath(
              { pathname: '/company-select', state: { isToggled: true } },
              companyId,
            )}
          >
            View Your Companies
          </Link>
          <div className="sidebarNavLink__companyMenuItem">{companyName}(current)</div>
          {companies.map(
            (company, index) =>
              company.id !== currentAccountId && (
                <div
                  key={`company-${company.id}`}
                  className="sidebarNavLink__companyMenuItem"
                  onClick={e => dispatch(setCompany(company.id, '/home'))}
                >
                  {company.name}
                </div>
              ),
          )}
        </Modal>
      )}
    </div>
  );
};

export default SideBarNavLink;
