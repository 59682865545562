import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SavviLoading from '../../SavviLoading';

import './InlineInputIcon.scss';

const InlineInputIcon = ({ icon, isFetching }) => (
  <>
    {icon && !isFetching && (
      <div className="inline-input-icon">
        <FontAwesomeIcon icon={icon} />
      </div>
    )}
    {isFetching && (
      <div className="inline-input-icon">
        <SavviLoading size="sm" />
      </div>
    )}
  </>
);

export default InlineInputIcon;
