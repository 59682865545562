// Set
export const SET_BOX_TOKEN = 'savvi/cabinet/SET_BOX_TOKEN';

// Box Token Actions
export const BOX_TOKEN_REQUEST = 'savvi/cabinet/BOX_TOKEN_REQUEST';
export const BOX_TOKEN_SUCCESS = 'savvi/cabinet/BOX_TOKEN_SUCCESS';
export const BOX_TOKEN_FAILED = 'savvi/cabinet/BOX_TOKEN_FAILED';

export const GET_BOX_FILE_INFO_REQUEST = 'savvi/cabinet/GET_BOX_FILE_INFO_REQUEST';
export const GET_BOX_FILE_INFO_SUCCESS = 'savvi/cabinet/GET_BOX_FILE_INFO_SUCCESS';
export const GET_BOX_FILE_INFO_FAILED = 'savvi/cabinet/GET_BOX_FILE_INFO_FAILED';

export const GET_BOX_TOKEN_FOLDER_ID_REQUEST =
  'savvi/cabinet/GET_BOX_TOKEN_FOLDER_ID_REQUEST';
export const GET_BOX_TOKEN_FOLDER_ID_SUCCESS =
  'savvi/cabinet/GET_BOX_TOKEN_FOLDER_ID_SUCCESS';
export const GET_BOX_TOKEN_FOLDER_ID_FAILED =
  'savvi/cabinet/GET_BOX_TOKEN_FOLDER_ID_FAILED';

export const GET_TEMPORARY_BOX_TOKEN_REQUEST =
  'savvi/cabinet/GET_TEMPORARY_BOX_TOKEN_REQUEST';
export const GET_TEMPORARY_BOX_TOKEN_SUCCESS =
  'savvi/cabinet/GET_TEMPORARY_BOX_TOKEN_SUCCESS';
export const GET_TEMPORARY_BOX_TOKEN_FAILED =
  'savvi/cabinet/GET_TEMPORARY_BOX_TOKEN_FAILED';
export const SET_TEMP_TOKEN_FETCH_INITIALIZED =
  'savvi/cabinet/SET_TEMP_TOKEN_FETCH_INITIALIZED';

export const UPLOAD_BOX_FILE_REQUEST = 'savvi/cabinet/UPLOAD_BOX_FILE_REQUEST';
export const UPLOAD_BOX_FILE_SUCCESS = 'savvi/cabinet/UPLOAD_BOX_FILE_SUCCESS';
export const UPLOAD_BOX_FILE_FAILED = 'savvi/cabinet/UPLOAD_BOX_FILE_FAILED';
