const boxTokenIsValid = ({ Cabinet }) => {
  const { accessToken = false, accessTokenTTLMS, acquiredAtMS } = Cabinet.box;

  if (!accessToken) {
    return false;
  }

  const expiresAt = new Date(accessTokenTTLMS + acquiredAtMS);
  const now = new Date();
  return expiresAt > now;
};

export { boxTokenIsValid };
