import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';

import get from 'lodash-es/get';
import isEqual from 'lodash-es/isEqual';
import trim from 'lodash-es/trim';

import { InputSelect, InputStyledTextField } from '../../inputs';

import './CompanyName.scss';

const suffixOptions = [
  { label: ', Inc.', value: ', Inc.', type: 'Corporation' },
  { label: ' Inc.', value: ' Inc.', type: 'Corporation' },
  { label: ', Incorporated', value: ', Incorporated', type: 'Corporation' },
  { label: ' Incorporated', value: ' Incorporated', type: 'Corporation' },
  { label: ', Co.', value: ', Co.', type: 'Corporation' },
  { label: ' Co.', value: ' Co.', type: 'Corporation' },
  { label: ', Company', value: ', Company', type: 'Corporation' },
  { label: ' Company', value: ' Company', type: 'Corporation' },
  { label: ', Corp.', value: ', Corp.', type: 'Corporation' },
  { label: ' Corp.', value: ' Corp.', type: 'Corporation' },
  { label: ', Corporation', value: ', Corporation', type: 'Corporation' },
  { label: ' Corporation', value: ' Corporation', type: 'Corporation' },
  { label: ', LLC', value: ', LLC', type: 'LLC' },
  { label: ' LLC', value: ' LLC', type: 'LLC' },
  { label: ', LP', value: ', LP', type: 'LP' },
  { label: ' LP', value: ' LP', type: 'LP' },
  { label: ', L.P.', value: ', L.P.', type: 'LP' },
  { label: ' L.P.', value: ' L.P.', type: 'LP' },
  { label: 'None', value: '', type: 'Corporation' },
];

class CompanyName extends Component {
  constructor(props) {
    super(props);
    this.textInput = createRef();
    this.timer = null;
    this.state = {
      companyFullName: '',
      companyName: '',
      companyNameInput: '',
      companyType: '',
      isFocused: false,
      isEditable: false,
    };
  }

  componentDidMount() {
    const {
      isEntityFormed,
      entityType,
      field: { name, value },
      form: { setFieldValue },
      handleResourceName,
      isFixed,
    } = this.props;
    if (isFixed) {
      return this.setState({ companyName: value, companyFullName: value });
    }
    let filteredOptions = suffixOptions;
    if (isEntityFormed || entityType.includes('Series')) {
      return this.setState({
        companyFullName: value,
        companyName: value,
        companyNameInput: value,
      });
    }
    if (entityType) {
      filteredOptions = suffixOptions.filter(e => e.type === entityType);
    }

    let companyName = trim(value) || '';
    // let updatedCompanyType = filteredOptions[0];
    let updatedCompanyType = '';
    let suffixIndex = -1;
    let optionIndex = -1;
    for (let i = 0; i < filteredOptions.length; i++) {
      const tempSuffixIndex = companyName.indexOf(filteredOptions[i].value);
      if (tempSuffixIndex !== -1) {
        suffixIndex = tempSuffixIndex;
        optionIndex = i;
        break;
      }
    }

    let formattedVal = companyName;

    if (suffixIndex !== -1 && filteredOptions[optionIndex].label !== 'None') {
      companyName = trim(value.substring(0, suffixIndex));
      formattedVal = companyName;
      if (optionIndex !== -1) {
        updatedCompanyType = filteredOptions[optionIndex];
        formattedVal = `${companyName}${updatedCompanyType.value}`;
      }
    }
    // let formattedVal = `${companyName}${updatedCompanyType.label}`;
    if (!companyName) {
      formattedVal = '';
    }

    this.setState({
      companyFullName: formattedVal,
      companyName,
      companyNameInput: companyName,
      companyType: updatedCompanyType,
    });

    setFieldValue(name, formattedVal);
    if (formattedVal !== value && !isFixed) {
      handleResourceName(formattedVal);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      isEntityFormed,
      entityType,
      field: { name },
      isFixed,
    } = this.props;
    const { isFocused } = this.state;

    if (!isFixed && !isEqual(prevState.isFocused, isFocused)) {
      if (isFocused) {
        clearTimeout(this.timer);
      } else {
        this.timer = setTimeout(() => this.setState({ isEditable: false }), 500);
      }
    }

    if (
      !isFixed &&
      (!isEqual(prevProps.isEntityFormed, isEntityFormed) ||
        !isEqual(prevProps.entityType, entityType))
    ) {
      const {
        form: { setFieldValue },
        handleResourceName,
      } = this.props;

      const { companyFullName, companyName, companyType } = this.state;
      if (isEntityFormed || entityType.includes('Series')) {
        return this.setState({
          companyFullName: companyFullName,
          companyName: companyFullName,
          companyNameInput: companyFullName,
        });
      }

      let filteredOptions = suffixOptions;
      if (entityType) {
        filteredOptions = suffixOptions.filter(e => e.type === entityType);
      }

      let updatedCompanyName = companyName;
      let optionIndex = suffixOptions.findIndex(e => e.value === companyType.value);
      let updatedCompanyType = get(filteredOptions, optionIndex, filteredOptions[0]);
      let suffixIndex = -1;

      if (prevProps.isEntityFormed && !isEntityFormed) {
        for (let i = 0; i < suffixOptions.length; i++) {
          const tempSuffixIndex = companyFullName.indexOf(suffixOptions[i].value);
          if (tempSuffixIndex !== -1) {
            suffixIndex = tempSuffixIndex;
            break;
          }
        }
        if (suffixIndex !== -1) {
          updatedCompanyName = trim(companyFullName.substring(0, suffixIndex));
          for (let i = 0; i < filteredOptions.length; i++) {
            const tempSuffixIndex = companyFullName.indexOf(filteredOptions[i].value);
            if (tempSuffixIndex !== -1) {
              updatedCompanyType = filteredOptions[i];
              break;
            }
          }
        }
      }

      let formattedVal = `${updatedCompanyName}${updatedCompanyType.value}`;
      if (!updatedCompanyName) {
        formattedVal = '';
      }

      setFieldValue(name, formattedVal);
      if (formattedVal !== companyFullName) {
        handleResourceName(formattedVal);
      }
      this.setState({
        companyFullName: formattedVal,
        companyName: updatedCompanyName,
        companyNameInput: updatedCompanyName,
        companyType: updatedCompanyType,
      });
    }
  }

  render() {
    const {
      isEntityFormed,
      entityType,
      field: { name, onBlur, onChange },
      form: { errors, setFieldValue, setFieldTouched, status, touched },
      handleResourceName,
      isLabel,
      myType,
      question,
      questionHelp,
      setLabel,
      isFixed,
      isRequired,
      isWorkbench,
      ...props // everything that we should pass goes through here classnames etc
    } = this.props;
    const {
      companyFullName,
      companyName,
      companyNameInput,
      companyType,
      isEditable,
      isFocused,
    } = this.state;

    let updatedVal = `${companyName}${companyType ? companyType.value : ''}`;

    if (!companyName) {
      updatedVal = '';
    }
    if (isFixed) {
      updatedVal = companyFullName;
    } else if (isEntityFormed || entityType.includes('Series')) {
      if (isFocused) {
        updatedVal = companyNameInput;
      } else {
        updatedVal = companyFullName;
      }
    }
    const apiError = get(status, name, false);
    const isTouched = get(touched, name, false);
    const error = apiError || (isTouched && get(errors, name, null));
    let updatedQuestionHelp = !!isFixed
      ? 'If your company name is incorrect contact the Savvi support team in the support chat or email us at support@savvi.legal'
      : questionHelp;
    if (isEditable && !isFixed && !isEntityFormed && !entityType.includes('Series')) {
      let filteredOptions = suffixOptions;
      if (entityType) {
        filteredOptions = suffixOptions.filter(e => e.type === entityType);
      }
      return (
        <div className="company-name__wrapper">
          <InputStyledTextField
            error={error}
            value={companyNameInput}
            isRequired={isRequired}
            ref={this.textInput}
            name={name}
            onChange={e => {
              this.setState({ companyNameInput: e.target.value });
              const updatedCompanyType = companyType.value;
              setFieldValue(name, `${e.target.value}${updatedCompanyType}`);
            }}
            onBlur={e => {
              const updatedCompanyType = companyType.value;
              const formattedVal = `${trim(e.target.value)}${updatedCompanyType}`;
              if (!isEqual(trim(e.target.value), trim(companyName))) {
                handleResourceName(formattedVal);
              }
              this.setState({
                companyFullName: formattedVal,
                companyName: trim(companyNameInput),
                companyNameInput: trim(companyNameInput),
                isFocused: false,
              });
              setFieldValue(name, formattedVal);
              setFieldTouched(name, true);
            }}
            onFocus={e => this.setState({ isFocused: true })}
            {...props}
            questionHelp={updatedQuestionHelp}
          />
          <InputSelect
            error={error && 'Amount Required'}
            isClearable={false}
            isCreatable={false}
            isSearchable={false}
            isRequired={isRequired}
            name={name}
            label="Company Suffix:"
            onBlur={e => {
              this.setState({ isFocused: false });
              setFieldTouched(name, true);
            }}
            onChange={e => {
              if (!isEqual(e, companyType)) {
                const formattedVal = `${companyName}${e.value === 'None' ? '' : e.value}`;
                this.setState({
                  companyFullName: formattedVal,
                  companyType: e,
                });
                setFieldValue(name, formattedVal);
                setFieldTouched(name, true);
                handleResourceName(formattedVal);
              }
            }}
            onFocus={e => this.setState({ isFocused: true })}
            defaultValue={filteredOptions[0]}
            value={companyType}
            options={filteredOptions}
          />
        </div>
      );
    }

    return (
      <InputStyledTextField
        error={error}
        isFixed={isFixed}
        isRequired={isRequired}
        name={name}
        onBlur={e => {
          const {
            target: { value },
          } = e;
          if (isLabel) {
            setLabel(value);
          }
          if (!isEqual(trim(value), trim(companyFullName))) {
            handleResourceName(value);
          }
          this.setState({
            companyFullName: value,
            companyName: value,
            companyNameInput: value,
            companyType: {},
          });
          onBlur(e);
        }}
        onFocus={e => {
          e.preventDefault();
          if (!isEntityFormed && !entityType.includes('Series')) {
            this.setState(
              {
                isFocused: true,
                isEditable: true,
              },
              () => this.textInput.current.focus(),
            );
          } else {
            this.setState({ isFocused: true });
          }
        }}
        value={updatedVal}
        onChange={e => {
          const {
            target: { value },
          } = e;
          this.setState({
            // companyFullName: value,
            // companyName: value,
            companyNameInput: value,
          });
          onChange(e);
        }}
        {...props}
        questionHelp={updatedQuestionHelp}
      />
    );
  }
}

CompanyName.propTypes = {
  entityType: PropTypes.string,
  handleResourceName: PropTypes.func,
};

CompanyName.defaultProps = {
  entityType: 'Corporation',
  handleResourceName: () => {},
  isCompany: false,
  isFixed: false,
};

export default CompanyName;
