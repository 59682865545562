import React from 'react';

import get from 'lodash-es/get';

import InputStyledCheckbox from '../../common/InputStyledCheckbox';

import './StyledCheckbox.scss';

const StyledCheckbox = ({
  isDetailedOptions,
  isCreatable,
  isOutsideForm,
  featureProps,
  feature_type_id,
  field, //: { name, value, onChange, onBlur },
  flipLabel,
  form: { errors, setFieldValue, status, touched },
  handleFormBlur,
  handleResourceFormBlur,
  isDisabled,
  isLabel,
  isLocked,
  isModal,
  isPairedColumn,
  markdown,
  multiEntry,
  resetValue,
  resource_id,
  resourceTypeName,
  setLabel,
  taskId,
  savedValue,
  ...props // everything that we should pass goes through here classnames etc
}) => {
  const apiError = get(status, field.name, false);
  const isTouched = get(touched, field.name, false);
  const error = apiError || (isTouched && get(errors, field.name, null));
  if (field.value === 'Yes') {
    field.value = true;
  }
  if (field.value === 'No' || field.value === 'false' || field.value === '0') {
    field.value = false;
  }

  return (
    <InputStyledCheckbox
      isDisabled={isDisabled}
      onClick={e => {
        if (e.target.value === 'true') {
          // weird timing here, makes it the opposite
          setFieldValue(field.name, 0);
          handleFormBlur({ target: { value: false } });
        } else {
          setFieldValue(field.name, 1);
          handleFormBlur({ target: { value: true } });
        }
      }}
      error={error}
      checked={!!field.value}
      labelStyle="form-styled-checkbox__label"
      parentStyle={isPairedColumn && 'styled-checkbox__paired-column'}
      {...field}
      {...props}
    />
  );
};

StyledCheckbox.defaultProps = {
  handleFormBlur: () => {},
};

export default StyledCheckbox;
