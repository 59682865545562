import { combineReducers } from 'redux';

import { apiErrorsReducer, createReducer, fetchingReducer } from '../../utils';
import * as Types from './types';

const allDocumentsReducer = createReducer({})({
  [Types['SET_ALL_DOCUMENTS']]: (state, { payload }) => {
    return {
      ...state,
      byArr: [...payload],
    };
  },
});

const documentReducer = createReducer({})({
  [Types['SET_DOCUMENTS_BY_PROJECT']]: (state, { payload, projectId }) => ({
    ...state,
    byProject: {
      ...state.byModule,
      [projectId]: [...payload],
    },
  }),
  [Types['SET_DOCUMENTS_BY_TRANSACTION']]: (state, { payload, transactionId }) => ({
    ...state,
    byTransaction: {
      ...state.byModule,
      [transactionId]: [...payload],
    },
  }),
  [Types['SET_DOCUMENTS']]: (state, { payload, taskId }) => {
    if (taskId) {
      return {
        ...state,
        byTaskId: {
          ...state.byTaskId,
          [taskId]: [...payload],
        },
      };
    }
    return {
      ...state,
      byArr: [...payload],
    };
  },
  [Types['SET_DOCUMENT_ERROR']]: (state, { error }) => ({
    ...state,
    error,
  }),
  [Types['CLEAR_DOCUMENT_ERROR']]: state => ({
    ...state,
    error: '',
  }),
});

const documentRecipientsLinks = createReducer({})({
  [Types['SET_DOCUMENT_RECIPIENTS_LINKS']]: (state, { links, documentId }) => ({
    ...state,
    [documentId]: {
      ...state[documentId],
      ...links,
    },
  }),
});

// const customDocumentsReducer = createReducer({})({
//   // [Types['SET_TRANSACTION_QUESTIONNAIRE']]: (state, { payload }) => {
//   //   return {
//   //     ...state,
//   //     byIds: {
//   //       ...state.byIds,
//   //       [payload.id]: [...payload.custom_documents],
//   //     },
//   //   };
//   // },
//   [Types['ADD_CUSTOM_DOCUMENT']]: (state, { payload, transactionId }) => ({
//     ...state,
//     byIds: {
//       ...state.byIds,
//       [transactionId]: [...state.byIds[transactionId], { ...payload }],
//     },
//   }),
//   [Types['SET_CUSTOM_DOCUMENT']]: (state, { payload, customDocIndex, transactionId }) => {
//     let newCustomDocuments = state.byIds[transactionId].slice();
//     newCustomDocuments[customDocIndex] = {
//       ...newCustomDocuments[customDocIndex],
//       custom_document: {
//         ...payload,
//       },
//     };

//     return {
//       ...state,
//       byIds: {
//         ...state.byIds,
//         [transactionId]: [...newCustomDocuments],
//       },
//     };
//   },
// });

const isFetching = fetchingReducer('document');

export default combineReducers({
  isFetching,
  apiErrors: apiErrorsReducer('document'),
  // customDocuments: customDocumentsReducer,
  allDocuments: allDocumentsReducer,
  documents: documentReducer,
  documentRecipientsLinks,
});
