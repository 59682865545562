import { get, startCase } from 'lodash-es';
import { createSelector, defaultMemoize } from 'reselect';
import { parseShareLink } from '../../../components/formik/ShareLinks/ShareLinks';

export const getRoomFeatures = defaultMemoize((state, key, features) =>
  get(state, `Data.roomFeatures[${key}]`, []).filter(feature => {
    if (features.findIndex(e => e.feature_id === feature.feature_id) === -1) {
      return true;
    }
    return false;
  }),
);

export const getRoomFeaturesAsOptions = createSelector(getRoomFeatures, features =>
  features
    .filter(e => e.feature_type !== 'document' && e.feature_type !== 'share_links')
    .map(e => ({ ...e, label: startCase(e.feature), value: e.feature_id })),
);

export const getRoomResourceOptions = createSelector(getRoomFeatures, features =>
  features.reduce((dict, e) => {
    if (dict.findIndex(resource => resource.label === e.resource) === -1) {
      dict.push({ label: e.resource, value: e.resource_id });
    }
    return dict;
  }, []),
);

// export const getRoomResourceFeatures = createSelector(
//   getAllRoomFeatures,
//   (state, accessCode, resourceId) => resourceId,
//   (allFeatures, resourceId) =>
//     allFeatures.filter(feature => feature.resource_id === resourceId),
// );

export const getRoom = defaultMemoize((state, accessCode) =>
  get(state, `Data.room[${accessCode}]`, {}),
);

export const getError = defaultMemoize((state, accessCode) =>
  get(state, `Data.room.error`, {}),
);

export const IsFetching = defaultMemoize((state, accessCode) =>
  get(state, `Data.isFetching`, false),
);

export const getRoomWithUpdatedFeatures = createSelector(getRoom, room => {
  if (!room || !room.room) {
    return room;
  }
  const updatedFeatures = room.room.features.reduce((dict, e) => {
    if (e.feature_type.feature_type === 'boolean') {
      const valStr = e.value + '';
      e.value = valStr === 'true' || valStr === '1' ? 'Yes' : 'No';
    } else if (e.feature_type.feature_type === 'document') {
      if (!!e.value) {
        e.fileId = e.value;
        e.value = 'Uploaded';
        e.isDocument = true;
      }
    } else if (e.feature_type.feature_type === 'share_links' && !!e.value) {
      e.value = parseShareLink(e.value);
    } else if (
      e.feature_type.feature_type === 'social_security' &&
      e.value &&
      e.value.length === 9
    ) {
      e.value = '*****' + e.value.slice(5);
    } else if (e.feature === 'filing_state') {
      e.feature_type.question_label = 'Filing State';
    }
    if (!dict[e.resource_custom_label]) {
      dict[e.resource_custom_label] = {
        resource_custom_label: e.resource_custom_label,
        resource_type: e.resource_type,
        resource_type_id: e.resource_type_id,
        resource_variable_name: e.resource_variable_name,
        resources: {
          [e.resource]: {
            resource: e.resource,
            resource_id: e.resource_id,
            resource_custom_label: e.resource_custom_label,
            resource_type: e.resource_type,
            resource_type_id: e.resource_type_id,
            resource_variable_name: e.resource_variable_name,
            features: [e],
          },
        },
      };
    } else if (!dict[e.resource_custom_label].resources[e.resource]) {
      dict[e.resource_custom_label].resources[e.resource] = {
        resource: e.resource,
        resource_id: e.resource_id,
        resource_custom_label: e.resource_custom_label,
        resource_type: e.resource_type,
        resource_type_id: e.resource_type_id,
        resource_variable_name: e.resource_variable_name,
        features: [e],
      };
    } else {
      const featuresArr = get(
        dict,
        `["${e.resource_custom_label}"].resources["${e.resource}"].features`,
        [],
      );
      dict[e.resource_custom_label].resources[e.resource].features = [...featuresArr, e];
    }
    return dict;
  }, {});

  const updatedAccessLog = (room.room.access_log || []).reverse().reduce(
    (dict, e) => {
      if (e.anonymous) {
        dict.anonymous = {
          name: 'Anonymous',
          logs: [...get(dict, 'anonymous.logs', []), e],
        };
      } else if (!dict[e.user_id]) {
        dict[e.user_id] = {
          name: e.user.name,
          email: e.user.email,
          user_id: 14,
          logs: [e],
        };
      } else {
        dict[e.user_id].logs = [...dict[e.user_id].logs, e];
      }
      return dict;
    },
    { anonymous: {} },
  );

  return {
    ...room,
    room: {
      ...room.room,
      access_log: updatedAccessLog,
      features: updatedFeatures,
    },
  };
});
