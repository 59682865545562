import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'moment';

import { setCompany } from '../../redux/modules/Company/operations';
import { getAccountId } from '../../redux/modules/Formation/selectors';
import { Button, Dropdown } from '../common';
import PandaDocStatusDict from '../../utils/PandaDocStatusDict';

import './AllDocsSection.scss';
import { resendLinkEmail } from '../../redux/modules/Transaction/operations';
import { setNotice } from '../../redux/modules/UI/actions';
import { getAuthUser } from '../../redux/modules/User/selectors';
import { get, isEmpty } from 'lodash-es';
import handlePath from '../../utils/handlePath';
import {
  deleteRoomDocument,
  swapRoomDocuments,
} from '../../redux/modules/Data/operations';
import { PDFViewer } from '../OverviewCard/OverviewCard';
import dayjs from 'dayjs';
import { requestHeaders } from '../../redux/utils/api';

const sortStrings = (a, b, dir) => {
  const aLower = a + ''.toLowerCase();
  const bLower = b + ''.toLowerCase();

  let isAGreater = aLower > bLower;
  let isBGreater = aLower < bLower;

  if (isAGreater) {
    return dir === 'asc' ? 1 : -1;
  }
  if (isBGreater) {
    return dir === 'asc' ? -1 : 1;
  }
  return 0;
};

const AllDocsSection = ({
  accessCode,
  accountId,
  hideToken,
  isAdmin,
  isEditing,
  isHeightUnset,
  isPdfHiding,
  isSectionOpenInit,
  isMyDashboard,
  isWarning,
  moduleId,
  sectionLabel,
  documents,
}) => {
  const dispatch = useDispatch();
  const currentAccountId = useSelector(getAccountId);
  const currentUser = useSelector(getAuthUser);
  const [dir, setDir] = useState('asc');
  const [filter, setFilterSlug] = useState('date');
  const [isSectionOpen, setIsOpen] = useState(isSectionOpenInit);
  const [myDocuments, setDocuments] = useState(documents);

  useEffect(() => {
    setDocuments(
      documents.sort((a, b) => Moment(b.draft_timestamp).diff(Moment(a.draft_timestamp))),
    );
  }, [documents]);

  const setFilter = useCallback(
    clickedFilter => {
      let updatedDir = dir;
      let updatedFilter = clickedFilter || filter;

      if (clickedFilter === filter && dir === 'asc') {
        updatedFilter = '';
        updatedDir = 'desc';
      } else if (clickedFilter === filter) {
        updatedDir = dir === 'desc' ? 'asc' : 'desc';
      } else if (clickedFilter !== filter) {
        updatedDir = 'desc';
      }

      const updatedDocs = myDocuments.sort((a, b) => {
        if (updatedFilter === 'name') {
          return sortStrings(a.filename, b.filename, updatedDir);
        }
        if (updatedFilter === 'status') {
          return sortStrings(a.status, b.status, updatedDir);
        }
        if (updatedFilter === 'task') {
          return sortStrings(
            get(a.account_transaction, 'label', '--'),
            get(b.account_transaction, 'label', '--'),
            updatedDir,
          );
        }
        if (updatedFilter === 'date') {
          return updatedDir === 'desc'
            ? Moment(b.sent_timestamp || b.draft_timestamp || b.upload_timestamp).diff(
                Moment(a.sent_timestamp || a.draft_timestamp || a.upload_timestamp),
              )
            : Moment(a.sent_timestamp || a.draft_timestamp || a.upload_timestamp).diff(
                Moment(b.sent_timestamp || b.draft_timestamp || b.upload_timestamp),
              );
        }
        if (updatedFilter === 'company_name') {
          return sortStrings(a.account.name, b.account.name, updatedDir);
        }
        // Default to id;
        if (a.id > b.id) {
          return -1;
        } else if (a.id < b.id) {
          return 1;
        }
        return 0;
      });
      setDir(updatedDir);
      setFilterSlug(updatedFilter);
      setDocuments(updatedDocs);
    },
    [dir, filter, myDocuments, setDir, setFilterSlug, setDocuments],
  );

  return (
    <div className="allDocsSection">
      <h2 className="allDocsSection__header">
        {!hideToken && <FontAwesomeIcon icon={['fal', 'copy']} />}
        {sectionLabel}
        <span>{` (${myDocuments.length > 0 ? myDocuments.length : 'None'})`}</span>
      </h2>
      {myDocuments.length > 0 && (
        <div
          className={`allDocsSection__list ${isMyDashboard ? 'myTasks' : ''} ${
            isHeightUnset ? 'unsetHeight' : ''
          }`}
        >
          <h4
            className="allDocsSection__listHeaderLabel first"
            onClick={() => setFilter('name')}
          >
            Document Name
            {filter === 'name' && (
              <FontAwesomeIcon icon={dir === 'desc' ? 'caret-down' : 'caret-up'} />
            )}
          </h4>
          <h4
            className="allDocsSection__listHeaderLabel"
            onClick={() => setFilter('status')}
          >
            Status
            {filter === 'status' && (
              <FontAwesomeIcon icon={dir === 'desc' ? 'caret-down' : 'caret-up'} />
            )}
          </h4>
          {isMyDashboard && (
            <h4
              className="allDocsSection__listHeaderLabel"
              onClick={() => setFilter('company_name')}
            >
              Company Name
              {filter === 'company_name' && (
                <FontAwesomeIcon icon={dir === 'desc' ? 'caret-down' : 'caret-up'} />
              )}
            </h4>
          )}
          <h4
            className="allDocsSection__listHeaderLabel"
            onClick={() => setFilter('date')}
          >
            Date Created
            {filter === 'date' && (
              <FontAwesomeIcon icon={dir === 'desc' ? 'caret-down' : 'caret-up'} />
            )}
          </h4>
          {myDocuments.map(
            (myDocument, index) =>
              (isSectionOpen || index < 4) && (
                <DocumentRow
                  key={`actionItems-${myDocument.id}-${index}`}
                  accessCode={accessCode}
                  accountId={accountId}
                  currentAccountId={currentAccountId}
                  currentUser={currentUser}
                  dispatch={dispatch}
                  myDocument={myDocument}
                  index={index}
                  isAdmin={isAdmin}
                  isEditing={isEditing}
                  isMyDashboard={isMyDashboard}
                  isPdfHiding={isPdfHiding}
                  moduleId={moduleId}
                  myDocuments={myDocuments}
                />
              ),
          )}
          {myDocuments.length > 4 && (
            <Button
              buttonType="link"
              className="allDocsSection__listToggle"
              onClick={() => setIsOpen(!isSectionOpen)}
              size="sm"
            >
              {!isSectionOpen
                ? `Show ${myDocuments.length - 4} more documents`
                : 'Show less documents'}
              <FontAwesomeIcon icon={isSectionOpen ? 'caret-up' : 'caret-down'} />
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

const DocumentRow = ({
  accessCode,
  accountId,
  currentAccountId,
  currentUser,
  myDocument,
  dispatch,
  index,
  isAdmin,
  isEditing,
  isMyDashboard,
  isPdfHiding,
  moduleId,
  myDocuments,
}) => {
  const { push } = useHistory();
  const { companyId } = useParams();
  const { search } = useLocation();
  const {
    account = {},
    account_id,
    // account_task_id,
    account_transaction = {},
    account_transaction_id,
    account_project_id,
    box_file_id,
    completed_timestamp,
    draft_timestamp,
    filename,
    file_token: { accessToken } = {},
    flat_features,
    panda_document_id,
    revision_count,
    recipients,
    room_document_id,
    sent_timestamp,
    status,
    upload_timestamp,
  } = myDocument;

  let dateLabel =
    sent_timestamp ||
    completed_timestamp ||
    draft_timestamp ||
    upload_timestamp ||
    get(flat_features, '[0].created_at', null);
  if (dateLabel) {
    dateLabel = dayjs(dateLabel).format('MM/DD/YYYY');
  } else {
    dateLabel = '--';
  }
  let taskPath = {
    pathname: `/workbench/${moduleId}/${account_project_id}/${account_transaction_id}/initialize`,
    search,
    state: { isClicked: true },
  };
  let path = `/cabinet/-1/${box_file_id}`;
  const isRedlining = revision_count > 0;
  const stateText =
    (isRedlining && 'Awaiting Approval') ||
    (box_file_id && !status && 'Uploaded') ||
    PandaDocStatusDict(status);

  const isTaskConnected = !isEmpty(account_transaction);
  const isSendingToTask =
    isTaskConnected && stateText !== 'Uploaded' && stateText !== 'Completed';

  const handleResendLinkEmail = (e, docId, email, accountId) => {
    const message = `Are you sure you want to resend signature link to ${email}?`;
    if (window.confirm(message)) {
      dispatch(resendLinkEmail(docId, email, accountId));
    }
  };

  const handleDocumentRecipientLink = (e, signatureLink) => {
    navigator.clipboard.writeText(signatureLink);
    dispatch(setNotice('Link copied'));
  };

  const updatedUrl = `${process.env.REACT_APP_BASEURL}/api/${
    accessCode
      ? `unlisted/room/${accessCode}/file/${box_file_id}`
      : `account/box/file/${box_file_id}`
  }`;

  return (
    <div
      className={classnames('allDocsSection__listRow', {
        showRecipients: recipients.length > 0,
        'allDocsSection__listRow--dataRoom': accessCode,
        'allDocsSection__listRow--upload':
          recipients.length === 0 && !!box_file_id && !isPdfHiding,
      })}
      onClick={e => {
        if (e.defaultPrevented || accessCode) {
          return;
        }
        if (isMyDashboard) {
          dispatch(
            setCompany(
              account.id,
              isSendingToTask ? taskPath : path,
              account.id === currentAccountId,
            ),
          );
        } else {
          push(handlePath(isSendingToTask ? taskPath : path, companyId || account_id));
        }
      }}
    >
      <div className="allDocsSection__listItem first">
        <h4>{filename || 'Unknown name'}</h4>
      </div>
      <div className="allDocsSection__listItem">
        {!myDocument.box_file_id && <h4>{stateText}</h4>}
        {!!myDocument.box_file_id && (
          <PDFViewer
            accessCode={accessCode}
            box_token={accessToken}
            isDownloadable
            isLinkOnHover
            isPdfHiding={isPdfHiding}
            file={{ ...myDocument, customLabel: `${stateText} Document` }}
            height={64}
            width={140}
          />
        )}
      </div>
      {isMyDashboard && (
        <div className="allDocsSection__listItem">
          <h4>{account.name}</h4>
        </div>
      )}
      <div className="allDocsSection__listItem">
        <h4>{dateLabel}</h4>
        {isTaskConnected && !isEditing && (
          <div className="allDocsSection__listActions">
            {box_file_id && (
              <Button
                buttonType="icon"
                onClick={e => {
                  e.preventDefault();
                  var client = new XMLHttpRequest();
                  client.open('GET', updatedUrl);
                  client.withCredentials = true;
                  client.responseType = 'blob';
                  Object.entries(requestHeaders(true)).forEach(([key, value]) => {
                    client.setRequestHeader(key, value);
                  });
                  client.onreadystatechange = () => {
                    switch (client.readyState) {
                      case 4:
                        dispatch(setNotice('Starting Download.'));
                        const url = window.URL.createObjectURL(client.response);
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', filename);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        break;
                      default:
                    }
                  };
                  client.send();
                }}
                size="sm"
                tooltip="Download file"
              >
                <FontAwesomeIcon icon={['fal', 'file-download']} />
              </Button>
            )}
            {!isMyDashboard && box_file_id && (
              <Button
                buttonType="icon"
                size="sm"
                onClick={e => {
                  e.preventDefault();
                  push(
                    handlePath(
                      !isSendingToTask ? taskPath : path,
                      companyId || account_id,
                    ),
                  );
                }}
                tooltip={`View in ${!isSendingToTask ? 'Task View' : 'File Cabinet'}`}
              >
                <FontAwesomeIcon icon={['fal', 'file-export']} />
              </Button>
            )}
            {isMyDashboard && box_file_id && (
              <Button
                buttonType="icon"
                size="sm"
                onClick={e => {
                  e.preventDefault();
                  dispatch(
                    setCompany(
                      account.id,
                      { pathname: !isSendingToTask ? taskPath : path, search },
                      account.id === currentAccountId,
                    ),
                  );
                }}
                tooltip="View in File Cabinet"
              >
                <FontAwesomeIcon icon={['fal', 'file-export']} />
              </Button>
            )}
          </div>
        )}
        {!isEditing && !isTaskConnected && (
          <div className="allDocsSection__listActions allDocsSection__listActions--open">
            {box_file_id && (
              <Button
                buttonType="icon"
                onClick={e => {
                  e.preventDefault();
                  var client = new XMLHttpRequest();
                  client.open('GET', updatedUrl);
                  client.withCredentials = true;
                  client.responseType = 'blob';
                  Object.entries(requestHeaders(true)).forEach(([key, value]) => {
                    client.setRequestHeader(key, value);
                  });
                  client.onreadystatechange = () => {
                    switch (client.readyState) {
                      case 4:
                        dispatch(setNotice('Starting Download.'));
                        const url = window.URL.createObjectURL(client.response);
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', filename);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        break;
                      default:
                    }
                  };
                  client.send();
                }}
                size="sm"
                tooltip="Download file"
              >
                <FontAwesomeIcon icon={['fal', 'file-download']} />
              </Button>
            )}
          </div>
        )}
        {isEditing && (
          <div className="allDocsSection__listActions allDocsSection__listActions--open">
            <Button
              buttonType="icon"
              size="sm"
              isDisabled={index === 0}
              onClick={e => {
                e.preventDefault();
                dispatch(
                  swapRoomDocuments(
                    room_document_id,
                    myDocuments[index - 1].room_document_id,
                    accessCode,
                    accountId,
                  ),
                );
              }}
              tooltip="Move up"
            >
              <FontAwesomeIcon icon="arrow-up" />
            </Button>
            <Button
              buttonType="icon"
              size="sm"
              isDisabled={index + 1 === myDocuments.length}
              onClick={e => {
                e.preventDefault();
                dispatch(
                  swapRoomDocuments(
                    room_document_id,
                    myDocuments[index + 1].room_document_id,
                    accessCode,
                    accountId,
                  ),
                );
              }}
              tooltip="Move down"
            >
              <FontAwesomeIcon icon="arrow-down" />
            </Button>
            <Button
              buttonType="icon"
              isWarning
              size="sm"
              onClick={e => {
                e.preventDefault();
                const message = `Are you sure you want to remove this document from the Data Room?`;
                if (window.confirm(message)) {
                  dispatch(deleteRoomDocument(room_document_id, accessCode, accountId));
                }
              }}
              tooltip="Remove Document"
            >
              <FontAwesomeIcon icon={['fal', 'trash-alt']} />
            </Button>
          </div>
        )}
      </div>
      <div
        className={classnames('allDocsSection__listRecipients', {
          myTasks: isMyDashboard,
        })}
      >
        {recipients.map((recipient, index) => (
          <RecipientRow
            key={`document-recipient-${recipient.id}`}
            accessCode={accessCode}
            accountId={accountId}
            currentUser={currentUser}
            isAdmin={isAdmin}
            handleDocumentRecipientLink={handleDocumentRecipientLink}
            handleResendLinkEmail={handleResendLinkEmail}
            panda_document_id={panda_document_id}
            recipient={recipient}
            status={status}
          />
        ))}
      </div>
    </div>
  );
};

const RecipientRow = ({
  accessCode,
  accountId,
  currentUser,
  isAdmin,
  handleDocumentRecipientLink,
  handleResendLinkEmail,
  panda_document_id,
  recipient,
  status,
}) => {
  const { email, email_sent, first_name, last_name, signature_link, signed, signer } =
    recipient;

  const [isActionsShowing, setIsActionsShowing] = useState(false);

  const recipientEmail = email.toLowerCase();
  const userEmail = currentUser.email.toLowerCase();

  const isActionsVisible = !accessCode || isAdmin || recipientEmail === userEmail;

  return (
    <div className="allDocsSection__listRecipient">
      <p className="allDocsSection__listRecipientLabel">
        {!first_name && !last_name && 'name not found: '}
        {(first_name || last_name) &&
          `${first_name || '(First name not given)'} ${
            last_name || '(Last name not given)'
          } - `}
        <span>{email}</span>:
      </p>
      {!!signed && !!signer && (
        <p>
          <FontAwesomeIcon icon={['fal', 'check']} />
          Signed
        </p>
      )}
      {(!signed || !signer) && (
        <div
          className={`allDocsSection__listRecipientStatus${
            isActionsVisible ? '' : 'allDocsSection__listRecipeintStatus--hideActions'
          }`}
        >
          <p
            onClick={e => {
              if (isActionsVisible) {
                e.preventDefault();
                setIsActionsShowing(!isActionsShowing);
              }
            }}
          >
            <FontAwesomeIcon
              className={signer ? 'allDocsSection__listRecipientWarning' : ''}
              icon={['fal', signer ? 'times' : 'check']}
            />
            <span>
              {(!email_sent && 'Email not sent') ||
                (status === 'document.draft' && 'Not yet sent') ||
                (!signer && 'Reviewer') ||
                'Not signed'}
            </span>
            <FontAwesomeIcon
              className="allDocsSection__listRecipientChevron"
              icon="chevron-down"
            />
          </p>
          {status !== 'document.draft' && isActionsShowing && (
            <Dropdown isOpen={isActionsShowing} onClose={e => setIsActionsShowing(false)}>
              <Button
                buttonType="secondary"
                onClick={e => {
                  e.preventDefault();
                  handleResendLinkEmail(e, panda_document_id, email, accountId);
                }}
                size="sm"
              >
                {email_sent === 0 ? 'Send email' : 'Resend email'}
              </Button>
              {!!signature_link && (
                <>
                  <Button
                    buttonType="secondary"
                    onClick={e => {
                      e.preventDefault();
                      handleDocumentRecipientLink(e, signature_link);
                    }}
                    size="sm"
                  >
                    {`Copy ${
                      (userEmail === recipientEmail && 'your') ||
                      (!!signed && !signer && 'reviewer') ||
                      ''
                    } link`}
                  </Button>
                  {userEmail === recipientEmail && (
                    <Button
                      buttonType="secondary"
                      href={signature_link}
                      target="_blank"
                      onClick={e => e.stopPropagation()}
                      rel="noopener noreferrer"
                      size="sm"
                    >
                      View document
                    </Button>
                  )}
                </>
              )}
            </Dropdown>
          )}
        </div>
      )}
    </div>
  );
};

AllDocsSection.propTypes = {
  handleDeleteProject: PropTypes.func,
  isHeightUnset: PropTypes.bool,
  isSectionOpenInit: PropTypes.bool,
  isMyDashboard: PropTypes.bool,
  isWarning: PropTypes.bool,
  moduleId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sectionLabel: PropTypes.string,
  documents: PropTypes.array,
};

AllDocsSection.defaultProps = {
  handleDeleteProject: () => {},
  isHeightUnset: false,
  isSectionOpenInit: false,
  isMyDashboard: false,
  isWarning: false,
  moduleId: -1,
  documents: [],
};

export default AllDocsSection;
