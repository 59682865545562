import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { pdfjs } from 'react-pdf';

import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';

import {
  deleteAccountProject,
  getAccountProject,
  postProject,
} from '../../redux/modules/Project/operations';
import { AccountProject } from '../../redux/modules/Project/selectors';
import { getTransaction } from '../../redux/modules/Transaction/operations';
import {
  getFormikValues,
  getTransactionById,
} from '../../redux/modules/Transaction/selectors';
import ModuleDict from '../../utils/ModuleDict';
import Workbench from './Workbench';
import handlePath from '../../utils/handlePath';
import { BoxToken } from '../../redux/modules/Cabinet/selectors';
import { getBoxToken } from '../../redux/modules/Cabinet/operations';

class WorkbenchContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetchingInit: true,
    };
  }

  handleGetTransaction = (transactionId, projectId, passedTransaction) => {
    let {
      history,
      match: { params },
      transaction,
    } = this.props;
    let { companyId, moduleId, step } = params;
    let initStep = step;
    let initTransactionId = params.initTransactionId;

    if (passedTransaction) {
      transaction = passedTransaction;
    }
    if (
      (!!transaction.template.is_assessment && step === 'initialize') ||
      step === 'enter-information' ||
      step === 'questionnaire' ||
      step === 'task-tracking' ||
      step === 'new'
    ) {
      step = 'task-view';
    } else if (step === 'initialize' || step === 'review' || step === 'finalize') {
      step = 'task-view';
    }

    if (!transaction.open) {
      if (transaction.template.is_assessment) {
        step = 'overview';
      } else if (step !== 'task-view') {
        step = 'task-view';
      }
    } else if (!!transaction.open && step === 'overview') {
      step = 'task-view';
    }
    if (transactionId + '' !== initTransactionId || step !== initStep)
      history.replace(
        handlePath(
          `/workbench/${moduleId}/${projectId}/${transactionId}/${step || 'error'}`,
          companyId,
        ),
      );
    this.setState({ isFetchingInit: false });
  };

  componentDidMount() {
    const {
      boxToken,
      createProject,
      getAccountProject,
      fetchBoxToken,
      getTransaction,
      history,
      match: { params = {} },
      project,
    } = this.props;
    const { companyId, moduleId, projectId, step } = params;
    if (!boxToken) {
      fetchBoxToken();
    }
    if (step === 'new') {
      createProject().then(
        e => {
          getTransaction(e.transactions[0].id).then(
            transaction => {
              this.handleGetTransaction(transaction.id, e.id, transaction);
            },
            error => history.replace(handlePath(ModuleDict[-1].path, companyId)),
          );
        },
        error => history.replace(handlePath(ModuleDict[moduleId || -1].path, companyId)),
      );
    } else {
      if (isEmpty(project)) {
        getAccountProject(projectId).then(
          e => {
            let transactionId = params.transactionId || e.transactions[0].id;
            if (params.transactionId === '-1') {
              transactionId = e.transactions[0].id;
            }
            getTransaction(transactionId).then(
              transaction => {
                this.handleGetTransaction(transactionId, e.id, transaction);
              },
              error => history.replace(handlePath(ModuleDict[-1].path, companyId)),
            );
          },
          error => history.replace(handlePath(ModuleDict[-1].path, companyId)),
        );
      } else {
        let transactionId = params.transactionId || project.transactions[0].id;
        if (params.transactionId === '-1') {
          transactionId = project.transactions[0].id;
        }
        getTransaction(transactionId).then(
          transaction => {
            this.handleGetTransaction(transactionId, projectId, transaction);
          },
          error => history.replace(handlePath(ModuleDict[-1].path, companyId)),
        );
      }
    }
    // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  }

  componentDidUpdate(prevProps, prevState) {
    let {
      getAccountProject,
      getTransaction,
      history,
      location,
      match: {
        params: { companyId, projectId, step, transactionId },
      },
      project,
      transaction,
    } = this.props;
    const isFinalized = get(location, 'state.isFinalized', false);
    if (
      prevProps.match.params.transactionId !== transactionId &&
      !this.state.isFetchingInit
    ) {
      this.setState({ isFetchingInit: true });
      if (transactionId === '-1') {
        transactionId = project.transactions[0].id;
        transaction = project.transactions[0];
        this.handleGetTransaction(transactionId, projectId, transaction);
      } else if (isEmpty(transaction)) {
        getTransaction(transactionId).then(
          e => this.handleGetTransaction(transactionId, projectId, e),
          error => history.replace(handlePath(ModuleDict[-1].path, companyId)),
        );
      } else {
        this.handleGetTransaction(transactionId, projectId);
      }
    } else if (
      prevProps.match.params.step !== step &&
      prevProps.match.params.transactionId !== '-1' &&
      !isFinalized &&
      !this.state.isFetchingInit &&
      step !== 'subscriptions'
    ) {
      this.setState({ isFetchingInit: true });
      this.handleGetTransaction(transactionId, projectId);
    }
    if (prevProps.match.params.step !== step) {
      if (step === 'overview') {
        getAccountProject(projectId);
      }
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  handleExitTransaction = () => {
    const {
      history,
      match: {
        params: { companyId },
      },
    } = this.props;

    history.push(handlePath(ModuleDict['-1'].path, companyId));
  };

  handleDiscardTransaction = () => {
    const {
      deleteProject,
      history,
      match: {
        params: { companyId, moduleId },
      },
    } = this.props;

    deleteProject().then(
      e => history.push(handlePath(ModuleDict[moduleId].path, companyId)),
      error => history.push(handlePath(ModuleDict['-1'].path, companyId)),
    );
  };

  render() {
    const {
      accountInfo,
      error,
      formikValues,
      isFetching,
      match,
      project,
      submitForm,
      taskLabel,
      transaction,
    } = this.props;
    const { isFetchingInit } = this.state;

    const currentTransactionIndex = get(project, 'transactions', []).findIndex(
      e => e.id + '' === match.params.transactionId,
    );

    const isDeletable =
      !!transaction.template &&
      (!!transaction.template.is_assessment || currentTransactionIndex === 0) &&
      !!transaction.open;

    return (
      <Workbench
        accountInfo={accountInfo}
        error={error}
        formikValues={formikValues}
        handleDiscardTransaction={this.handleDiscardTransaction}
        handleExitTransaction={this.handleExitTransaction}
        isDeletable={isDeletable}
        isFetching={isFetching}
        isFetchingInit={isFetchingInit}
        params={match.params}
        project={project}
        submitForm={submitForm}
        taskLabel={taskLabel}
        transaction={transaction}
      />
    );
  }
}

WorkbenchContainer.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  match: PropTypes.object,
  task: PropTypes.object,
  submitForm: PropTypes.func.isRequired,
};
WorkbenchContainer.defaultProps = {
  isFetching: false,
  task: {},
  getFormInfo: () => {},
  submitForm: () => {},
};

const mapStateToProps = (state, ownProps) => {
  const { Formation, Transaction } = state;
  const { isFetching } = Transaction;
  const {
    match: {
      params: { projectId, transactionId },
    },
  } = ownProps;
  return {
    accountInfo: Formation.accountInfo,
    boxToken: BoxToken(state),
    isFetching,
    formikValues: getFormikValues(Transaction, transactionId),
    project: AccountProject(state, projectId),
    transaction: getTransactionById(Transaction, transactionId),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    match: {
      params: { projectId },
    },
  } = ownProps;

  return {
    createProject: () => dispatch(postProject(projectId)),
    deleteProject: () => dispatch(deleteAccountProject(projectId)),
    getAccountProject: id => dispatch(getAccountProject(id)),
    fetchBoxToken: () => dispatch(getBoxToken()),
    // getCalendlyMeta: () => dispatch(getCalendlyMeta()),
    getTransaction: id => dispatch(getTransaction(id)),
  };
};

const enhance = connect(mapStateToProps, mapDispatchToProps);

export default enhance(WorkbenchContainer);
