import React from 'react';

import { Field, Form } from 'formik';

import logo from '../../assets/images/wordmark-white.svg';
import Button from '../../components/common/Button';
import { Password, Select, StyledCheckbox, TextField } from '../../components/formik';
import TermsOfService from '../../components/TermsOfServiceModal';

import './Register.scss';
import { businessTypeOptions } from '../NewCompany/NewCompany';
import { validateSelect } from '../../utils/FeatureTypes';

const RegisterForm = ({
  isFetching,
  isModalOpen,
  isPasswordPlainText,
  handleTogglePassword,
  handleOnLoginClick,
  status,
  toggleModal,
}) => (
  <div className="register">
    <div className="register__logo">
      <img src={logo} alt="savvi-logo" />
    </div>
    <Form className="card card-body register__form">
      <h2 className="form__heading">Create an account to get started.</h2>
      <div className="grid--flex">
        <div className="grid__column">
          <Field
            component={TextField}
            inputClassName="-js-register__input-first-name"
            name="first_name"
            label="First Name"
            icon="user"
          />
        </div>
        <div className="grid__column">
          <Field
            component={TextField}
            inputClassName="-js-register__input-last-name"
            name="last_name"
            label="Last Name"
            icon="user"
          />
        </div>
      </div>
      <Field
        component={TextField}
        autoComplete="username"
        inputClassName="-js-register__input-email"
        name="email"
        label={'Email'}
        placeholder={'john@savvi.com'}
        icon="envelope"
        type="email"
      />
      <Field
        component={Password}
        inputClassName="-js-register__input-password"
        name="password"
        label="Password"
        autoComplete="new-password"
        icon="lock"
        hasToggle={true}
        onToggle={handleTogglePassword}
        isPlainText={isPasswordPlainText}
      />
      <Field
        component={TextField}
        inputClassName="-js-register__input-company-name"
        label={'Company Name'}
        name="company_name"
        placeholder={'e.g. My Company Name, Inc.'}
        sublabel="If you currently aren't sure of your company name or suffix, go ahead and give us a placeholder name. You will be able to change it later."
      />
      <Field
        component={Select}
        isValidatingOnBlur={false}
        label="What is the purpose of this business entity?"
        name="business_type"
        options={businessTypeOptions}
        validate={validateSelect}
      />
      <Field
        component={StyledCheckbox}
        inputClassName="-js-register__input-tos"
        name="tos"
        label={
          <label htmlFor="tos">
            I agree to Savvi's{' '}
            <a
              href="http://docs.wixstatic.com/ugd/342422_fa8385a417404f138a687a08f8c5bc15.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>
            {' and'}
            <a
              href="http://docs.wixstatic.com/ugd/342422_2fcd1c73409a443d988a73a2fb263716.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="footer__link"
            >
              Privacy Policy
            </a>
          </label>
        }
      />
      {status.form && <div className="alert form__error">{status.form}</div>}
      <div className="register__actions">
        <Button
          className="-js-register__btn-back"
          buttonType="secondary"
          onClick={handleOnLoginClick}
        >
          Back
        </Button>
        <Button
          className="-js-register__btn-main"
          isFetching={isFetching}
          isDisabled={isFetching}
          type="submit"
        >
          Create Account
        </Button>
      </div>
    </Form>
    <TermsOfService
      isOpen={isModalOpen}
      onClose={() => toggleModal(false)}
      label="Terms of Service"
    />
  </div>
);

RegisterForm.defaultProps = {
  values: {},
  status: {},
};

export default RegisterForm;
