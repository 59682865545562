import React, { forwardRef, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import { InputFeedback, InputHelp, InputSublabel } from '..';

import './InputStyledTextField.scss';

const bem = elementName => `styled-text-field${elementName ? '__' + elementName : ''}`;

const TextField = forwardRef(
  (
    {
      autoComplete,
      autoFocus,
      customClass,
      defaultPlaceholder,
      placeholder,
      error,
      handleOnCopyClick,
      inputClassName,
      isCopyable,
      isDataHidden,
      isDisabled,
      isFetching,
      isFixed,
      isRequired,
      questionHelp,
      label,
      name,
      onFocus,
      onBlur,
      onChange,
      savedValue,
      sublabel,
      type,
      value,
    },
    ref,
  ) => {
    const defaultRef = useRef(null);
    const inputRef = ref || defaultRef;
    const inputPlaceholder = placeholder || defaultPlaceholder;
    const wrapperClasses = classnames(bem('wrapper'), {
      [bem('wrapper--error')]: !!error,
      [bem('wrapper--hidden')]: isDataHidden,
      [bem('wrapper--copyable')]: isCopyable,
      [bem('wrapper--disabled')]: isFixed || isDisabled,
      [bem('wrapper--padded-top')]: label.length > 100,
    });
    let updatedVal = value;
    if (updatedVal === null || updatedVal === undefined) {
      updatedVal = '';
    }
    updatedVal = updatedVal.trimStart();

    const [isActive, setIsActive] = useState(
      !!updatedVal || (!updatedVal && inputPlaceholder),
    );

    useEffect(() => {
      const trimVal = value.trim();
      if (!isActive && (trimVal || (!trimVal && inputPlaceholder))) {
        setIsActive(true);
      }
    }, [isActive, value, inputPlaceholder]);

    return (
      <div className={`${bem('container')} ${customClass}`}>
        <div className={wrapperClasses} onClick={handleOnCopyClick}>
          {label && (
            <label
              className={classnames(bem('label'), { [bem('label--active')]: isActive })}
              htmlFor={name}
            >
              {label}
              {!!isRequired && <span className="workbench-form__required">*</span>}
              {questionHelp && <InputHelp text={questionHelp} />}
            </label>
          )}
          <input
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            className={classnames(inputClassName, bem())}
            disabled={isFixed || isDisabled || isFetching}
            data-lpignore={isDataHidden ? 'true' : 'false'}
            id={name}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                onBlur(e);
              }
            }}
            onFocus={e => {
              setIsActive(true);
              if (e.target.value !== value) {
                onChange(e);
              }
              onFocus(e);
            }}
            onBlur={e => {
              if (!e.target.value) {
                setIsActive(false);
              }
              if (e.target.value.trim() === savedValue) {
                return;
              }
              onBlur(e);
            }}
            onChange={onChange}
            placeholder={placeholder || defaultPlaceholder}
            ref={inputRef}
            type={type}
            value={updatedVal}
            name={name}
          />
        </div>
        <InputSublabel text={sublabel} />
        <InputFeedback error={error} />
      </div>
    );
  },
);

TextField.propTypes = {
  isDisabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  type: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.array]),
  inputClassName: PropTypes.string,
  isRequired: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  sublabel: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

TextField.defaultProps = {
  autoComplete: 'on',
  customClass: '',
  isDisabled: false,
  error: null,
  handleOnCopyClick: () => {},
  name: 'input',
  type: 'text',
  icon: false,
  isRequired: false,
  label: '',
  placeholder: '',
  sublabel: null,
  value: '',
  onFocus: () => {},
  onBlur: () => {},
  onChange: val => {},
};

export default TextField;
