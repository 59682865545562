import React from 'react';
import { NavLink, useHistory, useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import './SavviNavLink.scss';
import handlePath from '../../utils/handlePath';

const SavviNavLink = ({ item, parentPathname }) => {
  const { push } = useHistory();
  const { hash } = useLocation();
  const { companyId } = useParams();

  return (
    <NavLink
      onClick={e => {
        if (item.path.hash === hash) {
          e.preventDefault();
          push(handlePath(parentPathname, companyId));
        }
      }}
      className="navlink__tag"
      activeClassName="selected"
      to={handlePath(item.path, companyId)}
      isActive={() => hash === item.path.hash}
    >
      {item.label}
      {item.isItemLengthShowing && (
        <>
          {' ('}
          <span className="navlink__tagLength">{item.itemLength}</span>)
        </>
      )}
    </NavLink>
  );
};

const SavviNavLinkBar = ({ hash, label, navLinkItems, parentPathname }) => (
  <div className="navlink__tags">
    {navLinkItems.length > 0 && <p>{label}:</p>}
    {navLinkItems.map(item => (
      <SavviNavLink
        key={`navlink-tag-${item.id}`}
        item={item}
        parentPathname={parentPathname}
      />
    ))}
    {/* {navLinkItems.length > 0 && (
        <Button
          buttonType="link"
          isWarning
          to={parentPathname}
          className="navlink__filterClear"
        >
          Clear
        </Button>
      )} */}
  </div>
);

SavviNavLinkBar.propTypes = {
  hash: PropTypes.string.isRequired,
  moduleTags: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      path: PropTypes.shape({
        pathname: PropTypes.string,
        hash: PropTypes.string,
      }),
    }),
  ).isRequired,
  parentPathname: PropTypes.string.isRequired,
  push: PropTypes.func.isRequired,
};

SavviNavLinkBar.defaultProps = {
  hash: '',
  label: 'FILTERS',
  moduleTags: [],
  push: () => {},
};

export { SavviNavLink, SavviNavLinkBar };
