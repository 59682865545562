import {
  CLEAR_NOTICE,
  CLOSE_TERMS_MODAL,
  CLOSE_USER_DROPDOWN,
  OPEN_TERMS_MODAL,
  OPEN_USER_DROPDOWN,
  SET_NOTICE,
} from './types';

export const openTermsModal = () => ({
  type: OPEN_TERMS_MODAL,
});

export const closeTermsModal = () => ({
  type: CLOSE_TERMS_MODAL,
});

export const openUserDropdown = () => ({
  type: OPEN_USER_DROPDOWN,
});

export const closeUserDropdown = () => ({
  type: CLOSE_USER_DROPDOWN,
});

export const setNotice = payload => ({
  type: SET_NOTICE,
  payload,
});

export const clearNotice = payload => ({
  type: CLEAR_NOTICE,
  payload,
});
