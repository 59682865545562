import * as Types from './types';

export function setCompaniesById(payload) {
  return {
    type: Types['SET_COMPANIES'],
    payload,
  };
}

export function setCompanyDataTables(payload) {
  return {
    type: Types['SET_COMPANY_DATA_TABLES'],
    payload,
  };
}
