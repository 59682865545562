import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useHistory, useParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';

import {
  deleteAccountProject,
  updateProject,
} from '../../../redux/modules/Project/operations';
import {
  getGroupedTransactionsForProjectView,
  getLearnMoreMetaForProjectView,
} from '../../../redux/modules/Project/selectors';
import ModuleDict from '../../../utils/ModuleDict';
import ProjectHeader from '../../ProjectHeader';

import './WorkbenchNav.scss';
import { SidebarNavLinkCompany } from '..';
import handlePath from '../../../utils/handlePath';

const WorkbenchNav = ({ isCollapsed }) => {
  const { push, replace } = useHistory();
  const { companyId, moduleId = 2, projectId, step, transactionId } = useParams();
  const dispatch = useDispatch();

  const project = useSelector(state =>
    getGroupedTransactionsForProjectView(state.Project, projectId),
  );
  const { groupedTransactions = [], label } = project;
  const learnMoreMeta = useSelector(state =>
    getLearnMoreMetaForProjectView(state.Project, projectId),
  );

  const [isInitLoading, setInitLoading] = useState(isEmpty(project));

  const handleDiscardTransaction = () => {
    dispatch(deleteAccountProject(projectId)).then(
      e => replace(handlePath(ModuleDict[moduleId].path, companyId)),
      error => push(handlePath(ModuleDict['-1'].path, companyId)),
    );
  };

  const handleUpdateProjectName = label => {
    dispatch(updateProject({ label }, projectId));
  };

  const currentTransactionIndex = get(project, 'transactions', []).findIndex(
    e => e.id + '' === transactionId,
  );

  const isCurrentTransactionOpen = !!get(
    project,
    `transactions[${currentTransactionIndex}].open`,
    false,
  );

  let isInitQuestionnaire = false;

  if (currentTransactionIndex === 0 && !!project.transactions[0].open) {
    isInitQuestionnaire = true;
  }

  useEffect(() => {
    if (!isEmpty(project)) {
      setInitLoading(false);
    }
  }, [project]);

  return (
    <>
      {isCollapsed && <div className="workbench-nav__fade" />}
      <div className="workbench-nav__content">
        <SidebarNavLinkCompany
          isCollapsed={isCollapsed}
          isWorkbench
          link={ModuleDict['-1'].path}
        />
        {!isInitLoading && (
          <ProjectHeader
            handleUpdateProjectName={handleUpdateProjectName}
            icon={get(ModuleDict, `[${moduleId}].icon`, ModuleDict['-1'].icon)}
            isHighlighted={isInitQuestionnaire}
            isLearnMoreShowing={learnMoreMeta.isLearnMoreShowing}
            learnMoreUrl={learnMoreMeta.learnMoreUrl}
            projectLabel={label}
          />
        )}
        <div className="workbench-nav__inner">
          {groupedTransactions.map(({ groupLabel, transactions }, index) => (
            <div
              className={classnames('workbench-nav__group', {
                borderless: !groupLabel,
              })}
              key={`groupedTransaction-${groupLabel}`}
            >
              {groupLabel && groupedTransactions.length > 1 && (
                <div className="workbench-nav__group-header">
                  <h2>{groupLabel}</h2>
                </div>
              )}
              {!isInitQuestionnaire &&
                transactions.map(
                  ({ id, label, open, template = {}, tasks = [] }, ind) => {
                    return (
                      <NavLink
                        activeClassName="workbench-nav__link--selected"
                        className="workbench-nav__link"
                        key={`transaction-link-${label}`}
                        to={handlePath(
                          `/workbench/${moduleId}/${projectId}/${id}/initialize`,
                          companyId,
                        )}
                        isActive={() => id + '' === transactionId}
                      >
                        {!template.is_assessment && tasks.length > 1 && (
                          <FontAwesomeIcon icon={['fal', 'chevron-right']} />
                        )}
                        {!template.is_assessment && tasks.length < 2 && (
                          <div className="workbench-nav__link-padding" />
                        )}
                        <div className="workbench-nav__link-items">
                          <h4>
                            {(!open && template.is_assessment && 'Overview') || label}
                          </h4>
                          {!template.is_assessment &&
                            id + '' === transactionId &&
                            tasks.map(
                              (e, index) =>
                                index !== 0 && (
                                  <p key={`transaction-${id}-${e.id}`}>{e.label}</p>
                                ),
                            )}
                        </div>
                      </NavLink>
                    );
                  },
                )}
            </div>
          ))}
        </div>
        <Link className="sidebar__exit" to={handlePath('/home', companyId)}>
          {!isCurrentTransactionOpen && 'Exit Workflow'}
          {isCurrentTransactionOpen && 'Save & Exit'}
        </Link>
        {isInitQuestionnaire && step === 'task-view' && (
          <button className="sidebar__exit warning" onClick={handleDiscardTransaction}>
            <FontAwesomeIcon icon={['fal', 'trash-alt']} />
            Discard Workflow & Exit
          </button>
        )}
      </div>
    </>
  );
};

export default WorkbenchNav;
