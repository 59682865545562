import React from 'react';

import classnames from 'classnames';
import get from 'lodash-es/get';

import InputStyledCheckbox from '../../common/InputStyledCheckbox/InputStyledCheckbox';
import { InputFeedback, InputHelp, InputSublabel } from '../../inputs';

import './CheckboxGroup.scss';

const CheckboxGroup = ({
  children,
  className,
  field,
  form,
  handleFormBlur,
  isDisabled,
  label,
  options,
  questionHelp,
  sublabel,
  type,
  ...otherProps
}) => {
  const updatedOptions = options.split(',');
  const { name } = field;
  const { errors, setFieldValue, touched, values } = form;
  let isTouched = get(touched, name, false);
  const handleChange = ({ target }) => {
    if (type === 'radio') {
      handleFormBlur({ target: { value: target.value } });
      return setFieldValue(name, target.value);
    }

    let valuesArr = get(values, name, '').split('|');
    let checked = valuesArr.indexOf(target.value) !== -1;
    if (!valuesArr[0]) {
      valuesArr.pop();
    }
    if (!checked) {
      valuesArr.push(target.value);
    } else {
      valuesArr.splice(valuesArr.indexOf(target.value), 1);
    }
    handleFormBlur({ target: { value: valuesArr.join('|') } });
    return setFieldValue(name, valuesArr.join('|'));
  };

  const error = isTouched && get(errors, name, null);

  const classes = classnames(
    'checkbox-group__container',
    {
      'checkbox-group__container--error': !!error,
      'checkbox-group__container--disabled': isDisabled,
    },
    className,
  );

  const labelClass = 'checkbox-group__label';
  return (
    <div className={classes}>
      <div className="checkbox-group">
        <label className={labelClass}>
          {label}
          {questionHelp && <InputHelp text={questionHelp} />}
        </label>
        {children ||
          updatedOptions.map((option, index) => {
            let checked = option === field.value;
            if (type === 'checkbox') {
              checked = get(values, name, '').split('|').indexOf(option) !== -1;
            }
            return (
              <InputStyledCheckbox
                key={'onboarding-checkbox-' + index}
                checked={checked}
                isDisabled={isDisabled}
                label={option}
                name={`${name}__${option}`}
                onChange={handleChange}
                type={type}
                value={option}
              />
            );
          })}
      </div>
      <InputSublabel>{sublabel}</InputSublabel>
      <InputFeedback error={error} />
    </div>
  );
};

CheckboxGroup.defaultProps = {
  sublabel: null,
  error: null,
};

export default CheckboxGroup;

// Children should be an array of Formik Field Components passed down
