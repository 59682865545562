import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-modal';
import { useHistory, useLocation, useParams } from 'react-router';

import wordMark from '../../assets/images/wordmark-white.svg';
import savviLogo from '../../assets/images/savvi-logo-white.svg';
import { DashboardNav, WorkbenchNav } from '../../components/Sidebar';
import TeamsNav from '../../components/Sidebar/TeamsNav';
import RoomNav from '../../components/Sidebar/RoomNav';
import { getAuthAccount, IsPartner } from '../../redux/modules/User/selectors';

import './Sidebar.scss';
import handlePath from '../../utils/handlePath';
import { setNotice } from '../../redux/modules/UI/actions';
import { getRoomWithUpdatedFeatures } from '../../redux/modules/Data/selectors';
import UseWindowSize from '../../utils/UseWindowSize';

const Sidebar = ({
  isDataRoom,
  isDashboard,
  isEditing,
  isWorkbench,
  isTeamView,
  transactionLabel,
}) => {
  const dispatch = useDispatch();
  const { width } = UseWindowSize();
  const { push } = useHistory();
  const { search, pathname } = useLocation();
  const { companyId, room_access_code } = useParams();
  const isPartner = useSelector(IsPartner);
  const dataRoom = useSelector(state =>
    getRoomWithUpdatedFeatures(state, room_access_code),
  );
  const currentAccount = useSelector(getAuthAccount);
  const { is_admin_user, room: { account_id } = {} } = dataRoom;
  const { isAuthenticated, accountId } = currentAccount;

  const isWhite = isWorkbench || isTeamView || isDataRoom;
  const isAuthorized = isAuthenticated && accountId === account_id && is_admin_user;

  const [isCollapsed, setIsCollapsed] = useState(width < 876);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  useEffect(() => {
    if (width > 1200 && isCollapsed) {
      setIsCollapsed(false);
    }
  }, [width, isCollapsed]);

  useEffect(() => {
    if (!isWhite) {
      setIsOverlayVisible(false);
    }
  }, [isWhite]);

  if (
    pathname.includes('/subscriptions') &&
    !pathname.includes('/settings/subscriptions')
  ) {
    return null;
  }

  return (
    <div
      className={classnames('sidebar__container', {
        hide: isCollapsed,
        white: isWhite,
        dataRoom: isDataRoom,
      })}
    >
      {((isWhite && !isDataRoom) || (isDataRoom && isAuthorized)) && (
        <div
          className={`sidebar__toggleOverlay${
            companyId + '' === '0' ? ' isDisabled' : ''
          }`}
          onClick={() => {
            if (companyId + '' !== '0') {
              setIsOverlayVisible(true);
            } else {
              dispatch(setNotice('Please first select or create a company.'));
            }
          }}
        >
          <FontAwesomeIcon icon="bars" />
        </div>
      )}
      {(!isWhite || isDataRoom) && (
        <div
          className={`sidebar__logo${isDataRoom ? ' isDisabled' : ''}`}
          onClick={e => {
            if (isDataRoom) {
              return;
            } else if (companyId + '' !== '0') {
              push(handlePath({ pathname: '/home', search }, companyId));
            } else {
              dispatch(setNotice('Please first select or create a company.'));
            }
          }}
        >
          {!isCollapsed && <img src={wordMark} alt="" />}
          {isCollapsed && <img src={savviLogo} alt="" />}
        </div>
      )}
      {(isWorkbench || isDashboard) && (
        <div
          className={classnames('sidebar__arrow', { isDashboard, isWorkbench })}
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          <FontAwesomeIcon
            icon={[
              'fal',
              (!isCollapsed && 'arrow-to-left') ||
                (isWhite && 'arrow-from-left') ||
                'bars',
            ]}
          />
        </div>
      )}
      {isDataRoom && <RoomNav isAuthorized={isAuthorized} isEditing={isEditing} />}
      {isTeamView && <TeamsNav isCollapsed={isCollapsed} isPartner={isPartner} />}
      {isDashboard && <DashboardNav isCollapsed={isCollapsed} />}
      {isWorkbench && (
        <WorkbenchNav isCollapsed={isCollapsed} transactionLabel={transactionLabel} />
      )}
      {!isWorkbench && (
        <div
          className={classnames('sidebar__toggle', { isCollapsed, isDashboard })}
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          <div className="sidebar__toggleIcons">
            <FontAwesomeIcon className="left-icon" icon="long-arrow-right" />
            <FontAwesomeIcon className="right-icon" icon="long-arrow-left" />
          </div>
          <h4>{isCollapsed ? 'Expand Menu' : 'Collapse Menu'}</h4>
        </div>
      )}
      {isWhite && (
        <Modal
          className="sidebar__container is-overlay"
          isOpen={isOverlayVisible}
          onRequestClose={() => setIsOverlayVisible(false)}
          overlayClassName="sidebar__overlay"
        >
          <div
            className="sidebar__logo"
            onClick={e => {
              if (companyId + '' !== '0') {
                push(handlePath({ pathname: '/home', search }, companyId));
              } else {
                dispatch(setNotice('First please select or create a company.'));
              }
            }}
          >
            <img src={wordMark} alt="" />
          </div>
          <div
            className="sidebar__arrow is-overlay"
            onClick={() => setIsOverlayVisible(!isOverlayVisible)}
          >
            <FontAwesomeIcon icon={['fal', 'times']} />
          </div>
          <DashboardNav account_id={account_id} />
        </Modal>
      )}
    </div>
  );
};

Sidebar.defaultProps = {
  isWorkbench: false,
};

export default Sidebar;
