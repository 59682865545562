import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import get from 'lodash-es/get';

import { Button } from '../../common';
import AddResourceModal from '../../AddResourceModal';
import { getBoxFileInfo } from '../../../redux/modules/Cabinet/operations';

import './ResourceList.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContentPreview from 'box-ui-elements/es/elements/content-preview';
import { BoxToken } from '../../../redux/modules/Cabinet/selectors';
import { isEmpty } from 'lodash-es';
import handlePath, { handleExternalUrlWithCompanyId } from '../../../utils/handlePath';
import classNames from 'classnames';
import { InputHelp } from '../../inputs';

const ResourceList = props => {
  const {
    featureProps: {
      json: {
        allow_new,
        feature_types = [],
        links = [],
        resource_label,
        resource_type,
        resource_type_id,
      } = {},
      question_text,
      question_label,
      resourceListData,
    },
    params,
    questionHelp,
    isDisabled,
    isOutsideForm,
  } = props;
  const { REACT_APP_BASEURL } = process.env;
  const dispatch = useDispatch();

  const boxToken = useSelector(BoxToken);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [selectedResource, setSelectedResource] = useState({});

  const handleDocumentLink = docId => {
    dispatch(getBoxFileInfo(docId)).then(payload => {
      const win = window.open(
        handlePath(
          `${REACT_APP_BASEURL}/cabinet/${get(payload, 'parent.id', -1)}/${docId}`,
          params.companyId,
        ),
        '_blank',
      );
      win.focus();
    });
  };

  const filteredFeatureTypes = feature_types.filter(e => !!e.table_view);

  const resourceList = Object.entries(resourceListData).reduce((dict, e, index) => {
    const rowItems = e[1].reduce(
      (features, feature) => ({
        ...features,
        [feature.feature]: feature,
      }),
      {},
    );
    dict.push(rowItems);
    return dict;
  }, []);

  return (
    <div className="resourceList__container">
      <h4 className="resourceList__question">
        {question_text || question_label}
        {questionHelp && <InputHelp text={questionHelp} />}
      </h4>
      <div
        className="resourceList"
        style={{
          gridTemplateColumns: `repeat(${filteredFeatureTypes.length}, auto)`,
        }}
      >
        {resourceList.length === 0 && (
          <h2 className="resourceList__empty">{`No ${resource_label} Data Found`}</h2>
        )}
        {resourceList.length > 0 &&
          filteredFeatureTypes.map(
            (jsonFeatureType, index) =>
              !!jsonFeatureType.table_view && (
                <h4
                  className={`resourceList__header ${index === 0 ? 'first' : ''}`}
                  key={`resource-list-header-${jsonFeatureType.feature_name}`}
                >
                  {jsonFeatureType.feature_name}:
                </h4>
              ),
          )}
        {resourceList.map((row, rowIndex) => (
          <ResourceRow
            boxToken={boxToken}
            companyId={params.companyId}
            featureTypes={filteredFeatureTypes}
            handleDocumentLink={handleDocumentLink}
            isDisabled={isDisabled || isOutsideForm}
            key={`resource-row-${rowIndex}`}
            links={links}
            row={row}
            rowIndex={rowIndex}
            setSelectedResource={setSelectedResource}
            setIsModalOpen={setIsModalOpen}
          />
        ))}
        {allow_new && !isDisabled && !isOutsideForm && (
          <Button
            buttonType="link"
            className="resourceList__add"
            size="sm"
            onClick={() => {
              setSelectedResource({});
              setIsNew(true);
              setIsModalOpen(true);
            }}
          >
            <FontAwesomeIcon icon={['fal', 'user-plus']} />
            {`Add Existing ${resource_label}`}
          </Button>
        )}
      </div>
      {!isDisabled &&
        !isOutsideForm &&
        links.map(
          (link, ind) =>
            link.table_view && (
              <h4 className="resourceList__subquestion" key={`resource-link-${ind}`}>
                <FontAwesomeIcon
                  className="resourceList__info"
                  icon="exclamation-circle"
                />
                {link.label}
                <Button buttonType="link" target="_blank" href={link.link_url}>
                  {link.link_text}
                </Button>
              </h4>
            ),
        )}
      <AddResourceModal
        feature_types={feature_types}
        handleClose={() => setIsModalOpen(false)}
        isNew={isNew}
        isOpen={isModalOpen || isDisabled}
        isLocked={isOutsideForm}
        links={links}
        params={params}
        resource={selectedResource}
        resource_label={resource_label}
        resource_type={resource_type}
        resource_type_id={resource_type_id}
        setIsNew={setIsNew}
        setSelectedResource={setSelectedResource}
      />
    </div>
  );
};

const ResourceRow = ({
  boxToken,
  companyId,
  featureTypes,
  handleDocumentLink,
  isDisabled,
  row,
  rowIndex,
  setSelectedResource,
  setIsModalOpen,
}) => {
  const [isPreviewShowing, setIsPreviewShowing] = useState(false);

  return featureTypes.map((jsonFeatureType, index) => {
    const item = get(row, `${jsonFeatureType.feature_type.name}`, {});
    let label = item.value || '--';
    if (label.indexOf('|') !== -1) {
      label = label.split('|').join(', ');
    }
    if (!item.value && !isEmpty(jsonFeatureType.action)) {
      label = jsonFeatureType.action.label;
    }
    return (
      !!jsonFeatureType.table_view && (
        <div
          key={`resource-list-item-${rowIndex}-${index}-${item.feature}`}
          className={classNames('resourceList__item', {
            first: index === 0,
            // 'resourceList__item--locked': index === 0 && (isDisabled || isOutsideForm),
          })}
          onClick={() => {
            if (index === 0) {
              setSelectedResource(row);
              setIsModalOpen(true);
            }
          }}
        >
          {index === 0 && (
            <FontAwesomeIcon icon={isDisabled ? 'file-search' : ['fal', 'edit']} />
          )}
          {item.feature_type === 'document' && (
            <Button
              buttonType="link"
              size="sm"
              onClick={() => setIsPreviewShowing(!isPreviewShowing)}
            >
              {isPreviewShowing ? 'Hide Document' : 'Show Document'}
            </Button>
          )}
          {isPreviewShowing && item.feature_type === 'document' && (
            <>
              <div className="resourceList__preview">
                <ContentPreview fileId={item.value} token={boxToken} />
              </div>
              {isDisabled && (
                <Button
                  buttonType="link"
                  size="sm"
                  onClick={() => handleDocumentLink(item.value)}
                >
                  View in File Cabinet
                </Button>
              )}
            </>
          )}
          {item.feature_type !== 'document' && label}
          {!item.value && !isEmpty(jsonFeatureType.action) && !isDisabled && (
            <>
              {label}
              <Button
                buttonType="link"
                href={handleExternalUrlWithCompanyId(
                  jsonFeatureType.action.link_url,
                  companyId,
                )}
                size="sm"
              >
                {jsonFeatureType.action.link_text}
              </Button>
            </>
          )}
        </div>
      )
    );
  });
};

export default ResourceList;
