import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik } from 'formik';

import FeatureTypes from '../../utils/FeatureTypes';
import { Button } from '../common';

import './AddResourceModal.scss';
import {
  createResourceName,
  getTransactionQuestionnaire,
  updateResourceName,
} from '../../redux/modules/Transaction/operations';
import {
  patchFeature,
  getFlatFeatures,
  updateResourceFeatures,
  fetchResourcesByTypeId,
  deleteIndividual,
  fetchResourceId,
} from '../../redux/modules/Formation/operations';
import { fetchCompanyDataTables } from '../../redux/modules/Company/operations';
import { get, isEmpty } from 'lodash-es';
import {
  getFlatFeaturesForOptions,
  getResourcesByTypeIdForSelect,
} from '../../redux/modules/Formation/selectors';
import { ResourceSelect } from '../formik';
import { handleExternalUrlWithCompanyId } from '../../utils/handlePath';
import moment from 'moment';

const customStyles = {
  overlay: {
    zIndex: 9,
    backgroundColor: 'rgba(0,0,0,.66)',
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    overflowY: 'auto',
    paddingTop: 20,
    WebkitOverflowScrolling: 'touch',
  },
  content: {
    position: 'static',
    maxWidth: '90vw',
    minHeight: '250px',
    margin: 'auto',
    padding: 0,
    border: 'none',
    boxShadow: '3px 3px 3px 3px #33373859',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'visible',
    width: 950,
  },
};

const initNewResourceVals = (feature_types, resource, isEdit) =>
  feature_types.reduce((dict, e, index) => {
    let value =
      (isEdit && get(resource, `${e.feature_type.name}.value`, '')) ||
      e.default_value ||
      '';

    if (e.feature_type.feature_type === 'boolean') {
      value = e.value === '0' || e.value === 'false' || !value ? false : true;
    }
    if (e.feature_type.feature_type === 'address') {
      let matches = value.match(/(.*)\n(.*)\n(.*), ([a-z\s]*) ([0-9]*)/i) || [];
      if (!isEmpty(value) && isEmpty(matches)) {
        value = {
          line_one: value,
          line_two: '',
          city: '',
          state: '',
          zip: '',
        };
      } else {
        value = {
          line_one: get(matches, '[1]', ''),
          line_two: get(matches, '[2]', ''),
          city: get(matches, '[3]', ''),
          state: get(matches, '[4]', ''),
          zip: get(matches, '[5]', ''),
        };
      }
    }
    if (e.feature_type.feature_type === 'date' && value) {
      value = moment(value, [
        'MM-DD-YYYY',
        'MMM Do, YYYY',
        'D MMM YYYY',
        'MMM Do, YYYY',
      ]).format('MM-DD-YYYY');
    }
    if (e.feature_type.feature_type === 'address_universal') {
      let matches =
        value.match(/(.*)\n(.*)\n(.*), ([a-z\s]*) ([0-9]*)( \n([a-z\s]*))?/i) || [];
      if (!isEmpty(value) && isEmpty(matches)) {
        value = {
          line_one: value,
          line_two: '',
          city: '',
          state: '',
          zip: '',
          country: 'United States of America',
        };
      } else {
        value = {
          line_one: get(matches, '[1]', ''),
          line_two: get(matches, '[2]', ''),
          city: get(matches, '[3]', ''),
          state: get(matches, '[4]', ''),
          zip: get(matches, '[5]', ''),
          country: get(matches, '[7]', 'United States of America'),
        };
      }
    }
    dict = {
      ...dict,
      [e.feature_type.name]: value,
    };
    return dict;
  }, {});

const AddResourceModal = ({
  feature_types,
  handleClose: passedHandleClose,
  isCompany,
  isLocked, // currently only for data room & outside form
  isNew,
  isOpen,
  links = [],
  params = {},
  resource,
  resource_label,
  resource_type,
  resource_type_id,
  setSelectedResource,
  setIsNew,
}) => {
  const dispatch = useDispatch();

  const directors = useSelector(state => getFlatFeaturesForOptions(state, 'directors'));
  const resources = useSelector(state =>
    getResourcesByTypeIdForSelect(state, resource_type_id),
  );

  const filteredLinks = links.filter(e => e.edit_view);

  const [isOpenInit, setIsOpenInit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [selectedResource, setSelectedResource] = useState({});

  // const resource = (!isEmpty(selectedResource) && selectedResource) || passedResource;
  const isEdit = !isNew || !isEmpty(resource);

  useEffect(() => {
    if (
      isEmpty(resource) &&
      resources.length === 0 &&
      resource_type_id &&
      isOpen &&
      !isOpenInit
    ) {
      setIsOpenInit(true);
      dispatch(fetchResourcesByTypeId(resource_type_id));
    } else if (isCompany && isOpen && !isOpenInit) {
      dispatch(
        getFlatFeatures(
          {
            select: [
              1, // individual > name
              9, // individual > is_board_director
            ],
            where: {
              9: '1', // individual > is_board_director
            },
          },
          'directors',
        ),
      );
      setIsOpenInit(true);
    }
  }, [isCompany, isOpen, isOpenInit, dispatch, resource, resources, resource_type_id]);

  const handleClose = () => {
    setSelectedResource({});
    setIsOpenInit(false);
    setIsSubmitting(false);
    setIsNew(false);
    passedHandleClose();
  };

  const handleDelete = (resourceId, resourceName) => {
    const message = `Are you sure you want to remove ${resourceName} from our system?`;

    if (window.confirm(message)) {
      dispatch(deleteIndividual(resourceId, resourceName, null, resource_type_id));
    }
  };

  const handleResourceName = (body, resourceId, formikProps) => {
    body.transactionId = params.transactionId;
    if (!resourceId) {
      return dispatch(createResourceName(body)).then(payload => {
        dispatch(fetchResourceId(payload.id, payload.resource_type_id)).then(e => {
          const updatedResource = e[payload.id].reduce((dict, e) => {
            dict = {
              ...dict,
              [e.feature]: e,
            };
            return dict;
          }, {});
          setSelectedResource(updatedResource);
          formikProps.resetForm({
            values: initNewResourceVals(feature_types, updatedResource, true),
          });
          setIsNew(true);
        });
      });
    } else if (isEmpty(resource)) {
      dispatch(fetchResourceId(resourceId, body.resource_type_id)).then(e => {
        const updatedResource = e[resourceId].reduce((dict, e) => {
          dict = {
            ...dict,
            [e.feature]: e,
          };
          return dict;
        }, {});
        setSelectedResource(updatedResource);
        formikProps.resetForm({
          values: initNewResourceVals(feature_types, updatedResource, true),
        });
      });
    }
  };

  const resourceLabel = get(
    resource,
    `${feature_types[0].feature_type.name}.resource`,
    resource_label,
  );

  const handleClosingModal = () => {
    const finalAction = params.transactionId
      ? getTransactionQuestionnaire
      : fetchCompanyDataTables;
    dispatch(finalAction(params.transactionId)).then(
      e => handleClose(),
      error => handleClose(),
    );
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose} style={customStyles}>
      <div className="addResource">
        <FontAwesomeIcon
          className="addResource__exit"
          onClick={handleClose}
          icon={['fal', 'times']}
        />
        <h2>
          {isEdit && `Update info for ${resourceLabel}`}
          {!isEdit && `Add existing ${resourceLabel}`}
          {!isEdit && <span>*</span>}
        </h2>
        {!isEdit && (
          <h4 className="addResource__subheader">
            Inputs marked with "<span>*</span>" are required.
          </h4>
        )}
        <Formik
          initialValues={initNewResourceVals(feature_types, resource, isEdit)}
          onSubmit={formikValues => {
            if (isLocked) {
              return handleClosingModal();
            }
            const resource_name = formikValues[feature_types[0].feature_type.name];
            const featureNameObject = get(
              resource,
              `${feature_types[0].feature_type.name}`,
              {},
            );

            const emptyPromise = () => async () => {
              return await Promise.resolve();
            };
            const resourceFunc =
              (resource_name !== featureNameObject.value && updateResourceName) ||
              emptyPromise;

            setIsSubmitting(true);
            dispatch(
              resourceFunc(
                {
                  name: resource_name,
                  resource_type_id,
                },
                featureNameObject.resource_id,
              ),
            ).then(
              payload =>
                dispatch(
                  updateResourceFeatures(
                    formikValues,
                    resource,
                    feature_types,
                    (payload && payload.id) || featureNameObject.resource_id,
                    resource_name,
                    true,
                  ),
                ).then(handleClosingModal, handleClosingModal),
              error => handleClosingModal,
            );
          }}
        >
          {formikProps => {
            return (
              <Form>
                {feature_types
                  .filter(e => e.edit_view)
                  .sort((a, b) => {
                    if (
                      (b.feature_type.feature_type === 'address' ||
                        b.feature_type.feature_type === 'address_universal') &&
                      a.feature_type.feature_type !== 'document'
                    ) {
                      return -1;
                    }
                    if (b.feature_type.feature_type === 'document') {
                      return -1;
                    }
                    return 0;
                  })
                  .map((featureType, index) => {
                    const {
                      allow_entry,
                      feature_name,
                      feature_type: {
                        feature_type,
                        filename,
                        is_creatable,
                        multi_entry_feature,
                        name,
                        options,
                        path,
                        question_comment,
                        question_help,
                        question_placeholder,
                        tags,
                      },
                      feature_type_id,
                      new_require,
                      required,
                    } = featureType;
                    const item = get(resource, `${name}`, {});
                    const featureNameObject = get(
                      resource,
                      `${feature_types[0].feature_type.name}`,
                      {},
                    );
                    const isSignatory = name === 'company_signatory';
                    let updatedOptions = options;
                    if (feature_type === 'detailed_options') {
                      updatedOptions = JSON.parse(options);
                    } else if (isSignatory) {
                      updatedOptions = directors;
                    }

                    return (
                      <Fragment key={`resource-feature-${index}-${feature_type}`}>
                        {index === 0 && (
                          <Field
                            autoComplete="nope"
                            component={ResourceSelect}
                            handleDelete={handleDelete}
                            handleResourceName={resourceName => {
                              handleResourceName(
                                {
                                  resource_type_id,
                                  name: get(resourceName, 'label', resourceName),
                                },
                                get(resourceName, 'id', item.resource_id),
                                formikProps,
                              );
                            }}
                            handleRemoveResource={() => {
                              setSelectedResource({});
                              formikProps.resetForm({
                                values: initNewResourceVals(feature_types, {}, false),
                              });
                            }}
                            icon="user"
                            isDisabled={isLocked}
                            isCreatable
                            isFixed={!!item.fixed || !allow_entry}
                            isNewResource={isEmpty(resource)}
                            placeholder="Type to search or create new..."
                            questionHelp={question_help}
                            sublabel={question_comment}
                            label={feature_name}
                            name={name}
                            onResourceSelect={() => {}}
                            options={resources}
                            params={params}
                            resourceTypeName={resource_type}
                            setLabel={() => {}}
                          />
                        )}
                        {index > 0 && (
                          <Field
                            autoComplete="nope"
                            component={
                              FeatureTypes[isSignatory ? 'options' : feature_type].Formik
                            }
                            name={name}
                            feature_type={feature_type}
                            feature_type_id={feature_type_id}
                            filename={filename}
                            handleFormBlur={e => {
                              if (feature_type === 'document') {
                                dispatch(
                                  patchFeature(
                                    {
                                      value: e.target.value,
                                      feature_type_id,
                                      resource_id: featureNameObject.resource_id,
                                    },
                                    featureNameObject.resource,
                                    name,
                                    true,
                                  ),
                                );
                              }
                            }}
                            isDisabled={isLocked}
                            isLocked={isLocked}
                            isPairedColumn
                            isCreatable={!!is_creatable}
                            isDetailedOptions={feature_type === 'detailed_options'}
                            isFixed={isEmpty(resource) || !!item.fixed || !allow_entry}
                            // isLabel={!!is_label}
                            isRequired={!isNew && !!new_require}
                            label={`${feature_name}${
                              feature_type === 'boolean' ? '' : ':'
                            }`}
                            multiEntry={multi_entry_feature}
                            options={updatedOptions}
                            params={params}
                            path={path}
                            placeholder={question_placeholder}
                            questionHelp={question_help}
                            resource_id={featureNameObject.resource_id}
                            resource_name={featureNameObject.resource}
                            resetValue={FeatureTypes[feature_type].value}
                            resourceTypeName={resource_type}
                            // setLabel={() => {}}
                            sublabel={question_comment}
                            tags={tags}
                            taskId={params.transactionId}
                            validate={val => {
                              if ((isNew && !!new_require) || !!required) {
                                if (isSignatory) {
                                  return FeatureTypes['options'].validate(val);
                                }
                                return FeatureTypes[feature_type].validate(val);
                              } else if (feature_type === 'date') {
                                return FeatureTypes[feature_type].validate(
                                  val,
                                  true,
                                  get(formikProps, `touched.${name}`, false),
                                );
                              }
                            }}
                          />
                        )}
                      </Fragment>
                    );
                  })}
                {filteredLinks.length > 0 && !isLocked && (
                  <div className="addResource__links">
                    <h4 className="addResource__subheader">
                      <FontAwesomeIcon
                        className="addResource__warning"
                        icon="exclamation-circle"
                      />
                      Certain fields can only be {isEdit ? 'adjusted' : 'created'} through
                      the workflow(s) linked below.
                    </h4>
                    {filteredLinks.map((link, ind) => (
                      <h4 className="addResource__subheader" key={`resource-link-${ind}`}>
                        <FontAwesomeIcon
                          className="addResource__info"
                          icon="exclamation-circle"
                        />
                        {link.label}
                        <Button
                          buttonType="link"
                          target="_blank"
                          href={handleExternalUrlWithCompanyId(
                            link.link_url,
                            params.companyId,
                          )}
                        >
                          {link.link_text}
                        </Button>
                      </h4>
                    ))}
                  </div>
                )}
                <div className="addResource__buttons">
                  <Button
                    buttonType="secondary"
                    isDisabled={isSubmitting}
                    isFetching={isSubmitting}
                    onClick={handleClose}
                  >
                    {isLocked ? 'Close' : 'Cancel'}
                  </Button>
                  {!isLocked && (
                    <Button
                      isDisabled={isSubmitting}
                      isFetching={isSubmitting}
                      type="submit"
                    >
                      {isEdit ? 'Save' : 'Assign'}
                    </Button>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

AddResourceModal.propTypes = {
  feature_types: PropTypes.array,
  resource_type: PropTypes.string,
};

export default AddResourceModal;
