import React, { useEffect, useRef, useState } from 'react';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import Modal from 'react-modal';
import { components } from 'react-select';

import BasicTopbar from '../../components/BasicTopbar';

import './Room.scss';
import Sidebar from '../Sidebar';
import DataTable from '../../components/DataTable';
import AllDocsSection from '../../components/AllDocsSection/AllDocsSection';
import InfoSidebar from '../InfoSidebar';
import { useDispatch, useSelector } from 'react-redux';
import {
  getRoomResourceOptions,
  getRoomWithUpdatedFeatures,
  getRoomFeaturesAsOptions,
} from '../../redux/modules/Data/selectors';
import {
  addRoomUser,
  deleteRoomFeature,
  fetchFeaturesForRoom,
  fetchRoom,
  createRoomFeatures,
  deleteRoomFeatures,
  updateRoomFeatures,
  updateRoomUser,
  deleteRoomUser,
  updateRoom,
  createRoomDocument,
} from '../../redux/modules/Data/operations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isArray, isEmpty } from 'lodash-es';
import { Button } from '../../components/common';
import { InputSelect, InputStyledTextField } from '../../components/inputs';
import { setNotice } from '../../redux/modules/UI/actions';
import { DropdownAction } from '../../components/common/Dropdown/Dropdown';
import {
  getAuthAccount,
  getAuthUser,
  IsInitLoading,
} from '../../redux/modules/User/selectors';
import { refreshCompanyToken } from '../../redux/modules/Company/operations';
import dayjs from 'dayjs';
import PageLoader from '../../components/PageLoader';
import { getAllDocuments } from '../../redux/modules/Document/operations';
import { getAllDocumentsForSelect } from '../../redux/modules/Document/selectors';
import handlePath from '../../utils/handlePath';
import {
  getBoxToken,
  getBoxTokenFolderId,
  uploadBoxFile,
} from '../../redux/modules/Cabinet/operations';
import { BoxToken } from '../../redux/modules/Cabinet/selectors';
import ContentPreview from 'box-ui-elements/es/elements/content-preview';
import OutsideFormContainer from '../OutsideForm/OutsideFormContainer';
import ContentUploader from 'box-ui-elements/es/elements/content-uploader';

const Room = () => {
  const dispatch = useDispatch();
  const {
    url,
    path,
    params: { room_access_code },
  } = useRouteMatch();
  const { pathname } = useLocation();

  const currentUser = useSelector(getAuthUser);
  const currentAccount = useSelector(getAuthAccount);
  const isInitLoading = useSelector(IsInitLoading);
  const { isAuthenticated } = currentUser;
  const { accountId } = currentAccount;
  const room = useSelector(state => getRoomWithUpdatedFeatures(state, room_access_code));
  const {
    account_id: passedAccountId,
    message: errorMessage,
    code,
    is_admin_user,
    room: {
      access_log = [],
      account_id,
      account_project_id,
      documents = [],
      faqs = [],
      features = {},
      id,
      label,
      links = [],
      open,
      require_auth,
      report_feature_types = [],
      strict,
      users = [],
    } = {},
  } = room || {};

  const [addFeatureMeta, setAddFeatureMeta] = useState({});
  const [addResourceMeta, setAddResourceMeta] = useState({});
  const [isAddDocumentOpen, setIsAddDocumentOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!isInitLoading && !initialized) {
      dispatch(fetchRoom(room_access_code));
    }
  }, [room_access_code, isInitLoading, initialized, dispatch]);

  useEffect(() => {
    const accId = account_id || passedAccountId;
    if (!initialized && accId) {
      if (isAuthenticated) {
        dispatch(refreshCompanyToken(accId)).then(
          e => {
            dispatch(getBoxToken(accId));
            dispatch(fetchRoom(room_access_code, accId));
            setInitialized(true);
          },
          error => {
            dispatch(fetchRoom(room_access_code, accId));
            setInitialized(true);
          },
        );
      } else {
        setInitialized(true);
      }
    }
  }, [
    room_access_code,
    account_id,
    dispatch,
    initialized,
    isAuthenticated,
    passedAccountId,
  ]);

  useEffect(() => {
    if (!pathname.includes('data-room')) {
      setInitialized(false);
    }
  }, [pathname]);

  if (errorMessage) {
    return (
      <div className="room__container">
        <BasicTopbar isDataRoom />
        <Sidebar isDataRoom />
        <div className={`room__main`}>
          <div className="room__content">
            <h1>Data Room</h1>
            <div className="room__section">
              {code === 'UNAUTHORIZED' && (
                <h2 className="room__error">
                  This room requires you to be logged in to view. Please{' '}
                  <Button
                    buttonType="link"
                    to={{ pathname: '/login', state: { redirectPathname: pathname } }}
                    size="sm"
                  >
                    sign in
                  </Button>{' '}
                  to be allowed access.
                </h2>
              )}
              {code !== 'UNAUTHORIZED' && <h2 className="room__error">{errorMessage}</h2>}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="room__container">
      <BasicTopbar isDataRoom />
      <Sidebar isDataRoom isEditing={isEditing} />
      {(isInitLoading || !initialized) && <PageLoader />}
      {!isInitLoading && initialized && (
        <div
          className={`room__main${
            faqs.length > 0 || isEditing ? ' room__main--show-faq' : ''
          }`}
        >
          <div
            className={`room__content${
              pathname.includes('outside-form') ? ' is-outside' : ''
            }`}
          >
            <Switch>
              <Route path={`${path}/settings`}>
                <RoomSettings
                  access_code={room_access_code}
                  access_log={access_log}
                  account_id={account_id}
                  account_project_id={account_project_id}
                  is_admin_user={is_admin_user}
                  label={label}
                  open={open}
                  require_auth={require_auth}
                  room_id={id}
                  strict={strict}
                  users={users}
                />
              </Route>
              <Route path={`${path}/outside-form/:access_code`}>
                {routeProps => (
                  <OutsideFormContainer
                    room_access_code={room_access_code}
                    account_id={account_id}
                    is_admin_user={is_admin_user}
                    open={open}
                    require_auth={require_auth}
                    room_id={id}
                    strict={strict}
                    {...routeProps}
                  />
                )}
              </Route>
              <Route exact path={url}>
                <h1>
                  Data Room
                  {is_admin_user && account_id === accountId && (
                    <Button
                      buttonType="link"
                      className="room__action"
                      onClick={() => setIsEditing(!isEditing)}
                    >
                      <FontAwesomeIcon
                        icon={isEditing ? ['far', 'low-vision'] : ['fal', 'edit']}
                      />
                      {isEditing ? 'Preview Data Room' : 'Edit Data Room'}
                    </Button>
                  )}
                </h1>
                {(documents.length > 0 || isEditing) && (
                  <div className="room__section">
                    <AllDocsSection
                      accessCode={room_access_code}
                      accountId={accountId}
                      hideToken
                      isAdmin={is_admin_user}
                      isEditing={isEditing}
                      isHeightUnset
                      sectionLabel="Documents"
                      documents={documents}
                    />
                    {isEditing && (
                      <Button
                        buttonType="link"
                        className="room__action"
                        onClick={() => setIsAddDocumentOpen(true)}
                      >
                        <FontAwesomeIcon icon="plus" /> Add document
                      </Button>
                    )}
                  </div>
                )}
                <div className="room__section">
                  <h2>Information</h2>
                  {Object.entries(features)
                    .sort((a, b) => {
                      if (a[0] === 'Company') {
                        return -1;
                      }
                      if (b[0] === 'Company') {
                        return 1;
                      }
                      if (b[0] === 'Directors') {
                        return -1;
                      }
                      return 0;
                    })
                    .map(
                      ([resourceTypeKey, resourceType], index) =>
                        resourceTypeKey !== 'Document' && (
                          <ResourceTypeTable
                            key={`resource-type-${index}`}
                            access_code={room_access_code}
                            account_id={account_id}
                            index={index}
                            isEditing={isEditing}
                            resourceTypeKey={resourceTypeKey}
                            resourceType={resourceType}
                            room_id={id}
                            setAddFeatureMeta={setAddFeatureMeta}
                            setAddResourceMeta={setAddResourceMeta}
                          />
                        ),
                    )}
                </div>
                <div className="room__section">
                  {report_feature_types.map(report => (
                    <DataTable
                      key={`reports-${report.id}`}
                      {...report}
                      question_label={report.question_label}
                      isDataRoom
                      isEditing={isEditing}
                    />
                  ))}
                </div>
              </Route>
            </Switch>
          </div>
          {!pathname.includes('/settings') && (
            <InfoSidebar
              access_code={room_access_code}
              account_id={account_id}
              faqs={faqs}
              isDataRoom
              isEditing={isEditing}
              dataRoomLinks={links}
              room_id={id}
            />
          )}
        </div>
      )}
      {isEditing && (
        <AddFeatureModal
          access_code={room_access_code}
          account_id={account_id}
          features={addFeatureMeta.features}
          isOpen={addFeatureMeta.isOpen}
          rootBody={addFeatureMeta.rootBody}
          // Set to empty object if we're seeing flickering data
          onClose={() => setAddFeatureMeta({ ...addFeatureMeta, isOpen: false })}
        />
      )}
      {isEditing && (
        <AddResourceModal
          access_code={room_access_code}
          account_id={account_id}
          isOpen={addResourceMeta.isOpen}
          resourceTypeId={addResourceMeta.resource_type_id}
          rootBody={addResourceMeta.rootBody}
          // Set to empty object if we're seeing flickering data
          onClose={() => setAddResourceMeta({ ...addResourceMeta, isOpen: false })}
        />
      )}
      {isEditing && (
        <AddDocumentModal
          access_code={room_access_code}
          account_id={account_id}
          isOpen={isAddDocumentOpen}
          onClose={() => setIsAddDocumentOpen(false)}
          room_id={id}
        />
      )}
    </div>
  );
};

const ResourceTypeTable = ({
  access_code,
  account_id,
  index,
  isEditing,
  resourceTypeKey,
  resourceType,
  setAddFeatureMeta,
  setAddResourceMeta,
  room_id,
}) => {
  const dispatch = useDispatch();
  const [resourceTypeInput, setResourceTypeInput] = useState(resourceTypeKey);
  const [isEditingResourceType, setIsEditingResourceType] = useState(false);

  const handleEditResourceType = () => {
    if (!resourceTypeInput) {
      dispatch(setNotice({ type: 'error', message: 'Cannot update to empty string.' }));
      setIsEditingResourceType(false);
      setResourceTypeInput(resourceTypeKey);
    } else if (resourceTypeInput === resourceTypeKey) {
      setIsEditingResourceType(false);
    } else {
      dispatch(
        updateRoomFeatures(
          resourceType.resources,
          {
            resource_custom_label: resourceTypeInput,
          },
          account_id,
        ),
      ).then(e => {
        dispatch(setNotice('Name Updated'));
        dispatch(fetchRoom(access_code, account_id));
        setIsEditingResourceType(false);
      });
    }
  };
  return (
    <div className="room__resources">
      <h2 className="room__resourceType">
        {!isEditingResourceType && `${resourceTypeKey} Information`}
        {isEditingResourceType && (
          <InputStyledTextField
            value={resourceTypeInput}
            onChange={e => setResourceTypeInput(e.target.value)}
            name="room-resource-type-input"
            onBlur={handleEditResourceType}
          />
        )}
        {isEditing && (
          <DropdownAction
            Toggle={toggleProps => (
              <Button buttonType="icon" {...toggleProps}>
                <FontAwesomeIcon icon="ellipsis-v" />
              </Button>
            )}
          >
            <Button buttonType="secondary" onClick={() => setIsEditingResourceType(true)}>
              Edit Label
            </Button>
            <Button
              buttonType="secondary"
              isDisabled={resourceTypeKey === 'Company'}
              onClick={() =>
                setAddResourceMeta({
                  isOpen: true,
                  resource_type_id: resourceType.resource_type_id,
                  rootBody: {
                    room_id,
                    resource_variable_name: resourceType.resource_variable_name,
                    resource_custom_label: resourceTypeKey,
                  },
                })
              }
            >
              Add Resource
            </Button>
            <Button
              buttonType="secondary"
              isWarning
              onClick={() =>
                dispatch(deleteRoomFeatures(resourceType.resources, account_id)).then(
                  e => {
                    dispatch(setNotice('Table Removed'));
                    dispatch(fetchRoom(access_code, account_id));
                  },
                )
              }
            >
              Remove Table
            </Button>
          </DropdownAction>
        )}
      </h2>
      {Object.entries(resourceType.resources).map(
        ([resourceKey, resource], resourceIndex) => (
          <ResourceCard
            key={`resource-${resourceKey}-${resourceIndex}`}
            access_code={access_code}
            account_id={account_id}
            setAddFeatureMeta={setAddFeatureMeta}
            index={index}
            isEditing={isEditing}
            resourceMeta={resource || []}
            resourceIndex={resourceIndex}
            room_id={room_id}
          />
        ),
      )}
    </div>
  );
};

const ResourceCard = ({
  access_code,
  account_id,
  setAddFeatureMeta,
  index,
  isEditing,
  resourceMeta,
  resourceIndex,
  room_id,
}) => {
  const {
    features,
    resource,
    resource_custom_label,
    resource_id,
    resource_variable_name,
  } = resourceMeta;
  const dispatch = useDispatch();

  return (
    <div className="room__resource">
      <h2 className="room__resourceName">
        {resource}
        {isEditing && (
          <DropdownAction
            Toggle={toggleProps => (
              <Button buttonType="icon" size="sm" {...toggleProps}>
                <FontAwesomeIcon icon="ellipsis-v" />
              </Button>
            )}
          >
            <Button
              buttonType="secondary"
              onClick={() =>
                setAddFeatureMeta({
                  features,
                  isOpen: true,
                  rootBody: {
                    room_id,
                    resource_id: resource_id,
                    resource_variable_name: resource_variable_name,
                    resource_custom_label: resource_custom_label,
                    resource,
                  },
                })
              }
            >
              Add Fields
            </Button>
            <Button
              buttonType="secondary"
              isWarning
              onClick={() =>
                dispatch(deleteRoomFeatures(features, access_code, account_id)).then(
                  e => {
                    dispatch(setNotice('Resource Removed'));
                    dispatch(fetchRoom(access_code, account_id));
                  },
                )
              }
            >
              Remove Resource
            </Button>
          </DropdownAction>
        )}
      </h2>
      <div className="room__features">
        {features.map((feature, featureIndex) => (
          <div key={`resource-${resourceIndex}-${featureIndex}`}>
            <h4>
              {feature.feature_type.question_label}
              {isEditing && (
                <Button
                  buttonType="icon"
                  isWarning
                  onClick={() =>
                    dispatch(
                      deleteRoomFeature(feature.room_feature_id, access_code, account_id),
                    )
                  }
                  size="xs"
                >
                  <FontAwesomeIcon icon={['fal', 'trash-alt']} />
                </Button>
              )}
            </h4>
            {isArray(feature.value) &&
              feature.value.map((link, linkIndex) => (
                <p key={`review-link-${index}-${linkIndex}`}>
                  Link #{linkIndex + 1}:{' '}
                  <a href={link.url} target="_blank" rel="noopener noreferrer">
                    {link.label}
                  </a>
                </p>
              ))}
            {!isArray(feature.value) && <p>{feature.value}</p>}
          </div>
        ))}
      </div>
    </div>
  );
};

const RoomSettings = ({
  access_code,
  access_log,
  account_id,
  account_project_id,
  is_admin_user,
  label,
  open,
  require_auth,
  room_id,
  strict,
  users,
}) => {
  const dispatch = useDispatch();
  const [groupedLogsShowing, setIsGroupedLogsShowing] = useState(-1);
  const [isAddUserShowing, setIsAddUserShowing] = useState(false);
  const currentUser = useSelector(getAuthUser);
  const currentAccount = useSelector(getAuthAccount);
  const canEdit = account_id === currentAccount.accountId && is_admin_user;
  const canInvite =
    canEdit ||
    users.findIndex(
      e => e.id === currentUser.userId && (e.admin_user || e.invite_rights),
    ) !== -1;

  return (
    <>
      <h1>Settings</h1>
      <div className="room__section">
        <h2 className="room__subheader">
          Room
          {canEdit && (
            <>
              <Button
                buttonType="icon"
                size="xs"
                tooltip="Copy Data Room link"
                onClick={() => {
                  const { REACT_APP_BASEURL } = process.env;
                  navigator.clipboard
                    .writeText(`${REACT_APP_BASEURL}/data-room/${access_code}`)
                    .then(
                      () => dispatch(setNotice('Link Copied!')),
                      err => console.error('Could not copy text: ', err),
                    );
                }}
              >
                <FontAwesomeIcon icon={['fal', 'copy']} />
              </Button>
              <Button
                buttonType="icon"
                size="xs"
                tooltip="Go to connected project"
                to={handlePath(
                  `/workbench/0/${account_project_id}/-1/overview`,
                  account_id,
                )}
              >
                <FontAwesomeIcon icon={['fal', 'external-link']} />
              </Button>
            </>
          )}
        </h2>
        <h4>Name</h4>
        <p>{label}</p>
        <h4>Status</h4>
        <div className="room__tags">
          <p className={open ? 'low' : ''}>{open ? 'Open' : 'Closed'}</p>
          <p className={require_auth ? 'low' : ''}>
            {require_auth ? 'Sign-in required' : 'Sign-in optional'}
          </p>
          <p className={strict ? 'low' : ''}>
            {strict ? 'Invite only' : 'Invite optional'}
          </p>
          {canEdit && (
            <DropdownAction
              Toggle={toggleProps => (
                <Button buttonType="icon" size="xs" {...toggleProps}>
                  <FontAwesomeIcon icon="ellipsis-v" />
                </Button>
              )}
            >
              <Button
                buttonType="secondary"
                onClick={() =>
                  dispatch(updateRoom({ open: !open }, room_id, access_code, account_id))
                }
              >
                {open ? 'Close room' : 'Open link'}
              </Button>
              <Button
                buttonType="secondary"
                onClick={() =>
                  dispatch(
                    updateRoom(
                      { require_auth: !require_auth },
                      room_id,
                      access_code,
                      account_id,
                    ),
                  )
                }
              >{`${require_auth ? 'Optional' : 'Require'} sign-in`}</Button>
              <Button
                buttonType="secondary"
                onClick={() =>
                  dispatch(
                    updateRoom({ strict: !strict }, room_id, access_code, account_id),
                  )
                }
              >{`${strict ? 'Optional' : 'Require'} Invite`}</Button>
            </DropdownAction>
          )}
        </div>
      </div>
      <div className="room__section">
        <h2 className="room__subheader">Users</h2>
        {users.map((user, ind) => (
          <div className="room__user" key={`room-user-${user.id}`}>
            <h3>{`User #${ind + 1}`}</h3>
            <h4>Name</h4>
            <div className="room__userRow">
              <p>
                {user.name} ({user.email})
              </p>
              {canEdit && (
                <DropdownAction
                  Toggle={toggleProps => (
                    <Button buttonType="icon" size="xs" {...toggleProps}>
                      <FontAwesomeIcon icon="ellipsis-v" />
                    </Button>
                  )}
                >
                  <Button
                    buttonType="secondary"
                    onClick={() =>
                      dispatch(
                        updateRoomUser(
                          { invite_rights: !!user.invite_rights ? 0 : 1 },
                          user.room_user_id,
                          access_code,
                          account_id,
                        ),
                      )
                    }
                  >{`${!!user.invite_rights ? 'Revoke' : 'Allow'} Invite Rights`}</Button>
                  <Button
                    buttonType="secondary"
                    isWarning
                    isDisabled={!canEdit}
                    onClick={() =>
                      dispatch(deleteRoomUser(user.room_user_id, access_code, account_id))
                    }
                  >
                    Remove User
                  </Button>
                </DropdownAction>
              )}
            </div>
            <h4>Access rights</h4>
            <div className="room__tags">
              {currentUser.userId === user.id && is_admin_user && (
                <p className="low">Admin</p>
              )}
              {!!user.invite_rights && <p className="low">Can invite others</p>}
              {((currentUser.userId === user.id &&
                !is_admin_user &&
                !user.invite_rights) ||
                !user.invite_rights) && <p>None</p>}
            </div>
          </div>
        ))}
        {users.length === 0 && <h4>No users connected.</h4>}
        {canInvite && (
          <Button buttonType="link" size="xs" onClick={() => setIsAddUserShowing(true)}>
            + Add User
          </Button>
        )}
        {canEdit && (
          <div className="room__section">
            <h2 className="room__subheader">Access Logs</h2>
            {Object.entries(access_log).map(
              ([groupKey, { name = 'Anonymous', email, logs = [] }], ind) =>
                logs.length > 0 && (
                  <div key={`grouped-log-${ind}`} className="room__grouped-logs">
                    <h4>{`${name}${email ? ' (' + email + ')' : ''}`}</h4>
                    <p>
                      Last Visited:{' '}
                      {dayjs(logs[0].created_at).format('ddd, MMM YYYY hh:mm A')}
                    </p>
                    <p>Times Visited:{' ' + logs.length}</p>
                    <Button
                      buttonType="link"
                      size="xs"
                      onClick={e =>
                        setIsGroupedLogsShowing(groupedLogsShowing === ind ? -1 : ind)
                      }
                    >
                      {groupedLogsShowing === ind
                        ? `Hide ${name.split(' ')[0]}'s access logs`
                        : `Show ${name.split(' ')[0]}'s access logs`}
                    </Button>
                    {groupedLogsShowing === ind &&
                      logs.map((log, groupInd) => (
                        <div className="room__access-logs" key={`log-${groupInd}`}>
                          <h4>{`Date accessed #${logs.length - groupInd}`}</h4>
                          <p>{dayjs(log.created_at).format('ddd, MMM YYYY hh:mm A')}</p>
                        </div>
                      ))}
                  </div>
                ),
            )}
          </div>
        )}
      </div>
      <AddUserModal
        access_code={access_code}
        account_id={account_id}
        isOpen={isAddUserShowing}
        onClose={() => setIsAddUserShowing(false)}
        room_id={room_id}
      />
    </>
  );
};

const AddResourceModal = ({
  access_code,
  account_id,
  isOpen,
  onClose,
  resourceTypeId,
  rootBody = {},
}) => {
  const dispatch = useDispatch();

  const { resource_custom_label } = rootBody;

  const [selectedResource, setSelectedResource] = useState({});
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [isInit, setIsInit] = useState(true);
  const { value: resourceId } = selectedResource || {};
  const updatedResourceValue = isEmpty(selectedResource)
    ? { label: '', value: '' }
    : selectedResource;

  // const roomFeatures = useSelector(state => getRoomFeatures(state, resourceTypeId));
  const resourceList = useSelector(state =>
    getRoomResourceOptions(state, resourceTypeId),
  );
  const resourceFeatures = useSelector(state =>
    getRoomFeaturesAsOptions(state, resourceId),
  );

  useEffect(() => {
    if (isOpen && isInit) {
      dispatch(
        fetchFeaturesForRoom(1, `?resource_type_id=${resourceTypeId}`, account_id),
      );
      setIsInit(false);
    }
  }, [dispatch, account_id, isOpen, isInit, resourceTypeId]);

  useEffect(() => {
    if (resourceId) {
      dispatch(
        fetchFeaturesForRoom(resourceId, `?resource_id=${resourceId}`, account_id),
      );
    }
  }, [dispatch, account_id, resourceId]);

  const handleClose = () => {
    onClose();
  };

  const handleAddFeatures = () => {
    onClose();
    dispatch(
      createRoomFeatures(
        selectedFeatures,
        { ...rootBody, resource_id: selectedResource.value },
        account_id,
      ),
    ).then(e => {
      dispatch(setNotice('Resource Added'));
      dispatch(fetchRoom(access_code, account_id));
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className="room__modal room__modal--lg"
      overlayClassName="room__modal-overlay"
    >
      <FontAwesomeIcon
        className="room__modal-exit"
        onClick={handleClose}
        icon={['fal', 'times']}
      />
      <h2>Add Resource to {resource_custom_label}</h2>
      <InputSelect
        options={resourceList}
        onChange={e => setSelectedResource(e)}
        label="Select a resource"
        name="select-resource-list"
        value={updatedResourceValue}
      />
      <InputSelect
        options={resourceFeatures}
        isDisabled={isEmpty(selectedResource)}
        isFeatures
        isMulti
        label="Select the fields you want to show for this resource"
        name="select-features-list"
        onChange={e => setSelectedFeatures(e)}
        value={selectedFeatures}
      />
      <div className="room__modalActions">
        <Button buttonType="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button onClick={handleAddFeatures}>
          Add Field{selectedFeatures.length > 1 ? 's' : ''}
        </Button>
      </div>
    </Modal>
  );
};

const AddFeatureModal = ({
  access_code,
  account_id,
  features,
  isOpen,
  onClose,
  rootBody = {},
}) => {
  const dispatch = useDispatch();

  const { resource, resource_id } = rootBody;

  const resourceFeatures = useSelector(state =>
    getRoomFeaturesAsOptions(state, resource_id, features),
  );

  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [isInit, setIsInit] = useState(true);

  useEffect(() => {
    if (isOpen && isInit) {
      dispatch(
        fetchFeaturesForRoom(resource_id, `?resource_id=${resource_id}`, account_id),
      );
      setIsInit(false);
    }
  }, [dispatch, account_id, isOpen, isInit, resource_id]);

  const handleClose = () => {
    setSelectedFeatures([]);
    onClose();
    setIsInit(true);
  };

  const handleAddFeatures = () => {
    handleClose();
    let updatedBody = { ...rootBody };
    delete updatedBody.resource;
    dispatch(createRoomFeatures(selectedFeatures, updatedBody, account_id)).then(e => {
      dispatch(setNotice('Fields Added'));
      dispatch(fetchRoom(access_code, account_id));
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className="room__modal"
      overlayClassName="room__modal-overlay"
    >
      <FontAwesomeIcon
        className="room__modal-exit"
        onClick={handleClose}
        icon={['fal', 'times']}
      />
      <h2>Add Fields to {resource}</h2>
      <InputSelect
        options={resourceFeatures}
        isFeatures
        isMulti
        label={`Select any additional features to show for ${resource}`}
        name="select-features-list"
        onChange={e => setSelectedFeatures(e)}
        value={selectedFeatures}
      />
      <div className="room__modalActions">
        <Button buttonType="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button onClick={handleAddFeatures}>
          Add Field{selectedFeatures.length > 1 ? 's' : ''}
        </Button>
      </div>
    </Modal>
  );
};

const Option = props => (
  <div
    className={`room__modal-option-wrapper${
      props.isSelected ? ' room__modal-option-wrapper--selected' : ''
    }`}
  >
    <components.Option {...props} />
    <h4 className="room__modal-option">{props.data.stateText}</h4>
  </div>
);

const AddDocumentModal = ({ access_code, account_id, isOpen, onClose, room_id }) => {
  const dispatch = useDispatch();

  const documents = useSelector(getAllDocumentsForSelect);
  const boxToken = useSelector(BoxToken);

  const uploaderRef = useRef(null);

  const [initialized, setInitialized] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState('');
  const [folderId, setFolderId] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    if (isOpen && !initialized) {
      dispatch(getAllDocuments(account_id));
      dispatch(
        getBoxTokenFolderId('/Miscellaneous/Data Room', false, access_code, account_id),
      )
        .then(payload => setFolderId(payload.upload_folder_id))
        .catch(error => {
          console.error(error);
          setFolderId('0');
        });
      setInitialized(true);
    }
  }, [access_code, dispatch, account_id, isOpen, initialized]);

  const handleClose = () => {
    setSelectedDoc('');
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className="room__modal room__modal--lg"
      overlayClassName="room__modal-overlay"
    >
      <FontAwesomeIcon
        className="room__modal-exit"
        onClick={handleClose}
        icon={['fal', 'times']}
      />
      <h2>Add Document from File Cabinet</h2>
      <InputSelect
        components={{ Option }}
        options={documents}
        isFeatures
        label={`Select from documents in your File Cabinet`}
        name="select-features-list"
        onChange={e => {
          setSelectedDoc(e);
          setIsUploading(false);
          setError(false);
        }}
        value={selectedDoc}
      />
      {isUploading && folderId && (
        <ContentUploader
          fileLimit={1}
          rootFolderId={folderId}
          onBeforeUpload={e => {
            setTimeout(() =>
              uploaderRef.current.lastElementChild.lastElementChild.lastElementChild.click(),
            );
          }}
          onUpload={e =>
            dispatch(
              uploadBoxFile(
                e.id,
                { filename: e.name, path: '/Miscellaneous/Data Room' },
                false,
                account_id,
              ),
            ).then(payload => {
              setSelectedDoc({
                ...payload.document_history,
                label: payload.document_history.filename,
                value: payload.document_history.box_file_id,
                stateText: 'Uploaded',
              });
              dispatch(getAllDocuments(account_id));
              dispatch(setNotice('File uploaded.'));
              setIsUploading(false);
            })
          }
          token={boxToken}
          measureRef={uploaderRef}
        />
      )}
      <Button buttonType="link" onClick={() => setIsUploading(!isUploading)} size="sm">
        {isUploading ? 'Cancel file upload' : 'Upload File'}
      </Button>
      {!isEmpty(selectedDoc) && !isUploading && (
        <>
          <h4>Selected Document</h4>
          <p>{selectedDoc.label || selectedDoc.filename}</p>
          <h4>Status</h4>
          <p>{selectedDoc.stateText || 'Uploaded'}</p>
          {error && (
            <p>
              <FontAwesomeIcon icon="exclamation-circle" />
              {error.message}
            </p>
          )}
          {selectedDoc.box_file_id && !error && (
            <div className="room__modal-preview">
              <ContentPreview
                fileId={selectedDoc.box_file_id}
                token={boxToken}
                responseInterceptor={e => {
                  if (e.type === 'error') {
                    setError(e);
                  }
                  return e;
                }}
              />
            </div>
          )}
        </>
      )}
      <div className="room__modalActions">
        <Button buttonType="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          isDisabled={isEmpty(selectedDoc)}
          onClick={() => {
            if (!isEmpty(selectedDoc)) {
              dispatch(
                createRoomDocument(
                  { document_history_id: selectedDoc.id, room_id },
                  access_code,
                  account_id,
                ),
              ).then(handleClose);
            }
          }}
        >
          Add Document
        </Button>
      </div>
    </Modal>
  );
};

const AddUserModal = ({ access_code, account_id, isOpen, onClose, room_id }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => {
    setEmail('');
    onClose();
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className="room__modal room__modal--sm"
      overlayClassName="room__modal-overlay"
    >
      <FontAwesomeIcon
        className="room__modal-exit"
        onClick={handleClose}
        icon={['fal', 'times']}
      />
      <h2>Add User to Room</h2>
      <InputStyledTextField
        onChange={e => setEmail(e.target.value)}
        defaultPlaceholder="ex. myname@gmail.com"
        value={email}
        label="user email:"
        name="user_email"
      />
      <div className="room__modalActions">
        <Button
          isDisabled={isSubmitting}
          isFetching={isSubmitting}
          onClick={() => {
            setIsSubmitting(true);
            dispatch(addRoomUser({ room_id, email }, access_code, account_id)).then(
              e => {
                setIsSubmitting(false);
                handleClose();
              },
              error => setIsSubmitting(false),
            );
          }}
        >
          Add User
        </Button>
      </div>
    </Modal>
  );
};

export default Room;
