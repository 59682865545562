export const CLEAR_ERRORS = 'savvi/user/CLEAR_ERRORS';

export const SET_INIT_LOADING = 'savvi/user/SET_INIT_LOADING';

// There are three possible states for our login
// process and we need actions for each of them
export const LOGIN_REQUEST = 'savvi/user/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'savvi/user/LOGIN_SUCCESS';
export const LOGIN_FAILED = 'savvi/user/LOGIN_FAILED';

export const TOKEN_REQUEST = 'savvi/user/TOKEN_REQUEST';
export const TOKEN_SUCCESS = 'savvi/user/TOKEN_SUCCESS';
export const TOKEN_FAILED = 'savvi/user/TOKEN_FAILED';

export const SEND_PW_RESET_REQUEST = 'savvi/user/SEND_PW_RESET_REQUEST';
export const SEND_PW_RESET_SUCCESS = 'savvi/user/SEND_PW_RESET_SUCCESS';
export const SEND_PW_RESET_FAILED = 'savvi/user/SEND_PW_RESET_FAILED';

export const RESET_PASSWORD_REQUEST = 'savvi/user/RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'savvi/user/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'savvi/user/SEND_PW_RESET_FAILED';

// Three possible states for our logout process as well.
export const LOGOUT = 'savvi/user/LOGOUT';
export const LOGOUT_REQUEST = 'savvi/user/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'savvi/user/LOGOUT_SUCCESS';
export const LOGOUT_FAILED = 'savvi/user/LOGOUT_FAILED';

// Set User
export const SET_ACCOUNT = 'savvi/user/SET_ACCOUNT';
export const SET_USER = 'savvi/user/SET_USER';
export const SET_BOX_TOKEN = 'savvi/user/SET_BOX_TOKEN';
export const REMOVE_EMAIL_VERIFICATION = 'savvi/user/REMOVE_EMAIL_VERIFICATION';

// Registration Actions
export const REGISTER_REQUEST = 'savvi/user/REGISTER_REQUEST';
export const REGISTER_FAILED = 'savvi/user/REGISTER_FAILED';
export const REGISTER_SUCCESS = 'savvi/user/REGISTER_SUCCESS';

export const CREATE_USER_REQUEST = 'savvi/user/CREATE_USER_REQUEST';
export const CREATE_USER_FAILED = 'savvi/user/CREATE_USER_FAILED';
export const CREATE_USER_SUCCESS = 'savvi/user/CREATE_USER_SUCCESS';

export const CREATE_COMPANY_REQUEST = 'savvi/user/CREATE_COMPANY_REQUEST';
export const CREATE_COMPANY_FAILED = 'savvi/user/CREATE_COMPANY_FAILED';
export const CREATE_COMPANY_SUCCESS = 'savvi/user/CREATE_COMPANY_SUCCESS';

// Checkpoint Actions

export const GET_CHECKPOINTS_REQUEST = 'savvi/user/GET_CHECKPOINTS_REQUEST';
export const GET_CHECKPOINTS_FAILED = 'savvi/user/GET_CHECKPOINTS_FAILED';
export const GET_CHECKPOINTS_SUCCESS = 'savvi/user/GET_CHECKPOINTS_SUCCESS';

export const SET_CHECKPOINT_REQUEST = 'savvi/user/SET_CHECKPOINT_REQUEST';
export const SET_CHECKPOINT_FAILED = 'savvi/user/SET_CHECKPOINT_FAILED';
export const SET_CHECKPOINT_SUCCESS = 'savvi/user/SET_CHECKPOINT_SUCCESS';

export const SET_CHECKPOINTS = 'savvi/user/SET_CHECKPOINTS';

// Registration Actions
export const EMAIL_CONFIRM_REQUEST = 'savvi/user/EMAIL_CONFIRM_REQUEST';
export const EMAIL_CONFIRM_FAILED = 'savvi/user/EMAIL_CONFIRM_FAILED';
export const EMAIL_CONFIRM_SUCCESS = 'savvi/user/EMAIL_CONFIRM_SUCCESS';

export const RESEND_EMAIL_CONFIRM_REQUEST = 'savvi/user/RESEND_EMAIL_CONFIRM_REQUEST';
export const RESEND_EMAIL_CONFIRM_FAILED = 'savvi/user/RESEND_EMAIL_CONFIRM_FAILED';
export const RESEND_EMAIL_CONFIRM_SUCCESS = 'savvi/user/RESEND_EMAIL_CONFIRM_SUCCESS';

export const COMPLETE_ONBOARD_REQUEST = 'savvi/formation/COMPLETE_ONBOARD_REQUEST';
export const COMPLETE_ONBOARD_FAILED = 'savvi/formation/COMPLETE_ONBOARD_FAILED';
export const COMPLETE_ONBOARD_SUCCESS = 'savvi/formation/COMPLETE_ONBOARD_SUCCESS';

// Box Token Actions
export const BOX_TOKEN_REQUEST = 'savvi/user/BOX_TOKEN_REQUEST';
export const BOX_TOKEN_SUCCESS = 'savvi/user/BOX_TOKEN_SUCCESS';
export const BOX_TOKEN_FAILED = 'savvi/user/BOX_TOKEN_FAILED';

// Post Hubspot Actions
export const SET_HUBSPOT_REQUEST = 'savvi/user/SET_HUBSPOT_REQUEST';
export const SET_HUBSPOT_SUCCESS = 'savvi/user/SET_HUBSPOT_SUCCESS';
export const SET_HUBSPOT_FAILED = 'savvi/user/SET_HUBSPOT_FAILED';

// Socket.io Actions
export const SET_NOTIFICATIONS = 'savvi/user/SET_NOTIFICATIONS';

// User Actions
export const CREATE_NEW_USER_REQUEST = 'savvi/user/CREATE_NEW_USER_REQUEST';
export const CREATE_NEW_USER_SUCCESS = 'savvi/user/CREATE_NEW_USER_SUCCESS';
export const CREATE_NEW_USER_FAILED = 'savvi/user/CREATE_NEW_USER_FAILED';

export const CHANGE_OWNER_REQUEST = 'savvi/user/CHANGE_OWNER_REQUEST';
export const CHANGE_OWNER_SUCCESS = 'savvi/user/CHANGE_OWNER_SUCCESS';
export const CHANGE_OWNER_FAILED = 'savvi/user/CHANGE_OWNER_FAILED';

export const DELETE_USER_REQUEST = 'savvi/user/DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'savvi/user/DELETE_USER_SUCCESS';
export const DELETE_USER_FAILED = 'savvi/user/DELETE_USER_FAILED';
// Partner Types
export const CONNECT_PARTNER_REQUEST = 'savvi/user/CONNECT_PARTNER_REQUEST';
export const CONNECT_PARTNER_SUCCESS = 'savvi/user/CONNECT_PARTNER_SUCCESS';
export const CONNECT_PARTNER_FAILED = 'savvi/user/CONNECT_PARTNER_FAILED';

export const REMOVE_PARTNER_REQUEST = 'savvi/user/REMOVE_PARTNER_REQUEST';
export const REMOVE_PARTNER_SUCCESS = 'savvi/user/REMOVE_PARTNER_SUCCESS';
export const REMOVE_PARTNER_FAILED = 'savvi/user/REMOVE_PARTNER_FAILED';

export const GET_PARTNER_BY_CODE_REQUEST = 'savvi/user/GET_PARTNER_BY_CODE_REQUEST';
export const GET_PARTNER_BY_CODE_SUCCESS = 'savvi/user/GET_PARTNER_BY_CODE_SUCCESS';
export const GET_PARTNER_BY_CODE_FAILED = 'savvi/user/GET_PARTNER_BY_CODE_FAILED';
export const SET_PARTNER_BY_CODE = 'savvi/user/SET_PARTNER_BY_CODE';
export const SET_PARTNER_CODE = 'savvi/user/SET_PARTNER_CODE';

// Validate Email
export const VALIDATE_EMAIL_REQUEST = 'savvi/user/VALIDATE_EMAIL_REQUEST';
export const VALIDATE_EMAIL_SUCCESS = 'savvi/user/VALIDATE_EMAIL_SUCCESS';
export const VALIDATE_EMAIL_FAILED = 'savvi/user/VALIDATE_EMAIL_FAILED';

export const REQUEST_VERIFICATION_EMAIL_REQUEST =
  'savvi/user/REQUEST_VERIFICATION_EMAIL_REQUEST';
export const REQUEST_VERIFICATION_EMAIL_SUCCESS =
  'savvi/user/REQUEST_VERIFICATION_EMAIL_SUCCESS';
export const REQUEST_VERIFICATION_EMAIL_FAILED =
  'savvi/user/REQUEST_VERIFICATION_EMAIL_FAILED';

export const CHECK_VERIFICATION_STATUS_REQUEST =
  'savvi/user/CHECK_VERIFICATION_STATUS_REQUEST';
export const CHECK_VERIFICATION_STATUS_SUCCESS =
  'savvi/user/CHECK_VERIFICATION_STATUS_SUCCESS';
export const CHECK_VERIFICATION_STATUS_FAILED =
  'savvi/user/CHECK_VERIFICATION_STATUS_FAILED';

export const CHECK_SECRET_STATUS_REQUEST = 'savvi/user/CHECK_SECRET_STATUS_REQUEST';
export const CHECK_SECRET_STATUS_SUCCESS = 'savvi/user/CHECK_SECRET_STATUS_SUCCESS';
export const CHECK_SECRET_STATUS_FAILED = 'savvi/user/CHECK_SECRET_STATUS_FAILED';

export const FINALIZE_EMAIL_VERIFICATION_REQUEST =
  'savvi/user/FINALIZE_EMAIL_VERIFICATION_REQUEST';
export const FINALIZE_EMAIL_VERIFICATION_SUCCESS =
  'savvi/user/FINALIZE_EMAIL_VERIFICATION_SUCCESS';
export const FINALIZE_EMAIL_VERIFICATION_FAILED =
  'savvi/user/FINALIZE_EMAIL_VERIFICATION_FAILED';

export const EXCHANGE_CHALLENGE_REQUEST = 'savvi/user/EXCHANGE_CHALLENGE_REQUEST';
export const EXCHANGE_CHALLENGE_SUCCESS = 'savvi/user/EXCHANGE_CHALLENGE_SUCCESS';
export const EXCHANGE_CHALLENGE_FAILED = 'savvi/user/EXCHANGE_CHALLENGE_FAILED';
