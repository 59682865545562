import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import get from 'lodash-es/get';

import Button from '../../components/common/Button';
import LandingTopbar from '../../components/LandingTopbar';
import {
  DocumentManagementDict,
  IntegratedServicesDict,
  KnowledgeLibraryDict,
  PortfolioManagementDict,
  SubscriptionsFeaturesDict,
  TaskManagementDict,
} from '../../utils/SubscriptionsDict';
import FeaturesSection from '../SubscriptionsModal/SubscriptionsFeaturesSection';

const SubscriptionsModal = ({ history }) => {
  // const [isMonthly, setMonthly] = useState(false);
  // const handleSetMonthly = isSetMonthly => {
  //   if (isSetMonthly !== isMonthly) {
  //     setMonthly(isSetMonthly);
  //   }
  // };
  const onClose = () => history.goBack();

  const optionsIndex = 0;
  // const optionsIndex = isMonthly ? 1 : 0;

  return (
    <div className="subscriptions-view">
      <LandingTopbar />
      <div className="subscriptions-view__wrapper">
        <div className="subscriptions-view-features">
          <div className="subscriptions-view-features__headers">
            <div className="subscriptions-view-features__header subscriptions-view-features__billing">
              <h3>Savvi Subscriptions</h3>
              <div className="subscriptions-view-features__divider" />
              <div className="subscriptions-view-features__options">
                <div
                  className={classnames('subscriptions-view-features__option', {
                    selected: true,
                    // selected: !isMonthly,
                  })}
                  // onClick={() => handleSetMonthly(false)}
                >
                  {/* <div className="subscriptions-view-features__option--icon">
                    <FontAwesomeIcon icon="circle" />
                  </div>
                  <h4>Billed Annually*</h4> */}
                </div>
                {/* <div
                  className={classnames('subscriptions-view-features__option', {
                    selected: isMonthly,
                  })}
                  onClick={() => handleSetMonthly(true)}
                >
                  <div className="subscriptions-view-features__option--icon">
                    <FontAwesomeIcon icon="circle" />
                  </div>
                  <h4>Billed Monthly</h4>
                </div> */}
              </div>
            </div>
            {SubscriptionsFeaturesDict.map((feature, ind) => {
              let valueLabel = get(feature, `options[${optionsIndex}].label`, '');
              return (
                <div
                  key={`features-header--${ind}`}
                  className="subscriptions-view-features__header simple"
                >
                  <h3>{feature.header}</h3>
                  <div className="subscriptions-view-features__divider" />
                  <h4 id="feature-price">{valueLabel}</h4>
                </div>
              );
            })}
          </div>
          <h3 className="subscriptions-view__header">Features</h3>
          <FeaturesSection
            featuresDict={KnowledgeLibraryDict}
            isSingleColumn
            sectionDescription="Access a customizable library of curated templates and connected workflows."
            sectionTitle="Knowledge Library"
          />
          <FeaturesSection
            featuresDict={DocumentManagementDict}
            isSingleColumn
            sectionDescription="One place for legal teams to create, execute, track, store, and collaborate on legal documents and templates."
            sectionTitle="Document Management"
          />
          <FeaturesSection
            featuresDict={TaskManagementDict}
            isSingleColumn
            sectionDescription="A shared workspace for in-house and oustide legal teams to streamline and track legal tasks."
            sectionTitle="Task Management"
          />
          <FeaturesSection
            featuresDict={PortfolioManagementDict}
            isSingleColumn
            sectionDescription="Manage your portfolio of entities in Savvi: designed for attorneys, investors, and serial founders."
            sectionTitle="Portfolio Management"
          />
          <FeaturesSection
            featuresDict={IntegratedServicesDict}
            isSingleColumn
            sectionDescription="Access curated services to complement existing providers and ensure that all the back-office needs are met."
            sectionTitle="Integrated Services"
          />
          {/* <h4>
            *For monthly billing options, please contact us at{' '}
            <a href="mailto:subscriptions@savvi.legal">subscriptions@savvi.legal</a>.
          </h4> */}
        </div>
      </div>
      <div className="subscriptions-view__buttons">
        <Button buttonType="secondary" onClick={onClose} size="wide">
          Back
        </Button>
        <Button
          href="https://app.savvi.legal/register"
          target="_blank"
          rel="noopener noreferrer"
          size="wide"
        >
          Register
        </Button>
      </div>
    </div>
  );
};

SubscriptionsModal.propTypes = {
  formData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string, //TODO: clean up Review PropTypes
            value: PropTypes.string,
          }),
        ),
      ]),
    }),
  ),
  title: PropTypes.string,
};

export default SubscriptionsModal;
