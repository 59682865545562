import React, { forwardRef, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import { InputFeedback, InputHelp, InputSublabel } from '../';

import './InputPassword.scss';

const bem = elementName => `input-password${elementName ? '__' + elementName : ''}`;

const InputPassword = forwardRef(
  (
    {
      autoComplete,
      error,
      hasToggle,
      icon,
      inputClass,
      isDataHidden,
      isDisabled,
      isFixed,
      isPlainText,
      isRequired,
      label,
      name,
      onBlur,
      onChange,
      onFocus,
      onToggle,
      questionHelp,
      sublabel,
      type,
      value,
    },
    ref,
  ) => {
    const defaultRef = useRef(null);
    const inputRef = ref || defaultRef;
    const toggleIcon = !isPlainText ? 'eye' : 'eye-slash';
    let updatedType = type;

    const [isActive, setIsActive] = useState(!!value);

    const wrapperClasses = classnames(bem('wrapper'), {
      [bem('wrapper--error')]: !!error,
      [bem('wrapper--hidden')]: isDataHidden,
      [bem('wrapper--disabled')]: isFixed || isDisabled,
      [bem('wrapper--active')]: isActive,
    });
    if (type === 'password') {
      updatedType = isPlainText ? 'text' : 'password';
    }

    useEffect(() => {
      if (!isActive && !!value.trim()) {
        setIsActive(true);
      }
    }, [isActive, value]);

    return (
      <div className={bem('container')}>
        <div className={wrapperClasses}>
          <label className={bem('label')} htmlFor={name}>
            {`${label}${isRequired ? '*' : ''}`}
            {questionHelp && <InputHelp text={questionHelp} />}
          </label>
          <input
            autoComplete={autoComplete}
            className={classnames(inputClass, bem(), {
              [bem('control')]: !isPlainText,
              'input-password--has-icon-left': !!icon,
            })}
            name={name}
            id={name}
            onBlur={e => {
              if (!e.target.value) {
                setIsActive(false);
              }
              onBlur(e);
            }}
            onChange={onChange}
            onFocus={e => {
              setIsActive(true);
              onFocus(e);
            }}
            ref={inputRef}
            type={updatedType}
            value={value}
          />
          {hasToggle && (
            <div className={bem('toggle')} onClick={onToggle}>
              <FontAwesomeIcon icon={toggleIcon} />
            </div>
          )}
        </div>
        <InputSublabel>{sublabel}</InputSublabel>
        <InputFeedback error={error} />
      </div>
    );
  },
);

InputPassword.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  hasToggle: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isPlainText: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onToggle: PropTypes.func,
  placeholder: PropTypes.string,
  questionHelp: PropTypes.string,
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
};

InputPassword.defaultProps = {
  autoComplete: 'current-password',
  hasToggle: false,
  icon: 'lock',
  isPlainText: false,
  label: 'label',
  name: 'password-input',
  onBlur: () => null,
  onChange: () => null,
  onFocus: () => null,
  onToggle: () => null,
  placeholder: '',
  sublabel: null,
  type: 'password',
};

export default InputPassword;
