import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PageLoader from '../../components/PageLoader';
import { SavviNavLinkBar } from '../../components/SavviNavLink';
import TodosSection from '../../components/TodosSection';
import AllDocsSection from '../../components/AllDocsSection';
import ModuleDict from '../../utils/ModuleDict';
import { Button } from '../../components/common';
import TasksSection from '../../components/TasksSection';

import './Home.scss';
import handlePath from '../../utils/handlePath';

const Home = ({
  companyId,
  handleDeleteProject,
  hash,
  homeNavLinkItems,
  isAllEmpty,
  isDataLoaded,
  moduleId,
  myDocuments,
  myFilterInput,
  myTasks,
  myTodos,
  setMyFilterInput,
}) => (
  <div className="dashboard__content">
    <div className="dashboard__inner">
      <label className="dashboard__search">
        <FontAwesomeIcon className="dashboard__searchIcon" icon={['fal', 'search']} />
        <input
          placeholder="Search Dashboard"
          value={myFilterInput}
          name="workflowsFilter"
          onChange={e => setMyFilterInput(e.target.value)}
        />
        <FontAwesomeIcon
          className="dashboard__searchClear"
          icon={['fal', 'times']}
          onClick={() => setMyFilterInput('')}
        />
      </label>
      <SavviNavLinkBar
        navLinkItems={homeNavLinkItems}
        parentPathname={ModuleDict[moduleId].path}
      />
      {!isDataLoaded && <PageLoader />}
      {isDataLoaded && (
        <>
          {isAllEmpty && (
            <h3 className="dashboard__empty">
              No current Action Items. Begin a new
              <Button
                buttonType="link"
                to={handlePath(ModuleDict['workflows'].path, companyId)}
              >
                Workflow
              </Button>
              or view your
              <Button
                buttonType="link"
                to={handlePath(ModuleDict['tasks'].path, companyId)}
              >
                Tasks
              </Button>
            </h3>
          )}
          {(!hash.slice(1) || '#actionable-documents' === hash) &&
            myDocuments.length > 0 && (
              <AllDocsSection
                isWarning
                moduleId={moduleId}
                sectionLabel="Documents Requiring Action"
                documents={myDocuments}
              />
            )}
          {(!hash.slice(1) || '#to-dos' === hash) && myTodos.length > 0 && (
            <TodosSection todos={myTodos} />
          )}
          {(!hash.slice(1) || '#open-tasks' === hash) && myTasks.length > 0 && (
            <TasksSection
              handleDeleteProject={handleDeleteProject}
              isSectionOpenInit={false}
              isHeightUnset
              moduleId={moduleId}
              sectionLabel="Open Tasks"
              transactions={myTasks}
            />
          )}
        </>
      )}
    </div>
  </div>
);

Home.propTypes = {
  actionableDocs: PropTypes.array,
  isDataLoaded: PropTypes.bool,
  isLoadingInitTimeout: PropTypes.bool,
  location: PropTypes.object,
  moduleId: PropTypes.string,
  homeNavLinkItems: PropTypes.array,
  todos: PropTypes.array,
};

export default Home;
