import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import { clearNotice } from '../../../redux/modules/UI/actions';
import { getNotices } from '../../../redux/modules/UI/selectors';

import './NoticeToast.scss';

class NoticeToast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hide: false,
    };
    this.timeoutNotice = null;
    this.timeoutClear = null;
  }

  componentDidMount() {
    const {
      notice: { delay, type },
    } = this.props;
    let noticeDelay =
      delay || ((type === 'error' || type === 'warning') && 15000) || 7000;
    this.timeoutNotice = setTimeout(() => this.setState({ hide: true }), noticeDelay);
  }

  componentDidUpdate() {
    if (this.state.hide) {
      this.timeoutClear = setTimeout(
        () => this.props.clearNotice(this.props.notice),
        5000,
      );
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutNotice);
    clearTimeout(this.timeoutClear);
  }

  render() {
    const { clearNotice, notice } = this.props;
    const { hide } = this.state;
    return (
      <div
        className={classnames('notice', {
          hide,
          error: notice.type === 'error',
          warning: notice.type === 'warning',
        })}
      >
        {notice.message}
        <FontAwesomeIcon
          className="notice__close"
          icon={['fal', 'times']}
          onClick={() => clearNotice(notice)}
        />
      </div>
    );
  }
}

class NoticeToasts extends Component {
  render() {
    const { clearNotice, notices } = this.props;
    return (
      <div className="notices">
        {notices.map((notice, ind) => (
          <NoticeToast key={`notice--${ind}`} notice={notice} clearNotice={clearNotice} />
        ))}
      </div>
    );
  }
}

NoticeToasts.propTypes = {
  notices: PropTypes.array.isRequired,
};

NoticeToasts.defaultProps = {
  notices: [],
};

const mapStateToProps = ({ UI }) => ({ notices: getNotices(UI) });

const mapDispatchToProps = dispatch => ({
  clearNotice: notice => dispatch(clearNotice(notice)),
});

const enhance = connect(mapStateToProps, mapDispatchToProps);

export default enhance(NoticeToasts);
