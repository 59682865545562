import * as Types from './types';

export function clearDocumentError(error) {
  return {
    type: Types['CLEAR_DOCUMENT_ERROR'],
    error,
  };
}

export function setDocumentError(error) {
  return {
    type: Types['SET_DOCUMENT_ERROR'],
    error,
  };
}

export function setAllDocuments(payload) {
  return {
    type: Types['SET_ALL_DOCUMENTS'],
    payload,
  };
}

export function setDocumentRecipientsLinks(links, documentId) {
  return {
    type: Types['SET_DOCUMENT_RECIPIENTS_LINKS'],
    links,
    documentId,
  };
}

export function setDocuments(payload, taskId) {
  return {
    type: Types['SET_DOCUMENTS'],
    payload,
    taskId,
  };
}

export function setDocumentsByProject(payload, projectId) {
  return {
    type: Types['SET_DOCUMENTS_BY_PROJECT'],
    payload,
    projectId,
  };
}

export function setDocumentsByTransaction(payload, transactionId) {
  return {
    type: Types['SET_DOCUMENTS_BY_TRANSACTION'],
    payload,
    transactionId,
  };
}

// export function addCustomDocument(payload, transactionId) {
//   return {
//     type: Types['ADD_CUSTOM_DOCUMENT'],
//     payload,
//     transactionId,
//   };
// }

// export function setCustomDocument(payload, customDocIndex, transactionId) {
//   return {
//     type: Types['SET_CUSTOM_DOCUMENT'],
//     payload,
//     customDocIndex,
//     transactionId,
//   };
// }
