import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';

import { getAllDocuments } from '../../redux/modules/Document/operations';
import { getActionableDocuments } from '../../redux/modules/Document/selectors';
import { deleteAccountProject } from '../../redux/modules/Project/operations';
import { getTodos } from '../../redux/modules/Todos/operations';
import { Todos } from '../../redux/modules/Todos/selectors';
import { getAccountTransactions } from '../../redux/modules/Transaction/operations';
import { getMissingInfoTransactions } from '../../redux/modules/Transaction/selectors';
import { someValuesEveryString } from '../../utils/FilterTools';
import Home from './Home';

const HomeContainer = ({ moduleId }) => {
  const { push } = useHistory();
  const { hash, search } = useLocation();
  const { companyId } = useParams();
  const dispatch = useDispatch();

  const openTasks = useSelector(state =>
    getMissingInfoTransactions(state.Transaction, moduleId),
  );
  const actionableDocs = useSelector(getActionableDocuments);
  const todos = useSelector(Todos);

  const [isLoadingInitialTimeout, setLoadingInitialTimeout] = useState(false);

  const [myDocuments, setMyDocuments] = useState(actionableDocs);
  const [myTasks, setMyTasks] = useState(openTasks);
  const [myTodos, setMyTodos] = useState(todos);
  const [myFilterInput, setMyFilterInput] = useState('');
  const [myFilter, setMyFilter] = useState('');

  const handleDeleteProject = id => {
    const message = 'Are you sure you want to delete this transaction?';
    if (window.confirm(message)) {
      dispatch(deleteAccountProject(id)).then(() => {
        dispatch(getAccountTransactions());
      });
    }
  };

  const setFilter = useCallback(
    filterInput => {
      const updatedDocuments = actionableDocs.filter(doc =>
        someValuesEveryString(doc, [filterInput]),
      );
      const updatedTasks = openTasks.filter(todo =>
        someValuesEveryString(todo, [filterInput]),
      );
      const updatedTodos = todos.filter(todo =>
        someValuesEveryString(todo, [filterInput]),
      );
      setMyFilter(filterInput);
      setMyDocuments(updatedDocuments);
      setMyTasks(updatedTasks);
      setMyTodos(updatedTodos);
    },
    [actionableDocs, openTasks, todos],
  );

  useEffect(() => {
    const loadingInitTimeout = setTimeout(() => setLoadingInitialTimeout(false), 1000);
    return () => {
      clearTimeout(loadingInitTimeout);
    };
  }, []);

  useEffect(() => {
    const redirect = new URLSearchParams(search).get('redirect');
    if (redirect) {
      return push(redirect);
    }
  }, [push, search]);

  useEffect(() => {
    dispatch(getTodos());
    dispatch(getAllDocuments());
    dispatch(getAccountTransactions(moduleId));
  }, [dispatch, moduleId]);

  useEffect(() => {
    setMyDocuments(actionableDocs);
  }, [actionableDocs]);
  useEffect(() => {
    setMyTodos(todos);
  }, [todos]);
  useEffect(() => {
    setMyTasks(openTasks);
  }, [openTasks]);

  useEffect(() => {
    if (myFilterInput !== myFilter) {
      setFilter(myFilterInput);
    }
  }, [myFilter, myFilterInput, setFilter]);

  const isAllEmpty =
    myTasks.length === 0 && myDocuments.length === 0 && myTodos.length === 0;

  const homeNavLinkItems = [
    {
      id: 'to_dos',
      isItemLengthShowing: true,
      itemLength: myTodos.length,
      label: 'To-dos',
      path: {
        pathname: '/home',
        hash: '#to-dos',
      },
    },
    {
      id: 'actionable_documents',
      isItemLengthShowing: true,
      itemLength: myDocuments.length,
      label: 'Documents Requiring Actions',
      path: {
        pathname: '/home',
        hash: '#actionable-documents',
      },
    },
    {
      id: 'open_tasks',
      isItemLengthShowing: true,
      itemLength: myTasks.length,
      label: 'Open Tasks',
      path: {
        pathname: '/home',
        hash: '#open-tasks',
      },
    },
  ];

  return (
    <Home
      companyId={companyId}
      myDocuments={myDocuments}
      handleDeleteProject={handleDeleteProject}
      hash={hash}
      homeNavLinkItems={homeNavLinkItems}
      isAllEmpty={isAllEmpty}
      isDataLoaded={!isLoadingInitialTimeout}
      moduleId={moduleId}
      myFilterInput={myFilterInput}
      myTodos={myTodos}
      myTasks={myTasks}
      setMyFilterInput={setMyFilterInput}
    />
  );
};

export default HomeContainer;
