import dayjs from 'dayjs';
import { refreshCompanyToken } from '../modules/Company/operations';
import { get } from 'lodash-es';

const PayloadHandler = store => next => action => {
  if (action.type.endsWith('FAILED')) {
    console.info(
      get(action, 'type', 'failed to get type on fail'),
      get(action, 'payload', 'Failed to get payload on fail'),
    );
    if (action.payload.response && action.payload.response.error) {
      action.payload = action.payload.response.error;
    } else if (action.payload.response) {
      action.payload = action.payload.response;
    } else {
      action.payload = 'There was a network error.';
    }
  } else if (action.type.endsWith('SUCCESS')) {
    if (action.payload.payload && action.payload.success) {
      action.payload = action.payload.payload;
    }
  } else if (action.error) {
    action.type = action.type.replace(/(?:REQUEST|SUCCESS)$/, 'FAILED');
  }

  return next(action);
};

export const myInterceptor =
  ({ dispatch, getState }) =>
  next =>
  async action => {
    const endpoint = get(action, '[@@redux-api-middleware/RSAA].endpoint', '');
    if (
      endpoint &&
      !endpoint.includes('authn') &&
      !endpoint.includes('public') &&
      !endpoint.includes('unlisted') &&
      !endpoint.includes('user')
    ) {
      let {
        Auth: {
          account: { accountId, exp, urlCompanyId },
          isInitLoading,
        },
        Company: { isFetching },
      } = getState();
      if (
        !isInitLoading &&
        !isFetching &&
        accountId &&
        dayjs().isAfter(dayjs.unix(exp))
      ) {
        await dispatch(refreshCompanyToken(accountId));
      } else if (!isInitLoading && !isFetching && !accountId && Number(urlCompanyId)) {
        await dispatch(refreshCompanyToken(urlCompanyId));
      }
    }
    return await next(action);
  };

export default PayloadHandler;
