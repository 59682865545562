import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import { InlineInputIcon, InputFeedback, InputHelp, InputSublabel } from '../';

import './InputTextField.scss';

const TextField = forwardRef(
  (
    {
      autoComplete,
      customClass,
      defaultPlaceholder,
      error,
      handleOnCopyClick,
      icon,
      inputClassName,
      isCopyable,
      isDataHidden,
      isDisabled,
      isFetching,
      isFixed,
      isRequired,
      questionHelp,
      label,
      name,
      onFocus,
      onBlur,
      onChange,
      placeholder,
      sublabel,
      type,
      value,
    },
    ref,
  ) => {
    const groupClasses = classnames(
      customClass,
      'workbench-form__group',
      'text-field__wrapper',
      {
        'workbench-form__group--has-icon form__group--has-icon-left': !!icon,
        'workbench-form__group--has-error': !!error,
        'workbench-form__group--is-hidden': isDataHidden,
      },
    );
    let updatedVal = value;
    if (updatedVal === null || updatedVal === undefined) {
      updatedVal = '';
    }

    return (
      <div className={groupClasses}>
        {label && (
          <label className="control__label workbench__label" htmlFor={name}>
            {label}
            {!!isRequired && <span className="workbench-form__required">*</span>}
            {questionHelp && <InputHelp text={questionHelp} />}
          </label>
        )}
        <div
          className={classnames(
            {
              'is-copyable': isCopyable,
            },
            'workbench-form__input-group text-field__input-group',
          )}
          onClick={handleOnCopyClick}
        >
          <input
            autoComplete={autoComplete}
            disabled={isFixed || isDisabled}
            data-lpignore={isDataHidden ? 'true' : 'false'}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                onBlur(e);
              }
            }}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            ref={ref}
            type={type}
            value={updatedVal}
            name={name}
            placeholder={placeholder || defaultPlaceholder}
            className={classnames(
              inputClassName,
              {
                'is-copyable': isCopyable,
              },
              'my-form__control text-field__control',
            )}
          />
          <InlineInputIcon icon={icon} isFetching={isFetching} />
          <InputSublabel text={sublabel} />
          <InputFeedback error={error} />
        </div>
      </div>
    );
  },
);

TextField.propTypes = {
  isDisabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  type: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.array]),
  inputClassName: PropTypes.string,
  isRequired: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  sublabel: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

TextField.defaultProps = {
  autoComplete: 'on',
  customClass: '',
  isDisabled: false,
  error: null,
  handleOnCopyClick: () => {},
  name: 'input',
  type: 'text',
  icon: false,
  isRequired: false,
  label: '',
  placeholder: '',
  sublabel: null,
  value: '',
  onFocus: () => {},
  onBlur: () => {},
  onChange: val => {},
};

export default TextField;
